
import CountryFlag from 'vue-country-flag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { I18nActions } from '../store/i18n/types';
const i18nNamespace = 'i18n';

@Component({
  components: {
    CountryFlag,
  },
})
export default class LocaleChanger extends Vue {
  @Prop()
  public textColour!: string;

  private langs = [
    {
      class: 'btnEN',
      flag: 'gb',
      lang: 'en',
      fullName: 'English',
    },
    {
      class: 'btnFR',
      flag: 'fr',
      lang: 'fr',
      fullName: 'Français',
    },
  ];

  @Action(I18nActions.SET_LOCALE, { namespace: i18nNamespace })
  private setLocale!: (locale: string) => void;

  get displayLocale(): string {
    return this.$i18n.locale;
  }
  set displayLocale(local: string) {}
}
