import { GetterTree } from 'vuex';
import { RootState } from '../types';
import {
  ReportGetters,
  ReportState,
  ReportType,
  UniqueUserReportMenuItem,
} from './types';

export const getters: GetterTree<ReportState, RootState> = {
  [ReportGetters.SELECTED_TYPE](state): ReportType | undefined {
    return state.selectedType;
  },

  [ReportGetters.UNIQUE_USER_MENU_ITEMS](): UniqueUserReportMenuItem[] {
    const items = [
      {
        title: 'Event',
        type: ReportType.UNIQUE_USER_EVENT,
      },
      {
        title: 'Posters',
        type: ReportType.UNIQUE_USER_POSTER,
      },
      {
        title: 'Sessions /  Presentations',
        type: ReportType.UNIQUE_USER_SESSION,
      },
      {
        title: 'Sponsors',
        type: ReportType.UNIQUE_USER_SPONSOR,
      },
    ];

    return items;
  },
};
