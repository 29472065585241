import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { BookmarkState } from './types';

export const state: BookmarkState = {
  bookmarks: [],
  loaded: false,
};

const namespaced = true;

export const bookmark: Module<BookmarkState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
