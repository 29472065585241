import { MutationTree } from 'vuex';
import { Platform } from '../../models/Platform';
import { PlatformMutations, PlatformState } from './types';

export const mutations: MutationTree<PlatformState> = {
  [PlatformMutations.SET_PLATFORM_STATE](state, platformState: Platform): void {
    state.allowAbstractDownload = platformState.allowAbstractDownload;
    state.allowPresentationDownload = platformState.allowPresentationDownload;
    state.alwaysUseDefaultThumbnail = platformState.alwaysUseDefaultThumbnail;
    state.bannerBarBackgroundColour = platformState.bannerBarBackgroundColour;
    state.bannerBarTextColour = platformState.bannerBarTextColour;
    state.calendarIntervalHeight = platformState.calendarIntervalHeight;
    state.closedText = platformState.closedText;
    state.defaultSessionThumbnailUri = platformState.defaultSessionThumbnailUri;
    state.demandGdpr = platformState.demandGdpr;
    state.enableSessionFilterInPosterModule =
      platformState.enableSessionFilterInPosterModule;
    state.eventCode = platformState.eventCode;
    state.eventId = platformState.eventId;
    state.eventTitle = platformState.eventTitle;
    state.externalCreateAccountUri = platformState.externalCreateAccountUri;
    state.externalForgotPasswordUri = platformState.externalForgotPasswordUri;
    state.gdprAcceptText = platformState.gdprAcceptText;
    state.gdprDeclineText = platformState.gdprDeclineText;
    state.gdprText = platformState.gdprText;
    state.helpDocumentUri = platformState.helpDocumentUri;
    state.loginBackgroundImageUri = platformState.loginBackgroundImageUri;
    state.loginCardBannerClientUri = platformState.loginCardBannerClientUri;
    state.loginCardBannerImageUri = platformState.loginCardBannerImageUri;
    state.loungeImageUri = platformState.loungeImageUri;
    state.navBarBackgroundColour = platformState.navBarBackgroundColour;
    state.navBarTextColour = platformState.navBarTextColour;
    state.open = platformState.open;
    state.portalBackgroundUri = platformState.portalBackgroundUri;
    state.portalBannerClientUri = platformState.portalBannerClientUri;
    state.portalBannerUri = platformState.portalBannerUri;
    state.sendForgottenPassowrdUri = platformState.sendForgottenPassowrdUri;
    state.showKeywords = platformState.showKeywords;
    state.showLikes = platformState.showLikes;
    state.showRatings = platformState.showRatings;
    state.showViews = platformState.showViews;
    state.showPresentationDurationInProgramme = platformState.showPresentationDurationInProgramme;
    state.showAllParticipantCount = platformState.showAllParticipantCount;
    state.showSpeakerCount = platformState.showSpeakerCount;
    state.showPosterCount = platformState.showPosterCount;
    state.showVodCount = platformState.showVodCount;
    state.signInEnabled = platformState.signInEnabled;
    state.navigationBarMini = platformState.navigationBarMini ?? true;
    state.programmeModuleEnabled = platformState.programmeModuleEnabled
    state.vodModuleEnabled = platformState.vodModuleEnabled
    state.posterModuleEnabled  = platformState.posterModuleEnabled
    state.exhibitionModuleEnabled  = platformState.exhibitionModuleEnabled
    state.speakersModuleEnabled = platformState.speakersModuleEnabled
    state.allParticipantsModuleEnabled  = platformState.allParticipantsModuleEnabled
    state.liveSessionModuleEnabled = platformState.liveSessionModuleEnabled
  },
};
