import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { ReportState } from './types';

export const state: ReportState = {
  selectedType: undefined,
};

const namespaced = true;

export const report: Module<ReportState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
