import { GetterTree } from 'vuex';
import { SignalrMessage } from '../signalr/types';
import { RootState } from '../types';
import { LoungeGetters, LoungeRoom, LoungeState } from './types';

export const getters: GetterTree<LoungeState, RootState> = {
  [LoungeGetters.DRAWER](state): boolean {
    return state.drawer;
  },

  [LoungeGetters.CURRENT_LOUNGE_ROOM]: (
    state: LoungeState,
  ): LoungeRoom | undefined => {
    return state.currentLoungeRoom;
  },

  [LoungeGetters.FAVOURITE_LOUNGE_ROOMS]: (state: LoungeState) => (
    parentId: string,
  ): LoungeRoom[] => {
    const filtered = state.loungeRooms.filter(
      (lr) => lr.parentId === parentId && lr.favourite,
    );
    const res = filtered.sort((a, b) =>
      a.groupName > b.groupName ? 1 : b.groupName > a.groupName ? -1 : 0,
    );
    return res;
  },

  [LoungeGetters.FAVOURITE_MESSAGES_FOR_GROUP_ID]: (state: LoungeState) => (
    groupId: string,
  ): SignalrMessage[] => {
    return state.favouriteGroupMessages.filter((fgm) => {
      return fgm.groupId === groupId;
    });
  },

  [LoungeGetters.FILTERED_NON_FAVOURITE_LOUNGE_ROOMS]: (state: LoungeState) => (
    parentId: string,
    roomFilter: string,
  ): LoungeRoom[] => {
    const filtered = state.loungeRooms.filter((lr) => {
      if (!roomFilter) {
        return lr.parentId === parentId && !lr.favourite;
      }
      let filter = roomFilter;
      filter = filter.trim().toLowerCase();
      filter = filter.replace(/\s+/g, '-');
      filter = filter.replace(/\W/g, '');
      if (!filter || filter.length < 2) {
        return lr.parentId === parentId && !lr.favourite;
      }
      return (
        lr.parentId === parentId &&
        !lr.favourite &&
        lr.groupName.toLowerCase().includes(filter)
      );
    });
    const res = filtered.sort((a, b) =>
      a.groupName > b.groupName ? 1 : b.groupName > a.groupName ? -1 : 0,
    );
    return res;
  },

  [LoungeGetters.IS_FAVOURITE]: (state: LoungeState) => (
    groupId: string,
  ): boolean => {
    const exists = state.loungeRooms.findIndex((lr) => lr.groupId === groupId);
    if (exists > -1) {
      return true;
    }
    return false;
  },

  [LoungeGetters.LOUNGE_ROOM_BY_GROUP_ID]: (state: LoungeState) => (
    groupId: string,
  ): LoungeRoom | undefined => {
    return state.loungeRooms.find((lr) => lr.groupId === groupId);
  },

  [LoungeGetters.LOUNGE_ROOM_BY_PARENT_ID_AND_GROUP_NAME]: (
    state: LoungeState,
  ) => (parentId: string, groupName: string): LoungeRoom | undefined => {
    return state.loungeRooms.find(
      (lr) => lr.parentId === parentId && lr.groupName === groupName,
    );
  },

  [LoungeGetters.REACHED_FAVOURITE_LIMIT](state: LoungeState): boolean {
    return (
      state.loungeRooms.filter((r) => r.favourite).length >=
      state.favouriteLoungeRoomLimit
    );
  },

  [LoungeGetters.LOUNGE_ROOM_LOADING](state: LoungeState): boolean {
    return state.loungeRoomLoading;
  },

  [LoungeGetters.LOUNGE_ROOM_LIST_LOADING](state: LoungeState): boolean {
    return state.loungeRoomListLoading;
  },
};
