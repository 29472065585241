
import moment from 'moment';
import {
  BookmarkActions,
  BookmarkEntityType,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '@/store/bookmark/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { PlatformGetters } from '../store/platform/types';
import { SessionItem } from '../store/session/types';
import { AuthGetters } from '@/store/auth/types';

const authNamespace = 'auth';
const bookmarkNamespace = 'bookmark';
const platformNamespace = 'platform';

@Component({})
export default class SessionCard extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public session!: SessionItem;

  @Prop()
  public textColourOverride!: string;

  @Prop()
  public clickOverride!: boolean;

  @Prop()
  public homeVod!: boolean;

  @Prop()
  public related!: boolean;

  @Prop()
  public listView!: boolean;

  /* PRIVATE PROPERTIES */
  private thumbnailError = false;
  private defaultThumbnailError = false;

  /* VUEX GETTERS */
  // Auth Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Bookmark Getters
  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  @Getter(BookmarkGetters.IS_A_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private isABookmark!: (entityId: string) => boolean;

  // Platform Getters
  @Getter(PlatformGetters.NAV_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarTextColour!: string;

  @Getter(PlatformGetters.NAV_BAR_BACKGROUND_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarBackgroundColour!: string;
  @Getter(PlatformGetters.SHOW_VIEWS, {
    namespace: platformNamespace,
  })
  private showViews!: boolean;

  @Getter(PlatformGetters.SHOW_RATINGS, {
    namespace: platformNamespace,
  })
  private showRatings!: boolean;

  @Getter(PlatformGetters.SHOW_LIKES, {
    namespace: platformNamespace,
  })
  private showLikes!: boolean;

  @Getter(PlatformGetters.SHOW_KEYWORDS, {
    namespace: platformNamespace,
  })
  private showKeywords!: boolean;

  @Getter(PlatformGetters.ALWAYS_USER_DEFAULT_THUMBNAIL, {
    namespace: platformNamespace,
  })
  private alwaysUseDefaultThumbnail!: boolean;

  @Getter(PlatformGetters.DEFAULT_SESSION_THUMBNAIL_URI, {
    namespace: platformNamespace,
  })
  private defaultSessionThumbnailUri!: string;





  /* VUEX ACTIONS */
  // Bookmark Actions
  @Action(BookmarkActions.ADD_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private bookmarkItem!: (bookmark: BookmarkItem) => Promise<void>;

  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get bannerImage(): string {
    if (
      this.homeVod &&
      !this.thumbnailError &&
      this.session &&
      this.session.homeVodThumbnailUri &&
      this.session.homeVodThumbnailUri.trim()
    ) {
      return this.session.homeVodThumbnailUri;
    } else if (
      !this.thumbnailError &&
      !this.alwaysUseDefaultThumbnail &&
      this.session &&
      this.session.thumbnailUri &&
      this.session.thumbnailUri.trim()
    ) {
      return this.session.thumbnailUri;
    } else if (!this.defaultThumbnailError && this.defaultSessionThumbnailUri) {
      return this.defaultSessionThumbnailUri;
    } else {
      return 'https://slidetotal.azureedge.net/slidetotal/defaultsession.png';
    }
  }

  get cardMinWidth(): number {
      let w = 414;
      if (this.homeVod && this.$vuetify.breakpoint.mobile)
      {
        return window.innerWidth * 0.75;
      } else {
        return window.innerWidth < 414 ? window.innerWidth * 0.95 : 414;
      }
  }

  /* PRIVATE METHODS*/
  private bookmarkExecute(s: SessionItem) {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const bi = this.getBookmark(s.id);
    if (bi) {
      this.unbookmarkItem(bi);
    } else {
      let t = '';
      let et = '';
      let eu = '';
      if (s.isAPoster) {
        t = BookmarkType.POSTER;
        et = BookmarkEntityType.POSTER;
        eu = `/${eventCode}/session/${s.sessionId}/presentation/${s.id}`;
      } else if (s.isPresentation) {
        t = BookmarkType.VIDEO;
        et = BookmarkEntityType.PRESENTATION;
        eu = `/${eventCode}/session/${s.sessionId}/presentation/${s.id}`;
      } else {
        t = BookmarkType.VIDEO;
        et = BookmarkEntityType.SESSION;
        eu = `/${eventCode}/session/${s.id}`;
      }

      const bm: BookmarkItem = {
        end: s.end,
        entityId: s.id,
        entityType: et,
        type: t,
        entityUri: eu,
        start: s.start,
        title: s.title,
      };
      this.bookmarkItem(bm);
    }
  }

  // '25/05/20 09:00-10:00',
  private formatStartEnd(start: Date, end: Date) {
    const s = moment(start).format('DD/MM/YYYY HH:mm');
    const e = moment(end).format('HH:mm');
    return `${s}-${e}`;
  }

  private handleImageError() {
    if (!this.thumbnailError) {
      this.thumbnailError = true;
    } else if (!this.defaultThumbnailError) {
      this.defaultThumbnailError = true;
    }
  }

  private navigateToSpeaker(pres: SessionItem) {
    if (!pres.activeSpeakerName) return;

    if (!pres.activeSpeakerId) return;

    this.$router.push({
      name: 'speakers',
      query: {
        search: pres.activeSpeakerName,
        id: pres.activeSpeakerId,
        speaker: 'true',
      },
    });
  }

  private async openNewTab(url: string | undefined) {
    if (url && (url.startsWith('https:') || url.startsWith('http:'))) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else if (url) {
      await this.$router.push(url);
    }
  }

  private sessionActive(session: SessionItem): boolean {
    if (!session) return false;
    if (
      this.validVideoIFrameUri(session.videoIFrameUri) ||
      (session.isExternalContentSession && session.externalContentUri) ||
      (session.isAPoster && session.posterUploadUri) ||
      (session.isAPoster &&
        session.isExternalContentSession &&
        session.externalContentUri)
    ) {
      return true;
    }
    return false;
  }

  private async sessionSelected(session: SessionItem) {
    if (!session || this.clickOverride) return;
    if (session.isExternalContentSession && session.externalContentUri) {
      this.openNewTab(session.externalContentUri);
    } else if (this.sessionActive(session)) {
      if (session.isSession) {
        const sessionId = session.id;
        await this.$router.push({
          name: 'session',
          params: { sessionId },
        });
      } else if (session.isPresentation) {
        const presentationId = session.id;
        const sessionId = session.sessionId;
        await this.$router.push({
          name: 'presentation',
          params: { sessionId, presentationId },
        });
      }
    } else {
      this.$emit('nocontent');
    }
  }

  private validVideoIFrameUri(uri?: string): boolean {
    if (uri && uri.toLowerCase().startsWith('https:')) {
      return true;
    }

    return false;
  }

  get textColour(): string {
    if (this.textColourOverride) {
      return this.textColourOverride;
    }
    return '#030303';
  }
}
