
import ConversationBox from '@/components/ConversationBox.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import {
  ConversationGetters,
  ConversationItem,
} from '../store/conversation/types';
const conversationNamespace = 'conversation';

@Component({
  components: {
    ConversationBox,
  },
})
export default class CoversationBar extends Vue {
  @Getter(ConversationGetters.CONVERSATIONS_OPEN, {
    namespace: conversationNamespace,
  })
  private conversationsOpen!: ConversationItem[];
}
