import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify, {
  VAlert,
  VApp,
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCol,
  VContainer,
  VDataTable,
  VFooter,
  VIcon,
  VImg,
  VLayout,
  VList,
  VNavigationDrawer,
  VProgressLinear,
  VRow,
  VSkeletonLoader,
  VSpacer,
  VTab,
  VTabs,
  VTabsItems,
  VTabItem,
  VToolbar,
  VToolbarTitle,
} from 'vuetify/lib';

// vue-cli a-la-carte installation
Vue.use(Vuetify, {
  components: {
    VAlert,
    VApp,
    VBtn,
    VCard,
    VCardActions,
    VCardText,
    VCol,
    VContainer,
    VDataTable,
    VFooter,
    VIcon,
    VImg,
    VLayout,
    VList,
    VNavigationDrawer,
    VProgressLinear,
    VRow,
    VSkeletonLoader,
    VSpacer,
    VTab,
    VTabs,
    VTabsItems,
    VTabItem,
    VToolbar,
    VToolbarTitle,
  },
});

const opts = {
  theme: {
    themes: {
      light: {
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        primary: '#1976D2',
        secondary: '#424242',
        success: '#4CAF50',
      },
      // dark: {
      // }
    },
  },
};

export default new Vuetify(opts);
