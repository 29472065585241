import { ActionTree } from 'vuex';
import { LoungeActions } from '../lounge/types';
import { SessionActions, SessionQuestionItem } from '../session/types';
import { RootState } from '../types';
import {
  SignalrActions,
  SignalrGetters,
  SignalrGroup,
  SignalrGroupTypes,
  SignalrMessage,
  SignalrMutations,
  SignalrState,
} from './types';

export const actions: ActionTree<SignalrState, RootState> = {
  /* Connection */
  // connectionOpened
  [SignalrActions.SIGNALR_CONNECTED]({ commit }): void {
    commit(SignalrMutations.SET_CONNECTION_STATE, true);
  },
  // connectionClosed
  [SignalrActions.SIGNALR_DISCONNECTED]({ commit }): void {
    commit(SignalrMutations.SET_CONNECTION_STATE, false);
  },
  // userConnected
  [SignalrActions.USER_CONNECTED]({ commit }): void {
    commit(SignalrMutations.SET_USER_CONNECTED_STATE, true);
  },
  // userDisconnected
  [SignalrActions.USER_DISCONNECTED]({ commit }): void {
    commit(SignalrMutations.SET_USER_CONNECTED_STATE, false);
  },
  // connectionError
  [SignalrActions.SIGNALR_ERROR]({ commit }, error): void {
    commit(SignalrMutations.SET_CONNECTION_ERROR, error);
  },

  [SignalrActions.CONNECT_SIGNALR](): void {
    console.assert(true);
  },
  [SignalrActions.CONNECT_USER](): void {
    console.assert(true);
  },
  logOff(): void {
    console.assert(true);
  },

  /* Enter room */
  [SignalrActions.ENTER_GROUP](_, msg: SignalrMessage): void {
    console.assert(msg);
  },

  [SignalrActions.ENTERED_GROUP]({ commit }, msg: SignalrMessage): void {
    if (msg.isCurrent) {
      commit(SignalrMutations.CLEAR_CURRENT_GROUP_MESSAGES);
      commit(SignalrMutations.CLEAR_CURRRENT_GROUP);
      this.dispatch(`lounge/${LoungeActions.CLEAR_CURRENT_LOUNGE_ROOM}`, {
        root: true,
      });

      const enteredGroup: SignalrGroup = {
        group: msg.group,
        groupCount: msg.groupCount ? msg.groupCount : 0,
        groupId: msg.groupId,
        groupType: msg.groupType,
        unreadCount: 0,
      };
      commit(SignalrMutations.SET_CURRENT_GROUP, enteredGroup);
      if (
        msg.groupType === SignalrGroupTypes.LOUNGE ||
        msg.groupType === SignalrGroupTypes.SPONSOR_LOUNGE
      ) {
        this.dispatch(
          `lounge/${LoungeActions.UPDATE_LOUNGE_ROOM_COUNT}`,
          { groupId: msg.groupId, count: msg.groupCount },
          { root: true },
        );
        this.dispatch(
          `lounge/${LoungeActions.SET_CURRENT_LOUNGE_ROOM}`,
          msg.groupId,
          { root: true },
        );
      }
    }
  },

  [SignalrActions.USER_ENTERED_LEFT_ROOM](
    { commit, getters },
    msg: SignalrMessage,
  ): void {
    const currentGroup: SignalrGroup = getters[SignalrGetters.CURRENT_GROUP];
    if (currentGroup.groupId === msg.groupId) {
      commit(SignalrMutations.UPDATE_CURRENT_ROOM_COUNT, msg.groupCount);
    }

    if (
      msg.groupType === SignalrGroupTypes.LOUNGE ||
      msg.groupType === SignalrGroupTypes.SPONSOR_LOUNGE
    ) {
      this.dispatch(
        `lounge/${LoungeActions.UPDATE_LOUNGE_ROOM_COUNT}`,
        { groupId: msg.groupId, count: msg.groupCount },
        { root: true },
      );
    }
  },

  [SignalrActions.UPDATE_CURRENT_ROOM_COUNT]({ commit }, count: number): void {
    commit(SignalrMutations.UPDATE_CURRENT_ROOM_COUNT, count);
  },

  [SignalrActions.SET_MESSAGES]({ commit }, msgs: SignalrMessage[]): void {
    msgs.forEach((m) => {
      commit(SignalrMutations.ADD_MESSAGE_TO_CURRENT_GROUP, m);
    });
  },

  /* Leave room */
  [SignalrActions.LEAVE_GROUP](_, msg: SignalrMessage): void {
    console.assert(msg);
  },

  [SignalrActions.LEFT_GROUP]({ commit, getters }, msg: SignalrMessage): void {
    if (
      msg.groupType === SignalrGroupTypes.LOUNGE_FOYER ||
      msg.groupType === SignalrGroupTypes.SPONSOR_LOUNGE_FOYER
    ) {
      return;
    }
    const leftGroup: SignalrGroup = {
      group: msg.group,
      groupCount: msg.groupCount ? msg.groupCount : 0,
      groupId: msg.groupId,
      groupType: msg.groupType,
      unreadCount: 0,
    };

    const currentGroup: SignalrGroup = getters[SignalrGetters.CURRENT_GROUP];
    if (currentGroup.groupId === leftGroup.groupId) {
      commit(SignalrMutations.CLEAR_CURRENT_GROUP_MESSAGES);
      commit(SignalrMutations.CLEAR_CURRRENT_GROUP);
    }

    if (
      msg.groupType === SignalrGroupTypes.LOUNGE ||
      msg.groupType === SignalrGroupTypes.SPONSOR_LOUNGE
    ) {
      this.dispatch(
        `lounge/${LoungeActions.UPDATE_LOUNGE_ROOM_COUNT}`,
        { groupId: msg.groupId, count: msg.groupCount },
        { root: true },
      );
    }
  },

  /* Message Handling */
  [SignalrActions.POST_CHAT_MESSAGE](_, msg: SignalrMessage): void {
    console.assert(msg);
  },

  [SignalrActions.CHAT_POSTED]({ commit, getters }, msg: SignalrMessage): void {
    const currentGroup: SignalrGroup = getters[SignalrGetters.CURRENT_GROUP];
    if (currentGroup.groupId === msg.groupId) {
      commit(SignalrMutations.ADD_MESSAGE_TO_CURRENT_GROUP, msg);
    }
  },

  [SignalrActions.CLEAR_CURRRENT_GROUP]({ commit }): void {
    commit(SignalrMutations.CLEAR_CURRRENT_GROUP);
  },

  [SignalrActions.CLEAR_CURRENT_GROUP_MESSAGES]({ commit }): void {
    commit(SignalrMutations.CLEAR_CURRENT_GROUP_MESSAGES);
  },

  [SignalrActions.REMOVE_CHAT_MESSAGE](_, msg: SignalrMessage): void {
    console.assert(msg);
  },

  [SignalrActions.CHAT_REMOVED](
    { commit, getters },
    msg: SignalrMessage,
  ): void {
    const exists = getters[SignalrGetters.CURRENT_MESSAGE_BY_ID](msg.id);
    if (exists) {
      commit(SignalrMutations.REMOVE_MESSAGE_FROM_CURRENT_GROUP, exists);
    }

    // TODO: UPDATE FAVOURITE LOUNGE ROOMS
  },

  [SignalrActions.SET_CURRENT_GROUP_MESSAGES](
    { commit },
    msgs: SignalrMessage[],
  ): void {
    commit(SignalrActions.SET_CURRENT_GROUP_MESSAGES, msgs);
  },

  /* Question Handling */

  [SignalrActions.QUESTION_POSTED](_, question: SessionQuestionItem): void {
    this.dispatch(`session/${SessionActions.ADD_NEW_QUESTION}`, question, {
      root: true,
    });
  },

  [SignalrActions.QUESTION_REPLY_POSTED](_, reply: SessionQuestionItem): void {
    this.dispatch(`session/${SessionActions.ADD_NEW_QUESTION_REPLY}`, reply, {
      root: true,
    });
  },

  [SignalrActions.QUESTION_APPROVED](_, questionId: string): void {
    this.dispatch(`session/${SessionActions.APPROVE_QUESTION}`, questionId, {
      root: true,
    });
  },

  [SignalrActions.QUESTION_HIDDEN](_, questionId: string): void {
    this.dispatch(`session/${SessionActions.HIDE_QUESTION}`, questionId, {
      root: true,
    });
  },
};
