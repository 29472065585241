import router from '@/router';
import { AppInsightsLogger } from '@/services/appInsightsLogger';
import { AuthService } from '@/services/auth/authService';
import Vue from 'vue';
import { ActionTree } from 'vuex';
// import i18n from '../../plugins/i18n';
import { AuthActions } from '../auth/types';
import { LoungeActions } from '../lounge/types';
import { SignalrActions, SignalrGroupTypes } from '../signalr/types';
import { RootState } from '../types';
import {
  PlatformUserActions,
  PlatformUserGetters,
  PlatformUserItem,
  PlatformUserMutations,
  PlatformUserState,
} from './types';

export const actions: ActionTree<PlatformUserState, RootState> = {
  [PlatformUserActions.CLEAR_CURRENT_PLATFORM_USER]({ commit }): void {
    commit(PlatformUserMutations.SET_CURRENT_PLATFORM_USER, null);
    this.dispatch(`auth/${AuthActions.SET_IS_AUTHENTICATED}`, false, {
      root: true,
    });
  },

  async [PlatformUserActions.IS_PLATFORM_MODERATOR](): Promise<boolean> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get(
        `/api/platform/${platformId}/can/platfrommod`,
      );
      return res.data;
    } catch (e: any) {
      AppInsightsLogger.logError(
        'PlatformUser Action - isPlatformModerator failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
      return false;
    }
  },

  async [PlatformUserActions.IS_LOUNGE_MODERATOR](): Promise<boolean> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get(
        `/api/platform/${platformId}/can/loungemod`,
      );
      return res.data;
    } catch (e: any) {
      AppInsightsLogger.logError(
        'PlatformUser Action - isLoungeModerator failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
      return false;
    }
  },

  async [PlatformUserActions.IS_PRESENTATION_MODERATOR](
    _,
    presentationId: string,
  ): Promise<boolean> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get(
        `/api/platform/${platformId}/can/presentationMod/${presentationId}`,
      );
      return res.data;
    } catch (e: any) {
      AppInsightsLogger.logError(
        'PlatformUser Action - isPresentationModerator failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
      return false;
    }
  },

  async [PlatformUserActions.IS_SESSION_MODERATOR](
    _,
    sessionId: string,
  ): Promise<boolean> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get(
        `/api/platform/${platformId}/can/sessionmod/${sessionId}`,
      );
      return res.data;
    } catch (e: any) {
      AppInsightsLogger.logError(
        'PlatformUser Action - isSessionModerator failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
      return false;
    }
  },

  async [PlatformUserActions.IS_SPONSOR_LOUNGE_MODERATOR](
    _,
    sponsorId: string,
  ): Promise<boolean> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get(
        `/api/platform/${platformId}/can/sponsormod/${sponsorId}`,
      );
      return res.data;
    } catch (e: any) {
      AppInsightsLogger.logError(
        'PlatformUser Action - isSponsorLoungeModerator failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
      return false;
    }
  },

  async [PlatformUserActions.IS_SPONSOR_MODERATOR](
    _,
    sponsorId: string,
  ): Promise<boolean> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get(
        `/api/platform/${platformId}/can/sponsorMod/${sponsorId}`,
      );
      return res.data;
    } catch (e: any) {
      AppInsightsLogger.logError(
        'PlatformUser Action - isSponsorModerator failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
      return false;
    }
  },

  async [PlatformUserActions.LOAD_CURRENT_PLATFORM_USER]({
    commit,
  }): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const userId = localStorage.getItem('userId');
    try {
      const res = await Vue.$http.get(
        `/api/platform/${platformId}/users/${userId}`,
      ); // get User dets
      commit(PlatformUserMutations.SET_CURRENT_PLATFORM_USER, res.data);
      this.dispatch(`auth/${AuthActions.SET_IS_AUTHENTICATED}`, true, {
        root: true,
      });
      this.dispatch(`auth/${AuthActions.SET_LOGIN_IN_ERROR}`, '', {
        root: true,
      });
    } catch (error: any) {
      AppInsightsLogger.logError(
        'PlatformUser Action - loadCurrentPlatformUser failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(error, false);
      const eventCode = sessionStorage.getItem('eventCode') ?? '';
      AuthService.logOut(false);
      await router.push({ name: 'login', params: { eventCode } }).catch((e) => {
        AppInsightsLogger.logError(
          'PlatformUser Action - loadCurrentPlatformUser push to loign failed',
          undefined,
          true,
        );
        AppInsightsLogger.logException(e, false);
      });
      // const msg = i18n.t('errors.accountNotRegisted', i18n.locale);
      // this.dispatch(`auth/${AuthActions.SET_LOGIN_IN_ERROR}`, msg, {
      //   root: true,
      // });
    }
  },

  async [PlatformUserActions.LOAD_PLATFORM_USER](
    { commit },
    userId: string,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get(
        `/api/platform/${platformId}/users/${userId}`,
      ); // get User dets
      commit(PlatformUserMutations.SET_VISIBLE_PLATFORM_USER, res.data);
      commit(PlatformUserMutations.SET_PLATFORM_USER_CARD_VISIBILITY, true);
    } catch (e: any) {
      AppInsightsLogger.logError(
        'PlatformUser Action - loadPlatformUser failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  },

  async [PlatformUserActions.LOAD_PLATFORM_USERS_FOR_GROUP](
    { commit },
    { groupId, groupType }: { groupId: string; groupType: string },
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    commit(PlatformUserMutations.SET_PLATFORM_USER_LIST_VISIBILITY, true);
    commit(PlatformUserMutations.SET_PLATFORM_USER_LOADING, true);
    try {
      const res = await Vue.$http.get(
        `/api/platform/${platformId}/report/${groupId}/usersin`,
      );
      commit('setVisiblePlatformUserList', res.data);

      if (
        groupType === SignalrGroupTypes.SESSION ||
        groupType === SignalrGroupTypes.SPONSOR
      ) {
        this.dispatch(
          `signalr/${SignalrActions.UPDATE_CURRENT_ROOM_COUNT}`,
          res.data.length,
          { root: true },
        );
      }

      if (
        groupType === SignalrGroupTypes.LOUNGE ||
        groupType === SignalrGroupTypes.SPONSOR_LOUNGE
      ) {
        this.dispatch(
          `lounge/${LoungeActions.UPDATE_LOUNGE_ROOM_COUNT}`,
          { groupId, count: res.data.length },
          { root: true },
        );
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'PlatformUser Action - loadPlatformUsersForGroup failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    commit(PlatformUserMutations.SET_PLATFORM_USER_LOADING, false);
  },

  async [PlatformUserActions.SET_CURRENT_PAGE](
    { commit },
    page: number,
  ): Promise<void> {
    commit(PlatformUserMutations.SET_CURRENT_PAGE, page);
  },

  [PlatformUserActions.SET_CURRENT_PLATFORM_USER_PHOTO_URI](
    { commit },
    uri: string,
  ): void {
    commit(PlatformUserMutations.SET_CURRENT_PLATFORM_USER_PHOTO_URI, uri);
  },

  [PlatformUserActions.SET_PLATFORM_USER_CARD_POSITION](
    { commit },
    { x, y }: { x: number; y: number },
  ): void {
    commit(PlatformUserMutations.SET_PLATFORM_USER_CARD_POSITION, { x, y });
  },

  [PlatformUserActions.SET_PLATFORM_USER_CARD_VISIBILITY](
    { commit },
    visible: boolean,
  ): void {
    commit(PlatformUserMutations.SET_PLATFORM_USER_CARD_VISIBILITY, visible);
  },

  [PlatformUserActions.SET_PLATFORM_USER_DATA](
    { commit },
    data: { sessions: PlatformUserItem[]; totalSessionCount: number },
  ): void {
    commit(PlatformUserMutations.SET_PLATFORM_USER_DATA, data);
  },

  [PlatformUserActions.SET_PLATFORM_USER_LIST_POSITION](
    { commit },
    { x, y }: { x: number; y: number },
  ): void {
    commit(PlatformUserMutations.SET_PLATFORM_USER_LIST_POSITION, { x, y });
  },

  [PlatformUserActions.SET_PLATFORM_USER_LIST_VISIBILITY](
    { commit },
    visible: boolean,
  ): void {
    commit(PlatformUserMutations.SET_PLATFORM_USER_LIST_VISIBILITY, visible);
  },

  [PlatformUserActions.SET_PLATFORM_USER_LOADING](
    { commit },
    loading: boolean,
  ): void {
    commit(PlatformUserMutations.SET_PLATFORM_USER_LOADING, loading);
  },

  [PlatformUserActions.SET_PLATFORM_USERS_LOADING](
    { commit },
    loading: boolean,
  ): void {
    commit(PlatformUserMutations.SET_PLATFORM_USERS_LOADING, loading);
  },

  async [PlatformUserActions.SET_PLATFORM_USERS_SEARCH](
    { commit },
    search: string,
  ): Promise<void> {
    commit(PlatformUserActions.SET_PLATFORM_USERS_SEARCH, search);
    commit(PlatformUserActions.SET_CURRENT_PAGE, 1);
    if (search) {
      await router.push({
        query: {
          ...router.currentRoute.query,
          search,
          page: undefined,
          id: undefined,
        },
      });
    } else {
      await router.push({
        query: {
          ...router.currentRoute.query,
          search: undefined,
          page: undefined,
          id: undefined,
        },
      });
    }
  },

  async [PlatformUserActions.UPDATE_PLATFORM_USER_PROFILE](
    { commit, getters },
    {
      userId,
      organisation,
      country,
      biography,
      jobTitle,
      accepted,
    }: {
      userId: string;
      organisation: string;
      country: string;
      biography: string;
      jobTitle: string;
      accepted: boolean;
    },
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.put(
        `/api/platform/${platformId}/users/${userId}/profile`,
        { organisation, country, biography, jobTitle, accepted },
      );
      if (res.status === 201) {
        const newUser: PlatformUserItem =
          getters[PlatformUserGetters.GET_CURRENT_PLATFORM_USER];
        newUser.organisation = organisation;
        newUser.country = country;
        newUser.jobTitle = jobTitle;
        commit(PlatformUserMutations.SET_CURRENT_PLATFORM_USER, newUser);
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'PlatformUser Action - updatePlatformUserProfile failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  },

  // [PlatformUserActions.SET_PLATFORM_USER_DATA](
  //   { commit },
  //   data: { sessions: PlatformUserItem[]; totalSessionCount: number },
  // ): void {
  //   commit(PlatformUserMutations.SET_PLATFORM_USER_DATA, data);
  // },
};
