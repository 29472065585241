
import moment from 'moment';
import Avatar from '@/components/Avatar.vue';
import TweetBox from '@/components/TweetBox.vue';
import EmojiPicker from '@/components/EmojiPicker.vue';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import {
  SignalrGetters,
  SignalrGroup,
  SignalrMessage,
  SignalrMessageTypes,
} from '../store/signalr/types';
import data from '../data/emojis.json';

const signalrNamespace = 'signalr';

@Component({ components: { TweetBox, Avatar, EmojiPicker } })
export default class LiveChat extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public isMod!: boolean;

  @Prop()
  public pageLoaded!: string;

  @Prop()
  public isActive!: boolean;

  @Prop()
  public isSession!: boolean;

  @Prop()
  public stillNotConnected!: boolean;

  @Ref()
  readonly tweetbox!: TweetBox;

  /* PRIVATE PROPERTIES */
  private newChatMessageText = '';

  /* VUEX GETTERS */

  // Signalr Getters
  @Getter(SignalrGetters.CURRENT_GROUP, { namespace: signalrNamespace })
  private currentGroup!: SignalrGroup;

  @Getter(`${SignalrGetters.USER_CONNECTED}`, { namespace: signalrNamespace })
  private userConnected!: boolean;

  @Getter(SignalrGetters.CURRENT_GROUP_MESSAGES, {
    namespace: signalrNamespace,
  })
  private currentGroupMessages!: SignalrMessage[];

  /* WATCHES */
  @Watch('isActive')
  private isActiveChanged() {
    this.scrollBottom();
  }

  /* LOCAL GETTERS */

  /* PRIVATE METHODS*/
  private data() {
    return {
      data: data,
      body: ""
    };
  }

  private mounted() {
    this.scrollBottom();
  }

  private scrollBottom() {
    const e = document.getElementById('bottom');
    if (e) {
      e.scrollIntoView();
    }
  }

  private getIcon(rolename: string | undefined): string {
    if (!rolename) return '';
    let icon = '';
    switch (rolename.toLowerCase()) {
      case 'bot':
        icon = 'robot';
        break;
      case 'staff':
        icon = 'mdi-shield-star';
        break;
      case 'modAndSpeaker':
        icon = 'mdi-shield-star';
        break;
      case 'moderator':
        icon = 'mdi-shield-star';
        break;
      case 'speaker':
        icon = 'mdi-account-voice';
        break;
      default:
        icon = '';
    }
    return icon;
  }

  private getSecondIcon(rolename: string | undefined): string {
    if (!rolename) return '';
    let icon = '';
    switch (rolename.toLowerCase()) {
      case 'bot':
        icon = '';
        break;
      case 'staff':
        icon = 'mdi-account-voice';
        break;
      case 'modAndSpeaker':
        icon = 'mdi-account-voice';
        break;
      case 'moderator':
        icon = '';
        break;
      case 'speaker':
        icon = '';
        break;
      default:
        icon = '';
    }
    return icon;
  }

  getLocalTime(time: string | undefined) {
    if (time) {
      return moment.utc(time, 'HH:mm').local().format('HH:mm');
    }
  }

  private onNewMessage() {
    this.scrollBottom();
  }

  private postChatMessageExecute() {
    if (this.newChatMessageText.length > 1) {
      this.$emit('newMessage', {
        msg: this.newChatMessageText,
        type: 'postChat',
      });
      this.newChatMessageText = '';
    }
  }

  private removeChatMessageExecute(msg: SignalrMessage) {
    msg.messageType = SignalrMessageTypes.REMOVE_CHAT;
    this.$emit('newMessage', {
      msg,
      type: 'deleteChat',
    });
  }

  private postChatAsQuestionExecute(msg: SignalrMessage) {
    this.$emit('newMessage', {
      msg,
      type: 'makeChatAQuestion',
    });
  }

  private handleEmojiPicked(emoji: string) {
      this.newChatMessageText += emoji;
      this.tweetbox.textFocus();
    }

}
