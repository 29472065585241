import { MutationTree } from 'vuex';
import { BookmarkItem, BookmarkMutations, BookmarkState } from './types';

export const mutations: MutationTree<BookmarkState> = {
  [BookmarkMutations.ADD_BOOKMARK_ITEM](state, bookmark: BookmarkItem): void {
    if (bookmark) {
      state.bookmarks.push(bookmark);
    }
  },

  [BookmarkMutations.LOAD_BOOKMARKS](state, bookmarks: BookmarkItem[]): void {
    state.bookmarks = bookmarks;
  },

  [BookmarkMutations.REMOVE_BOOKMARK_ITEM](state, bookmark: BookmarkItem) {
    const index = state.bookmarks.indexOf(bookmark);
    if (index > -1) {
      state.bookmarks.splice(index, 1);
    }
  },
};
