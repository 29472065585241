
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { AuthGetters } from '../store/auth/types';
import {
  ConversationActions,
  ConversationGetters,
  ConversationItem,
} from '../store/conversation/types';
import { PlatformGetters } from '../store/platform/types';
import {
  PlatformUserGetters,
  PlatformUserItem,
} from '../store/platformUser/types';

const authNamespace = 'auth';
const conversationNamespace = 'conversation';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';

@Component({})
export default class ConversationsList extends Vue {
  /* PUBLIC PROPERTIES */
  private conversationsOpen = false;
  private foundUsers: PlatformUserItem[] = [];
  private isFindingUsers = false;
  private isFocused = false;
  private isSearching = false;
  private userSearchText = '';

  /* VUEX GETTERS */
  // Auth Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Conversation Getters
  @Getter(ConversationGetters.CONVERSATIONS, {
    namespace: conversationNamespace,
  })
  private conversations!: ConversationItem[];

  @Getter(ConversationGetters.CONVERSATIONS_UNREAD_COUNT, {
    namespace: conversationNamespace,
  })
  private unread!: number;

  @Getter(ConversationGetters.CONVERSATION_IS_OPEN, {
    namespace: conversationNamespace,
  })
  private conversationIsOpen!: (id: string) => boolean;

  // Platform Getters
  @Getter(PlatformGetters.BANNER_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private bannerBarTextColour!: string;

  // Platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  /* VUEX ACTIONS */
  @Action(ConversationActions.OPEN_CONVERSATION, {
    namespace: conversationNamespace,
  })
  private openConversation!: (conversation: ConversationItem) => void;

  @Action(ConversationActions.SET_CONVERSATIONS, {
    namespace: conversationNamespace,
  })
  private setConversations!: (conversations: ConversationItem[]) => void;

  @Action(ConversationActions.VERIFY_CONVERSATION_VISIBLE, {
    namespace: conversationNamespace,
  })
  private verifyConversationVisible!: (id: string) => void;

  /* WATCHES */
  @Watch('userSearchText')
  private userSearchTextChanged(): void {
    if (this.userSearchText && this.userSearchText.length > 2) {
      this.isSearching = true;
      this.findUsers(this.userSearchText);
    } else {
      this.isSearching = false;
      this.foundUsers = [];
    }
  }

  @Watch('conversationsOpen')
  private async conversationsOpenChanged(): Promise<void> {
    if (!this.conversationsOpen) {
      await this.loadConversationItems();
    } else {
      this.resetSearch();
    }
  }

  @Watch('isAuthenticated')
  private async isAuthenticatedChanged() {
    if (this.isAuthenticated) {
      await this.loadConversationItems();
    }
  }

  private async openConversationExecute(conversationId: string): Promise<void> {
    if (this.conversationIsOpen(conversationId)) {
      this.verifyConversationVisible(conversationId);
      return;
    }
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get<ConversationItem>(
        `/api/platform/${platformId}/conversations/${conversationId}`,
      );
      if (res && res.data) {
        this.openConversation(res.data);
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'BannerBar - openConversationExecute failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.conversationsOpen = false;
    this.resetSearch();
  }

  private async findUsers(query: string): Promise<void> {
    this.isFindingUsers = true;
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get<PlatformUserItem[]>(
        `/api/platform/${platformId}/conversations/users?q=${query}`,
      );
      if (res && res.data) {
        this.foundUsers = res.data;
      } else {
        this.foundUsers = [];
      }
    } catch (e: any) {
      this.foundUsers = [];
      AppInsightsLogger.logError(
        'BannerBar - openConversationExecute failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.isFindingUsers = false;
  }

  private async openNewConverstation(user2: string): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const user1 = this.currentUserId;
    try {
      const convo = { eventId: platformId, user1, user2 };
      const res = await Vue.$http.post(
        `/api/platform/${platformId}/conversations/new`,
        convo,
      );
      const conversationId = res.data.conversationId;
      const item = await Vue.$http.get<ConversationItem>(
        `/api/platform/${platformId}/conversations/${conversationId}`,
      );
      this.openConversation(item.data);
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - openNewConverstation failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.conversationsOpen = false;
    this.resetSearch();
  }

  private async loadConversationItems(): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get<ConversationItem[]>(
        `/api/platform/${platformId}/conversations`,
      );
      if (res && res.data) {
        this.setConversations(res.data);
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'BannerBar - loadConversationItems',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private async mounted() {
    await this.loadConversationItems();
  }

  private onBlur(): void {
    // this.resetSearch();
  }

  private onFocus(): void {
    this.isFocused = true;
  }

  private onEsc(): void {
    (this.$refs.search as HTMLElement).blur();
  }

  private resetSearch(): void {
    this.isFocused = false;
    this.foundUsers = [];
    this.userSearchText = '';
    this.isFindingUsers = false;
    this.isSearching = false;
  }
}
