import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { SignalrState } from './types';

export const state: SignalrState = {
  connectionError: '',
  currentGroup: undefined,
  currentGroupMessages: [],
  maxMessageStorelimit: 100,
  signalrConnected: false,
  userConnected: false,
};

const namespaced = true;

export const signalr: Module<SignalrState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
