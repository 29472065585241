import { AppInsightsLogger } from '@/services/appInsightsLogger';
import Vue from 'vue';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { PlatformUserGetters, PlatformUserItem } from '../platformUser/types';
import { RootState } from '../types';

/* **************** */
/* TYPES            */
/* **************** */
export interface NavigationLog {
  eventId: string;
  eventCode: string;
  userId?: string;
  userName?: string;
  firstName?: string;
  lastName?: string;
  from: string;
  to: string;
}

export interface TraceLog {
  eventId: string;
  eventCode: string;
  userId?: string;
  userName?: string;
  firstName?: string;
  lastName?: string;
  page: string;
  entityId1?: string;
  entityId2?: string;
  title: string;
}

export interface LogState {
  entityId1?: string;
  entityId2?: string;
  page: string;
  title: string;
  flag: boolean;
}

/* **************** */
/* ACTIONS          */
/* **************** */
// Action enums
export enum LogActions {
  SEND_TRACE_LOG = 'SEND_TRACE_LOG',
  SEND_NAVIGATION_LOG = 'NAVIGATION_LOG',
  SET_TRACE_LOG_STATE = 'SET_TRACE_LOG_STATE',
  SET_TRACE_LOG_STATE_TITLE = 'SET_TRACE_LOG_STATE_TITLE',
}

// Action methods
export const actions: ActionTree<LogState, RootState> = {
  async [LogActions.SEND_TRACE_LOG]({ state, rootGetters }): Promise<void> {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const currentUser: PlatformUserItem | undefined =
      rootGetters[
        `platformUser/${PlatformUserGetters.GET_CURRENT_PLATFORM_USER}`
      ];

    if (eventCode && platformId) {
      const log: TraceLog = {
        eventId: platformId,
        eventCode,
        userId: currentUser?.userId,
        userName: currentUser?.userName,
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
        page: state.page,
        entityId1: state.entityId1,
        entityId2: state.entityId2,
        title: state.title,
      };

      const uri = `/api/platform/${platformId}/log/trace`;
      try {
        await Vue.$http.post(uri, log);
      } catch (e: any) {
        AppInsightsLogger.logError('Log Action - Trace log failed', log, true);
        AppInsightsLogger.logException(e, false);
      }
    }
  },

  async [LogActions.SEND_NAVIGATION_LOG](
    { rootGetters },
    { from, to }: { from: string; to: string },
  ): Promise<void> {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const currentUser: PlatformUserItem =
      rootGetters[
        `platformUser/${PlatformUserGetters.GET_CURRENT_PLATFORM_USER}`
      ];
    if (eventCode && platformId) {
      const log: NavigationLog = {
        eventId: platformId,
        eventCode,
        userId: currentUser?.userId,
        userName: currentUser?.userName,
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
        from,
        to,
      };

      const uri = `/api/platform/${platformId}/log/navigation`;
      try {
        await Vue.$http.post(uri, log);
      } catch (e: any) {
        AppInsightsLogger.logError(
          'Log Action - Navigation log failed',
          log,
          true,
        );
        AppInsightsLogger.logException(e, false);
      }
    }
  },

  [LogActions.SET_TRACE_LOG_STATE](
    { commit },
    {
      entityId1,
      entityId2,
      page,
      title,
    }: { page: string; title: string; entityId1?: string; entityId2?: string },
  ): void {
    commit(LogMutations.SET_TRACE_LOG_STATE, {
      entityId1,
      entityId2,
      page,
      title,
    });
  },

  [LogActions.SET_TRACE_LOG_STATE_TITLE]({ commit }, title: string): void {
    commit(LogMutations.SET_TRACE_LOG_STATE_TITLE, title);
  },
};

/* **************** */
/* GETTERS          */
/* **************** */
// Getter enums
export enum LogGetters {
  TRACE_LOG_FLAG = 'TRACE_LOG_FlAG',
  TRACE_LOG_TITLE = 'TRACE_LOG_TITLE',
}

// Getter methods
export const getters: GetterTree<LogState, RootState> = {
  [LogGetters.TRACE_LOG_FLAG](state): boolean {
    return state.flag;
  },

  [LogGetters.TRACE_LOG_TITLE](state): string {
    return state.title;
  },
};

/* **************** */
/* MUTATIONS        */
/* **************** */
// Mutation enums
export enum LogMutations {
  SET_TRACE_LOG_STATE = 'SET_TRACE_LOG_STATE',
  SET_TRACE_LOG_STATE_TITLE = 'SET_TRACE_LOG_STATE_TITLE',
}
// Mutation methods
export const mutations: MutationTree<LogState> = {
  [LogMutations.SET_TRACE_LOG_STATE](
    state,
    {
      entityId1,
      entityId2,
      page,
      title,
    }: { entityId1: string; entityId2: string; page: string; title: string },
  ): void {
    state.entityId1 = entityId1;
    state.entityId2 = entityId2;
    state.page = page;
    state.title = title;

    if (state.title) {
      state.flag = !state.flag;
    }
  },

  [LogMutations.SET_TRACE_LOG_STATE_TITLE](state, title: string): void {
    state.title = title;
    if (state.title) {
      state.flag = !state.flag;
    }
  },
};

/* **************** */
/* MOUDULE          */
/* **************** */
export const state: LogState = {
  entityId1: '',
  entityId2: '',
  page: '',
  title: '',
  flag: false,
};

const namespaced = true;

export const log: Module<LogState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
