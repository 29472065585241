
import Avatar from '@/components/Avatar.vue';
import BiographyDialog from '@/components/BiographyDialog.vue';
import PresentationsDialog from '@/components/PresentationsDialog.vue';
import FlippingCard from '@/components/FlippingCard.vue';
import { AppInsightsLogger } from '@/services/appInsightsLogger';
import { AuthGetters } from '@/store/auth/types';
import {
  ConversationActions,
  ConversationGetters,
  ConversationItem,
} from '@/store/conversation/types';
import { PlatformGetters } from '@/store/platform/types';
import {
  PlatformUserGetters,
  PlatformUserItem,
} from '@/store/platformUser/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
const conversationNamespace = 'conversation';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';
const authNamespace = 'auth';

@Component({
  components: {
    Avatar,
    BiographyDialog,
    FlippingCard,
    PresentationsDialog,
  },
})
export default class PlatformUserCard extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public pu: PlatformUserItem | undefined;

  /* PRIVATE PROPERTIES */
  private flipped = false;
  private imageLoaded = false;

  private delayImageDisplay() {
    window.setTimeout(() => {
      this.imageLoaded = true;
    }, 1000);
  }

  /* VUEX GETTERS */
  // Authencation Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Conversation Getters
  @Getter(ConversationGetters.CONVERSATION_IS_OPEN, {
    namespace: conversationNamespace,
  })
  private conversationIsOpen!: (id: string) => boolean;

  // Platform Getters
  @Getter(PlatformGetters.NAV_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarTextColour!: string;

  @Getter(PlatformGetters.NAV_BAR_BACKGROUND_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarBackgroundColour!: string;

  // Platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  /* VUEX ACTIONS */
  // Converstation Actions
  @Action(ConversationActions.OPEN_CONVERSATION, {
    namespace: conversationNamespace,
  })
  private openConversation!: (conversation: ConversationItem) => void;

  @Action(ConversationActions.VERIFY_CONVERSATION_VISIBLE, {
    namespace: conversationNamespace,
  })
  private verifyConversationVisible!: (id: string) => void;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get cardWidth(): number {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return 320;
    } else {
      return 400;
    }
  }
  get cardHeight(): number {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return 179;
    } else {
      return 224;
    }
  }

  get avatarSize(): number {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return 60;
    } else {
      return 110;
    }
  }
  /* PRIVATE METHODS*/

  private async openNewConverstation(
    user: PlatformUserItem | undefined,
  ): Promise<void> {
    if (!user) return;
    const user2 = user.userId;
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const user1 = this.currentUserId;
    try {
      const convo = { eventId: platformId, user1, user2 };
      const res = await Vue.$http.post(
        `/api/platform/${platformId}/conversations/new`,
        convo,
      );
      const conversationId = res.data.conversationId;
      if (this.conversationIsOpen(conversationId)) {
        this.verifyConversationVisible(conversationId);
        return;
      }
      const item = await Vue.$http.get<ConversationItem>(
        `/api/platform/${platformId}/conversations/${conversationId}`,
      );
      this.openConversation(item.data);
    } catch (e: any) {
      AppInsightsLogger.logError(
        'PlatformUserCard - openNewConverstation failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }
}
