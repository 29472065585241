import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { PlatformState } from './types';

export const state: PlatformState = {
  allowAbstractDownload: false,
  allowPresentationDownload: false,
  alwaysUseDefaultThumbnail: false,
  authorisationForCommentsEnabled: false,
  bannerBarBackgroundColour: '#121212',
  bannerBarTextColour: '#FFFFFF',
  calendarIntervalHeight: '48',
  closedText: '',
  commentsEnabled: false,
  defaultSessionThumbnailUri: '',
  demandGdpr: false,
  enableSessionFilterInPosterModule: false,
  eventCode: '',
  eventId: '',
  eventTitle: '',
  externalCreateAccountUri: '',
  externalForgotPasswordUri: '',
  gdprAcceptText: '',
  gdprDeclineText: '',
  gdprText: '',
  helpDocumentUri: '',
  loginBackgroundImageUri: '',
  loginCardBannerClientUri: '',
  loginCardBannerImageUri: '',
  loungeImageUri: '',
  navBarBackgroundColour: '#121212',
  navBarTextColour: '#FFFFFF',
  open: false,
  portalBackgroundUri: '',
  portalBannerClientUri: '',
  portalBannerUri: '',
  sendForgottenPassowrdUri: '',
  showKeywords: false,
  showLikes: false,
  showRatings: false,
  showViews: false,
  showPresentationDurationInProgramme: false,
  showAllParticipantCount: false,
  showSpeakerCount: false,
  showPosterCount: false,
  showVodCount: false,
  signInEnabled: false,
  navigationBarMini: true,
  programmeModuleEnabled: false,
  vodModuleEnabled: false,
  posterModuleEnabled: false,
  exhibitionModuleEnabled: false,
  speakersModuleEnabled: false,
  allParticipantsModuleEnabled: false,
  liveSessionModuleEnabled: false
};

const namespaced = true;

export const platform: Module<PlatformState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
