

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TokenStorage } from '../services/auth/tokenStorage';
import {
  PlatformUserActions,
  PlatformUserGetters,
  PlatformUserItem,
    } from '../store/platformUser/types';

import { PlatformGetters } from '@/store/platform/types';
import { NavigationActions, NavigationGetters } from '@/store/navigation/types';

// Import FilePond and plugins
import vueFilePond from 'vue-filepond';
import { FilePond as IFilePond, FilePondFile as File } from 'filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';

// Import Filepond styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond/dist/filepond.min.css';


const FilePond = vueFilePond(
  FilePondPluginFileEncode,
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
);

const navigationNamespace = 'navigation';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';

@Component({
  components: {
    FilePond,
  },
})
export default class BannerDialogEditProfile extends Vue {
  /* PUBLIC PROPERTIES */
  /* PRIVATE PROPERTIES */
  private dialogTitle = '';
  private loading = false;
  private saving = false;

  // Pages

  // User Profile
  private organisation = '';
  private country = '';
  private biography = '';
  private jobTitle = '';
  private accepted: string | Boolean | null = null;
  private initialAccepted = '';

  /* VUEX GETTERS */
  // Navigation Getters
  @Getter(NavigationGetters.PROFILE_DIALOG, {
    namespace: navigationNamespace,
  })
  private dialog!: boolean;

  @Getter(PlatformGetters.DEMAND_GDPR, {
    namespace: platformNamespace,
  })
  private demandGdpr!: string;

  @Getter(PlatformGetters.GDPR_ACCEPT_TEXT, {
    namespace: platformNamespace,
  })
  private gdprAcceptText!: string;

  @Getter(PlatformGetters.GDPR_DECLINE_TEXT, {
    namespace: platformNamespace,
  })
  private gdprDeclineText!: string;

  @Getter(PlatformGetters.GDPR_TEXT, {
    namespace: platformNamespace,
  })
  private gdprText!: string;

  // Platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER, {
    namespace: platformUserNamespace,
  })
  private getCurrentPlatformUser!: PlatformUserItem;

  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  @Action(PlatformUserActions.LOAD_CURRENT_PLATFORM_USER, {
    namespace: platformUserNamespace,
  })
  private loadCurrentPlatformUser!: () => Promise<void>;

  /* VUEX ACTIONS */
  // Navigation Action
  @Action(NavigationActions.PROFILE_INVERT_DIALOG, {
    namespace: navigationNamespace,
  })
  private invertDialog!: () => void;

  // Platfor User Action
  @Action(PlatformUserActions.SET_CURRENT_PLATFORM_USER_PHOTO_URI, {
    namespace: platformUserNamespace,
  })
  private setCurrentPlatformUserPhotoUri!: (uri: string) => void;

  @Action(PlatformUserActions.UPDATE_PLATFORM_USER_PROFILE, {
    namespace: platformUserNamespace,
  })
  private updatePlatformUserProfile!: ({
    userId,
    organisation,
    country,
    biography,
    jobTitle,
    accepted,
  }: {
    userId: string;
    organisation: string;
    country: string;
    biography: string;
    jobTitle: string;
    accepted: boolean;
  }) => Promise<void>;

  /* WATCHES */
  @Watch('dialog', { immediate: true })
  private async onDialogChanged(newVal: boolean): Promise<void> {
    console.log('here');
    if (newVal) {
      this.loading = true;
      await this.loadCurrentPlatformUser();
      this.organisation = this.getCurrentPlatformUser.organisation;
      this.country = this.getCurrentPlatformUser.country;
      this.biography = this.getCurrentPlatformUser.biography;
      this.jobTitle = this.getCurrentPlatformUser.jobTitle;
      this.accepted = this.initialAccepted =
        this.getCurrentPlatformUser.accepted === null ||
        this.getCurrentPlatformUser.accepted === undefined
          ? ''
          : this.initialAccepted
          ? 'True'
          : 'False';
      this.loading = false;
    }
  }

  /* LOCAL GETTERS/SETTERS */
  get dialogState(): boolean {
    return this.dialog;
  }
  set dialogState(state) {
    if (state !== this.dialog) {
      this.invertDialog();
    }
  }

  get serverOptions(): any {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const options = {
      headers: {
        Authorization: TokenStorage.getAuthentication().Authorization as string,
      },
      url: `/api/platform/${platformId}/upload?type=profile&id=${this.currentUserId}`,
    };
    return options;
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  // private mounted() {}
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}

  /* PRIVATE METHODS*/

  private onError() {
    this.setCurrentPlatformUserPhotoUri('');
  }

  private onInitFile() {
    if (this.getCurrentPlatformUser.photoUri) {
      const pond = this.$refs.pond as unknown as IFilePond;
      pond.addFile(this.getCurrentPlatformUser.photoUri);
    }
  }

  private onLoadFile(error: string, file: File) {
    if (file) {
      this.setCurrentPlatformUserPhotoUri(file.serverId);
    }
  }

  private onRemoveFile() {
    this.setCurrentPlatformUserPhotoUri('');
  }

  private async save() {
    this.saving = true;
    await this.updatePlatformUserProfile({
      country: this.country,
      organisation: this.organisation,
      userId: this.currentUserId,
      biography: this.biography,
      jobTitle: this.jobTitle,
      accepted: this.accepted === 'True' ? true : false,
    });
    this.saving = false;
    this.dialogState = false;
  }
}
