import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { AuthState } from './types';

export const state: AuthState = {
  isAuthenticated: false,
  loginError: '',
};

const namespaced = true;

export const auth: Module<AuthState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
