import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { AuthActions, AuthMutations, AuthState } from './types';

export const actions: ActionTree<AuthState, RootState> = {
  [AuthActions.SET_IS_AUTHENTICATED]({ commit }, val: boolean): void {
    commit(AuthMutations.SET_IS_AUTHENTICATED, val);
  },

  [AuthActions.SET_LOGIN_IN_ERROR]({ commit }, val: string): void {
    commit(AuthMutations.SET_LOGIN_IN_ERROR, val);
  },
};
