import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import {
  ErrorMessage,
  SignalrActions,
  SignalrGetters,
  SignalrGroupTypes,
  SignalrMessage,
  SignalrMessageEvents,
  SignalrMessageTypes,
} from '../store/signalr/types';

import { AppInsightsLogger } from '@/services/appInsightsLogger';
import {
  ConversationActions,
  ConversationMessageItem,
} from '@/store/conversation/types';
import { LoungeActions, LoungeRoom } from '@/store/lounge/types';
import { TokenStorage } from '../services/auth/tokenStorage';
import { RootState } from '../store/types';
import { ActionPayload, Store } from 'vuex';
import { PlatformUserGetters } from '@/store/platformUser/types';

function defaultClient() {
  return (
    new HubConnectionBuilder()
      // .configureLogging(process.env.VUE_APP_SIGNALR_LOG_LEVEL)
      .withUrl(`/signalr`, {
        accessTokenFactory: () => {
          return TokenStorage.getAuthentication(true);
        },
      })
      // .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          if (retryContext.elapsedMilliseconds < 300000) {
            return Math.random() * 10000;
          } else {
            return null;
          }
        },
      })
      .build()
  );
}

export default function createHubPlugin(
  client = defaultClient(),
): (store: Store<RootState>) => void {
  const conversationNamespace = 'conversation';
  const loungeNamespace = 'lounge';
  const signalrNamespace = 'signalr';
  const signrlrHubMessageEndpoint = 'RecieveSignalrMessage';

  return (store: Store<RootState>) => {
    /* Connection Management */
    client.onreconnecting(() => {
      store.dispatch(
        `${signalrNamespace}/${SignalrActions.SIGNALR_DISCONNECTED}`,
      );
      store.dispatch(`${signalrNamespace}/${SignalrActions.USER_DISCONNECTED}`);
      const userName: string =
        store.getters[
          `platformUser/${PlatformUserGetters.GET_CURRENT_PLATFORM_USER_NAME}`
        ] ?? '"Unknown"';
      AppInsightsLogger.logInformation(
        `User ${userName} reconnecting to singalr`,
        undefined,
        true,
      );
    });

    client.onreconnected(() => {
      store.dispatch(`${signalrNamespace}/${SignalrActions.SIGNALR_CONNECTED}`);
      store.dispatch(`${signalrNamespace}/${SignalrActions.CONNECT_USER}`);
      const userName: string =
        store.getters[
          `platformUser/${PlatformUserGetters.GET_CURRENT_PLATFORM_USER_NAME}`
        ] ?? '"Unknown"';
      AppInsightsLogger.logInformation(
        `User ${userName} reconnected to signalr`,
        undefined,
        true,
      );

      // TODO(David):  if active live session or sponsor or lounge reconnect
    });

    client.onclose(() => {
      const eventCode: string = sessionStorage.getItem('eventCode') ?? '';
      const userName: string =
        store.getters[
          `platformUser/${PlatformUserGetters.GET_CURRENT_PLATFORM_USER_NAME}`
        ] ?? '"Unknown"';

      store.dispatch(`${signalrNamespace}/${SignalrActions.USER_DISCONNECTED}`);
      AppInsightsLogger.logError(
        `User ${userName} disconnected from event ${eventCode}`,
        undefined,
        true,
      );

      store.dispatch(
        `${signalrNamespace}/${SignalrActions.SIGNALR_DISCONNECTED}`,
      );
      AppInsightsLogger.logError(
        `User ${userName} disconnected from singalr`,
        undefined,
        true,
      );
    });

    client.on(SignalrMessageEvents.SIGNALR_CONNECTED, (res: boolean) => {
      if (res) {
        store.dispatch(
          `${signalrNamespace}/${SignalrActions.SIGNALR_CONNECTED}`,
        );
        store.dispatch(`${signalrNamespace}/${SignalrActions.CONNECT_USER}`);
        const userName: string =
          store.getters[
            `platformUser/${PlatformUserGetters.GET_CURRENT_PLATFORM_USER_NAME}`
          ] ?? '"Unknown"';
        AppInsightsLogger.logInformation(
          `User ${userName} connected to signalr`,
          undefined,
          true,
        );
      }
    });

    client.on(SignalrMessageEvents.USER_CONNECTED, (res: SignalrMessage) => {
      if (res) {
        console.log(res.message);
        store.dispatch(`${signalrNamespace}/${SignalrActions.USER_CONNECTED}`);
      } else {
        console.log('User disconnected');
        store.dispatch(
          `${signalrNamespace}/${SignalrActions.USER_DISCONNECTED}`,
        );
      }
    });

    client.on(
      `${signalrNamespace}/${SignalrMessageEvents.SIGNALR_ERROR}`,
      async (errorMessage: ErrorMessage) => {
        if (errorMessage.hasError) {
          store.dispatch(
            `${signalrNamespace}/${SignalrActions.SIGNALR_ERROR}`,
            errorMessage.message,
          );
          AppInsightsLogger.logError(
            `Signalr hub error - ${errorMessage.message}`,
            undefined,
            true,
          );
          client.stop();

          const userName: string =
            store.getters[
              `platformUser/${PlatformUserGetters.GET_CURRENT_PLATFORM_USER_NAME}`
            ] ?? '"Unknown"';
          store.dispatch(
            `${signalrNamespace}/${SignalrActions.SIGNALR_DISCONNECTED}`,
          );
          AppInsightsLogger.logInformation(
            `User ${userName} disconnected from singalr`,
            undefined,
            true,
          );

          await new Promise((resolve) => setTimeout(resolve, 3000));
          client.start();
          AppInsightsLogger.logInformation(
            `User ${userName} restarting singalr`,
            undefined,
            true,
          );
        }
      },
    );

    store.subscribeAction(async (action: ActionPayload) => {
      if (
        action.type === `${signalrNamespace}/${SignalrActions.CONNECT_SIGNALR}`
      ) {
        if (client.state === HubConnectionState.Disconnected) {
          store.dispatch(
            `${signalrNamespace}/${SignalrActions.SIGNALR_DISCONNECTED}`,
          );
          store.dispatch(
            `${signalrNamespace}/${SignalrActions.USER_DISCONNECTED}`,
          );
          try {
            await client.start();
            const userName: string =
              store.getters[
                `platformUser/${PlatformUserGetters.GET_CURRENT_PLATFORM_USER_NAME}`
              ] ?? '"Unknown"';
            AppInsightsLogger.logInformation(
              `User ${userName} starting singalr client`,
              undefined,
              true,
            );
          } catch (e: any) {
            store.dispatch(
              `${signalrNamespace}/${SignalrActions.SIGNALR_ERROR}`,
              e,
            );
            const userName: string =
              store.getters[
                `platformUser/${PlatformUserGetters.GET_CURRENT_PLATFORM_USER_NAME}`
              ] ?? '"Unknown"';
            AppInsightsLogger.logInformation(
              `User ${userName} client starting error - ${e}`,
              e,
              true,
            );
          }
        }
      } else if (
        action.type === `${signalrNamespace}/${SignalrActions.CONNECT_USER}`
      ) {
        const eventCode: string = sessionStorage.getItem('eventCode') ?? '';
        const eventId: string = sessionStorage.getItem('platformId') ?? '';
        const userId: string =
          store.getters[
            `platformUser/${PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID}`
          ];
        const userName: string =
          store.getters[
            `platformUser/${PlatformUserGetters.GET_CURRENT_PLATFORM_USER_NAME}`
          ];
        if (
          eventCode &&
          eventId &&
          userId &&
          userName &&
          store.getters[
            `${signalrNamespace}/${SignalrGetters.SIGNALR_CONNECTED}`
          ]
        ) {
          await new Promise((resolve) => setTimeout(resolve, 3000));
          if (
            store.getters[
              `${signalrNamespace}/${SignalrGetters.SIGNALR_CONNECTED}`
            ] &&
            !store.getters[
              `${signalrNamespace}/${SignalrGetters.USER_CONNECTED}` // test
            ]
          ) {
            const msg: SignalrMessage = {
              eventCode,
              eventId,
              group: eventCode,
              groupId: eventId,
              groupType: SignalrGroupTypes.EVENT,
              messageType: SignalrMessageTypes.CONNECT_USER,
              userId,
              userName,
            };
            AppInsightsLogger.logInformation(
              `User ${userName} requesting to connect to event ${eventCode}`,
              msg,
              true,
            );
            client.send('RecieveSignalrMessage', msg);
          }
        }
      } else if (action.type === 'signalr/logOff') {
        client.stop();
      }
    });

    /* Room Creation / Removal */

    client.on(SignalrMessageEvents.LOUNGE_GROUP_CREATED, (msg: LoungeRoom) => {
      store.dispatch(
        `${loungeNamespace}/${LoungeActions.LOUNGE_ROOM_CREATED}`,
        msg,
      );
    });

    client.on(SignalrMessageEvents.LOUNGE_GROUP_DELETED, (msg: string) => {
      store.dispatch(
        `${loungeNamespace}/${LoungeActions.LOUNGE_ROOM_DELETED}`,
        msg,
      );
    });

    /* Enter / Leave room */
    store.subscribeAction((action: ActionPayload) => {
      if (action.type === `${signalrNamespace}/${SignalrActions.ENTER_GROUP}`) {
        const msg: SignalrMessage = action.payload;
        if (client.state === HubConnectionState.Connected) {
          client.send(signrlrHubMessageEndpoint, msg);
        }
      }

      if (action.type === `${signalrNamespace}/${SignalrActions.LEAVE_GROUP}`) {
        const msg: SignalrMessage = action.payload;
        if (client.state === HubConnectionState.Connected) {
          client.send(signrlrHubMessageEndpoint, msg);
        }
      }
    });

    client.on(SignalrMessageEvents.GROUP_ENTERED, (msg: SignalrMessage) => {
      store.dispatch(
        `${signalrNamespace}/${SignalrActions.ENTERED_GROUP}`,
        msg,
      );
    });

    client.on(SignalrMessageEvents.GROUP_LEFT, (msg: SignalrMessage) => {
      store.dispatch(`${signalrNamespace}/${SignalrActions.LEFT_GROUP}`, msg);
    });

    client.on(
      SignalrMessageEvents.USER_ENTERED_LEFT_ROOM,
      (msg: SignalrMessage) => {
        store.dispatch(
          `${signalrNamespace}/${SignalrActions.USER_ENTERED_LEFT_ROOM}`,
          msg,
        );
      },
    );

    /* Messaging Handling */

    store.subscribeAction((action: ActionPayload) => {
      if (
        action.type ===
        `${signalrNamespace}/${SignalrActions.POST_CHAT_MESSAGE}`
      ) {
        client.send(signrlrHubMessageEndpoint, action.payload);
      }
      if (
        action.type ===
        `${signalrNamespace}/${SignalrActions.REMOVE_CHAT_MESSAGE}`
      ) {
        client.send(signrlrHubMessageEndpoint, action.payload);
      }
    });

    client.on(SignalrMessageEvents.CHAT_POSTED, (msg: SignalrMessage) => {
      store.dispatch(`${signalrNamespace}/${SignalrActions.CHAT_POSTED}`, msg);
    });

    client.on(SignalrMessageEvents.CHAT_REMOVED, (msg: SignalrMessage) => {
      store.dispatch(`${signalrNamespace}/${SignalrActions.CHAT_REMOVED}`, msg);
    });

    /* Question Handling */

    client.on(SignalrMessageEvents.QUESTION_POSTED, (question) => {
      store.dispatch(
        `${signalrNamespace}/${SignalrActions.QUESTION_POSTED}`,
        question,
      );
    });

    client.on(SignalrMessageEvents.QUESTION_APPROVED, (questionId: string) => {
      store.dispatch(
        `${signalrNamespace}/${SignalrActions.QUESTION_APPROVED}`,
        questionId,
      );
    });

    client.on(SignalrMessageEvents.QUESTION_HIDDEN, (questionId: string) => {
      store.dispatch(
        `${signalrNamespace}/${SignalrActions.QUESTION_HIDDEN}`,
        questionId,
      );
    });

    client.on(SignalrMessageEvents.QUESTION_REPLY_POSTED, (question) => {
      store.dispatch(
        `${signalrNamespace}/${SignalrActions.QUESTION_REPLY_POSTED}`,
        question,
      );
    });

    /* Instant Messaging */

    client.on(
      'newConversationMessageItem',
      (message: ConversationMessageItem) => {
        store.dispatch(
          `${conversationNamespace}/${ConversationActions.RECIEVE_NEW_CONVERSATION_ITEM}`,
          message,
        );
      },
    );
  };
}
