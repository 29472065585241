
import uniqueFilter from '@/filters/unique.filter';
import {
  PresentationItem,
  SessionActions,
  SessionGetters,
  SessionItem,
} from '@/store/session/types';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { GroupedSessionCalendarItem } from '../models/GroupedSessionCalendarItem';
import { GroupedSessionCalendarItems } from '../models/GroupedSessionCalendarItems';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import {
  BookmarkActions,
  BookmarkEntityType,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '../store/bookmark/types';
import { PlatformGetters } from '../store/platform/types';
const bookmarkNamespace = 'bookmark';
const platformNamespace = 'platform';
const sessionNamespace = 'session';

Component.registerHooks(['beforeRouteUpdate']);

@Component({
  components: {},
})
export default class Programme extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public userProgramme!: boolean;

  /* PRIVATE PROPERTIES */
  private currentTab = 0;
  private customHtml = '';
  private hoveredElement: EventTarget | null = null;
  private hoveredEvent: GroupedSessionCalendarItem | null = null;
  private loaded = false;
  private loadingSession = false;
  private overDetails = false;
  private sessionDetailOpen = false;
  private sessionGroups: GroupedSessionCalendarItems[] = [];
  private noUserSessions = false;

  private mobileDialogState: boolean = false;
  private mobileAlertState: boolean = false; //TODO(David): Cross site alerts
  private mobileSelectedDay: GroupedSessionCalendarItems | null = null;
  private mobileSelectedRoom: string | null = null;
  private mobileSessions: GroupedSessionCalendarItem[] = [];
  private mobileSelectedEvent: GroupedSessionCalendarItem | null = null;

  /* VUEX GETTERS */
  // Bookmark Getters
  @Getter(BookmarkGetters.IS_A_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private isABookmark!: (entityId: string) => boolean;

  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  // Platform Getters
  @Getter(PlatformGetters.CALENDAR_INTERVAL_HEIGHT, {
    namespace: platformNamespace,
  })
  private calendarIntervalHeight!: string;

  // Session Getters
  @Getter(SessionGetters.SELECTED_SESSION, { namespace: sessionNamespace })
  private selectedSession!: SessionItem;

  /* VUEX ACTIONS */
  // Session Actions
  @Action(SessionActions.LOAD_SELECTED_SESSION, { namespace: sessionNamespace })
  private loadSelectedSession!: ({
    isMod,
    noq,
    sessionId,
  }: {
    isMod: boolean;
    noq: boolean;
    sessionId: string;
  }) => Promise<boolean>;

  // Bookmarks Actions
  @Action(BookmarkActions.ADD_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private bookmarkItem!: (bookmark: BookmarkItem) => Promise<void>;

  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  /* WATCHES */
  @Watch('mobileSelectedDay', { immediate: true })
  mobileSelectedDayChanged() {
    if (this.$vuetify.breakpoint.mobile && this.mobileSelectedRoom) {
      this.setMobileSessions();
    }
  }

  @Watch('mobileSelectedRoom', { immediate: true })
  mobileSelectedRoomChanged() {
    if (this.$vuetify.breakpoint.mobile) {
      this.setMobileSessions();
    }
  }

  /* LOCAL GETTERS/SETTERS */
  get dynamicComponent(): { template: string } {
    return {
      template: `${this.customHtml}`,
    };
  }
  /* LIFECYCLE METHODS */

  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  private async mounted() {
    await this.loadSessionGroups();
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}

  /* PRIVATE METHODS*/
  private async beforeRouteUpdate() {
    await this.loadSessionGroups();
  }

  private bookmarkExecute(s: SessionItem) {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const bi = this.getBookmark(s.id);
    if (bi) {
      this.unbookmarkItem(bi);
      this.sessionDetailOpen = false;
    } else {
      const bm: BookmarkItem = {
        end: s.end,
        entityId: s.id,
        entityType: BookmarkEntityType.SESSION,
        type: BookmarkType.VIDEO,
        entityUri: `/${eventCode}/session/${s.id}`,
        start: s.start,
        title: s.title,
      };
      this.bookmarkItem(bm);
    }
  }

  private closeMobileDialog() {
    this.mobileDialogState = false;
    this.mobileSelectedEvent = null;
  }

  private closeSessionDetails() {
    this.sessionDetailOpen = false;
  }

  private firstInterval(sessions: GroupedSessionCalendarItem[]): number {
    const starts = sessions.map((s) => moment(s.start));
    const earliest = moment.min(starts);
    const nearestPastQtr = this.nearestPastMinutes(15, moment(earliest));
    const startMidnight = nearestPastQtr.clone().startOf('day');
    const diffStartMinutes = nearestPastQtr.diff(startMidnight, 'minutes');
    const startInterval = Math.floor(diffStartMinutes / 15);
    return startInterval;
  }

  private formatDay(item: GroupedSessionCalendarItems): string {
    const s = moment(item.groupDate)
      .locale(this.$i18n.locale)
      .format('ddd DD MMM');
    return s;
  }

  private formatStartEnd(start: Date, end: Date) {
    const s = moment(start).format('HH:mm');
    const e = moment(end).format('HH:mm');
    return `${s}-${e}`;
  }

  private getCategories(sessions: GroupedSessionCalendarItem[]): string[] {
    const groups = [...new Set(sessions.map((s) => s.category))];
    return groups;
  }

  private getEventColor(event: GroupedSessionCalendarItem) {
    if (event.colour) {
      return event.colour;
    }
    return 'blue';
  }

  private getItemId(item: GroupedSessionCalendarItems): string {
    return item.groupId.toString();
  }

  private getSessions(
    sessions: GroupedSessionCalendarItem[],
  ): GroupedSessionCalendarItem[] {
    if (this.userProgramme) {
      const userSessions = sessions.filter((s) => {
        return this.isABookmark(s.id);
      });
      if (userSessions.length < 1) {
        this.noUserSessions = true;
      }
      return userSessions;
    } else {
      return sessions;
    }
  }

  private intervalCount(sessions: GroupedSessionCalendarItem[]): number {
    const starts = sessions.map((s) => moment(s.start));
    const ends = sessions.map((s) => moment(s.end));
    const earliest = moment.min(starts);
    const latest = moment.max(ends);
    const nearestPastQtr = this.nearestPastMinutes(15, moment(earliest));
    const midnight = nearestPastQtr.clone().startOf('day');
    const diffStartMinutes = nearestPastQtr.diff(midnight, 'minutes');
    const startInterval = Math.floor(diffStartMinutes / 15);
    const nearestFutureQtr = this.nearestFutureMinutes(15, moment(latest));
    const diffEndMinutes = nearestFutureQtr.diff(midnight, 'minutes');
    const endInterval = Math.floor(diffEndMinutes / 15);
    const temp = endInterval - startInterval;
    return temp;
  }

  private async loadSessionGroups() {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      let url = `/api/platform/${platformId}/sessions/calendar`;
      const sessionGroups = await Vue.$http.get<GroupedSessionCalendarItems[]>(
        url,
      );
      if (sessionGroups.data) {
        this.sessionGroups = sessionGroups.data;
        this.mobileSelectedDay = sessionGroups.data[0];
        sessionGroups.data.forEach(
          (sg: GroupedSessionCalendarItems, i: number) => {
            if (sg.today) {
              this.currentTab = i;
              this.mobileSelectedDay = sg;
            }
          },
        );
      }

      this.loaded = true;
    } catch (e: any) {
      AppInsightsLogger.logError('Programme - created failed', undefined, true);
      AppInsightsLogger.logException(e, false);
    }
  }

  private longerThanTwenty(ev: GroupedSessionCalendarItem): boolean {
    const s = moment(ev.start);
    const e = moment(ev.end);
    return e.diff(s, 'minutes') >= 20;
  }

  private async mobileSessionSelected({
    event,
  }: {
    event: GroupedSessionCalendarItem;
  }) {
    this.mobileSelectedEvent = event;
    this.mobileDialogState = true;
    this.loadingSession = true;
    await this.loadSelectedSession({
      isMod: false,
      noq: true,
      sessionId: event.id,
    });
    this.loadingSession = false;
  }

  private async mobibleOpenSession(event: GroupedSessionCalendarItem) {
    if (event.hasContent) {
      if (event.externalContentUri) {
        this.openNewTab(event.externalContentUri);
      } else {
        this.mobileSelectedEvent = event;
        this.mobileDialogState = true;
      }
    }
  }

  private mouseEnterDetails(event: MouseEvent) {
    this.overDetails = true;
    event.cancelBubble = true;
  }

  private async mouseEnterSession({
    nativeEvent,
    event,
  }: {
    nativeEvent: MouseEvent;
    event: GroupedSessionCalendarItem;
  }) {
    const open = async () => {
      if (this.overDetails) {
        return;
      }
      this.hoveredElement = nativeEvent.target;
      setTimeout(() => {
        this.hoveredEvent = event;
        this.sessionDetailOpen = true;
      }, 10);
      this.loadingSession = true;
      await this.loadSelectedSession({
        isMod: false,
        noq: true,
        sessionId: event.id,
      });
      this.loadingSession = false;
    };

    if (this.sessionDetailOpen && !this.overDetails) {
      this.sessionDetailOpen = false;
      setTimeout(open, 10);
    } else {
      setTimeout(open, 10);
    }
  }

  private mouseLeaveSession() {
    const close = () => {
      if (!this.overDetails) {
        this.sessionDetailOpen = false;
      }
    };

    setTimeout(close, 10);
  }

  private nearestFutureMinutes(
    interval: number,
    someMoment: moment.Moment,
  ): moment.Moment {
    const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
  }

  private nearestMinutes(
    interval: number,
    someMoment: moment.Moment,
  ): moment.Moment {
    const roundedMinutes =
      Math.round(someMoment.clone().minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
  }

  private nearestPastMinutes(
    interval: number,
    someMoment: moment.Moment,
  ): moment.Moment {
    const roundedMinutes =
      Math.floor(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
  }

  private async openNewTab(url: string) {
    if (url.startsWith('https:') || url.startsWith('http:')) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else {
      this.$router.push(url);
    }
  }

  private sessionSelected({
    event,
  }: {
    event: GroupedSessionCalendarItem | null;
  }) {
    if (event && event.hasContent) {
      if (event.externalContentUri) {
        this.openNewTab(event.externalContentUri);
      } else {
        this.$router.push({ name: 'session', params: { sessionId: event.id } });
      }
    }
  }

  private setMobileSessions() {
    if (this.mobileSelectedDay && this.mobileSelectedRoom) {
      this.mobileSessions = this.mobileSelectedDay.groupSessions.filter(
        (gs) => gs.category === this.mobileSelectedRoom,
      );
    } else {
      this.mobileSessions = [];
    }
  }

  private uniqueSpeakers(items: PresentationItem[]) {
    const speakers = items.map((i) => {
      return i.speakerNameOverride ? i.speakerNameOverride : i.speakerName;
    });
    return speakers.filter(uniqueFilter);
  }
}
