
import { DownloadLog } from '@/models/DownloadLog';
import { SponsorMapItem } from '@/models/Sponsor';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { vueVimeoPlayer } from 'vue-vimeo-player';
import { Youtube } from 'vue-youtube';

@Component({
  components: {
    Youtube,
    vueVimeoPlayer,
  },
})
export default class SessionDetails extends Vue {
  @Prop()
  public item!: SponsorMapItem;

  @Prop()
  public text!: boolean;

  private YOUTUBE_TYPE = 'Youtube';
  private VIMEO_TYPE = 'Vimeo';
  private IFRAME_TYPE = 'IFrame';
  private videoDialog = 0;
  private youtubePlayerVars = { controls: 0, showInfo: 0, autoplay: 1 };

  private videoEnded() {
    this.videoDialog = 0;
  }

  private mobileWidth(item: SponsorMapItem) {
    return window.innerWidth;
  }

  private mobileHeight(item: SponsorMapItem): number {
    const w1 = Number.parseInt(item.vidWidth);
    const h1 = Number.parseInt(item.vidHeight);
    const w2 = window.innerWidth;
    const h2: number = Math.trunc((h1 / w1) * w2);
    return h2;
  }

  private stopVideoPlayer() {
    if (this.item.type === this.YOUTUBE_TYPE) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this as any).$refs.youtube.player.stopVideo();
    } else if (this.item.type === this.VIMEO_TYPE) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this as any).$refs.vimeoplayer.pause();
    }
  }
}
