
import TweetBox from '@/components/TweetBox.vue';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  SessionActions,
  SessionGetters,
  SessionQuestionItem,
} from '../store/session/types';
import {
  SignalrGetters,
  SignalrGroup,
  SignalrMessage,
} from '../store/signalr/types';

const signalrNamespace = 'signalr';
const sessionNamespace = 'session';

@Component({ components: { TweetBox } })
export default class SessionQuestions extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public isMod!: boolean;

  @Prop()
  public pageLoaded!: string;

  @Prop()
  public isActive!: boolean;

  @Prop()
  public authorisationForComments!: boolean;

  /* PRIVATE PROPERTIES */
  private newQuestiontText = '';
  private replyQuestiontText = '';
  private showId = '';
  private sentDialog = false;

  /* VUEX GETTERS */
  // Session Getters
  @Getter(SessionGetters.CURRENT_SESSION_QUESTIONS, {
    namespace: sessionNamespace,
  })
  private questions!: SessionQuestionItem[];

  @Getter(SessionGetters.SELECTED_SESSION_AUTO_APPROVE_QUESTIONS, {
    namespace: sessionNamespace,
  })
  private getAutoApproveQuestions!: boolean;

  // Signalr Getters
  @Getter(SignalrGetters.CURRENT_GROUP, { namespace: signalrNamespace })
  private currentGroup!: SignalrGroup;

  @Getter(`${SignalrGetters.USER_CONNECTED}`, { namespace: signalrNamespace })
  private userConnected!: boolean;

  @Getter(SignalrGetters.CURRENT_GROUP_MESSAGES, {
    namespace: signalrNamespace,
  })
  private currentGroupMessages!: SignalrMessage[];

  /* VUEX ACTIONS */
  // Session Actions
  @Action(SessionActions.SET_SELECTED_SESSION_AUTO_APPROVE_QUESTIONS, {
    namespace: sessionNamespace,
  })
  private setAutoApproveQuestions!: (val: boolean) => void;

  /* LOCAL GETTERS / SETTERS */

  get autoApproveQuestions(): boolean {
    return this.getAutoApproveQuestions;
  }

  set autoApproveQuestions(val: boolean) {
    this.setAutoApproveQuestions(val);
  }

  get questionCount(): number {
    if (this.isMod) {
      return this.questions.length;
    } else {
      return this.questions.filter((x) => x.isApproved).length;
    }
  }

  @Watch('isActive')
  private isActiveChanged() {
    this.scrollTop();
  }

  /* PRIVATE METHODS*/
  private getIcon(rolename: string | undefined): string {
    if (!rolename) return '';
    let icon = '';
    switch (rolename.toLowerCase()) {
      case 'bot':
        icon = 'robot';
        break;
      case 'staff':
        icon = 'mdi-shield-star';
        break;
      case 'modAndSpeaker':
        icon = 'mdi-shield-star';
        break;
      case 'moderator':
        icon = 'mdi-shield-star';
        break;
      case 'speaker':
        icon = 'mdi-account-voice';
        break;
      default:
        icon = '';
    }
    return icon;
  }

  private getSecondIcon(rolename: string | undefined): string {
    if (!rolename) return '';
    let icon = '';
    switch (rolename.toLowerCase()) {
      case 'bot':
        icon = '';
        break;
      case 'staff':
        icon = 'mdi-account-voice';
        break;
      case 'modAndSpeaker':
        icon = 'mdi-account-voice';
        break;
      case 'moderator':
        icon = '';
        break;
      case 'speaker':
        icon = '';
        break;
      default:
        icon = '';
    }
    return icon;
  }

  private formatTimestamp(time: string | undefined): string {
    if (!time) {
      return '';
    }
    const t = moment.utc(time);
    if (moment().isSame(t.local(), 'day')) {
      return t.local().format('HH:mm:ss');
    } else {
      return t.local().format('DD/MM/YY');
    }
  }

  private mounted() {
    this.setAutoApproveQuestions(false);
    this.scrollTop();
  }

  private scrollTop() {
    const e = document.getElementById('top');
    if (e) {
      e.scrollIntoView();
    }
  }

  private onNewQuestion() {
    this.scrollTop();
  }

  private showReply(id: string | undefined): void {
    if (!id) {
      this.showId = '';
    } else {
      this.showId = id;
    }
  }

  private clearQuestionReply() {
    this.replyQuestiontText = '';
  }

  private closeReplyTextArea() {
    this.replyQuestiontText = '';
    this.showId = '';
  }

  /* Question Handling */
  private async approveAllQuestionsExecute() {
    const questions = this.questions.filter((q) => {
      if (!q.isApproved) {
        return q;
      }
    });

    questions.forEach(async (q) => {
      await this.approveQuestionExecute(q);
    });
  }

  private async approveQuestionExecute(question: SessionQuestionItem) {
    this.$emit('newMessage', {
      msg: question,
      type: 'approveQuestion',
    });
    this.newQuestiontText = '';
  }

  private async hideAllQuestionsExecute() {
    const questions = this.questions.filter((q) => {
      if (q.isApproved) {
        return q;
      }
    });

    questions.forEach(async (q) => {
      await this.hideQuestionExecute(q);
    });
  }

  private async hideQuestionExecute(question: SessionQuestionItem) {
    this.$emit('newMessage', {
      msg: question,
      type: 'hideQuestion',
    });
    this.newQuestiontText = '';
  }

  private postQuestionExecute() {
    if (this.newQuestiontText.length > 1) {
      this.$emit('newMessage', {
        msg: this.newQuestiontText,
        type: 'postQuestion',
      });
      this.newQuestiontText = '';
      // TODO(David): Check is questions being moderated
      this.sentDialog = true;
    }
  }

  private postQuestionReplyExecute(questionId: string | undefined) {
    if (!questionId) {
      return;
    }
    if (this.replyQuestiontText.length > 1) {
      this.$emit('newMessage', {
        msg: this.replyQuestiontText,
        questionId,
        type: 'postReplyQuestion',
      });
      this.closeReplyTextArea();

      // TODO(David): Check is questions being moderated
    }
  }

  private resetSessionQuestionItemUserPhotoUri(question: SessionQuestionItem) {
    this.$emit('newMessage', {
      msg: question,
      type: 'resetUserPhoto',
    });
  }
}
