import { MutationTree } from 'vuex';
import { NavigationMutations, NavigationState } from './types';

export const mutations: MutationTree<NavigationState> = {
  [NavigationMutations.NAVIGATION_INVERT_MINI_VARIANT](state): void {
    state.navigationMiniVariant = !state.navigationMiniVariant;
  },

  [NavigationMutations.NAVIGATION_INVERT_DRAWER](state): void {
    state.navigationDrawer = !state.navigationDrawer;
  },

  [NavigationMutations.NAVIGATION_SET_DRAWER](state, value: boolean): void {
    state.navigationDrawer = value;
  },

  [NavigationMutations.ACTIVITY_INVERT_MINI_VARIANT](state): void {
    state.activityMiniVariant = !state.activityMiniVariant;
  },

  [NavigationMutations.ACTIVITY_INVERT_DRAWER](state): void {
    state.activityDrawer = !state.activityDrawer;
  },

  [NavigationMutations.ACTIVITY_SET_DRAWER](state, value: boolean): void {
    state.activityDrawer = value;
  },
  [NavigationMutations.PROFILE_INVERT_DIALOG](state): void {
    state.profileDialog = !state.profileDialog;
  },

  [NavigationMutations.SET_MOBILE_PAGE_TITLE](state, value: string): void {
    state.mobilePageTitle = value;
  },
};
