export enum ScanTypes {
  ENTER_EVENT_LOG = 'ENTER_EVENT_LOG',
  ENTER_LOUNGE_LOG = 'ENTER_LOUNGE_LOG',
  ENTER_POSTER_LOG = 'ENTER_POSTER_LOG',
  ENTER_PRESENTATION_LOG = 'ENTER_PRESENTATION_LOG',
  ENTER_SESSION_LOG = 'ENTER_SESSION_LOG',
  ENTER_SPONSOR_LOUNGE_LOG = 'ENTER_SPONSOR_LOUNGE_LOG',
  ENTER_STAND_LOG = 'ENTER_STAND_LOG',
  HEARTBEAT = 'HEARTBEAT',
  IN_OUT_STATUS_LOG = 'IN_OUT_STATUS_LOG',
  LEAVE_EVENT_LOG = 'LEAVE_EVENT_LOG',
  LEAVE_LOUNGE_LOG = 'LEAVE_LOUNGE_LOG',
  LEAVE_POSTER_LOG = 'LEAVE_POSTER_LOG',
  LEAVE_PRESENTATION_LOG = 'LEAVE_PRESENTATION_LOG',
  LEAVE_SESSION_LOG = 'LEAVE_SESSION_LOG',
  LEAVE_SPONSOR_LOUNGE_LOG = 'LEAVE_SPONSOR_LOUNGE_LOG',
  LEAVE_STAND_LOG = 'LEAVE_STAND_LOG',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}
