import { ActionTree } from 'vuex';
import { RootState } from '../types';
import {
  ReportActions,
  ReportMutations,
  ReportState,
  ReportType,
} from './types';

export const actions: ActionTree<ReportState, RootState> = {
  [ReportActions.SET_SELECTED_TYPE]({ commit }, type: ReportType): void {
    commit(ReportMutations.SET_SELECTED_TYPE, type);
  },
};
