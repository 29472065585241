var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"height":"100%"}},[_c('ActivityDrawer',{attrs:{"is-mod":_vm.isMod,"is-session":true,"page-loaded":_vm.pageLoaded}}),(_vm.pageLoaded)?_c('div',{staticClass:"ma-0 pa-0 black"},[_c('v-responsive',{staticStyle:{"margin":"auto"},attrs:{"aspect-ratio":16 / 9,"max-height":_vm.screenHeight - 185,"max-width":((_vm.screenHeight - 185) / 9) * 16}},[(!_vm.$vuetify.breakpoint.mobile)?_c('v-overlay',{attrs:{"id":"related","absolute":true,"value":_vm.overlay}},[_c('v-card',{attrs:{"flat":"","tile":"","color":"transparent","width":"100%"}},[_c('v-container',[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t('session.recommendation')))]),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.related),function(s){return _c('v-col',{key:s.id},[_c('SessionCard',{staticClass:"ma-0 pa-0",staticStyle:{"'{cursor":"crosshair"},attrs:{"textColourOverride":"#FFFFFF","session":s,"related":true},on:{"nocontent":function($event){_vm.dialog = true}}})],1)}),1)],1)],1)],1):_vm._e(),(
          _vm.$vuetify.breakpoint.mobile &&
          _vm.selectedSession &&
          _vm.selectedSession.isAPoster &&
          _vm.selectedSession.posterUploadUri &&
          _vm.selectedSession.posterUploadUri.includes('.pdf')
        )?_c('AdobePdfViewer',{staticClass:"mobile-poster",attrs:{"src":_vm.selectedSession.posterUploadUri}}):_vm._e(),(
          _vm.selectedSession &&
          _vm.selectedSession.isAPoster &&
          _vm.selectedSession.posterUploadUri &&
        _vm.selectedSession.posterUploadUri.includes('.pdf'))?_c('iframe',{ref:"pdfposteriframe",staticClass:"resp-iframe",attrs:{"src":_vm.selectedSession.posterUploadUri + '#view=FitV&toolbar=0',"allowfullscreen":"","width":"100%","height":"100%","type":"application/pdf"}}):(
          _vm.selectedSession &&
          _vm.selectedSession.isAPoster &&
          _vm.selectedSession.posterUploadUri &&
          _vm.selectedSession.posterUploadUri.includes('.pptx'))?_c('iframe',{staticClass:"resp-iframe",attrs:{"width":"100%","height":"100%","frameborder":"0","src":`https://view.officeapps.live.com/op/embed.aspx?src=${_vm.selectedSession.posterUploadUri}`}}):(
          _vm.selectedSession &&
          _vm.selectedSession.isAPoster &&
          _vm.selectedSession.posterUploadUri &&
          (_vm.selectedSession.posterUploadUri.includes('.jpg') ||
            _vm.selectedSession.posterUploadUri.includes('.png'))
        )?_c('v-img',{attrs:{"id":"poster-image","src":_vm.selectedSession.posterUploadUri,"contain":""}}):(
          _vm.selectedSession &&
          _vm.selectedSession.isAPoster &&
          _vm.selectedSession.posterUploadUri &&
          _vm.selectedSession.posterUploadUri.includes('https')
        )?_c('iframe',{staticClass:"resp-iframe",attrs:{"width":"100%","height":"100%","frameborder":"0","src":_vm.selectedSession.posterUploadUri}}):(_vm.validVideoIFrameUri(_vm.selectedSession.videoIFrameUri))?_c('VimeoPlayer',{attrs:{"src":_vm.selectedSession.videoIFrameUri,"frameborder":"0"},on:{"ended":_vm.ended}}):(
          _vm.selectedSession.isExternalContentSession &&
          _vm.selectedSession.externalContentUri
        )?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"banner"},[_c('v-img',{attrs:{"src":_vm.bannerImage}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#036358"}},[_c('v-btn',{attrs:{"x-large":"","color":"primary"},on:{"click":function($event){return _vm.openNewTab(_vm.selectedSession.externalContentUri)}}},[_vm._v(_vm._s(_vm.$t('session.externalContent')))])],1):_vm._e()],1)],1)]}}],null,false,2092528150)}):_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"banner"},[_c('v-img',{attrs:{"src":_vm.bannerImage}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#036358"}},[_c('v-chip',{attrs:{"x-large":"","color":"primary","label":""}},[_vm._v(_vm._s(_vm.$t('session.noContent')))])],1):_vm._e()],1)],1)]}}],null,false,3519852879)})],1),(_vm.pageLoaded)?_c('SessionDetails',{attrs:{"session":_vm.selectedSession}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }