import { MutationTree } from 'vuex';
import { LoungeMutations, LoungeRoom, LoungeState } from './types';

export const mutations: MutationTree<LoungeState> = {
  [LoungeMutations.INVERT_DRAWER](state: LoungeState): void {
    state.drawer = !state.drawer;
  },

  [LoungeMutations.ADD_LOUNGE_ROOM](
    state: LoungeState,
    newLoungeRoom: LoungeRoom,
  ): void {
    state.loungeRooms.push(newLoungeRoom);
  },

  [LoungeMutations.CLEAR_CURRENT_LOUNGE_ROOM](state: LoungeState): void {
    state.currentLoungeRoom = undefined;
  },

  [LoungeMutations.DELETE_FAVOURITE_MESSAGES](
    state: LoungeState,
    groupId: string,
  ): void {
    const indexes: number[] = [];
    state.favouriteGroupMessages.forEach((fgm, i) => {
      if (fgm.groupId === groupId) {
        indexes.push(i);
      }
    });
    indexes.forEach((i) => {
      if (i) {
        state.favouriteGroupMessages.splice(i, 1);
      }
    });
  },

  [LoungeMutations.DELETE_LOUNGE_ROOM](
    state: LoungeState,
    groupId: string,
  ): void {
    const index = state.loungeRooms.findIndex((lr) => lr.groupId === groupId);
    if (index > -1) {
      state.loungeRooms.splice(index, 1);
    }
  },

  [LoungeMutations.UPDATE_LOUNGE_ROOM](
    state: LoungeState,
    { orgRoom, updatedRoom }: { orgRoom: LoungeRoom; updatedRoom: LoungeRoom },
  ): void {
    const index = state.loungeRooms.indexOf(orgRoom);
    if (index > -1) {
      state.loungeRooms.splice(index, 1, updatedRoom);
    }
  },

  [LoungeMutations.LOAD_LOUNGE_ROOMS](
    state: LoungeState,
    rooms: LoungeRoom[],
  ): void {
    state.loungeRooms = [];
    state.loungeRooms.push(...rooms);
  },

  [LoungeMutations.SET_CURRENT_LOUNGE_ROOM](
    state: LoungeState,
    room: LoungeRoom,
  ): void {
    state.currentLoungeRoom = room;
  },
};
