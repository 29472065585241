import { GetterTree } from 'vuex';
import { RootState } from '../types';
import {
  PosterSessionItem,
  RoomItem,
  SessionGetters,
  SessionItem,
  SessionQuestionItem,
  SessionState,
} from './types';

export const getters: GetterTree<SessionState, RootState> = {
  [SessionGetters.CURRENT_PAGE](state): number {
    return state.currentPage;
  },

  [SessionGetters.KEYWORDS](state): string {
    return state.keywords;
  },

  [SessionGetters.LIST_VIEW](state): boolean {
    return state.listView;
  },

  [SessionGetters.LOADING_SESSIONS](state): boolean {
    return state.loadingSessions;
  },

  [SessionGetters.POSTER_SESSIONS](state): PosterSessionItem[] {
    return state.posterSessions;
  },

  [SessionGetters.ROOMS](state): RoomItem[] {
    return state.rooms;
  },

  [SessionGetters.THEMES](state): string[] {
    return state.themes;
  },

  [SessionGetters.TYPES](state): string[] {
    return state.types;
  },

  [SessionGetters.KEYWORD_ITEMS](state): string[] {
    return state.keywordItems;
  },

  [SessionGetters.SELECTED_POSTER_SESSION](
    state,
  ): PosterSessionItem | undefined {
    return state.selectedPosterSession;
  },

  [SessionGetters.SELECTED_ROOM](state): RoomItem | undefined {
    return state.selectedRoom;
  },

  [SessionGetters.SELECTED_SESSION](state): SessionItem | undefined {
    return state.selectedSession;
  },

  [SessionGetters.SELECTED_THEME](state): string {
    return state.selectedTheme;
  },

  [SessionGetters.SELECTED_TYPE](state): string {
    return state.selectedType;
  },

  [SessionGetters.SESSIONS](state): SessionItem[] {
    return state.sessions;
  },

  [SessionGetters.RELATED_SESSIONS](state): SessionItem[] {
    return state.relatedSessions;
  },

  [SessionGetters.TOTAL_SESSION_COUNT](state): number {
    return state.totalSessionCount;
  },

  [SessionGetters.CURRENT_SESSION_QUESTIONS](state): SessionQuestionItem[] {
    return state.selectedSessionQuestions;
  },

  [SessionGetters.SELECTED_SESSION_AUTO_APPROVE_QUESTIONS](state): boolean {
    return state.selectedSessionAutoApproveQuestions;
  },

  getSelectedSesionsQuestions(state): SessionQuestionItem[] {
    return state.selectedSessionQuestions;
  },

  getSessionById:
    (state: SessionState) =>
    (id: string): SessionItem | undefined => {
      return state.sessions.find((c) => c.id === id);
    },

  [SessionGetters.CURRENT_SESSION_QUESTION_BY_ID]:
    (state: SessionState) =>
    (id: string): SessionQuestionItem | undefined => {
      return state.selectedSessionQuestions.find((c) => c.id === id);
    },
};
