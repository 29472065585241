
import { BlockBlobClient } from '@azure/storage-blob';
import { AbortController } from "@azure/abort-controller";
import moment from 'moment';
import {
    AzureStorageSASResult,
    SubmitPresentationItem,
    SubmitSessionItem,
    SubmitSettings,
} from '../models/Submit';
import { Action, Getter } from 'vuex-class';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { getFilenameFromURL } from '../utils/getFilenameFromURL';

// Import FilePond and plugins
import vueFilePond from 'vue-filepond';
import { FilePond as IFilePond } from 'filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Import Filepond styles
import 'filepond/dist/filepond.min.css';

const FilePond = vueFilePond(
    FilePondPluginFileEncode,
    FilePondPluginFileValidateType,
);


@Component({
    components: { FilePond, },
})
export default class SubmitUploadFileDialog extends Vue {
    /* PUBLIC PROPERTIES */
    @Prop()
    public item!: SubmitPresentationItem;

    @Prop()
    public settings!: SubmitSettings;

    @Prop()
    public sessionMode!: boolean;

     //@Prop()
     //public posterMode!: boolean;

    /* PRIVATE PROPERTIES */
    private type: string = '';
    /* VUEX GETTERS */
    /* VUEX ACTIONS */
    /* WATCHES */
     @Watch('item')
     private async itemChange(val: SubmitPresentationItem) {
        if (val) {
           await this.loadItem();
        }
    }

    /* LOCAL GETTERS/SETTERS */
    get canFinish(): boolean {

        if (this.sessionMode) return true;

        if (this.settings.requestRecordPermission && (this.settings.requestRecordPermissionForAllPresentations || this.item.record)) {
            if (this.item.authorisedRecordWeb === null || this.item.authorisedRecordWeb == undefined) {
                return false;
            }
        }

        if (this.settings.requestPublishPermission && (this.settings.requestPublishPermissionForAllPresentations || this.item.publish)) {
            if (this.item.authorisedPublish === null || this.item.authorisedPublish == undefined) {
                return false;
            }
        }

        if (this.settings.requestConflictOfInterest && (this.settings.requestConflictOfInterestForAllPresentations || this.item.declareConflictOfInterest)) {
            if (this.item.hasConflictOfInterest === null || this.item.hasConflictOfInterest == undefined) {
                return false;
            }
        }

        if (this.settings.requestCustomAuthorisation1 && (this.settings.requestCustomAuthorisation1ForAllPresentations || this.item.requireCustomAuthorisation1)) {
            if (this.item.authorisedCustomAuthorisation1 === null || this.item.authorisedCustomAuthorisation1 == undefined) {
                return false;
            }
        }

        if (this.settings.requestCustomAuthorisation2 && (this.settings.requestCustomAuthorisation2ForAllPresentations || this.item.requireCustomAuthorisation2)) {
            if (this.item.authorisedCustomAuthorisation2 === null || this.item.authorisedCustomAuthorisation2 == undefined) {
                return false;
            }
        }

        if (this.settings.requestCustomAuthorisation3 && (this.settings.requestCustomAuthorisation3ForAllPresentations || this.item.requireCustomAuthorisation3)) {
            if (this.item.authorisedCustomAuthorisation3 === null || this.item.authorisedCustomAuthorisation3 == undefined) {
                return false;
            }
        }

        if (this.settings.requestCustomAuthorisation4 && (this.settings.requestCustomAuthorisation4ForAllPresentations || this.item.requireCustomAuthorisation4)) {
            if (this.item.authorisedCustomAuthorisation4 === null || this.item.authorisedCustomAuthorisation4 == undefined) {
                return false;
            }
        }

        if (this.settings.requestCustomAuthorisation5 && (this.settings.requestCustomAuthorisation5ForAllPresentations || this.item.requireCustomAuthorisation5)) {
            if (this.item.authorisedCustomAuthorisation5 === null || this.item.authorisedCustomAuthorisation5 == undefined) {
                return false;
            }
        }

        return true
    }

    get audioUploadServerOptions(): any {
        const options = {
            fetch: (url, load, error, progress, abort, headers) => {
                console.log('fetch');
            },
            load: async (source, load, error, progress, abort, headers) => {
                console.log('load');
            },
            process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                const platformId = sessionStorage.getItem('platformId') ?? '';
                const presId = this.item?.id;
                const urlBase = `/api/v2/platform/${platformId}/submit/presentations/${presId}`
                const controller = new AbortController();
                let url = `${urlBase}/token?orgFileName=${file.name}&type=audio`;
                Vue.$http.get<AzureStorageSASResult>(url).then(res => {
                    if (res) {
                        const s = res.data;
                        const client = new BlockBlobClient(s.sasUri);

                        client.uploadBrowserData(file, {
                            blobHTTPHeaders: {
                                blobContentType: file.type,
                            },
                            onProgress: p => {
                                progress(true, p.loadedBytes, file.size);
                            },
                            abortSignal: controller.signal,
                        }).then(() => {
                            url = `${urlBase}?type=audio`;
                            Vue.$http.put(url, { blobUri: s.blobUri }).then(res2 => {
                                if (res2) {
                                    load(s.blobUri);
                                    this.item.audioFileUri = s.blobUri;
                                    return;
                                }
                            }).catch(e => {
                                const msg = `Upload canceled/failed: ${e}`;
                                console.log(msg);
                                return error(msg);
                            });
                        }).catch(e => {
                            const msg = `Upload canceled/failed: ${e}`;
                            console.log(msg);
                            return error(msg);
                        });
                    }
                }).catch(e => {
                    const msg = `Upload canceled/failed: ${e}`;
                    console.log(msg);
                    return error(msg);
                });

                return {
                    abort: () => {
                        controller.abort();
                        abort();
                    },
                };
            },
            remove: (source, load, error) => {
                const platformId = sessionStorage.getItem('platformId') ?? '';
                const presId = this.item?.id;
                const url = `/api/v2/platform/${platformId}/submit/presentations/${presId}?orgFileName=${getFilenameFromURL(source)}&type=audio`;
                Vue.$http.delete(url).then(res => {
                    if (res && res.status < 300) {
                        load();
                        this.item.audioFileUri = "";
                        return;
                    }
                }).catch(e => {
                    const msg = `Remove failed: ${e}`;
                    console.log(msg);
                    return error(msg);
                });
            },
            revert: (source, load, error) => {
                const platformId = sessionStorage.getItem('platformId') ?? '';
                const presId = this.item?.id;
                const url = `/api/v2/platform/${platformId}/submit/presentations/${presId}?orgFileName=${getFilenameFromURL(source)}&type=audio`;
                Vue.$http.delete(url).then(res => {
                    if (res && res.status < 300) {
                        load();
                        this.item.audioFileUri = "";
                        return;
                    }
                }).catch(e => {
                    const msg = `Revert failed: ${e}`;
                    console.log(msg);
                    return error(msg);
                });
            }
        }
        return options;
    }

    get additionalUploadServerOptions(): any {
        const options = {
            fetch: (url, load, error, progress, abort, headers) => {
                console.log('fetch');
            },
            load: async (source, load, error, progress, abort, headers) => {
                console.log('load');
            },
            process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                const platformId = sessionStorage.getItem('platformId') ?? '';
                const presId = this.item?.id;
                const urlBase = `/api/v2/platform/${platformId}/submit/presentations/${presId}`
                const controller = new AbortController();
                let url = `${urlBase}/token?orgFileName=${file.name}&type=other`;
                Vue.$http.get<AzureStorageSASResult>(url).then(res => {
                    if (res) {
                        const s = res.data;
                        const client = new BlockBlobClient(s.sasUri);

                        client.uploadBrowserData(file, {
                            blobHTTPHeaders: {
                                blobContentType: file.type,
                            },
                            onProgress: p => {
                                progress(true, p.loadedBytes, file.size);
                            },
                            abortSignal: controller.signal,
                        }).then(() => {
                            url = `${urlBase}?type=other`;
                            Vue.$http.put(url, { blobUri: s.blobUri }).then(res2 => {
                                if (res2) {
                                    load(s.blobUri);
                                    this.item.additionalUploadFileUris?.push(s.blobUri);
                                    return;
                                }
                            }).catch(e => {
                                const msg = `Upload canceled/failed: ${e}`;
                                console.log(msg);
                                return error(msg);
                            });
                        }).catch(e => {
                            const msg = `Upload canceled/failed: ${e}`;
                            console.log(msg);
                            return error(msg);
                        });
                    }
                }).catch(e => {
                    const msg = `Upload canceled/failed: ${e}`;
                    console.log(msg);
                    return error(msg);
                });

                return {
                    abort: () => {
                        controller.abort();
                        abort();
                    },
                };
            },
            remove: (source, load, error) => {
                const platformId = sessionStorage.getItem('platformId') ?? '';
                const presId = this.item?.id;
                const url = `/api/v2/platform/${platformId}/submit/presentations/${presId}?orgFileName=${getFilenameFromURL(source)}&type=other`;
                Vue.$http.delete(url).then(res => {
                    if (res && res.status < 300) {
                        load();
                        this.item.additionalUploadFileUris?.splice(this.item.additionalUploadFileUris.indexOf(source), 1);
                        return;
                    }
                }).catch(e => {
                    const msg = `Remove failed: ${e}`;
                    console.log(msg);
                    return error(msg);
                });
            },
            revert: (source, load, error) => {
                const platformId = sessionStorage.getItem('platformId') ?? '';
                const presId = this.item?.id;
                const url = `/api/v2/platform/${platformId}/submit/presentations/${presId}?orgFileName=${getFilenameFromURL(source)}&type=other`;
                Vue.$http.delete(url).then(res => {
                    if (res && res.status < 300) {
                        load();
                        this.item.additionalUploadFileUris?.splice(this.item.additionalUploadFileUris.indexOf(source), 1);
                        return;
                    }
                }).catch(e => {
                    const msg = `Revert failed: ${e}`;
                    console.log(msg);
                    return error(msg);
                });
            }

        }
        return options;
    }

    get presentationUploadServerOptions(): any {
        const options = {
            fetch: (url, load, error, progress, abort, headers) => {
                console.log('fetch');
            },
            load: async (source, load, error, progress, abort, headers) => {
                console.log('load');
            },
            process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {

                const platformId = sessionStorage.getItem('platformId') ?? '';
                const presId = this.item?.id;
                const urlBase = `/api/v2/platform/${platformId}/submit/presentations/${presId}`
                const controller = new AbortController();
                let url = `${urlBase}/token?orgFileName=${file.name}&type=${this.type}`;
                Vue.$http.get<AzureStorageSASResult>(url).then(res => {
                    if (res) {
                        const s = res.data;
                        const client = new BlockBlobClient(s.sasUri);

                        client.uploadBrowserData(file, {
                            blobHTTPHeaders: {
                                blobContentType: file.type,
                            },
                            onProgress: p => {
                                progress(true, p.loadedBytes, file.size);
                            },
                            abortSignal: controller.signal,
                        }).then(() => {
                            url = `${urlBase}?type=${this.type}`;
                            Vue.$http.put(url, { blobUri: s.blobUri }).then(res2 => {
                                if (res2) {
                                    load(s.blobUri);
                                    this.item!.isAPoster ? this.item!.posterUploadUri = s.blobUri : this.item!.presentationFileUri = s.blobUri;
                                    return;
                                }
                            }).catch(e => {
                                const msg = `Upload canceled/failed: ${e}`;
                                console.log(msg);
                                return error(msg);
                            });
                        }).catch(e => {
                            const msg = `Upload canceled/failed: ${e}`;
                            console.log(msg);
                            return error(msg);
                        });
                    }
                }).catch(e => {
                    const msg = `Upload canceled/failed: ${e}`;
                    console.log(msg);
                    return error(msg);
                });

                return {
                    abort: () => {
                        controller.abort();
                        abort();
                    },
                };
            },
            remove: (source, load, error) => {
                const platformId = sessionStorage.getItem('platformId') ?? '';
                const presId = this.item?.id;
                const url = `/api/v2/platform/${platformId}/submit/presentations/${presId}?orgFileName=${getFilenameFromURL(source)}&type=${this.type}`;
                Vue.$http.delete(url).then(res => {
                    if (res && res.status < 300) {
                        load();
                        this.item!.isAPoster ? this.item!.posterUploadUri = '' : this.item!.presentationFileUri = '';
                        return;
                    }
                }).catch(e => {
                    const msg = `Remove failed: ${e}`;
                    console.log(msg);
                    return error(msg);
                });
            },
            revert: (source, load, error) => {
                const platformId = sessionStorage.getItem('platformId') ?? '';
                const presId = this.item?.id;
                const url = `/api/v2/platform/${platformId}/submit/presentations/${presId}?orgFileName=${getFilenameFromURL(source)}&type=${this.type}`;
                Vue.$http.delete(url).then(res => {
                    if (res && res.status < 300) {
                        load();
                        this.item!.isAPoster ? this.item!.posterUploadUri = '' : this.item!.presentationFileUri = '';
                        return;
                    }
                }).catch(e => {
                    const msg = `Revert failed: ${e}`;
                    console.log(msg);
                    return error(msg);
                });
            }
        }
        return options;
    }


    /* LIFECYCLE METHODS */
    // private beforeCreate() {}
    // private created() {}
    // private beforeMount() {}
    private async mounted() {
        await this.loadItem();
    }
    // private beforeUpdate() {}
    // private updated() {}
    // private activated() {}
    // private deactivated() {}
    // private beforeDestroy() {}
    // private destroyed() {}
    // private errorCaptured() {}
    /* PRIVATE METHODS*/

    private formatLongDate(start: Date, end: Date) {
        let s = '';
        let e = '';
        if (start) {
            s = moment(start).locale(this.$i18n.locale).format('ddd DD MMM HH:mm');
        }

        if (end) {
            e = moment(end).locale(this.$i18n.locale).format('HH:mm');
        }
        return `${s} - ${e}`;
    }

    private handleClose() {
        this.$emit('close');
    }

    private async handleFileActivated(fileItem) {

        if (fileItem.fileExtension.toLowerCase() !== 'ppt' || fileItem.fileExtension.toLowerCase() !== 'pptx' || fileItem.fileExtension.toLowerCase() !== 'pdf') {
            let res = await this.$dialog.confirm({
                text: this.$t('submit.LargeFileMessage1') as string,
                title: this.$t('submit.LargeFile') as string,
                persistent: true
            })

            if (!res) return

            window.location = fileItem.serverId;

        } else if ((fileItem.fileExtension.toLowerCase() === 'ppt' || fileItem.fileExtension.toLowerCase() === 'pptx') && fileItem.fileSize > 100000000) {
            let res = await this.$dialog.confirm({
                text: this.$t('submit.LargeFileMessage2') as string,
                title: this.$t('submit.LargeFile') as string,
                persistent: true
            })

            if (!res) return

            window.location = fileItem.serverId;

        } else {

            if (fileItem.fileExtension.toLowerCase() === 'ppt' || fileItem.fileExtension.toLowerCase() === 'pptx') {
                const link = `https://view.officeapps.live.com/op/view.aspx?src=${fileItem.serverId}&d=${Date.now()}`
                const win = window.open(link, '_blank')
                if (win) {
                    win.focus();
                }
            } else {
                const win = window.open(fileItem.serverId, '_blank');
                if (win) {
                    win.focus();
                }
            }
        }
    }

    private handleRemoveFile(fileItem) {
        return this.$dialog.warning({
            text: this.$t('submit.DeleteFileMessage') as string,
            title: this.$t('submit.DeleteFile') as string,
            persistent: true
        });
    }

    private async loadItem()
    {
        if(this.item && this.item.isAPoster)
        {
            this.type = 'poster';
        }
        else
        {
            this.type = 'presentation';
        }

        if (this.item && this.item.isAPoster && this.item.posterUploadUri)
        {
            const res = await fetch(this.item?.posterUploadUri, { method: "HEAD" });

            if (res) {
                const file = {
                    name: getFilenameFromURL(decodeURI(this.item.posterUploadUri!)),
                    size: res.headers.get('content-length'),
                    type: res.headers.get('content-type')
                };
                (this.$refs.presentationUpload as any).addFile(decodeURI(this.item.posterUploadUri!), { type: 'local', file: file });
            }
        } else if (this.item && this.item.presentationFileUri) {
            const res = await fetch(this.item?.presentationFileUri, { method: "HEAD" });

            if (res) {
                const file = {
                    name: getFilenameFromURL(decodeURI(this.item.presentationFileUri!)),
                    size: res.headers.get('content-length'),
                    type: res.headers.get('content-type')
                };
                (this.$refs.presentationUpload as any).addFile(decodeURI(this.item.presentationFileUri!), { type: 'local', file: file });
            }
        }

        if (this.item && this.item.audioFileUri)
        {
            const res = await fetch(this.item?.audioFileUri, { method: "HEAD" });
            if (res) {
                const file = {
                    name: getFilenameFromURL(decodeURI(this.item.audioFileUri!)),
                    size: res.headers.get('content-length'),
                    type: res.headers.get('content-type')
                };
                (this.$refs.audioUpload as any).addFile(decodeURI(this.item.audioFileUri!), { type: 'local', file: file });
            }

        }

        if (this.item && this.item.additionalUploadFileUris && this.item.additionalUploadFileUris.length > 0)
        {
            this.item.additionalUploadFileUris.forEach(async a => {
                const res = await fetch(a, { method: "HEAD" });
                if (res) {
                    const file = {
                        name: getFilenameFromURL(decodeURI(a)),
                        size: res.headers.get('content-length'),
                        type: res.headers.get('content-type')
                    };
                    (this.$refs.additionalUpload as any).addFile(decodeURI(a), { type: 'local', file: file });
                }

            });
        }
    }

    private async updateAuthorisedPublish() {
        console.log(this.item.authorisedPublish);
        try {
            const platformId = sessionStorage.getItem('platformId') ?? '';
            const presId = this.item?.id;
            const url = `/api/v2/platform/${platformId}/submit/presentations/${presId}/authorisepublish?value=${this.item.authorisedPublish}`;
            await Vue.$http.put(url);
        } catch (e)
        {
            console.log(e);
        }
    }

    private async updateAuthorisedRecordWeb()
    {
        console.log(this.item.authorisedRecordWeb);
        try {
            const platformId = sessionStorage.getItem('platformId') ?? '';
            const presId = this.item?.id;
            const url = `/api/v2/platform/${platformId}/submit/presentations/${presId}/authoriserecord?value=${this.item.authorisedRecordWeb}`;
            await Vue.$http.put(url);
        } catch (e) {
            console.log(e);
        }
    }

    private async updateHasConflictOfInterest() {
        console.log(this.item.hasConflictOfInterest);
        try {
            const platformId = sessionStorage.getItem('platformId') ?? '';
            const presId = this.item?.id;
            const url = `/api/v2/platform/${platformId}/submit/presentations/${presId}/conflictofinterest?value=${this.item.hasConflictOfInterest}`;
            await Vue.$http.put(url);
        } catch (e)
        {
            console.log(e);
        }
    }

    private async updateCustomAuthorisation1() {
        console.log(this.item.authorisedCustomAuthorisation1);
        try {
            const platformId = sessionStorage.getItem('platformId') ?? '';
            const presId = this.item?.id;
            const url = `/api/v2/platform/${platformId}/submit/presentations/${presId}/customauthorisation1?value=${this.item.authorisedCustomAuthorisation1}`;
            await Vue.$http.put(url);
        } catch (e)
        {
            console.log(e);
        }
    }

    private async updateCustomAuthorisation2() {
        console.log(this.item.authorisedCustomAuthorisation2);
        try {
            const platformId = sessionStorage.getItem('platformId') ?? '';
            const presId = this.item?.id;
            const url = `/api/v2/platform/${platformId}/submit/presentations/${presId}/customauthorisation2?value=${this.item.authorisedCustomAuthorisation2}`;
            await Vue.$http.put(url);
        } catch (e)
        {
            console.log(e);
        }
    }

    private async updateCustomAuthorisation3() {
        console.log(this.item.authorisedCustomAuthorisation3);
        try {
            const platformId = sessionStorage.getItem('platformId') ?? '';
            const presId = this.item?.id;
            const url = `/api/v2/platform/${platformId}/submit/presentations/${presId}/customauthorisation3?value=${this.item.authorisedCustomAuthorisation3}`;
            await Vue.$http.put(url);
        } catch (e)
        {
            console.log(e);
        }
    }

    private async updateCustomAuthorisation4() {
        console.log(this.item.authorisedCustomAuthorisation4);
        try {
            const platformId = sessionStorage.getItem('platformId') ?? '';
            const presId = this.item?.id;
            const url = `/api/v2/platform/${platformId}/submit/presentations/${presId}/customauthorisation4?value=${this.item.authorisedCustomAuthorisation4}`;
            await Vue.$http.put(url);
        } catch (e)
        {
            console.log(e);
        }
    }

    private async updateCustomAuthorisation5() {
        console.log(this.item.authorisedCustomAuthorisation5);
        try {
            const platformId = sessionStorage.getItem('platformId') ?? '';
            const presId = this.item?.id;
            const url = `/api/v2/platform/${platformId}/submit/presentations/${presId}/customauthorisation5?value=${this.item.authorisedCustomAuthorisation5}`;
            await Vue.$http.put(url);
        } catch (e)
        {
            console.log(e);
        }
    }

}
