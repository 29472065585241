import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { PlatformUserState } from './types';

export const state: PlatformUserState = {
  currentPage: 1,
  currentPlatformUser: {
    biography: '',
    businessCardImageUri: '',
    businessCardImageClickUri: '',
    country: '',
    firstName: '',
    isSpeaker: false,
    isModerator: false,
    jobTitle: '',
    lastName: '',
    organisation: '',
    photoUri: '',
    title: '',
    town: '',
    userId: '',
    userName: '',
    urevetnRef: '',
  },
  platformUserCardVisible: false,
  platformUserCardXPos: 0,
  platformUserCardYPos: 0,
  platformUserListVisible: false,
  platformUserListXPos: 0,
  platformUserListYPos: 0,
  platformUserLoading: false,
  platformUsersLoading: false,
  platformUsersSearch: '',
  platformUsers: [],
  totalPlatformUsersCount: 0,
  visiblePlatformUser: {
    biography: '',
    businessCardImageUri: '',
    businessCardImageClickUri: '',
    country: '',
    firstName: '',
    isSpeaker: false,
    isModerator: false,
    jobTitle: '',
    lastName: '',
    organisation: '',
    photoUri: '',
    title: '',
    town: '',
    userId: '',
    userName: '',
    urevetnRef: '',
  },
  visiblePlatformUserList: [],
};

const namespaced = true;

export const platformUser: Module<PlatformUserState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
