export interface I18nState {
  locale: string;
}

export enum I18nActions {
  SET_LOCALE = 'SET_LOCALE',
}

export enum I18nGetters {
  LOCALE = 'LOCALE',
}

export enum I18nMutations {
  SET_LOCALE = 'SET_LOCALE',
}
