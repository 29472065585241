import { AppInsightsLogger } from '@/services/appInsightsLogger';
import { ActionTree } from 'vuex';
import Vue from 'vue';
import {
  BookmarkActions,
  BookmarkItem,
  BookmarkMutations,
  BookmarkState,
} from './types';
import { RootState } from '../types';

export const actions: ActionTree<BookmarkState, RootState> = {
  async [BookmarkActions.ADD_BOOKMARK_ITEM](
    { commit },
    bookmark: BookmarkItem,
  ): Promise<void> {
    if (!(bookmark && bookmark.entityId)) {
      return;
    }
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const uri = `/api/platform/${platformId}/bookmarks`;

    try {
      const res = await Vue.$http.post(uri, bookmark);
      if (res.status === 201) {
        bookmark.id = res.data;
        commit(BookmarkMutations.ADD_BOOKMARK_ITEM, bookmark);
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'BookmarkActions.ADD_BOOKMARK_ITEM failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  },

  async [BookmarkActions.LOAD_BOOKMARKS]({ commit }): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const uri = `/api/platform/${platformId}/bookmarks`;

    try {
      const res = await Vue.$http.get<BookmarkItem[]>(uri);
      if (res.status === 200) {
        commit(BookmarkMutations.LOAD_BOOKMARKS, res.data);
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'BookmarkActions.LOAD_BOOKMARKS failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  },

  async [BookmarkActions.REMOVE_BOOKMARK_ITEM](
    { commit },
    bi: BookmarkItem,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const uri = `/api/platform/${platformId}/bookmarks/${bi.id}`;
    try {
      const res = await Vue.$http.delete(uri);
      if (res.status === 204) {
        commit(BookmarkMutations.REMOVE_BOOKMARK_ITEM, bi);
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'BookmarkActions.REMOVE_BOOKMARK_ITEM failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  },
};
