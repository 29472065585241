
import uniqueFilter from '@/filters/unique.filter';
import { AuthGetters } from '@/store/auth/types';
import { PlatformGetters } from '@/store/platform/types';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import {
  ProgrammeData,
  ProgrammePresentationItem,
  ProgrammeSessionItem,
} from '../models/Programme';
import { AppInsightsLogger } from '../services/appInsightsLogger';

import {
  BookmarkActions,
  BookmarkEntityType,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '../store/bookmark/types';

const authNamespace = 'auth';
const bookmarkNamespace = 'bookmark';
const platformNamespace = 'platform';

@Component({
  components: {},
})
export default class NewProgramme extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public userProgramme!: boolean;

  /* PRIVATE PROPERTIES */

  private loaded: boolean = false;
  private noUserSessions: boolean = false;
  private selectedSessionItem: ProgrammeSessionItem | null = null;
  private viewMode: string = 'calendar';
  private sessionItems: ProgrammeSessionItem[] = [];
  private title: string = '';
  private title_en: string = '';
  private title_fr: string = '';
  private calendarIntervalHeight = 48;
  private dialog = false;
  private dialog2 = false;

  private dateFilter: string[] = [];
  private timeFilter: string[] = [];
  private typeFilter: string[] = [];
  private themeFilter: { theme: string; colour: string }[] = [];
  private roomFilter: {
    roomName: string;
    roomOrder: number;
  }[] = [];

  private selectedDate: string = '';
  private selectedRoom: string = '';
  private selectedTime: string = '';
  private selectedTheme: string = '';
  private selectedType: string = '';
  private filteredDate: string = '';



  private filteredSessionItems: ProgrammeSessionItem[] = [];
  private selectedSession: ProgrammeSessionItem | null = null;
  private selectedPresentation: ProgrammePresentationItem | null = null;

  /* VUEX GETTERS */

  // Auth Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Bookmark Getters
  @Getter(BookmarkGetters.IS_A_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private isABookmark!: (entityId: string) => boolean;

  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  // Platform Getters
  @Getter(PlatformGetters.SHOW_PRESENTATION_DURATION_IN_PROGRAMME, {
    namespace: platformNamespace,
  })
  private showPresentationDurationInProgramme!: boolean;

  /* VUEX ACTIONS */
  // Bookmarks Actions
  @Action(BookmarkActions.ADD_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private bookmarkItem!: (bookmark: BookmarkItem) => Promise<void>;

  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  /* WATCHES */
  @Watch('selectedDate', { immediate: true })
  private selectedDateChanged(value: string) {
    if (this.selectedDate)
    {
      this.setupFilters()
    }
  }

  @Watch('selectedRoom', { immediate: true })
  private selectedRoomChanged(value: string) {
    this.applyFilters();
  }

  @Watch('selectedTheme', { immediate: true })
  private selectedThemeChanged(value: string) {
    this.applyFilters();
  }

  @Watch('selectedTime', { immediate: true })
  private selectedTimeChanged(value: string) {
    this.applyFilters();
  }

  @Watch('selectedType', { immediate: true })
  private selectedTypeChanged(value: string) {
    this.applyFilters();
  }

  @Watch('$route.query', { immediate: false, deep: true })
  private async onQueryChange() {
    await this.loadSessions();
  }

  /* LOCAL GETTERS/SETTERS */
  get locale(): string {
    return this.$i18n.locale;
  }

  private test(event) {
    console.log(event);
    return '';
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  private async mounted() {
    await this.loadSessions();
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}
  /* PRIVATE METHODS*/
  private bookmarkExecute(s: ProgrammeSessionItem) {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const bi = this.getBookmark(s.id);
    if (bi) {
      this.unbookmarkItem(bi);
    } else {
      const bm: BookmarkItem = {
        end: s.end,
        entityId: s.id,
        entityType: BookmarkEntityType.SESSION,
        type: BookmarkType.VIDEO,
        entityUri: `/${eventCode}/session/${s.id}`,
        start: s.start,
        title: s.title!,
      };
      this.bookmarkItem(bm);
    }
  }

  private applyFilters() {
    this.filteredSessionItems.forEach((s) => {
      s.filtered = false;
      if (this.selectedRoom) {
        if (this.selectedRoom !== s.roomName) {
          s.filtered = true;
        }
      }
      if (this.selectedTime) {
        if (this.selectedTime !== moment(s.start).format('HH:mm')) {
          s.filtered = true;
        }
      }

      if (this.selectedTheme) {
        if (this.selectedTheme !== s.theme) {
          s.filtered = true;
        }
      }

      if (this.selectedType) {
        if (this.selectedType !== s.type) {
          s.filtered = true;
        }
      }
    });

    if (this.viewMode === 'calendar') {
      this.filteredSessionItems.sort((a, b) => {
        return (a.roomOrder ?? 0) - (b.roomOrder ?? 0);
      });
    } else {
      this.filteredSessionItems.sort((a, b) => {
        if (a.start < b.start) {
          return -1;
        }
        if (a.start > b.start) {
          return 1;
        }
        return 0;
      });
    }
  }

  private createFilters() {

    // Date Filter
    const dates = this.sessionItems.map((s) => {
      return moment(s.start)
    });

    const uniqueDatesAsArray = [
      ...new Set(dates.map((date) => date.format("YYYY-MM-DD"))),
    ].map((s) => moment(s))

    uniqueDatesAsArray.sort((a, b) => {
      return a.diff(b);
    });

    this.dateFilter = uniqueDatesAsArray.map(d => d.format('DD/MM/YYYY'));
    this.selectedDate = '';
    uniqueDatesAsArray.forEach(d => {
      if (moment().isSame(d, 'day'))
      {
        this.selectedDate = d.format('DD/MM/YYYY')
        this.setupFilters();
      }
    });

    if (!this.selectedDate) {
      this.selectedDate = this.dateFilter[0];
      this.setupFilters();
    }

    // Theme Filter
    var unique = [];
    const themes = this.sessionItems.map((i) => {
      return { theme: i.theme ?? '', colour: i.colour ?? '' };
    });
    for (let i = 0; i < themes.length; i++) {
      if (themes[i].theme && !unique[themes[i].theme]) {
        this.themeFilter.push(themes[i]);
        unique[themes[i].theme] = 1;
      }
    }
    this.themeFilter.sort((a, b) => {
      const nameA = a.theme?.toUpperCase();
      const nameB = b.theme?.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    // Type Filter
    unique = [];
    const types = this.sessionItems.map((i) => {
      return i.type ?? '';
    });
    for (let i = 0; i < types.length; i++) {
      if (types[i] && !unique[types[i]]) {
        this.typeFilter.push(types[i]);
        unique[types[i]] = 1;
      }
    }
    this.typeFilter.sort();

    // Room Filter
    unique = [];
    const rooms = this.sessionItems.map((i) => {
      return { roomName: i.roomName ?? '', roomOrder: i.roomOrder ?? 0 };
    });
    for (let i = 0; i < rooms.length; i++) {
      if (!unique[rooms[i].roomName]) {
        this.roomFilter.push(rooms[i]);
        unique[rooms[i].roomName] = 1;
      }
    }
    this.roomFilter.sort(function (a, b) {
      return a.roomOrder - b.roomOrder;
    });

    // Time Filter TODO: Only show times for currently selected date?
    unique = [];
    const times = this.sessionItems.map((s) => {
      return moment(s.start).format('HH:mm');
    });
    this.timeFilter = times.filter(uniqueFilter);
    this.timeFilter.sort();
  }

  private setupFilters()
  {
    this.filteredSessionItems = this.filterSessionsByDate(this.selectedDate);
    this.filteredDate = moment(this.selectedDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.applyFilters();
  }

  private async eventSelected(ev: any): Promise<void> {
    await this.sessionSelected(ev.event);
  }

  private filterSessionsByDate(date: string): ProgrammeSessionItem[] {
    return this.sessionItems.filter((s) => {
      return moment(s.start).format('DD/MM/YYYY') === date;
    });
  }

  private firstInterval(sessions: ProgrammeSessionItem[]): number {
    const starts = sessions.map((s) => moment(s.start));
    const earliest = moment.min(starts);
    const nearestPastQtr = this.nearestPastMinutes(15, moment(earliest));
    const startMidnight = nearestPastQtr.clone().startOf('day');
    const diffStartMinutes = nearestPastQtr.diff(startMidnight, 'minutes');
    const startInterval = Math.floor(diffStartMinutes / 15);
    return startInterval;
  }

  private formatDate(start: Date) {
    let s = '';
    if (start) {
      s = moment(start).format('DD/MM');
    }
    return s;
  }

  private formatDateWithYear(date: Date)
  {
    let s = '';
    if (date) {
      s = moment(date).format('DD/MM/YYYY');
    }
    return s;
  }

  private formatStartEnd(start: Date, end: Date) {
    let s = '';
    let e = '';
    if (start) {
      s = moment(start).format('HH:mm');
    }
    if (end) {
      e = moment(end).format('HH:mm');
    }
    return `${s}-${e}`;
  }

  private startEndTimeDifference(start: Date, end: Date) {
    if (start && end) {
      return moment(end).diff(moment(start), 'minutes');
    }
  }

  private formatLongDate(start: Date, end: Date) {
    let s = '';
    let e = '';
    if (start) {
      s = moment(start).locale(this.$i18n.locale).format('ddd DD MMM HH:mm');
    }

    if (end) {
      e = moment(end).locale(this.$i18n.locale).format('HH:mm');
    }
    return `${s} - ${e}`;
  }

  private getCategories(
    sessions: ProgrammeSessionItem[],
  ): (string | undefined)[] {
    const categories = [...new Set(sessions.map((s) => s.category))];
    return categories;
  }

  private getEventColor(event: ProgrammeSessionItem): string {
    var colour = '';
    if (event.colour) {
      colour = event.colour;
    } else {
      colour = '#0000FF';
    }

    if (colour.length === 9) {
      if (event.filtered) {
        colour = colour.slice(0, -2) + '80';
      }
    } else {
      if (event.filtered) {
        colour += '80';
      } else {
        colour += 'FF';
      }
    }
    return colour;
  }

  private intervalCount(sessions: ProgrammeSessionItem[]): number {
    const starts = sessions.map((s) => moment(s.start));
    const ends = sessions.map((s) => moment(s.end));
    const earliest = moment.min(starts);
    const latest = moment.max(ends);
    const nearestPastQtr = this.nearestPastMinutes(15, moment(earliest));
    const midnight = nearestPastQtr.clone().startOf('day');
    const diffStartMinutes = nearestPastQtr.diff(midnight, 'minutes');
    const startInterval = Math.floor(diffStartMinutes / 15);
    const nearestFutureQtr = this.nearestFutureMinutes(15, moment(latest));
    const diffEndMinutes = nearestFutureQtr.diff(midnight, 'minutes');
    const endInterval = Math.floor(diffEndMinutes / 15);
    const temp = endInterval - startInterval;
    return temp;
  }

  private async LoadSelectedSession(sessionId: string) {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      let url = `/api/v2/platform/${platformId}/programme/${sessionId}`;
      const res = await Vue.$http.get<ProgrammeSessionItem>(url);
      if (res.data) {
        this.selectedSession = res.data;
        if (
          this.selectedSession.presentations &&
          this.selectedSession.presentations.length > 0
        ) {
          this.selectedSession.presentations.sort((a, b) => {
            return a.order - b.order;
          });
        }
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    } catch (error) {
      console.log(error);
      this.dialog = false;
    }
  }

  private async loadSessions() {
    const platformId = sessionStorage.getItem('platformId') ?? '';

    try {
      let url = `/api/v2/platform/${platformId}/programme/${window.location.search}`;
      const pData = await Vue.$http.get<ProgrammeData>(url);
      if (pData.data) {
        this.title = pData.data.title;
        this.title_en = pData.data.title_en;
        this.title_fr = pData.data.title_fr;
        this.calendarIntervalHeight = pData.data.calendarIntervalHeight ?? 48;
        if (this.userProgramme) {
          const userSessions = pData.data.sessions.filter((s) => {
            return this.isABookmark(s.id);
          });
          if (userSessions.length < 1) {
            this.noUserSessions = true;
          } else {
            this.noUserSessions = false;
          }
          this.sessionItems = userSessions;
        } else {
          this.sessionItems = pData.data.sessions;
        }
      }
      this.createFilters();

      //this.selectedDate = moment().format('DD/MM/YYYY');
      (this.$refs.calendar as any);
      this.loaded = true;
    } catch (e: any) {
      AppInsightsLogger.logError('Programme - created failed', undefined, true);
      AppInsightsLogger.logException(e, false);
    }
  }

  private maxLines(ev: ProgrammeSessionItem): number {
    const s = moment(ev.start);
    const e = moment(ev.end);
    const time = e.diff(s, 'minutes');
    const maxLines = Math.floor(time / 10);
    return maxLines;
  }

  private navigateToSpeaker(pres: ProgrammePresentationItem) {
    if (pres.speakerNameOverride) return;

    if (!pres.speakerId) return;

    this.$router.push({
      name: 'speakers',
      query: {
        search: pres.speakerName,
        id: pres.speakerId,
        speaker: 'true',
      },
    });
  }

  private nearestFutureMinutes(
    interval: number,
    someMoment: moment.Moment,
  ): moment.Moment {
    const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
  }

  private nearestPastMinutes(
    interval: number,
    someMoment: moment.Moment,
  ): moment.Moment {
    const roundedMinutes =
      Math.floor(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
  }

  private async openNewTab(url: string) {
    if (url.startsWith('https:') || url.startsWith('http:')) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else {
      this.$router.push(url);
    }
  }

  private async sessionSelected(session: ProgrammeSessionItem): Promise<void> {
    if (session.externalContentUri) {
      this.openNewTab(session.externalContentUri);
    } else {
      await this.LoadSelectedSession(session.id);
    }
  }

  private viewPresentationDescripion(
    presentation: ProgrammePresentationItem,
  ): void {
    if (presentation.description) {
      this.selectedPresentation = presentation;
      this.dialog2 = true;
    } else {
      this.dialog2 = false;
      this.selectedPresentation = null;
    }
  }

  private hidePresentationDescription(): void {
    this.dialog2 = false;
    this.selectedPresentation = null;
  }

  private async viewSession(
    session: ProgrammeSessionItem | null,
  ): Promise<void> {
    if (session && session.hasContent) {
      if (session.externalContentUri) {
        this.openNewTab(session.externalContentUri);
      } else {
        this.$router.push({
          name: 'session',
          params: { sessionId: session.id },
        });
      }
    }
  }
}
