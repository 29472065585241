export interface SignalrState {
  connectionError: string;
  currentGroup?: SignalrGroup;
  currentGroupMessages: SignalrMessage[];
  maxMessageStorelimit: number;
  signalrConnected: boolean;
  userConnected: boolean;
}
export interface ErrorMessage {
  hasError: boolean;
  message: string;
}

export interface SignalrMessage {
  eventCode: string;
  eventId: string;
  group: string;
  groupCount?: number;
  groupId: string;
  groupType: string;
  id?: string;
  isApproved?: boolean;
  isCurrent?: boolean;
  isDeleted?: boolean;
  isMod?: boolean;
  message?: string;
  messageType: string;
  parentId?: string;
  roleName?: string;
  timeStamp?: string;
  userId: string;
  userName: string;
  userPhotoUri?: string;
}

export interface SignalrGroup {
  group: string;
  groupCount: number;
  groupId: string;
  groupType: string;
  unreadCount: number;
}

export enum SignalrMessageTypes {
  CONNECT_USER = 'CONNECT_USER',
  ENTER_GROUP = 'ENTER_GROUP',
  LEAVE_GROUP = 'LEAVE_GROUP',
  POST_CHAT = 'POST_CHAT',
  REMOVE_CHAT = 'REMOVE_CHAT',
  USER_GROUP_ENTERED = 'USER_GROUP_ENTERED',
  USER_GROUP_LEFT = 'USER_GROUP_LEFT',
}

export enum SignalrMessageEvents {
  CHAT_POSTED = 'CHAT_POSTED',
  CHAT_REMOVED = 'CHAT_REMOVED',
  GROUP_ENTERED = 'GROUP_ENTERED',
  GROUP_LEFT = 'GROUP_LEFT',
  QUESTION_APPROVED = 'QUESTION_APPROVED',
  QUESTION_HIDDEN = 'QUESTION_HIDDEN',
  QUESTION_POSTED = 'QUESTION_POSTED',
  QUESTION_REPLY_POSTED = 'QUESTION_REPLY_POSTED',
  REFRESH_QUESTIONS = 'REFRESH_QUESTIONS',
  SIGNALR_CONNECTED = 'SIGNALR_CONNECTED',
  SIGNALR_ERROR = 'SIGNALR_ERROR',
  USER_CONNECTED = 'USER_CONNECTED',
  USER_ENTERED_LEFT_ROOM = 'USER_ENTERED_LEFT_ROOM',
  LOUNGE_GROUP_CREATED = 'LOUNGE_GROUP_CREATED',
  LOUNGE_GROUP_DELETED = 'LOUNGE_GROUP_DELETED',
}

export enum SignalrGroupTypes {
  EVENT = 'EVENT',
  LOUNGE = 'LOUNGE',
  LOUNGE_FOYER = 'LOUNGE_FOYER',
  SESSION = 'SESSION',
  SPONSOR = 'SPONSOR',
  SPONSOR_LOUNGE = 'SPONSOR_LOUNGE',
  SPONSOR_LOUNGE_FOYER = 'SPONSOR_LOUNGE_FOYER',
}

export enum SignalrRoleNames {
  LOUNGEMOD = 'LOUNGEMOD',
  SESSIONMOD = 'SESSIONMOD',
  SESSIONMODANDSPEAKER = 'SESSIONMODANDSPEAKER',
  SPEAKER = 'SPEAKER',
  SPONSORSTAFF = 'SPONSORSTAFF',
  STAFF = 'STAFF',
  USER = 'USER',
}

export enum SignalrGetters {
  MESSAGES_BY_GROUP_ID = 'GET_MESSAGES_BY_GROUP_ID',
  FAVOURITE_GROUPS = 'FAVOURITE_GROUPS',

  SIGNALR_CONNECTED = 'SIGNALR_CONNECTED',
  USER_CONNECTED = 'USER_CONNECTED',
  CURRENT_GROUP = 'CURRENT_GROUP',
  CURRENT_GROUP_MESSAGES = 'CURRENT_GROUP_MESSAGES',
  CURRENT_MESSAGE_BY_ID = 'CURRENT_MESSAGE_BY_ID',
}

export enum SignalrActions {
  CHAT_POSTED = 'CHAT_POSTED',
  CHAT_REMOVED = 'CHAT_REMOVED',
  CLEAR_CURRENT_GROUP_MESSAGES = 'CLEAR_CURRENT_GROUP_MESSAGES',
  CLEAR_CURRRENT_GROUP = 'CLEAR_CURRRENT_GROUP',
  CONNECT_SIGNALR = 'CONNECT_SIGNALR',
  CONNECT_USER = 'CONNECT_USER',
  ENTERED_GROUP = 'ENTERED_GROUP',
  ENTER_GROUP = 'ENTER_GROUP',
  LEAVE_GROUP = 'LEAVE_GROUP',
  LEFT_GROUP = 'LEFT_GROUP',
  POST_CHAT_MESSAGE = 'POST_CHAT_MESSAGE',
  QUESTION_APPROVED = 'QUESTION_APPROVED',
  QUESTION_HIDDEN = 'QUESTION_HIDDEN',
  QUESTION_POSTED = 'QUESTION_POSTED',
  QUESTION_REPLY_POSTED = 'QUESTION_REPLY_POSTED',
  REMOVE_CHAT_MESSAGE = 'REMOVE_CHAT_MESSAGE',
  SET_CURRENT_GROUP = 'SET_CURRENT_GROUP',
  SET_CURRENT_GROUP_MESSAGES = 'SET_CURRENT_GROUP_MESSAGES',
  SET_MESSAGES = 'SET_MESSAGES',
  SIGNALR_CONNECTED = 'SIGNALR_CONNECTED',
  SIGNALR_DISCONNECTED = 'SIGNALR_DISCONNECTED',
  SIGNALR_ERROR = 'SIGNALR_ERROR',
  UPDATE_CURRENT_ROOM_COUNT = 'UPDATE_CURRENT_ROOM_COUNT',
  UPDATE_QUESTION = 'UPDATE_QUESTION',
  USER_CONNECTED = 'USER_CONNECTED',
  USER_DISCONNECTED = 'USER_DISCONNECTED',
  USER_ENTERED_LEFT_ROOM = 'USER_ENTERED_LEFT_ROOM',
}

export enum SignalrMutations {
  ADD_MESSAGE_TO_CURRENT_GROUP = 'ADD_MESSAGE_TO_CURRENT_GROUP',
  CLEAR_CURRENT_GROUP_MESSAGES = 'CLEAR_CURRENT_GROUP_MESSAGES',
  CLEAR_CURRRENT_GROUP = 'CLEAR_CURRRENT_GROUP',
  REMOVE_MESSAGE_FROM_CURRENT_GROUP = 'REMOVE_MESSAGE_FROM_CURRENT_GROUP',
  SET_CONNECTION_ERROR = 'SET_CONNECTION_ERROR',
  SET_CONNECTION_STATE = 'SET_CONNECTION_STATE',
  SET_CURRENT_GROUP = 'SET_CURRENT_GROUP',
  SET_CURRENT_GROUP_MESSAGES = 'SET_CURRENT_GROUP_MESSAGES',
  SET_USER_CONNECTED_STATE = 'SET_USER_CONNECTED_STATE',
  UPDATE_CURRENT_ROOM_COUNT = 'UPDATE_CURRENT_ROOM_COUNT',
}
