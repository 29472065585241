import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { PlatformGetters, PlatformState } from './types';

export const getters: GetterTree<PlatformState, RootState> = {
  [PlatformGetters.ALLOW_ABSTRACT_DOWNLOAD](state): boolean {
    return state.allowAbstractDownload;
  },

  [PlatformGetters.ALLOW_PRESENTATION_DOWNLOAD](state): boolean {
    return state.allowPresentationDownload;
  },

  [PlatformGetters.ALWAYS_USER_DEFAULT_THUMBNAIL](state): boolean {
    return state.alwaysUseDefaultThumbnail;
  },

  [PlatformGetters.LOGIN_BACKGROUND_IMAGE_URI](state): string {
    return state.loginBackgroundImageUri;
  },

  [PlatformGetters.LOGIN_CARD_BANNER_IMAGE_URI](state): string {
    return state.loginCardBannerImageUri;
  },

  [PlatformGetters.LOUNGE_IMAGE_URI](state): string {
    return state.loungeImageUri;
  },

  [PlatformGetters.PORTAL_BACKGROUND_URI](state): string {
    return state.portalBackgroundUri;
  },

  [PlatformGetters.PORTAL_BANNER_URI](state): string {
    return state.portalBannerUri;
  },

  [PlatformGetters.PORTAL_HELP_DOCUMENT_URI](state): string {
    return state.helpDocumentUri;
  },

  [PlatformGetters.ENABLE_SESSION_FILTER_IN_POSTER_MODULE](state): boolean {
    return state.enableSessionFilterInPosterModule;
  },

  [PlatformGetters.EXTERNAL_CREATE_ACCOUNT_URI](state): string {
    return state.externalCreateAccountUri;
  },

  [PlatformGetters.EXTERNAL_FORGOT_PASSWORD_URI](state): string {
    return state.externalForgotPasswordUri;
  },

  [PlatformGetters.SEND_FORGOTTEN_PASSWORD_URI](state): string {
    return state.sendForgottenPassowrdUri;
  },

  [PlatformGetters.PLATFORM_ID](state): string {
    return state.eventId;
  },

  [PlatformGetters.SHOW_VIEWS](state): boolean {
    return state.showViews;
  },

  [PlatformGetters.SHOW_RATINGS](state): boolean {
    return state.showRatings;
  },

  [PlatformGetters.SHOW_LIKES](state): boolean {
    return state.showLikes;
  },

  [PlatformGetters.SHOW_PRESENTATION_DURATION_IN_PROGRAMME](state): boolean {
    return state.showPresentationDurationInProgramme;
  },

  [PlatformGetters.SHOW_KEYWORDS](state): boolean {
    return state.showKeywords;
  },

  [PlatformGetters.SHOW_ALL_PARTICIPANT_COUNT](state): boolean {
    return state.showAllParticipantCount;
  },

  [PlatformGetters.SHOW_SPEAKER_COUNT](state): boolean {
    return state.showSpeakerCount;
  },

  [PlatformGetters.SHOW_POSTER_COUNT](state): boolean {
    return state.showPosterCount;
  },

  [PlatformGetters.SHOW_VOD_COUNT](state): boolean {
    return state.showVodCount;
  },

  [PlatformGetters.GDPR_ACCEPT_TEXT](state): string {
    return state.gdprAcceptText;
  },

  [PlatformGetters.GDPR_DECLINE_TEXT](state): string {
    return state.gdprDeclineText;
  },

  [PlatformGetters.GDPR_TEXT](state): string {
    return state.gdprText;
  },

  [PlatformGetters.DEMAND_GDPR](state): boolean {
    return state.demandGdpr;
  },

  [PlatformGetters.CALENDAR_INTERVAL_HEIGHT](state): string {
    return state.calendarIntervalHeight;
  },

  [PlatformGetters.BANNER_BAR_BACKGROUND_COLOUR](state): string {
    if (state.bannerBarBackgroundColour) {
      return state.bannerBarBackgroundColour;
    } else {
      return '#FFFFFF';
    }
  },

  [PlatformGetters.BANNER_BAR_TEXT_COLOUR](state): string {
    if (state.bannerBarTextColour) {
      return state.bannerBarTextColour;
    } else {
      return '#000000';
    }
  },

  [PlatformGetters.NAV_BAR_BACKGROUND_COLOUR](state): string {
    if (state.navBarBackgroundColour) {
      return state.navBarBackgroundColour;
    } else {
      return '#212121';
    }
  },

  [PlatformGetters.NAV_BAR_TEXT_COLOUR](state): string {
    if (state.navBarTextColour) {
      return state.navBarTextColour;
    } else {
      return '#FFFFFF';
    }
  },

  [PlatformGetters.DEFAULT_SESSION_THUMBNAIL_URI](state): string {
    return state.defaultSessionThumbnailUri;
  },

  [PlatformGetters.HELP_DOCUMENT_URI](state): string {
    return state.helpDocumentUri;
  },

  [PlatformGetters.PORTAL_BANNER_CLIENT_URI](state): string {
    return state.portalBannerClientUri;
  },

  [PlatformGetters.LOGIN_CARD_BANNER_CLIENT_URI](state): string {
    return state.loginCardBannerClientUri;
  },

  [PlatformGetters.PORTAL_OPEN](state): boolean {
    return state.open;
  },

  [PlatformGetters.PORTAL_CLOSED_TEXT](state): string {
    return state.closedText;
  },

  [PlatformGetters.NAV_BAR_MINI](state): boolean {
    return state.navigationBarMini;
  },

  [PlatformGetters.PROGRAMME_MODULE_ENABLED ](state): boolean {
    return state.programmeModuleEnabled;
  },

  [PlatformGetters.VOD_MODULE_ENABLED](state): boolean {
    return state.vodModuleEnabled;
  },

  [PlatformGetters.POSTER_MODULE_ENABLED](state): boolean {
    return state.posterModuleEnabled;
  },

  [PlatformGetters.EXHIBITION_MODULE_ENABLED](state): boolean {
    return state.exhibitionModuleEnabled;
  },

  [PlatformGetters.SPEAKERS_MODULE_ENABLED](state): boolean {
    return state.speakersModuleEnabled;
  },

  [PlatformGetters.ALL_PARTICIPANTS_MODULE_ENABLED](state): boolean {
    return state.allParticipantsModuleEnabled;
  },

  [PlatformGetters.LIVE_SESSION_MODULE_ENABLED](state): boolean {
    return state.liveSessionModuleEnabled;
  }
};
