
import Background from '@/components/Background.vue';
import HomeVodSessionGroup from '@/components/HomeVodSessionGroup.vue';
import SessionDetails from '@/components/SessionDetails.vue';
import { AppInsightsLogger } from '@/services/appInsightsLogger';
import {
  SessionActions,
  SessionGetters,
  HomeVodItems,
  SessionItem,
} from '@/store/session/types';
import { Getter, Action } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import {
  BookmarkActions,
  BookmarkEntityType,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '@/store/bookmark/types';
const bookmarkNamespace = 'bookmark';
const sessionNamespace = 'session';

@Component({
  components: {
    Background,
    SessionDetails,
    HomeVodSessionGroup,
  },
})
export default class HomeVod extends Vue {
  /* PUBLIC PROPERTIES */

  /* PRIVATE PROPERTIES */
  private items: HomeVodItems | null | undefined = null;
  private dialog = false;

  /* VUEX GETTERS */
  // Bookmark Getters
  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  @Getter(BookmarkGetters.IS_A_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private isABookmark!: (entityId: string) => boolean;
  @Getter(SessionGetters.LOADING_SESSIONS, { namespace: sessionNamespace })
  private loading!: boolean;

  // Session Getters
  @Getter(SessionGetters.SELECTED_SESSION, { namespace: sessionNamespace })
  private selectedSession!: SessionItem;

  /* VUEX ACTIONS */
  // Bookmark Actions
  @Action(BookmarkActions.ADD_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private bookmarkItem!: (bookmark: BookmarkItem) => Promise<void>;

  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  // Session Actions
  @Action(SessionActions.SET_LOADING_SESSIONS, { namespace: sessionNamespace })
  private setLoadingSessions!: (val: boolean) => void;

  @Action(SessionActions.LOAD_SELECTED_SESSION, { namespace: sessionNamespace })
  private loadSelectedSession!: ({
    isMod,
    noq,
    sessionId,
  }: {
    isMod: boolean;
    noq: boolean;
    sessionId: string;
  }) => Promise<boolean>;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get cssVars(): unknown {
    let col = this.items?.backgroundColour
      ? this.items.backgroundColour
      : '#141414';
    let res = {
      '--image-bottom-gradient': `linear-gradient(
          to bottom,
          ${this.hexToRGB(col, 0.01)} 0,
          ${this.hexToRGB(col, 0.15)} 15%,
          ${this.hexToRGB(col, 0.35)} 29%,
          ${this.hexToRGB(col, 0.58)} 44%,
          ${this.hexToRGB(col)} 68%,
          ${this.hexToRGB(col)} 100%
        )`,
      '--background-colour': `${col}`,
    };
    return res;
  }

  get textColour(): string {
    if (this.items && this.items.textColour) {
      return this.items.textColour;
    }
    return '#030303';
  }

  get backgroundColour(): string {
    if (this.items && this.items.backgroundColour) {
      return this.items.backgroundColour;
    }
    return '#141414';
  }

  get pinnedSessionButtonSize(): Record<string, unknown> {
    const size = {
      xs: 'small',
      sm: 'small',
      md: 'large',
      lg: 'large',
      xl: 'x-large',
    }[this.$vuetify.breakpoint.name];
    return size ? { [size]: true } : {};
  }

  get pinnedSession(): SessionItem | undefined {
    return this.items?.pinnedSession;
  }

  /* PRIVATE METHODS*/
  private async beforeMount() {
    await this.loadData();
  }

  private bookmarkExecute(s: SessionItem | null) {
    if (!s) return;
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const bi = this.getBookmark(s.id);
    if (bi) {
      this.unbookmarkItem(bi);
    } else {
      let t = '';
      let et = '';
      let eu = '';
      t = BookmarkType.VIDEO;
      et = BookmarkEntityType.SESSION;
      eu = `/${eventCode}/session/${s.id}`;

      const bm: BookmarkItem = {
        end: s.end,
        entityId: s.id,
        entityType: et,
        type: t,
        entityUri: eu,
        start: s.start,
        title: s.title,
      };
      this.bookmarkItem(bm);
    }
  }

  private async loadData(): Promise<void> {
    this.setLoadingSessions(true);
    const platformId = sessionStorage.getItem('platformId') ?? '';

    try {
      const res = await Vue.$http.get<HomeVodItems>(
        `/api/platform/${platformId}/sessions/homevod`,
      );
      this.items = res.data;
    } catch (e: any) {
      AppInsightsLogger.logError('HomeVod - loadData failed', undefined, true);
      AppInsightsLogger.logException(e, false);
    }
    this.setLoadingSessions(false);
  }

  private hexToRGB(hex: string, alpha = 0): string {
    if (hex.indexOf('#') > -1) {
      hex = hex.substring(1);
    }
    let r: number, g: number, b: number;

    if (hex.length == 3) {
      r = parseInt(hex.slice(0, 1) + hex.slice(0, 1), 16);
      g = parseInt(hex.slice(1, 2) + hex.slice(1, 2), 16);
      b = parseInt(hex.slice(2, 3) + hex.slice(2, 3), 16);
    } else {
      r = parseInt(hex.slice(0, 2), 16);
      g = parseInt(hex.slice(2, 4), 16);
      b = parseInt(hex.slice(4, 6), 16);
    }

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
      return `rgb(${r}, ${g}, ${b})`;
    }
  }

  private async showMoreInfo(session: SessionItem | undefined) {
    if (!session) return;
    await this.loadSelectedSession({
      isMod: false,
      sessionId: session.id,
      noq: true,
    });
    this.dialog = true;
  }

  private async openNewTab(url: string | undefined) {
    if (url && (url.startsWith('https:') || url.startsWith('http:'))) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else if (url) {
      await this.$router.push(url);
    }
  }

  private sessionActive(session: SessionItem | null): boolean {
    if (!session) return false;
    if (
      this.validVideoIFrameUri(session.videoIFrameUri) ||
      (session.isExternalContentSession && session.externalContentUri)
    ) {
      return true;
    }
    return false;
  }

  private async sessionSelected(session: SessionItem | undefined | null) {
    if (!session) return;
    if (session.isExternalContentSession) {
      this.openNewTab(session.externalContentUri);
    } else if (this.sessionActive(session)) {
      if (session.isSession) {
        const sessionId = session.id;
        await this.$router.push({
          name: 'session',
          params: { sessionId },
        });
      }
    }
  }

  private validVideoIFrameUri(uri?: string): boolean {
    if (uri && uri.toLowerCase().startsWith('https:')) {
      return true;
    }
    return false;
  }
}
