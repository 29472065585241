import { ActionTree } from 'vuex';
import { RootState } from '../types';
import {
  NavigationActions,
  NavigationMutations,
  NavigationState,
} from './types';

export const actions: ActionTree<NavigationState, RootState> = {
  [NavigationActions.NAVIGATION_INVERT_MINI_VARIANT]({ commit }): void {
    commit(NavigationMutations.NAVIGATION_INVERT_MINI_VARIANT);
  },

  [NavigationActions.NAVIGATION_INVERT_DRAWER]({ commit }): void {
    commit(NavigationMutations.NAVIGATION_INVERT_DRAWER);
  },

  [NavigationActions.NAVIGATION_SET_DRAWER]({ commit }, value: boolean): void {
    commit(NavigationMutations.NAVIGATION_SET_DRAWER, value);
  },

  [NavigationActions.ACTIVITY_INVERT_MINI_VARIANT]({ commit }): void {
    commit(NavigationMutations.ACTIVITY_INVERT_MINI_VARIANT);
  },

  [NavigationActions.ACTIVITY_INVERT_DRAWER]({ commit }): void {
    commit(NavigationMutations.ACTIVITY_INVERT_DRAWER);
  },

  [NavigationActions.ACTIVITY_SET_DRAWER]({ commit }, value: boolean): void {
    commit(NavigationMutations.ACTIVITY_SET_DRAWER, value);
  },

  [NavigationActions.PROFILE_INVERT_DIALOG]({ commit }): void {
    commit(NavigationMutations.PROFILE_INVERT_DIALOG);
  },

  [NavigationActions.SET_MOBILE_PAGE_TITLE]({ commit }, value: string): void {
    commit(NavigationMutations.SET_MOBILE_PAGE_TITLE, value);
  },
};
