import axios, { AxiosRequestConfig } from 'axios';
import { IJwtBody } from './jwtBody';

export class TokenStorage {
  public static isAuthenticated(): boolean {
    return this.getToken() !== null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static getAuthentication(tokenOnly = false): any {
    if (tokenOnly) {
      return this.getToken();
    } else {
      return {
        Authorization: `Bearer ${this.getToken()}`,
      };
    }
  }

  public static getNewToken(): Promise<string> {
    const tokenUrl = `${process.env.VUE_APP_OPEN_ID_AUTHORITY}/connect/token`;

    const requestOptions: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        common: {
          Authorization: undefined,
        },
      },
      method: 'POST',
    };

    const params = new URLSearchParams();
    const token = this.getRefreshToken();
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', token ? token : '');

    return new Promise((resolve, reject) => {
      axios
        .post<IJwtBody>(tokenUrl, params, requestOptions)
        .then((response) => {
          this.storeToken(response.data.access_token);
          resolve(response.data.access_token);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static storeToken(token: string): void {
    if (this.getRemeberMeState()) {
      localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
    } else {
      sessionStorage.setItem(TokenStorage.SESSION_STORAGE_TOKEN, token);
    }
    axios.defaults.headers.common.Authorization = TokenStorage.getAuthentication().Authorization;
  }

  public static storeRefreshToken(refreshToken: string): void {
    if (this.getRemeberMeState()) {
      localStorage.setItem(
        TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN,
        refreshToken,
      );
    } else {
      sessionStorage.setItem(
        TokenStorage.SESSION_STORAGE_REFRESH_TOKEN,
        refreshToken,
      );
    }
  }

  public static storeRememberMe(value: boolean): void {
    localStorage.setItem(
      TokenStorage.LOCAL_STORAGE_REMEMBER_ME,
      value.toString(),
    );
  }

  public static clear(): void {
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REMEMBER_ME);
    sessionStorage.removeItem(TokenStorage.SESSION_STORAGE_TOKEN);
    sessionStorage.removeItem(TokenStorage.SESSION_STORAGE_REFRESH_TOKEN);
    axios.defaults.headers.common.Authorization = undefined;
  }

  private static readonly LOCAL_STORAGE_TOKEN = 'refresh_token';
  private static readonly LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';
  private static readonly LOCAL_STORAGE_REMEMBER_ME = 'remember_me';
  private static readonly SESSION_STORAGE_TOKEN = 'token';
  private static readonly SESSION_STORAGE_REFRESH_TOKEN = 'refresh_token';

  private static getRemeberMeState(): boolean {
    return (
      localStorage.getItem(TokenStorage.LOCAL_STORAGE_REMEMBER_ME) === 'true'
    );
  }

  private static getRefreshToken(): string | null {
    if (this.getRemeberMeState()) {
      return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
    } else {
      return sessionStorage.getItem(TokenStorage.SESSION_STORAGE_REFRESH_TOKEN);
    }
  }

  private static getToken(): string | null {
    if (this.getRemeberMeState()) {
      return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    } else {
      return sessionStorage.getItem(TokenStorage.SESSION_STORAGE_TOKEN);
    }
  }
}
