import { render, staticRenderFns } from "./Avatar.vue?vue&type=template&id=8d1f448e&scoped=true&"
import script from "./Avatar.vue?vue&type=script&lang=ts&"
export * from "./Avatar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d1f448e",
  null
  
)

export default component.exports