import { NewSessionQuestionMessage } from '@/models/NewSessionQuestionMessage';
import { ReplyQuestionMessage } from '@/models/ReplyQuestionMessage';
import { AppInsightsLogger } from '@/services/appInsightsLogger';
import Vue from 'vue';
import { ActionTree } from 'vuex';
import router from '../../router';
import { RootState } from '../types';
import {
  PosterSessionItem,
  RoomItem,
  SessionActions,
  SessionGetters,
  SessionItem,
  SessionMutations,
  SessionQuestionItem,
  SessionState,
} from './types';

// function hasKey<O>(obj: O, key: keyof any): key is keyof O {
//   return key in obj;
// }

export const actions: ActionTree<SessionState, RootState> = {
  [SessionActions.APPLY_INITIAL_QUERY_FILTERS]({ commit }) {
    const q = router.currentRoute.query;

    if (q.room) {
      commit(SessionMutations.SET_SELECTED_ROOM_BY_ID, q.room);
    }

    if (q.psession) {
      commit(SessionMutations.SET_SELECTED_POSTER_SESSION_BY_ID, q.psession);
    }

    if (q.type) {
      commit(SessionMutations.SET_SELECTED_TYPE, q.type);
    }

    if (q.theme) {
      commit(SessionMutations.SET_SELECTED_THEME, q.theme);
    }

    if (q.keywords) {
      commit(SessionMutations.SET_KEYWORDS, q.keywords);
    }

    if (q.listview) {
      commit(SessionMutations.SET_LIST_VIEW, q.listview);
    }
  },

  async [SessionActions.CLEAR_FILTERS](
    { commit, getters },
    push = false,
  ): Promise<void> {
    commit(SessionMutations.SET_SELECTED_THEME, undefined);
    commit(SessionMutations.SET_SELECTED_TYPE, undefined);
    commit(SessionMutations.SET_KEYWORDS, '');
    commit(SessionMutations.SET_SELECTED_ROOM, undefined);
    commit(SessionMutations.SET_SELECTED_POSTER_SESSION, undefined);
    if (push) {
      if (getters[SessionGetters.LIST_VIEW]) {
        await router.push({ query: { listview: 'true' } });
      } else {
        await router.push({});
      }
    }
  },

  [SessionActions.CLEAR_SELECTED_SESSION]({ commit }): void {
    const empty = {} as SessionItem;
    commit(SessionMutations.SET_SELECTED_SESSION, empty);
  },

  [SessionActions.SET_FILTER_ITEMS](
    { commit },
    {
      rooms,
      themes,
      types,
      keywordItems,
      posterSessions,
    }: {
      rooms: RoomItem[];
      themes: string[];
      types: string[];
      keywordItems: string[];
      posterSessions: PosterSessionItem[];
    },
  ): void {
    commit(SessionMutations.SET_ROOMS, rooms);
    commit(SessionMutations.SET_THEMES, themes);
    commit(SessionMutations.SET_TYPES, types);
    commit(SessionMutations.SET_KEYWORD_ITEMS, keywordItems);
    commit(SessionMutations.SET_POSTER_SESSIONS, posterSessions);
  },

  async [SessionActions.LOAD_SELECTED_SESSION](
    { commit },
    {
      sessionId,
      isMod,
      noq = false,
    }: { sessionId: string; isMod: boolean; noq: boolean },
  ): Promise<boolean> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get<SessionItem>(
        `/api/platform/${platformId}/sessions/${sessionId}?check=${isMod}&noq=${noq}`,
      );
      commit(SessionMutations.SET_SELECTED_SESSION, res.data);
      return true;
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - loadSelectedSession failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
      return false;
    }
  },

  [SessionActions.SET_LIST_VIEW]({ commit }, listView: boolean): void {
    commit(SessionMutations.SET_LIST_VIEW, listView);
  },

  [SessionActions.SET_SELECTED_SESSION](
    { commit },
    session: SessionItem,
  ): void {
    commit(SessionMutations.SET_SELECTED_SESSION, session);
  },

  [SessionActions.SET_SELECTED_SESSION_QUESTIONS](
    { commit },
    questions: SessionQuestionItem[],
  ): void {
    commit(SessionMutations.SET_SELECTED_SESSION_QUESTIONS, questions);
  },

  [SessionActions.SET_SESSION_DATA](
    { commit },
    data: { sessions: SessionItem[]; totalSessionCount: number },
  ): void {
    commit(SessionMutations.SET_SESSION_DATA, data);
  },

  [SessionActions.SET_RELATED_SESSION_DATA](
    { commit },
    sessions: SessionItem[],
  ): void {
    commit(SessionMutations.SET_RELATED_SESSION_DATA, sessions);
  },

  [SessionActions.UPDATE_SELECTED_SESSION_RATING](
    { commit },
    { rating, rates }: { rating: number; rates: number },
  ) {
    commit(SessionActions.UPDATE_SELECTED_SESSION_RATING, { rating, rates });
  },

  async [SessionActions.SET_CURRENT_PAGE](
    { commit },
    page: number,
  ): Promise<void> {
    commit(SessionMutations.SET_CURRENT_PAGE, page);
  },

  async [SessionActions.SET_KEYWORDS](
    { commit },
    keywords: string,
  ): Promise<void> {
    commit(SessionMutations.SET_KEYWORDS, keywords);
    commit(SessionMutations.SET_CURRENT_PAGE, 1);
    if (keywords) {
      await router.push({
        query: { ...router.currentRoute.query, keywords, page: undefined },
      });
    } else {
      await router.push({
        query: {
          ...router.currentRoute.query,
          keywords: undefined,
          page: undefined,
        },
      });
    }
  },

  [SessionActions.SET_LOADING_SESSIONS]({ commit }, val: boolean): void {
    commit(SessionMutations.SET_LOADING_SESSIONS, val);
  },

  [SessionActions.SET_SELECTED_SESSION_AUTO_APPROVE_QUESTIONS](
    { commit },
    val: boolean,
  ): void {
    commit(SessionMutations.SET_SELECTED_SESSION_AUTO_APPROVE_QUESTIONS, val);
  },

  async [SessionActions.SET_SELECTED_POSTER_SESSION](
    { commit },
    posterSession: PosterSessionItem,
  ): Promise<void> {
    commit(SessionMutations.SET_SELECTED_POSTER_SESSION, posterSession);
    commit(SessionMutations.SET_CURRENT_PAGE, 1);
    if (posterSession) {
      await router.push({
        query: {
          ...router.currentRoute.query,
          psession: posterSession.id,
          page: undefined,
        },
      });
    }
  },

  async [SessionActions.SET_SELECTED_ROOM](
    { commit },
    room: RoomItem,
  ): Promise<void> {
    commit(SessionMutations.SET_SELECTED_ROOM, room);
    commit(SessionMutations.SET_CURRENT_PAGE, 1);
    if (room) {
      await router.push({
        query: { ...router.currentRoute.query, room: room.id, page: undefined },
      });
    }
  },

  async [SessionActions.SET_SELECTED_THEME](
    { commit },
    theme: string,
  ): Promise<void> {
    commit(SessionMutations.SET_SELECTED_THEME, theme);
    commit(SessionMutations.SET_CURRENT_PAGE, 1);
    if (theme) {
      await router.push({
        query: { ...router.currentRoute.query, theme, page: undefined },
      });
    }
  },

  async [SessionActions.SET_SELECTED_TYPE](
    { commit },
    type: string,
  ): Promise<void> {
    commit(SessionMutations.SET_SELECTED_TYPE, type);
    commit(SessionMutations.SET_CURRENT_PAGE, 1);
    if (type) {
      await router.push({
        query: { ...router.currentRoute.query, type, page: undefined },
      });
    }
  },

  async [SessionActions.ADD_NEW_QUESTION](
    { commit, getters },
    orgQuestion: SessionQuestionItem,
  ): Promise<void> {
    commit(SessionMutations.ADD_NEW_QUESTION, orgQuestion);
    if (getters[SessionGetters.SELECTED_SESSION_AUTO_APPROVE_QUESTIONS]) {
      const platformId = sessionStorage.getItem('platformId') ?? '';
      try {
        const response = await Vue.$http.put(
          `/api/platform/${platformId}/sessions/${orgQuestion.sessionId}/question/${orgQuestion.id}/approve`,
          orgQuestion,
        );
        if (response.status === 200) {
          const updatedQuestion = { ...orgQuestion };
          updatedQuestion.isApproved = true;
          commit(SessionMutations.UPDATE_QUESTION, {
            orgQuestion,
            updatedQuestion,
          });
        }
      } catch (e: any) {
        AppInsightsLogger.logError(
          'Player - approveQuestionApi failed',
          undefined,
          true,
        );
        AppInsightsLogger.logException(e, false);
      }
    }
  },

  [SessionActions.APPROVE_QUESTION](
    { commit, getters },
    questionId: string,
  ): void {
    const orgQuestion: SessionQuestionItem =
      getters[SessionGetters.CURRENT_SESSION_QUESTION_BY_ID](questionId);
    if (orgQuestion) {
      const updatedQuestion = { ...orgQuestion };
      updatedQuestion.isApproved = true;
      commit(SessionMutations.UPDATE_QUESTION, {
        orgQuestion,
        updatedQuestion,
      });
    }
  },

  [SessionActions.HIDE_QUESTION](
    { commit, getters },
    questionId: string,
  ): void {
    const orgQuestion: SessionQuestionItem =
      getters[SessionGetters.CURRENT_SESSION_QUESTION_BY_ID](questionId);
    if (orgQuestion) {
      const updatedQuestion = { ...orgQuestion };
      updatedQuestion.isApproved = false;
      commit(SessionMutations.UPDATE_QUESTION, {
        orgQuestion,
        updatedQuestion,
      });
    }
  },

  [SessionActions.ADD_NEW_QUESTION_REPLY](
    { commit },
    reply: SessionQuestionItem,
  ): void {
    commit(SessionMutations.ADD_NEW_QUESTION_REPLY, reply);
  },

  async [SessionActions.POST_NEW_QUESTION](
    _,
    question: NewSessionQuestionMessage,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      await Vue.$http.post(
        `/api/platform/${platformId}/sessions/${question.sessionId}/question`,
        question,
      );
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - postNewQuestionApi failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  },

  async [SessionActions.POST_QUESTION_REPLY](
    _,
    reply: ReplyQuestionMessage,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      await Vue.$http.post(
        `/api/platform/${platformId}/sessions/${reply.sessionId}/question/${reply.questionId}/reply`,
        reply,
      );
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - postReplyMessageApi failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  },

  async [SessionActions.POST_APPROVE_QUESTION](
    { commit },
    question: SessionQuestionItem,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const response = await Vue.$http.put(
        `/api/platform/${platformId}/sessions/${question.sessionId}/question/${question.id}/approve`,
        question,
      );
      if (response.status === 200) {
        const updatedQuestion = { ...question };
        updatedQuestion.isApproved = true;
        commit(SessionMutations.UPDATE_QUESTION, {
          question,
          updatedQuestion,
        });
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - approveQuestionApi failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  },

  async [SessionActions.POST_HIDE_QUESTION](
    { commit },
    question: SessionQuestionItem,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const response = await Vue.$http.put(
        `/api/platform/${platformId}/sessions/${question.sessionId}/question/${question.id}/hide`,
        question,
      );
      if (response.status === 200) {
        const updatedQuestion = { ...question };
        updatedQuestion.isApproved = false;
        commit(SessionMutations.UPDATE_QUESTION, {
          question,
          updatedQuestion,
        });
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - hideQuestionApi failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  },

  async [SessionActions.RESET_SESSION_QUESTION_USER_PHOTO_URI](
    { commit },
    question: SessionQuestionItem,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const response = await Vue.$http.put(
        `/api/platform/${platformId}/sessions/${question.sessionId}/question/${question.id}/resetphotouri`,
        question,
      );
      if (response.status === 200) {
        const updatedQuestion = { ...question };
        updatedQuestion.userPhotoUri = '';
        commit(SessionMutations.UPDATE_QUESTION, {
          question,
          updatedQuestion,
        });
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - resetSessionQuestionItemUserPhotoUri failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  },
};
