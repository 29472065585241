import { GetterTree } from 'vuex';
import { RootState } from '../types';
import {
  PlatformUserGetters,
  PlatformUserItem,
  PlatformUserState,
} from './types';

export const getters: GetterTree<PlatformUserState, RootState> = {
  [PlatformUserGetters.GET_CURRENT_PAGE](state): number {
    return state.currentPage;
  },
  [PlatformUserGetters.GET_CURRENT_PLATFORM_USER](
    state,
  ): PlatformUserItem | undefined {
    return state.currentPlatformUser;
  },
  [PlatformUserGetters.GET_CURRENT_PLATFORM_USER_FIRST_NAME](
    state,
  ): string | undefined {
    return `${state.currentPlatformUser?.firstName}`;
  },
  [PlatformUserGetters.GET_CURRENT_PLATFORM_USER_LAST_NAME](
    state,
  ): string | undefined {
    return `${state.currentPlatformUser?.lastName}`;
  },
  [PlatformUserGetters.GET_CURRENT_PLATFORM_USER_INITIALS](
    state,
  ): string | undefined {
    return `${state.currentPlatformUser?.firstName.substring(
      0,
      1,
    )} ${state.currentPlatformUser?.lastName.substring(0, 1)} `;
  },
  [PlatformUserGetters.GET_CURRENT_PLATFORM_USER_NAME](
    state,
  ): string | undefined {
    return `${state.currentPlatformUser?.userName}`;
  },
  [PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID](
    state,
  ): string | undefined {
    return state.currentPlatformUser?.userId;
  },
  [PlatformUserGetters.PLATFORM_USERS_LOADING](state): boolean {
    return state.platformUsersLoading;
  },
  [PlatformUserGetters.PLATFORM_USER_CARD_VISIBLE](state): boolean {
    return state.platformUserCardVisible;
  },
  [PlatformUserGetters.PLATFORM_USER_CARD_X_POS](state): number {
    return state.platformUserCardXPos;
  },
  [PlatformUserGetters.PLATFORM_USER_CARD_Y_POS](state): number {
    return state.platformUserCardYPos;
  },
  [PlatformUserGetters.PLATFORM_USER_LIST_VISIBLE](state): boolean {
    return state.platformUserListVisible;
  },
  [PlatformUserGetters.PLATFORM_USER_LIST_X_POS](state): number {
    return state.platformUserListXPos;
  },
  [PlatformUserGetters.PLATFORM_USER_LIST_Y_POS](state): number {
    return state.platformUserListYPos;
  },
  [PlatformUserGetters.PLATFORM_USER_LOADING](state): boolean {
    return state.platformUserLoading;
  },
  [PlatformUserGetters.PLATFORM_USERS](state): PlatformUserItem[] {
    return state.platformUsers;
  },
  [PlatformUserGetters.PLATFORM_USERS_SEARCH](state): string {
    return state.platformUsersSearch;
  },
  [PlatformUserGetters.TOTAL_PLATFORM_USER_COUNT](state): number {
    return state.totalPlatformUsersCount;
  },

  [PlatformUserGetters.VISIBLE_PLATFORM_USER](state): PlatformUserItem {
    return state.visiblePlatformUser;
  },
  [PlatformUserGetters.VISIBLE_PLATFORM_USER_LIST](state): PlatformUserItem[] {
    return state.visiblePlatformUserList;
  },
  [PlatformUserGetters.VISIBLE_PLATFORM_USER_EXISTS]:
    (state: PlatformUserState) =>
    (userId: string): boolean => {
      if (!userId) return false;
      const temp = state.visiblePlatformUserList.filter((pui) => {
        return pui.userId.toLowerCase() === userId.toLowerCase();
      });
      return temp.length > 0;
    },
};
