
import {
  ProgrammeParticipantItem,
  ProgrammeRoomItem,
  ProgrammeSessionItem,
  ProgrammeSessionItemEditData,
} from '@/models/Programme';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  extend,
} from 'vee-validate';
import { AppInsightsLogger } from '@/services/appInsightsLogger';
import moment from 'moment';
import { required, max } from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
});

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class AddEditSession extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public item!: ProgrammeSessionItem;

  /* PRIVATE PROPERTIES */
  private editItem: ProgrammeSessionItem = {
    id: '00000000-0000-0000-0000-000000000000',
    start: new Date(),
    end: new Date(),
  };

  private allowedStep = (m) => m % 5 === 0;
  private colourPicker = false;
  private dirty = false;
  private endTimeOnly: string = '';
  private endTimePicker = false;
  private eventStart: Date = new Date();
  private loaded = false;
  private participants: ProgrammeParticipantItem[] = [];
  private rooms: ProgrammeRoomItem[] = [];
  private startDateOnly: string = '';
  private startDatePicker = false;
  private startTimeOnly: string = '';
  private startTimePicker = false;
  private textColourPicker = false;
  private themes: string[] = [];
  private types: string[] = [];
  private startEndTimeDirty = false;
  private updatePresTime = false;

  /* VUEX GETTERS */
  /* VUEX ACTIONS */

  /* WATCHES */
  @Watch('item')
  private async itemChange(val: ProgrammeSessionItem) {
    if (val) {
      this.loaded = false;
      await this.loadItem();
      this.loaded = true;
    }
  }

  /* LOCAL GETTERS/SETTERS */
  get formatedStartDate() {
    return this.startDateOnly
      ? moment(this.startDateOnly)
          .locale(this.$i18n.locale)
          .format('ddd DD MMM YYYY')
      : '';
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  private async mounted() {
    await this.loadItem();
    this.loaded = true;
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}
  /* PRIVATE METHODS*/

  private async handleCancel() {
    if (this.dirty) {
      const res = await this.$dialog.confirm({
        title: 'Discard changes?',
        text: 'Are you sure you want to discard your changes?',
        actions: {
          false: 'Cancel',
          true: 'Discard',
        },
      });

      if (res) {
        if (this.item.id) {
          this.$emit('cancel');
        } else {
          this.$router.push({ name: 'sessionlist' });
        }
      }
    } else {
      if (this.item.id) {
        this.$emit('cancel');
      } else {
        this.$router.push({ name: 'sessionlist' });
      }
    }
  }

  private async loadItem() {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      let url = `/api/v2/platform/${platformId}/programme/sessions/${
        this.item.id ? this.item.id : this.editItem.id
      }`;
      const pData = await Vue.$http.get<ProgrammeSessionItemEditData>(url);
      if (pData.data) {
        this.editItem = pData.data.item ?? this.editItem;

        this.startDateOnly = pData.data.item
          ? moment(this.editItem.start).format('YYYY-MM-DD')
          : moment(pData.data.eventStart).format('YYYY-MM-DD');
        this.startTimeOnly = pData.data.item
          ? moment(this.editItem.start).format('HH:mm')
          : '07:00';
        this.endTimeOnly = pData.data.item
          ? moment(this.editItem.end).format('HH:mm')
          : '08:00';

        this.editItem.colour = this.editItem.colour
          ? this.editItem.colour
          : '#FFFFFFFF';
        this.editItem.textColour = this.editItem.textColour
          ? this.editItem.textColour
          : '#000000FF';

        if (this.editItem.colour.length === 7) {
          this.editItem.colour = `${this.editItem.colour}FF`;
        }

        if (this.editItem.textColour.length === 7) {
          this.editItem.textColour = `${this.editItem.textColour}FF`;
        }

        this.rooms = pData.data.rooms;
        this.participants = pData.data.participants;
        this.themes = pData.data.themes;
        this.types = pData.data.types;
        this.eventStart = pData.data.eventStart;
      }
    } catch (e: any) {
      console.log(e);
      AppInsightsLogger.logError(
        'Add Edit Session - get item failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private saveColour() {
    if (this.editItem) {
      (this.$refs.colourPickerMenu as any).save(this.editItem.colour);
      this.dirty = true;
    }
  }

  private saveTextColour() {
    if (this.editItem) {
      (this.$refs.textColourPickerMenu as any).save(this.editItem.textColour);
      this.dirty = true;
    }
  }

  private async submit() {
    if (this.$refs.observer) {
      var res = await (this.$refs.observer as any).validate();
      if (!res) return;

      if (
        this.startEndTimeDirty &&
        this.editItem.presentations &&
        this.editItem.presentations.length > 0
      ) {
        this.updatePresTime = await this.$dialog.confirm({
          title: 'Apply to presentations?',
          text: 'You have changed session start or end date/time - do you wish to update the session presentations?',
          actions: {
            false: 'No',
            true: 'Yes',
          },
        });
      }

      this.editItem.start = moment
        .utc(`${this.startDateOnly} ${this.startTimeOnly}`)
        .toDate();
      this.editItem.end = moment
        .utc(`${this.startDateOnly} ${this.endTimeOnly}`)
        .toDate();

      const platformId = sessionStorage.getItem('platformId') ?? '';

      try {
        let url = `/api/v2/platform/${platformId}/programme/sessions`;
        if (this.updatePresTime) {
          url = url + `?updatePresTime=true`;
        }
        const res = await Vue.$http.post<ProgrammeSessionItem>(
          url,
          this.editItem,
        );
        if (res.data) {
          if (this.item.id) {
            this.$emit('close', res.data);
          } else {
            this.$router.push({ name: 'sessionlist' });
          }
        }
      } catch (error) {
        this.$dialog.message.error('Save failed', {
          position: 'bottom-right',
        });
        console.error(error);
      }
    }
  }
}
