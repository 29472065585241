import { MutationTree } from 'vuex';
import {
  PosterSessionItem,
  RoomItem,
  SessionItem,
  SessionMutations,
  SessionQuestionItem,
  SessionState,
} from './types';

export const mutations: MutationTree<SessionState> = {
  [SessionMutations.SET_SESSION_DATA](
    state,
    data: { items: SessionItem[]; totalItemCount: number },
  ): void {
    if (!data) {
      state.sessions = [];
      state.totalSessionCount = 0;
    } else {
      state.sessions.push(...data.items);
      state.totalSessionCount = data.totalItemCount;
    }
  },

  [SessionMutations.SET_RELATED_SESSION_DATA](
    state,
    items: SessionItem[],
  ): void {
    state.relatedSessions = items;
  },

  [SessionMutations.SET_SELECTED_SESSION](state, session: SessionItem) {
    state.selectedSession = { ...session };
    const questions = session.sessionQuestionItems;

    if (questions) {
      state.selectedSessionQuestions = questions;
    }
  },

  [SessionMutations.SET_SELECTED_SESSION_QUESTIONS](
    state,
    questions: SessionQuestionItem[],
  ): void {
    if (questions) {
      state.selectedSessionQuestions = questions;
    }
  },

  [SessionMutations.SET_LOADING_SESSIONS](state, val: boolean): void {
    state.loadingSessions = val;
  },

  [SessionMutations.SET_SELECTED_SESSION_AUTO_APPROVE_QUESTIONS](
    state,
    val: boolean,
  ): void {
    state.selectedSessionAutoApproveQuestions = val;
  },

  [SessionMutations.SET_CURRENT_PAGE](state, page: number): void {
    state.currentPage = page;
  },

  [SessionMutations.SET_KEYWORDS](state, keywords: string): void {
    state.keywords = keywords;
  },

  [SessionMutations.SET_LIST_VIEW](state, listView: boolean): void {
    state.listView = listView;
  },

  [SessionMutations.SET_ROOMS](state, rooms: RoomItem[]): void {
    state.rooms = rooms;
  },

  [SessionMutations.SET_THEMES](state, themes: string[]): void {
    state.themes = themes;
  },

  [SessionMutations.SET_TYPES](state, types: string[]): void {
    state.types = types;
  },

  [SessionMutations.SET_KEYWORD_ITEMS](state, keywordItems: string[]): void {
    state.keywordItems = keywordItems;
  },

  [SessionMutations.SET_POSTER_SESSIONS](
    state,
    posterSessions: PosterSessionItem[],
  ): void {
    state.posterSessions = posterSessions;
  },

  [SessionMutations.SET_SELECTED_THEME](state, theme: string): void {
    state.selectedTheme = theme;
  },

  [SessionMutations.SET_SELECTED_TYPE](state, type: string): void {
    state.selectedType = type;
  },

  [SessionMutations.SET_SELECTED_ROOM](state, room: RoomItem): void {
    state.selectedRoom = room;
  },

  [SessionMutations.SET_SELECTED_ROOM_BY_ID](state, roomId: string): void {
    const room = state.rooms.find((r) => r.id === roomId);
    if (room) {
      state.selectedRoom = room;
    }
  },

  [SessionMutations.SET_SELECTED_POSTER_SESSION](
    state,
    posterSessionItem: PosterSessionItem,
  ): void {
    state.selectedPosterSession = posterSessionItem;
  },

  [SessionMutations.SET_SELECTED_POSTER_SESSION_BY_ID](
    state,
    posterSessionId: string,
  ): void {
    const posterSession = state.posterSessions.find(
      (s) => s.id === posterSessionId,
    );
    if (posterSession) {
      state.selectedPosterSession = posterSession;
    }
  },

  updateSession(
    state,
    {
      orgSession,
      updatedSession,
    }: { orgSession: SessionItem; updatedSession: SessionItem },
  ): void {
    const index = state.sessions.indexOf(orgSession);
    if (index > -1) {
      state.sessions.splice(index, 1, updatedSession);
    }
  },

  [SessionMutations.UPDATE_SELECTED_SESSION_RATING](
    state,
    { rating, rates }: { rating: number; rates: number },
  ): void {
    if (state.selectedSession) {
      state.selectedSession.numOfRatings = rates;
      state.selectedSession.rating = rating;
    }
  },

  [SessionMutations.ADD_NEW_QUESTION](
    state,
    message: SessionQuestionItem,
  ): void {
    state.selectedSessionQuestions.unshift(message);
  },

  [SessionMutations.UPDATE_QUESTION](
    state,
    {
      orgQuestion,
      updatedQuestion,
    }: {
      orgQuestion: SessionQuestionItem;
      updatedQuestion: SessionQuestionItem;
    },
  ): void {
    const index = state.selectedSessionQuestions.indexOf(orgQuestion);
    if (index > -1) {
      state.selectedSessionQuestions.splice(index, 1, updatedQuestion);
    }
  },

  [SessionMutations.ADD_NEW_QUESTION_REPLY](
    state,
    reply: SessionQuestionItem,
  ): void {
    const q = state.selectedSessionQuestions.find(
      (si) => si.id === reply.parentId,
    );
    if (q) {
      q.replies.push(reply);
      q.isApproved = true;
    }
  },
};
