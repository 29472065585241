import { render, staticRenderFns } from "./SessionDetailsSide.vue?vue&type=template&id=ea6bfac4&scoped=true&"
import script from "./SessionDetailsSide.vue?vue&type=script&lang=ts&"
export * from "./SessionDetailsSide.vue?vue&type=script&lang=ts&"
import style0 from "./SessionDetailsSide.vue?vue&type=style&index=0&id=ea6bfac4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea6bfac4",
  null
  
)

export default component.exports