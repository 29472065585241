
import PlatformUserCard from '@/components/PlatformUserCard.vue';
import ParticipantFilters from '@/components/ParticipantFilters.vue';
import Observer from '@/components/Observer.vue';
import { PlatformGetters } from '@/store/platform/types';
import {
  PlatformUserActions,
  PlatformUserGetters,
  PlatformUserItem,
} from '@/store/platformUser/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AppInsightsLogger } from '../services/appInsightsLogger';
const platformUserNamespace = 'platformUser';
const platformNamespace = 'platform';

@Component({
  components: {
    ParticipantFilters,
    PlatformUserCard,
    Observer,
  },
})
export default class Participants extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public onlySpeakers!: boolean;

  /* PRIVATE PROPERTIES */
  private loading = false;
  private PAGINATION_LENGTH = 25;

  /* VUEX GETTERS */
  @Getter(PlatformUserGetters.GET_CURRENT_PAGE, {
    namespace: platformUserNamespace,
  })
  private currentPage!: number;

  @Getter(PlatformUserGetters.PLATFORM_USERS, {
    namespace: platformUserNamespace,
  })
  private platformUsers!: PlatformUserItem[];

  @Getter(PlatformGetters.PORTAL_BACKGROUND_URI, {
    namespace: platformNamespace,
  })
  private portalBackgroundUri!: string;

  @Getter(PlatformUserGetters.TOTAL_PLATFORM_USER_COUNT, {
    namespace: platformUserNamespace,
  })
  private totalPlatformUserCount!: number;

  /* VUEX ACTIONS */
  @Action(PlatformUserActions.SET_CURRENT_PAGE, {
    namespace: platformUserNamespace,
  })
  private setCurrentPage!: (page: number) => Promise<void>;

  @Action(PlatformUserActions.SET_PLATFORM_USER_DATA, {
    namespace: platformUserNamespace,
  })
  private setUserPlatformData!: (
    data: { items: PlatformUserItem[]; totaItemsCount: number } | undefined,
  ) => void;

  @Action(PlatformUserActions.SET_PLATFORM_USERS_LOADING, {
    namespace: platformUserNamespace,
  })
  private setPlatformUsersLoading!: (loading: boolean) => void;

  /* WATCHES */
  @Watch('$route.query', { immediate: false, deep: true })
  private async onQueryChange() {
    this.setPlatformUsersLoading(true);
    this.setCurrentPage(1);
    this.clearPlatformUsers();
    await this.loadData();
    this.setPlatformUsersLoading(false);
  }

  /* LOCAL GETTERS/SETTERS */
  get cssVars(): unknown {
    return {
      '--bg-image': `url('${this.portalBackgroundUri}')`,
    };
  }
  get paginationLength(): number {
    return Math.ceil(this.totalPlatformUserCount / 12);
  }

  get cardWidth(): number {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return 320;
    } else {
      return 400;
    }
  }

  /* PRIVATE METHODS*/
  private async beforeMount() {
    this.setPlatformUsersLoading(true);
    this.clearPlatformUsers();
    this.setCurrentPage(1);
    await this.loadData();
    this.setPlatformUsersLoading(false);
  }

  private clearPlatformUsers() {
    this.setUserPlatformData(undefined);
  }

  private async intersected() {
    if (
      !this.loading &&
      this.currentPage * this.PAGINATION_LENGTH < this.totalPlatformUserCount
    ) {
      this.setCurrentPage(this.currentPage + 1);
      await this.loadData();
    }
  }

  private async loadData(): Promise<void> {
    this.loading = true;
    const platformId = sessionStorage.getItem('platformId') ?? '';

    let queryString = '?page=' + this.currentPage;
    if (this.onlySpeakers) {
      queryString = queryString + '&speaker=true';
    }

    if (window.location.search) {
      queryString = queryString + '&' + window.location.search.substring(1); // remove ?
    }

    try {
      const res = await Vue.$http.get(
        `/api/platform/${platformId}/users${queryString}`,
      );
      this.setUserPlatformData(res.data);
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Participants - loadData failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.loading = false;
  }

  private scrollTop() {
    const e = document.getElementById('top');
    if (e) {
      e.scrollIntoView();
    }
  }
}
