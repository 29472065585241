
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component({})
export default class AdobePdfViewer extends Vue {

/* PUBLIC PROPERTIES */
@Prop()
  public src!: string;

/* PRIVATE PROPERTIES */

private pdfAPIReady = false;
private adobeDCView = null;

/* VUEX GETTERS */
/* VUEX ACTIONS */
/* WATCHES */
@Watch('pdfAPIReady', { immediate: true })
private pdfAPIReadyChange(value)
{
    if(value) {
      this.adobeDCView = new (window as any).AdobeDC.View({
        clientId2: '02973a636c39459795296a4b77854e16',
        clientId: 'a336ece1b17641a693995aecd58fe156',
        divId: "pdf-view"
      });

      this.viewPDF();
    }
}

/* LOCAL GETTERS/SETTERS */
/* LIFECYCLE METHODS */
// private beforeCreate() {}
private created() {
  (document as any).addEventListener("adobe_dc_view_sdk.ready", () => { this.pdfAPIReady = true; });

  if((window as any).AdobeDC) this.pdfAPIReady = true;
}
// private beforeMount() {}
// private mounted() {}
// private beforeUpdate() {}
// private updated() {}
// private activated() {}
// private deactivated() {}
// private beforeDestroy() {}
// private destroyed() {}
// private errorCaptured() {}
/* PRIVATE METHODS*/

  private viewPDF()
  {
      const file = this.src;
      const viewer = this.adobeDCView as any;
      const prom =  viewer.previewFile({
          content: { location: { url: file} },
          metaData: { fileName: "poster" }
        }, {
          embedMode: this.$vuetify.breakpoint.mobile ? "FULL_WINDOW" :  "SIZED_CONTAINER",
          defaultViewMode: "SINGLE_PAGE",
          showFullScreen: !this.$vuetify.breakpoint.mobile,
          showAnnotationTools: false,
          showZoomControl: false,
          focusOnRendering: true,
          showDownloadPDF: false,
          showPrintPDF: false
        });
      prom.then(av => {

      });
  }

  // private goFullscreen() {
  //   const event = new KeyboardEvent('keydown', { key: '', ctrlKey: true, shiftKey: true });
  //   (this.$refs as any).pdfview.children[0].focus().dispatchEvent(event);
  // }

}
