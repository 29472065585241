
import AudioPlayer from '@/components/AudioPlayer.vue';
import { AuthGetters } from '@/store/auth/types';
import { PlatformGetters } from '@/store/platform/types';
import Presentation from '@/views/Presentation.vue';
import { LocaleMessages } from 'vue-i18n';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import uniqueFilter from '../filters/unique.filter';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import {
  BookmarkActions,
  BookmarkEntityType,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '../store/bookmark/types';
import {
  PresentationItem,
  SessionGetters,
  SessionItem,
} from '../store/session/types';

const authNamespace = 'auth';
const platformNamespace = 'platform';
const sessionNamespace = 'session';
const bookmarkNamespace = 'bookmark';

@Component({
  components: {
    AudioPlayer,
  },
})
export default class SessionDetailsSide extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public isActive!: boolean;

  @Prop()
  public pageLoaded!: boolean;

  /* PRIVATE PROPERTIES */

  /* VUEX GETTERS */
  // Auth Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Bookmark Getters
  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  @Getter(BookmarkGetters.IS_A_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private isABookmark!: (entityId: string, entityUri?: string) => boolean;

  // Platform Getters
  @Getter(PlatformGetters.ALLOW_ABSTRACT_DOWNLOAD, {
    namespace: platformNamespace,
  })
  private allowAbstractDownload!: boolean;

  @Getter(PlatformGetters.ALLOW_PRESENTATION_DOWNLOAD, {
    namespace: platformNamespace,
  })
  private allowPresentationDownload!: boolean;

  // Session Getters
  @Getter(SessionGetters.SELECTED_SESSION, { namespace: sessionNamespace })
  private session!: SessionItem;

  /* VUEX ACTIONS */
  // Bookmark Actions
  @Action(BookmarkActions.ADD_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private bookmarkItem!: (bookmark: BookmarkItem) => Promise<void>;

  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */

  /* PRIVATE METHODS*/

  private getExtension(uri: string) {
    const a = uri.split('?')[0].split('.');
    if (a.length > 0) {
      return a[a.length - 1];
    } else {
      return 'pdf';
    }
  }

  private async downloadFile(uri: string | undefined, type: string) {
    if (!uri) {
      return;
    }
    try {
      const res = await fetch(uri, {
        method: 'GET',
      });
      const url = window.URL.createObjectURL(new Blob([await res.blob()]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${type}.${this.getExtension(uri)}`);
      document.body.appendChild(link);
      link.click();
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Session Details Side - download failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private uniqueSpeakers(items: PresentationItem[]): PresentationItem[] {

    var unique = [] ;
    var distinct: PresentationItem[] = [];
    for( let i = 0; i < items.length; i++ ) {
      if(!unique[items[i].speakerId]){
        distinct.push(items[i]);
        unique[items[i].speakerId] = 1;
      }
    }
    return distinct;


    // const speakers = items.map((i) => {
    //   return i.speakerNameOverride ? i.speakerNameOverride : i.speakerName;
    // });
    // return speakers.filter(uniqueFilter);
  }

  private bookmarkExecute(
    title: string | LocaleMessages,
    url: string | undefined,
  ) {
    if (url) {
      const bi = this.getBookmark(this.session.id, url);
      if (bi) {
        this.unbookmarkItem(bi);
      } else {
        let et = '';
        if (this.session.isAPoster) {
          et = BookmarkEntityType.POSTER_DOCUMENT;
        } else if (this.session.isPresentation) {
          et = BookmarkEntityType.PRESENTATION_DOCUMENT;
        } else {
          et = BookmarkEntityType.SESSION_DOCUMENT;
        }

        const bm: BookmarkItem = {
          entityId: this.session.id,
          entityType: et,
          type: BookmarkType.DOCUMENT,
          entityUri: url,
          title: `${title} - ${this.session.title}`,
        };
        this.bookmarkItem(bm);
      }
    }
  }

  private async openNewTab(url: string | undefined) {
    if (!url) {
      return;
    }
    if (url.startsWith('https:') || url.startsWith('http:')) {
      const win = window.open(url, '_blank', 'fullscreen=yes');
      if (win) {
        win.focus();
      }
    } else {
      this.$router.push(url);
    }
  }

  private startSlideShowPresentation(url:string | undefined)
  {
    if (!url) return;

    this.$root.$emit("start-slide-show", url);

  }

  private navigateToSpeaker(pres: PresentationItem) {
    if (pres.speakerNameOverride) return;

    if (!pres.speakerId) return;

    this.$router.push({
      name: 'speakers',
      query: {
        search: pres.speakerName,
        id: pres.speakerId,
        speaker: 'true',
      },
    });
  }
}
