
import { LogActions } from '@/store/log/log';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { AppInsightsLogger } from '../services/appInsightsLogger';

const logNamespace = 'log';

@Component
export default class extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public customId!: string;

  /* PRIVATE PROPERTIES */
  private loaded = false;
  private customHtml = '';

  /* VUEX GETTERS */

  /* VUEX ACTIONS */
  // Log Actions
  @Action(LogActions.SET_TRACE_LOG_STATE, { namespace: logNamespace })
  private setTraceLogState!: ({
    entityId1,
    entityId2,
    page,
    title,
  }: {
    entityId1?: string;
    entityId2?: string;
    page: string;
    title: string;
  }) => void;

  /* WATCHES */
  /* LOCAL GETTERS/SETTERS */
  get dynamicComponent(): { template: string } {
    return {
      template: `${this.customHtml}`,
    };
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  private async created() {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get(
        `/api/platform/${platformId}/custom/${this.customId}`,
      );
      this.customHtml = res.data;
      this.loaded = true;
    } catch (e: any) {
      AppInsightsLogger.logError(
        `Custom page id: ${this.customId} - created failed`,
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }
  // private beforeMount() {}

  private mounted() {
    this.setTraceLogState({
      page: 'Custom',
      title: 'Custom',
    });
  }

  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}
  /* PRIVATE METHODS*/
}
