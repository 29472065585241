import { MutationTree } from 'vuex';
import { AuthMutations, AuthState } from './types';

export const mutations: MutationTree<AuthState> = {
  [AuthMutations.SET_IS_AUTHENTICATED](state, val: boolean): void {
    state.isAuthenticated = val;
  },

  [AuthMutations.SET_LOGIN_IN_ERROR](state, val: string): void {
    state.loginError = val;
  },
};
