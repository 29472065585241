
    import moment from 'moment';
    import {
        SubmitPresentationItem,
        SubmitSessionItem,
        SubmitSettings,
    } from '../../models/Submit';
    import { Action, Getter } from 'vuex-class';
    import { AppInsightsLogger } from '../../services/appInsightsLogger';
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { getFilenameFromURL } from '../../utils/getFilenameFromURL';
    import SubmitUploadFileDialog from '../../components/SubmitUploadFileDialog.vue'


    @Component({
        components: { SubmitUploadFileDialog, },
    })
    export default class SessionList extends Vue {
        /* PUBLIC PROPERTIES */
        /* PRIVATE PROPERTIES */
        private loading: boolean = true;
        private loaded: boolean = false;
        private submitSettings: SubmitSettings = {};
        private dialog: boolean = false;
        private dateFilterValue = null;
        private roomFilterValue = null;
        private search: string = '';
        private sessionItems: SubmitSessionItem[] = [];
        private selectedPresentation: SubmitPresentationItem | null = null;

        private sessionHeaders = [
            { text: this.$t('submit.SessionTitle'), value: 'title' },
            { text: this.$t('submit.Room'), value: 'roomName', filter: this.RoomFilter },
            { text: this.$t('submit.StartEnd'), value: 'start', filter: this.DateFilter },
            { text: '', value: 'actions', sortable: false },
            { text: '', value: 'data-table-expand' },
        ];
        private presentationHeaders = [
            { text: this.$t('submit.Order'), value: 'order' },
            { text: this.$t('submit.PresentationTitle'), value: 'title' },
            { text: this.$t('submit.Speaker'), value: 'speakerName' },
            { text: this.$t('submit.StartEnd'), value: 'start' },
            { text: '', value: 'actions', sortable: false },
        ];
        private footerProps = {
            itemsPerPageOptions: [100, 200, 500, -1],
            itemsPerPageText: this.$t('submit.RowsPerPage'),
            itemsPerPageAllText: this.$t('submit.All'),
        };

        /* VUEX GETTERS */
        /* VUEX ACTIONS */
        /* WATCHES */
        /* LOCAL GETTERS/SETTERS */
        get sessionDates(): string[] {
            if (!this.sessionItems || this.sessionItems.length < 1) {
                return [];
            } else {
                return [
                    ...new Set(
                        this.sessionItems.map((d) => {
                            return moment(d.start)
                                .locale(this.$i18n.locale)
                                .format('DD/MM/YYYY');
                        }),
                    ),
                ];
            }
        }

        get sessionRooms(): string[] {
            return [
                ...new Set(
                    this.sessionItems.map((d) => {
                        if (d.roomName) {
                            return d.roomName;
                        } else {
                            return this.$t('submit.NoRoom') as string; // TODO(david): translate
                        }
                    }),
                ),
            ];
        }

        /* LIFECYCLE METHODS */
        // private beforeCreate() {}
        // private created() {}
        // private beforeMount() {}
        private async mounted() {
            const platformId = sessionStorage.getItem('platformId') ?? '';
            const res = await Vue.$http.get<SubmitSettings>(`/api/platform/${platformId}/submit`);
            this.submitSettings = res.data
            if (this.submitSettings.closedText) {
                this.loaded = true;
            } else {
                await this.loadSessions();
            }
        }
        // private beforeUpdate() {}
        // private updated() {}
        // private activated() {}
        // private deactivated() {}
        // private beforeDestroy() {}
        // private destroyed() {}
        // private errorCaptured() {}
        /* PRIVATE METHODS*/
        private DateFilter(value, search, item) {
            if (!this.dateFilterValue) {
                return true;
            }
            return (
                moment(item.start).locale(this.$i18n.locale).format('DD/MM/YYYY') ===
                this.dateFilterValue
            );
        }

        private formatLongDate(start: Date, end: Date) {
            let s = '';
            let e = '';
            if (start) {
                s = moment(start).locale(this.$i18n.locale).format('ddd DD MMM HH:mm');
            }

            if (end) {
                e = moment(end).locale(this.$i18n.locale).format('HH:mm');
            }
            return `${s} - ${e}`;
        }

        private async handleFileUploads(item: SubmitPresentationItem) {
            this.selectedPresentation = item;
            this.dialog = true;
        }

        private handleClose() {
            this.selectedPresentation = null;
            this.dialog = false;
        }

        private async loadSessions() {
            const platformId = sessionStorage.getItem('platformId') ?? '';
            try {
                let url = `/api/v2/platform/${platformId}/submit/sessions`;
                const pData = await Vue.$http.get<SubmitSessionItem[]>(url);
                if (pData.data) {
                    this.sessionItems = pData.data;
                }
                this.loaded = true;
                this.loading = false;
            } catch (e: any) {
                AppInsightsLogger.logError(
                    'Submit Session List - created failed',
                    undefined,
                    true,
                );
                AppInsightsLogger.logException(e, false);
            }
        }

        private RoomFilter(value, search, item) {
            if (!this.roomFilterValue) {
                return true;
            }
            return value === this.roomFilterValue;
        }
    }
