import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { LoungeState } from './types';

export const state: LoungeState = {
  connected: false,
  currentLoungeRoom: undefined,
  drawer: true,
  error: '',
  favouriteGroupMessages: [],
  favouriteLoungeRoomLimit: 5,
  loungeRoomListLoading: false,
  loungeRoomLoading: false,
  loungeRooms: [],
  maxGroupMessageLimit: 100,
};

const namespaced = true;

export const lounge: Module<LoungeState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
