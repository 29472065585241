
import BannerDialogEditProfile from '@/components/BannerDialogEditProfile.vue';
import LocaleChanger from '@/components/LocaleChanger.vue';
import MyBookmarks from '@/components/MyBookmarks.vue';
import Programme from '@/components/Programme.vue';
import { HeartbeatResponse } from '@/models/HeartbeatResponse';
import { Scan } from '@/models/Scan';
import { ScanEntityTypes } from '@/models/ScanEntityTypes';
import { ScanTypes } from '@/models/ScanTypes';
import { AuthService } from '@/services/auth/authService';
import { NavigationActions, NavigationGetters } from '@/store/navigation/types';
import { PlatformActions, PlatformGetters } from '@/store/platform/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  PlatformUserActions,
  PlatformUserGetters,
  PlatformUserItem,
} from '../store/platformUser/types';

const navigationNamespace = 'navigation';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';

@Component({
  components: {
    BannerDialogEditProfile,
    LocaleChanger,
    MyBookmarks,
    Programme,
  },
})
export default class BannerDialog extends Vue {
  /* PUBLIC PROPERTIES */
  /* PRIVATE PROPERTIES */
  private dialogTitle = '';

  // Pages
  private menu = true;
  private language = false;
  private myAgenda = false;
  private myBookmarks = false;
  private privateMessages = false;
  private editProfile = false;

  /* VUEX GETTERS */
  // Navigation Getters
  @Getter(NavigationGetters.PROFILE_DIALOG, {
    namespace: navigationNamespace,
  })
  private dialog!: boolean;

  // Platform Getters
  @Getter(PlatformGetters.BANNER_BAR_BACKGROUND_COLOUR, {
    namespace: platformNamespace,
  })
  private bannerBarBackgroundColour!: string;

  @Getter(PlatformGetters.BANNER_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private bannerBarTextColour!: string;

  // Platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER, {
    namespace: platformUserNamespace,
  })
  private getCurrentPlatformUser!: PlatformUserItem;

  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  @Action(PlatformUserActions.LOAD_CURRENT_PLATFORM_USER, {
    namespace: platformUserNamespace,
  })
  private loadCurrentPlatformUser!: () => Promise<void>;

  /* VUEX ACTIONS */
  // Navigation Action
  @Action(NavigationActions.PROFILE_INVERT_DIALOG, {
    namespace: navigationNamespace,
  })
  private invertDialog!: () => void;

  // Platform Actions
  @Action(PlatformActions.LOG_SCAN, { namespace: platformNamespace })
  private scanLog!: (scan: Scan) => Promise<void | HeartbeatResponse>;

  // Platfor User Action
  @Action(PlatformUserActions.SET_CURRENT_PLATFORM_USER_PHOTO_URI, {
    namespace: platformUserNamespace,
  })
  private setCurrentPlatformUserPhotoUri!: (uri: string) => void;

  /* WATCHES */

  /* WATCHES */
  @Watch('dialog', { immediate: true })
  private async onDialogChanged(newVal: boolean): Promise<void> {
    if (this.$vuetify.breakpoint.mobile && newVal) {
      if (
        this.getCurrentPlatformUser &&
        this.getCurrentPlatformUser.userId &&
        (this.getCurrentPlatformUser.accepted === undefined ||
          this.getCurrentPlatformUser.accepted === null)
      ) {
        this.showPage('editProfile');
      }
    }
  }

  /* LOCAL GETTERS/SETTERS */
  get dialogState(): boolean {
    return this.dialog;
  }
  set dialogState(state) {
    if (state !== this.dialog) {
      this.invertDialog();
    }
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  private mounted() {
    if (!this.$vuetify.breakpoint.mobile) {
      this.dialogTitle = this.$t('myProfile.title') as string;
    }
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}

  /* PRIVATE METHODS*/
  private closeDialog()
  {
    console.log('here2');
    this.showPage('menu')
    this.dialogState = false;
  }

  private async logOut() {
    const userId = localStorage.getItem('userId') ?? '';
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const scan: Scan = {
      entityId: platformId,
      scanEntityType: ScanEntityTypes.EVENT_PLATFORM,
      scanType: ScanTypes.LOGOUT,
      userId,
    };
    await this.scanLog(scan);
    await AuthService.logOut().finally(() => {
      // this.$appInsights.clearAuthenticatedUserContext();
      const eventCode = sessionStorage.getItem('eventCode');
      window.location.href = `/${eventCode}`;
    });
  }

  private showPage(page: string) {
    switch (page) {
      case 'language':
        this.menu = false;
        this.language = true;
        this.myAgenda = false;
        this.myBookmarks = false;
        this.privateMessages = false;
        this.editProfile = false;
        this.dialogTitle = this.$t('bannerBar.language') as string;
        break;

      case 'myAgenda':
        this.menu = false;
        this.language = false;
        this.myAgenda = true;
        this.myBookmarks = false;
        this.privateMessages = false;
        this.editProfile = false;
        this.dialogTitle = this.$t('bannerBar.myProgramme') as string;
        break;

      case 'myBookmarks':
        this.menu = false;
        this.language = false;
        this.myAgenda = false;
        this.myBookmarks = true;
        this.privateMessages = false;
        this.editProfile = false;
        this.dialogTitle = this.$t('bannerBar.myBookmarks') as string;
        break;

      case 'privateMessages':
        this.menu = false;
        this.language = false;
        this.myAgenda = false;
        this.myBookmarks = false;
        this.privateMessages = true;
        this.editProfile = false;
        break;

      case 'editProfile':
        this.menu = false;
        this.language = false;
        this.myAgenda = false;
        this.myBookmarks = false;
        this.privateMessages = false;
        this.editProfile = true;
        break;

      default:
        this.menu = true;
        this.language = false;
        this.myAgenda = false;
        this.myBookmarks = false;
        this.privateMessages = false;
        this.editProfile = false;
        this.dialogTitle = '';
    }
  }
}
