
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  BookmarkActions,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '@/store/bookmark/types';
import { PlatformGetters } from '@/store/platform/types';

const bookmarkNamespace = 'bookmark';
const platformNamespace = 'platform';

@Component
export default class MyBookmarks extends Vue {
  /* PUBLIC PROPERTIES */

  /* PRIVATE PROPERTIES */
  private bookmarksSeachText = '';
  private bookmarksOpen = false;

  /* VUEX GETTERS */
  // Bookmark getters
  @Getter(BookmarkGetters.GET_BOOKMARKS, {
    namespace: bookmarkNamespace,
  })
  private bookmarks!: BookmarkItem[];

  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  // Platform Getters
  @Getter(PlatformGetters.BANNER_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private bannerBarTextColour!: string;

  /* VUEX ACTIONS */
  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get filteredGroupedBookmarks(): { [key: string]: BookmarkItem[] } {
    const filtered = this.bookmarks.filter((bm) => {
      return bm.title.includes(this.bookmarksSeachText);
    });
    return this.getGroupsByEntityType(filtered);
  }

  /* PRIVATE METHODS*/
  private unbookmarkExecute(bi: BookmarkItem) {
    this.unbookmarkItem(bi);
  }

  private getGroupsByEntityType(b: BookmarkItem[]) {
    b = b.sort((a, b) => a.type.localeCompare(b.type));
    const groups = b.reduce<{ [key: string]: BookmarkItem[] }>((acc, bm) => {
      acc[bm.type] = [...(acc[bm.type] || []), bm];
      return acc;
    }, {});
    return groups;
  }

  private getTypeName(type: string) {
    switch (type) {
      case BookmarkType.DOCUMENT:
        return 'Documents';
      case BookmarkType.EXHIBITION:
        return this.$t('menuItems.exhibition');
      case BookmarkType.POSTER:
        return this.$t('menuItems.posters');
      case BookmarkType.VIDEO:
        return this.$t('menuItems.vod');
      default:
        return '';
    }
  }

  private getTypeIcon(type: string) {
    switch (type) {
      case BookmarkType.DOCUMENT:
        return 'mdi-file-document-multiple-outline';
      case BookmarkType.EXHIBITION:
        return 'store_mall_directory';
      case BookmarkType.POSTER:
        return 'mdi-billboard';
      case BookmarkType.VIDEO:
        return 'video_library';
      default:
        return '';
    }
  }

  private async openNewTab(url: string | undefined) {
    console.log('here');
    this.$emit("close");
    if (url) {
      if (url.startsWith('https:') || url.startsWith('http:')) {
        const win = window.open(url, '_blank');
        if (win) {
          win.focus();
        }
      } else {
        this.$router.push(url);
      }

    }
  }
}
