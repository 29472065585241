
import { CategorisedEmojis, Emoji, SkinTone } from '@/models/EmojiTypes';
import Popper from 'popper.js';
import skinTone, { SkinToneType } from 'skin-tone';
import ClickOutside from './ClickOutside.vue';
import frequentlyUsed from '../data/frequently-used.json';
import {categories, searchSVG, emojiInvokerOpen, emojiInvokerClose} from '../data/svg.js';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { throttle} from '../utils/throttle';

const DEFUALT_SKIN_TONE = 'default_skin_tone';
const FREQUENTLY_USED = 'frequently_used';
const FREQUENTLY_USED_CATEGORY_NAME = 'Frequently Used';
const FREQUENTLY_USED_EMOJIS = 'frequently_used_emojis';
const skinToneNames = [
  { name: 'NONE', type: 'none' as SkinToneType },
  { name: 'WHITE', type: 'white' as SkinToneType },
  { name: 'CREAM_WHITE', type: 'creamWhite' as SkinToneType },
  { name: 'LIGHT_BROWN', type: 'lightBrown' as SkinToneType },
  { name: 'BROWN', type: 'brown'as SkinToneType },
  { name: 'DARK_BROWN', type: 'darkBrown'as SkinToneType}
];


@Component({ components: { ClickOutside }})
export default class EmojiPicker extends Vue {

 /* PUBLIC PROPERTIES */
  @Prop()
  public data!: Emoji[];

  /* PRIVATE PROPERTIES */
  private show = false;
  private search = '';
  private popper: any = null;
  private skinTonePickers: any = [];
  private hoveredEmoji: any = null;
  private emojiInvoker: any = null;
  private emojiDropdown: any = null;
  private selectedEmoji: any = null;
  private searchSVG = searchSVG;
  private categorizedEmojis: any = {};
  private categories = categories;
  private emojiInvokerIcon: any = null;
  private isInvokerHovered = false;
  private showSkinTonePickers = false;
  private emojiInvokerClose = emojiInvokerClose;
  private emojiInvokerOpen = emojiInvokerOpen;
  private scrolledTo = FREQUENTLY_USED;

  /* VUEX GETTERS */
  /* VUEX ACTIONS */

  /* WATCHES */
  @Watch('search')
  private searchWatcher() {
    if (this.search !== '') {
      this.scrolledTo = FREQUENTLY_USED;
    }
  }

  @Watch('show')
  private showWatcher() {
    if (!this.show) {
      this.search = '';
    }

    this.$nextTick(() => {
      this.setupPopper();
    });
  }

  /* LOCAL GETTERS/SETTERS */
  get emojis(): Array<Array<Emoji>> {
    return this.search ? this.filteredEmojis() : this.categorizedEmojis;
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  private mounted() {
    this.skinTonePickers = this.getToneHands();
    this.categorizedEmojis = this.categorizeEmojis();
    this.emojiDropdown = this.$refs.emojiDropdown;
    this.emojiInvoker = this.$refs.emojiInvoker;
    this.emojiInvokerIcon = this.$refs.emojiInvokerIcon;
    this.setupScroll();
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}

  /* PRIVATE METHODS*/
  private open() {
    this.show = true;
  }

  private close() {
    this.show = false;
  }

  private toggleDropdown() {
    this.show ? this.close() : this.open();
  }

  private setFrequentUsedEmoji(emojiObj) {
    const maxLength = 9;
    let temp = this.getFrequentUsedEmojis()
    let frequentUsedEmojis: Emoji[] = [];
    if (temp)
    {
      frequentUsedEmojis = temp.filter(item => item.description !== emojiObj.description);
    }
    emojiObj.category = FREQUENTLY_USED_CATEGORY_NAME;
    frequentUsedEmojis = [...frequentUsedEmojis, emojiObj];

    if (frequentUsedEmojis.length > maxLength) {
      frequentUsedEmojis.shift();
    }

    localStorage.setItem(FREQUENTLY_USED_EMOJIS, JSON.stringify(frequentUsedEmojis));
    this.categorizedEmojis[FREQUENTLY_USED_CATEGORY_NAME] = frequentUsedEmojis;
  }

  private getFrequentUsedEmojis(): Emoji[] {
    let frequentUsedEmojis : Emoji[] = []
    let temp = localStorage.getItem(FREQUENTLY_USED_EMOJIS);

    if (temp === null) {
      frequentUsedEmojis = frequentlyUsed as Emoji[];
    } else if (!Array.isArray(temp)) {
      frequentUsedEmojis = JSON.parse(temp);
    }
    return frequentUsedEmojis;
  }

  private selectEmoji(emojiObj) {
    this.$emit('emoji:picked', emojiObj.emoji);
    this.setFrequentUsedEmoji(emojiObj);
    this.close();
  }

  private scrollToCategory(category) {
    let [element] = this.$refs[category] as Element[];

    if (element && category !== 'frequently_used') {
      element.scrollIntoView({
        block: 'start',
        inline: "center",
        behavior: 'smooth',
      })
    } else {
      element.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  }

  private setupScroll () {
    let emojis = this.$refs.emojis as Element;
    emojis.addEventListener('scroll', this.handleScroll);
  }

  private handleScroll() {
    let emojis = this.$refs.emojis as Element;
    if (this.search) {
      return;
    }

    let temp = '';
    Object.keys(this.categories).forEach(category => {
      let [element] = this.$refs[this.getCategoryRef(category)] as any;
      if (element && Math.floor(emojis.scrollTop) >= element.offsetTop) {
        // console.log(`Category: ${category} - ScrollTop: ${emojis.scrollTop}  - OffsetTop: ${element.offsetTop}`)
        temp = category;
      }
    });
    if (temp)
    {
      this.scrolledTo = temp;
    }
  }

  private filteredEmojis(): CategorisedEmojis {
    let filteredEmojis: CategorisedEmojis = {};
    for (let category in this.categorizedEmojis) {
      this.categorizedEmojis[category].forEach(emoji => {
        if (!emoji.description.toLowerCase().indexOf(this.search.toLowerCase())) {
          return;
        }
        if (!Object.prototype.hasOwnProperty.call(filteredEmojis, category)) {
          filteredEmojis[category] = [];
        }
        filteredEmojis[category] = [ ...filteredEmojis[category], emoji ];
      });
    }

    return filteredEmojis;
  }

  private getCategoryRef(category) {
    return category.toLowerCase().split(' ').join('_');
  }

  private getCategoryName(categorRef) {
    const words = categorRef.split("_");

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(' ');
  }

  private categorizeEmojis (): CategorisedEmojis {
    let categorizedEmojis: CategorisedEmojis = {};
    let allEmojis = [...this.getFrequentUsedEmojis(), ...this.data];
    let sk = this.getDefaultSkinTone();
    allEmojis.forEach(emojiObj => {
      if (!Object.prototype.hasOwnProperty.call(categorizedEmojis, emojiObj.category)) {
        categorizedEmojis[emojiObj.category] = [];
      }
      emojiObj.emoji = skinTone(emojiObj.emoji, sk.type);
      categorizedEmojis[emojiObj.category] = [...categorizedEmojis[emojiObj.category], emojiObj];
    });

    return categorizedEmojis;
  }

  private getDefaultSkinTone(): SkinTone {
    let defaultSkinTone = localStorage.getItem(DEFUALT_SKIN_TONE);
    return defaultSkinTone === null
      ? { name: 'NONE', emoji: '✋', type: 'none' as SkinToneType }
      : JSON.parse(defaultSkinTone) as SkinTone;
  }

  private setDefaultSkinTone(skinTone) {
    this.showSkinTonePickers = !this.showSkinTonePickers;
    localStorage.setItem(DEFUALT_SKIN_TONE, JSON.stringify(skinTone));
    this.resetInitEmojis(skinTone.name);
  }

  private resetInitEmojis(type: SkinToneType) {
    for (let category in this.categorizedEmojis) {
      this.categorizedEmojis[category].forEach(emojiObj => {
        emojiObj.emoji = skinTone(emojiObj.emoji, type);
      });
    }
  }

  private getToneHands(): SkinTone[] {
    return skinToneNames.map(item => {
      return { ...item, ...{ emoji: skinTone('✋', item.type) } };
    }).sort(this.sortToneHands);
  }

  private sortToneHands(a: SkinTone, b: SkinTone) {
    return a.name === this.getDefaultSkinTone().name ? 1 : 0;
  }

  private handleMouseLeaveFooter() {
    if (this.showSkinTonePickers) {
      this.showSkinTonePickers = false;
    }

    if (this.hoveredEmoji !== null) {
      this.hoveredEmoji = null;
    }
  }

  private handleMouseEnterEmoji(emoji) {
    if (this.showSkinTonePickers) {
      this.showSkinTonePickers = false;
    }
    this.hoveredEmoji = emoji;
  }

  private handleMouseLeaveEmoji(emoji) {
    this.hoveredEmoji = null;
  }

  private setupPopper() {
    if (this.popper === null) {
      this.popper = new Popper(this.emojiInvoker, this.emojiDropdown, { placement: 'top', modifiers: { offset: { offset: '0, 34' }  } });
      return;
    }
    this.popper.scheduleUpdate();
  }
}
