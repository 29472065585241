export interface BookmarkState {
  bookmarks: BookmarkItem[];
  loaded: boolean;
}

export interface BookmarkItem {
  id?: string;
  entityId: string;
  entityType: string;
  entityUri?: string;
  type: string;
  title: string;
  subTitle1?: string;
  subTitle2?: string;
  start?: Date;
  end?: Date;
}

export enum BookmarkEntityType {
  SESSION = 'SESSION',
  PARTICIPANT = 'PARTICIPANT',
  PARTICIPANT_SPEAKER = 'PARTICIPANT_SPEAKER',
  PRESENTATION = 'PRESENTATION',
  POSTER = 'POSTER',
  SPONSOR_STAND = 'SPONSOR_STAND',
  SPONSOR_DOCUMENT = 'SPONSOR_DOCUMENT',
  SESSION_DOCUMENT = 'SESSION_DOCUMENT',
  PRESENTATION_DOCUMENT = 'PRESENTATION_DOCUMENT',
  POSTER_DOCUMENT = 'POSTER_DOCUMENT',
}

export enum BookmarkType {
  DOCUMENT = 'DOCUMENT',
  EXHIBITION = 'EXHBITION',
  PARTICIPANT = 'PARTICIPANT',
  POSTER = 'POSTER',
  VIDEO = 'VIDEO',
}

export enum BookmarkActions {
  ADD_BOOKMARK_ITEM = 'ADD_BOOKMARK_ITEM',
  LOAD_BOOKMARKS = 'LOAD_BOOKMARKS',
  REMOVE_BOOKMARK_ITEM = 'REMOVE_BOOKMARK_ITEM',
}

export enum BookmarkGetters {
  GET_BOOKMARKS = 'GET_BOOKMARKS',
  GET_BOOKMARK = 'GET_BOOKMARK',
  GET_SESSION_BOOKMARKS = 'GET_BOOKMARKS',
  IS_A_BOOKMARK = 'IS_A_BOOKMARK',
}

export enum BookmarkMutations {
  ADD_BOOKMARK_ITEM = 'ADD_BOOKMARK_ITEM',
  LOAD_BOOKMARKS = 'LOAD_BOOKMARKS',
  REMOVE_BOOKMARK_ITEM = 'REMOVE_BOOKMARK_ITEM',
}
