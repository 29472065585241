
import { Component, Vue } from 'vue-property-decorator';
import xlsx from 'xlsx';
import { AppInsightsLogger } from '../../services/appInsightsLogger';
import {
  DataTable,
  MessageItem,
  MessagesSummary,
} from '@/models/Reports/DataTable';
import { EntityListItem } from '@/models/Reports/EntityListItem';

@Component({})
export default class UniqueUsers extends Vue {
  private messageSummary: MessagesSummary | null = null;
  private sessions: EntityListItem[] | null = null;
  private entityMessageSummary: MessagesSummary | null = null;
  private entityMessageData: DataTable<MessageItem> | null = null;
  private messageSummaryLoading = false;
  private entityMessagesLoading = false;
  private entityMessageSearch = '';
  private selectedEntity = '';

  get entity(): string {
    return this.selectedEntity;
  }
  set entity(value: string) {
    this.selectedEntity = value;
    this.selectedEntityChanged();
  }

  private async created() {
    this.messageSummary = null;
    this.sessions = null;
    this.entityMessageData = null;
    this.entityMessageSummary = null;
    this.entityMessageSearch = '';
  }

  private downloadItems() {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const temp = this.sessions!.find(
      (ti: EntityListItem) => ti.id === this.selectedEntity,
    );
    if (!temp) {
      return;
    }
    const title = `${eventCode}_messages_${temp.title}`;

    if (title && this.entityMessageData && this.entityMessageData.items) {
      const fileName = title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      const workSheet = xlsx.utils.json_to_sheet(this.entityMessageData.items);
      const wb = xlsx.utils.book_new();

      xlsx.utils.book_append_sheet(wb, workSheet, 'export');
      xlsx.writeFile(wb, `${fileName}.xlsx`);
    }
  }

  private async mounted() {
    this.messageSummaryLoading = true;
    this.messageSummary = null;
    this.sessions = null;
    this.entityMessageData = null;
    this.entityMessageSummary = null;
    this.entityMessageSearch = '';
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get<DataTable<EntityListItem>>(
        `/api/platform/${platformId}/report/messages`,
      );
      this.messageSummary = res.data.messagesSummary;
      this.sessions = res.data.items;
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Reports - get messages summary data failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.messageSummaryLoading = false;
  }

  private async selectedEntityChanged() {
    this.entityMessagesLoading = true;
    this.entityMessageData = null;
    this.entityMessageSummary = null;
    this.entityMessageSearch = '';

    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get<DataTable<MessageItem>>(
        `/api/platform/${platformId}/report/${this.selectedEntity}/messages`,
      );
      this.entityMessageData = res.data;
      this.entityMessageSummary = res.data.messagesSummary;
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - selectedEntityChanged failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.entityMessagesLoading = false;
  }
}
