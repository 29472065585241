// import './registerServiceWorker';
import dateFilter from '@/filters/date.filter';
import store from '@/store/index';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import VueAppInsights from 'vue-application-insights';
import App from './App.vue';
import './plugins/axios';
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import vueSecureHTML from './plugins/v-html-secure.js';
import router from './router';
import { I18nActions } from './store/i18n/types';
import VuetifyDialog from 'vuetify-dialog';
import 'vuetify-dialog/dist/vuetify-dialog.css';

if (vuetify.userPreset.breakpoint) {
  vuetify.framework.breakpoint.mobileBreakpoint = 960;
  vuetify.preset.breakpoint.mobileBreakpoint = 960;
  vuetify.userPreset.breakpoint.mobileBreakpoint = 960;
} else {
  vuetify.framework.breakpoint.mobileBreakpoint = 960;
  vuetify.preset.breakpoint.mobileBreakpoint = 960;
  vuetify.userPreset.breakpoint = {}
  vuetify.userPreset.breakpoint.mobileBreakpoint = 960;
}

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
});

Vue.config.productionTip = false;
Vue.filter('date', dateFilter);

// Vue.use(VueAppInsights, {
//   id: '736d64e4-28e6-4a05-8ca4-3bc234f64ad8',
//   trackInitialPageView: false,
//   router,
// });

Vue.use(vueSecureHTML);
Vue.prototype.$safeHTML = vueSecureHTML.safeHTML;
Vue.prototype.$escapeHTML = vueSecureHTML.escapeHTML;
Vue.prototype.$removeHTML = vueSecureHTML.removeHTML;

new Vue({
  created: () => {
    let locale = 'en';
    const local = localStorage.getItem('locale');
    const nav = navigator.language.slice(0, 2);
    if (local) {
      locale = JSON.parse(local);
    } else if (nav) {
      locale = nav;
    }

    if (!(locale  == 'en' || locale == 'fr'))
    {
      locale = 'en';
    }


    store.dispatch(`i18n/${I18nActions.SET_LOCALE}`, locale);
  },
  i18n,
  render: (h) => h(App),
  router,
  store,
  vuetify,
}).$mount('#app');
function VeeValidate(VeeValidate: any) {
  throw new Error('Function not implemented.');
}
