
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

@Component({})
export default class TweetBox extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public limit!: number;

  @Prop()
  public placeholder!: string;

  @Prop()
  public value!: string;

  @Ref('textarea')
  readonly textarea!: HTMLTextAreaElement;

  get name(): string {
    return 'TweetBox';
  }

  get valueAllowed(): number | string {
    return this.limit ? this.value.slice(0, this.limit) : this.value;
  }

  get valueExcess(): number | string {
    return this.limit ? this.value.slice(this.limit) : '';
  }

  get limitStatus(): number {
    return (this.value.length / this.limit) * 100;
  }
  get remainingCharacters(): number {
    return this.limit - this.value.length;
  }

  get textareaStyle(): CSSStyleDeclaration {
    return getComputedStyle(this.$refs.textarea as Element);
  }

  public textFocus() {
    this.textarea.focus();
  }

  private updateValue(e: Event) {
    this.textareaGrow();
    if (e && e.target) {
      this.$emit(`input`, (e.target as HTMLInputElement).value);
    }
  }
  // Update the size of the textarea to fit the number
  // of lines of text.
  private textareaGrow() {
    const paddingTop = parseInt(
      this.textareaStyle.getPropertyValue('padding-top'),
      10,
    );
    const paddingBottom = parseInt(
      this.textareaStyle.getPropertyValue('padding-bottom'),
      10,
    );
    const lineHeight = parseInt(
      this.textareaStyle.getPropertyValue('line-height'),
      10,
    );

    // Resetting the row count to `1` is necessary for
    // recalculating the `scrollHeight` of the textarea.
    (this.$refs.textarea as HTMLTextAreaElement).rows = 1;

    // We're calculating the inner height of the textare
    // and take this value to also calculate the number
    // of rows needed to fit the currently entered text.
    const innerHeight =
      (this.$refs.textarea as HTMLTextAreaElement).scrollHeight -
      paddingTop -
      paddingBottom;
    (this.$refs.textarea as HTMLTextAreaElement).rows =
      innerHeight / lineHeight;
  }

  private mounted() {
    this.textareaGrow();
  }
}
