import store from '@/store/index';
import Vue from 'vue';
import Router, { NavigationGuardNext, Route } from 'vue-router';
import { TokenStorage } from './services/auth/tokenStorage';
import { PlatformActions } from './store/platform/types';
import { SignalrActions, SignalrGetters } from './store/signalr/types';
import AdClicks from './views/reports/AdClicks.vue';
import AllParticipants from './views/AllParticipants.vue';
import Custom from './views/Custom.vue';
import EventProgramme from './views/EventProgramme.vue';
import Expo from './views/Expo.vue';
import LiveNow from './views/LiveNow.vue';
import Login from './views/Login.vue';
import Lounge from './views/Lounge.vue';
import Messages from './views/reports/Messages.vue';
import NotFound from './views/NotFound.vue';
import Posters from './views/Posters.vue';
import Presentation from './views/Presentation.vue';
import Session from './views/Session.vue';
import Speakers from './views/Speakers.vue';
import Stand from './views/Stand.vue';
import Summary from './views/reports/Summary.vue';
import UniqueUsers from './views/reports/UniqueUsers.vue';
import UserProgramme from './views/UserProgramme.vue';
import Vod from './views/Vod.vue';
import Welcome from './views/Welcome.vue';

// Submit
import SubmitPosterList from './views/submit/PosterList.vue'
import SubmitPresentationList from './views/submit/PresentationList.vue'
import SubmitSessionList from './views/submit/SessionList.vue'

// Admin
import AddPresentation from './views/admin/AddPresentation.vue';
import AddSession from './views/admin/AddSession.vue';
import SessionList from './views/admin/SessionList.vue';

import { LogActions } from './store/log/log';

Vue.use(Router);

const logNamespace = 'log';
const platformNamespace = 'platform';
const signalrNamespace = 'signalr';

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [
    {
      component: NotFound,
      meta: { fullPage: true },
      name: 'notfound',
      path: '/notfound',
    },
    {
      component: Welcome,
      meta: { fullPage: false },
      name: 'welcome',
      path: '/:eventCode/',
    },
    {
      component: EventProgramme,
      meta: { fullPage: false },
      name: 'programme',
      path: '/:eventCode/programme',
    },
    {
      component: Custom,
      meta: { fullPage: false },
      name: 'programmeCustom',
      path: '/:eventCode/programme/custom/:customId',
      props: true,
    },
    {
      component: LiveNow,
      meta: { fullPage: false },
      name: 'livenow',
      path: '/:eventCode/livenow',
    },
    {
      component: Custom,
      meta: { fullPage: false },
      name: 'livenowCustom',
      path: '/:eventCode/livenow/custom/:customId',
      props: true,
    },
    {
      component: Vod,
      meta: { fullPage: false },
      name: 'vod',
      path: '/:eventCode/vod/',
    },
    {
      component: Custom,
      meta: { fullPage: false },
      name: 'vodCustom',
      path: '/:eventCode/vod/:custom/:customId',
      props: true,
    },
    {
      component: Posters,
      meta: { fullPage: false },
      name: 'posters',
      path: '/:eventCode/posters',
    },
    {
      component: Custom,
      meta: { fullPage: false },
      name: 'postersCustom',
      path: '/:eventCode/posters/custom/:customId',
      props: true,
    },
    {
      component: Speakers,
      meta: { fullPage: false },
      name: 'speakers',
      path: '/:eventCode/speakers',
    },
    {
      component: Custom,
      meta: { fullPage: false },
      name: 'speakersCustom',
      path: '/:eventCode/speakers/custom/:customId',
      props: true,
    },
    {
      component: AllParticipants,
      meta: { fullPage: false },
      name: 'allparticipants',
      path: '/:eventCode/all-participants',
    },
    {
      component: Custom,
      meta: { fullPage: false },
      name: 'allparticipantsCustom',
      path: '/:eventCode/all-participants/custom/:customId',
      props: true,
    },
    {
      component: Expo,
      meta: { fullPage: false },
      name: 'expo',
      path: '/:eventCode/expo',
    },
    {
      component: Custom,
      meta: { fullPage: false },
      name: 'expoCustom',
      path: '/:eventCode/expo/custom/:customId',
      props: true,
    },
    {
      component: Lounge,
      meta: { fullPage: false },
      name: 'lounge',
      path: '/:eventCode/lounge',
    },
    {
      component: Custom,
      meta: { fullPage: false },
      name: 'loungeCustom',
      path: '/:eventCode/lounge/custom/:customId',
      props: true,
    },
    {
      component: Session,
      meta: { fullPage: false },
      name: 'session',
      path: '/:eventCode/session/:sessionId',
      props: true,
    },
    {
      component: Presentation,
      meta: { fullPage: false },
      name: 'presentation',
      path: '/:eventCode/session/:sessionId/presentation/:presentationId',
      props: true,
    },
    {
      component: Stand,
      meta: { fullPage: false },
      name: 'stand',
      path: '/:eventCode/stand/:sponsorId',
      props: true,
    },
    {
      component: UserProgramme,
      meta: { fullPage: false },
      name: 'myprogramme',
      path: '/:eventCode/my-programme',
      props: { userProgramme: true },
    },
    {
      component: Login,
      meta: { fullPage: true },
      name: 'login',
      path: '/:eventCode/login',
    },
    {
      component: UniqueUsers,
      meta: { fullPage: false },
      name: 'uniqueusers',
      path: '/:eventCode/reports/uniqueusers',
    },
    {
      component: AdClicks,
      meta: { fullPage: false },
      name: 'adclicks',
      path: '/:eventCode/reports/adclicks',
    },
    {
      component: Messages,
      meta: { fullPage: false },
      name: 'messages',
      path: '/:eventCode/reports/messages',
    },
    {
      component: Summary,
      meta: { fullpage: false },
      name: 'summary',
      path: '/:eventCode/reports/summary',
    },
    {
      component: SubmitPosterList,
      meta: { fullPage: false },
      name: 'submitPosterList',
      path: '/:eventCode/submit/posters',
    },
    {
      component: SubmitPresentationList,
      meta: { fullPage: false },
      name: 'submitPresentationList',
      path: '/:eventCode/submit/presentations',
    },
    {
      component: SubmitSessionList,
      meta: { fullPage: false },
      name: 'submitSessionlist',
      path: '/:eventCode/submit/sessions',
    },
    {
      component: AddPresentation,
      meta: { fullPage: false },
      name: 'addpresentation',
      path: '/:eventCode/admin/addpresentation',
    },
    {
      component: AddSession,
      meta: { fullPage: false },
      name: 'addsession',
      path: '/:eventCode/admin/addsession',
    },
    {
      component: SessionList,
      meta: { fullPage: false },
      name: 'sessionlist',
      path: '/:eventCode/admin/sessions',
    },
    {
      component: NotFound,
      meta: { fullPage: true },
      name: 'everything',
      path: '*',
    },
  ],
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
  if (to.params.eventCode) {
    await store.dispatch(`${logNamespace}/${LogActions.SEND_NAVIGATION_LOG}`, {
      to: to.fullPath,
      from: from.fullPath,
    });

    const eventCode = to.params.eventCode.toUpperCase();
    if (to.name === 'login') {
      await store.dispatch(
        `${platformNamespace}/${PlatformActions.INITIALISATION_PLATFORM}`,
        { eventCode, andUser: false },
      );
      return next();
    }

    try {
      const platform = await store.dispatch(
        `${platformNamespace}/${PlatformActions.INITIALISATION_PLATFORM}`,
        { eventCode, andUser: true },
        { root: true },
      );
      if (!platform) {
        throw new Error('Platform does not exist');
      }

      await checkConnection();
      if (to.path.includes('reports')) {
        next();
        // } else if (!platform.open && !platform.closedText) {
        //   if (to.path !== `/${eventCode}`) {
        //     next(`/${eventCode}`);
        //   } else {
        //     next();
        //   }
      } else if (!platform.open) {
        if (to.path !== `/${eventCode}/login`) {
          next(`/${eventCode}/login`);
        } else {
          next();
        }
      } else {
        next();
      }
    } catch (err: any) {
      console.error(err.message);
      if (to.path !== `/notfound`) {
        sessionStorage.removeItem('eventCode');
        sessionStorage.removeItem('platformId');
        next(`/notfound`);
      } else {
        next();
      }
    }
  } else {
    if (to.path !== '/notfound') {
      sessionStorage.removeItem('eventCode');
      sessionStorage.removeItem('platformId');
      // return (window.location.href =
      //   'https://www.openslideservices.com/#virtualevents');
    } else {
      next();
    }
  }
});

const checkConnection = async () => {
  if (TokenStorage.isAuthenticated()) {
    if (
      !store.getters[`${signalrNamespace}/${SignalrGetters.SIGNALR_CONNECTED}`]
    ) {
      store.dispatch(`${signalrNamespace}/${SignalrActions.CONNECT_SIGNALR}`);
    }
  }
};

export default router;
