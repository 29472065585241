
import AdobePdfViewer from '@/components/AdobePdfViewer.vue';
import ActivityDrawer from '@/components/ActivityDrawer.vue';
import SessionCard from '@/components/SessionCard.vue';
import SessionDetails from '@/components/SessionDetails.vue';
import VimeoPlayer from '@/components/VimeoPlayer.vue';
import isMobile from 'ismobilejs';
import {
  NavigationActions,
  NavigationGetters,
} from '../store/navigation/types';
import {
  SessionActions,
  SessionGetters,
  SessionItem,
  SessionQuestionItem,
} from '../store/session/types';
import { Action, Getter } from 'vuex-class';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { HeartbeatResponse } from '@/models/HeartbeatResponse';
import { PlatformActions } from '../store/platform/types';
import {
  PlatformUserActions,
  PlatformUserGetters,
} from '@/store/platformUser/types';
import { Route } from 'vue-router';
import { Scan } from '../models/Scan';
import { ScanEntityTypes } from '../models/ScanEntityTypes';
import { ScanTypes } from '../models/ScanTypes';
import { SignalrActions } from '../store/signalr/types';
import { LogActions } from '@/store/log/log';

const navigationNamespace = 'navigation';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';
const sessionNamespace = 'session';
const signalrNamespace = 'signalr';

const logNamespace = 'log';

@Component({
  components: {
    ActivityDrawer,
    AdobePdfViewer,
    SessionCard,
    SessionDetails,
    VimeoPlayer,
  },
})
export default class ContentVideoPlayer extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public isPresentation!: boolean;

  @Prop()
  public presentationId!: string;

  @Prop()
  public sessionId!: string;

  /* PRIVATE PROPERTIES */
  private enter = '';
  private entityId = '';
  private heartBeat!: Scan;
  private heartBeatInterval = 0;
  private isMod = false;
  private leave = '';
  private pageLoaded = false;
  private questionInterval = 0;
  private rolename = '';
  private scanEntity = '';
  private screenHeight = this.getViewportSize(window).h;
  private screenWidth = this.getViewportSize(window).w;
  private username = '';
  private dialog = false;

  private overlay = false;

  /* VUEX GETTERS */
  // Navigation Getters
  @Getter(NavigationGetters.ACTIVITY_DRAWER, { namespace: navigationNamespace })
  private drawer!: boolean;

  @Getter(NavigationGetters.NAVIGATION_MINI_VARIANT, {
    namespace: navigationNamespace,
  })
  private miniVariant!: boolean;

  // Platfor User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  // Session Getters
  @Getter(SessionGetters.RELATED_SESSIONS, { namespace: sessionNamespace })
  private related!: SessionItem[];

  @Getter(SessionGetters.SELECTED_SESSION, { namespace: sessionNamespace })
  private selectedSession!: SessionItem;

  /* VUEX ACTIONS */
  // Navigation Actions
  @Action(NavigationActions.ACTIVITY_INVERT_DRAWER, {
    namespace: navigationNamespace,
  })
  private invertDrawer!: () => void;

  // Log Actions
  @Action(LogActions.SET_TRACE_LOG_STATE, { namespace: logNamespace })
  private setTraceLogState!: ({
    entityId1,
    entityId2,
    page,
    title,
  }: {
    entityId1?: string;
    entityId2?: string;
    page: string;
    title: string;
  }) => void;

  // Platform Actions
  @Action(PlatformActions.LOG_SCAN, { namespace: platformNamespace })
  private scanLog!: (scan: Scan) => Promise<void | HeartbeatResponse>;

  // Platform User Actions
  @Action(PlatformUserActions.IS_PRESENTATION_MODERATOR, {
    namespace: platformUserNamespace,
  })
  private isPresentationModerator!: (
    presentationId: string,
  ) => Promise<boolean>;

  @Action(PlatformUserActions.IS_SESSION_MODERATOR, {
    namespace: platformUserNamespace,
  })
  private isSessionModerator!: (sessionId: string) => Promise<boolean>;

  // Session Actions
  @Action(SessionActions.CLEAR_SELECTED_SESSION, {
    namespace: sessionNamespace,
  })
  private clearSelectedSession!: () => void;

  @Action(SessionActions.LOAD_SELECTED_SESSION, { namespace: sessionNamespace })
  private loadSelectedSession!: ({
    sessionId,
    isMod,
  }: {
    sessionId: string;
    isMod: boolean;
  }) => Promise<boolean>;

  @Action(SessionActions.SET_RELATED_SESSION_DATA, {
    namespace: sessionNamespace,
  })
  private setRelatedSessions!: (sessions: SessionItem[]) => void;

  @Action(SessionActions.SET_SELECTED_SESSION, { namespace: sessionNamespace })
  private setSelectedSession!: (session: SessionItem) => void;

  @Action(SessionActions.SET_SELECTED_SESSION_QUESTIONS, {
    namespace: sessionNamespace,
  })
  private setSelectedSessionQuestions!: (
    questions: SessionQuestionItem[],
  ) => void;

  // SignalrActions
  @Action(SignalrActions.UPDATE_CURRENT_ROOM_COUNT, {
    namespace: signalrNamespace,
  })
  private updatedCurrenRoomCount!: (count: number) => void;

  /* WATCHES */
  @Watch('$route', { immediate: false, deep: true })
  private async onRouteChange(newRoute: Route, oldRoute: Route) {
    await this.handleLeave();
    await this.loadData();
  }

  /* LOCAL GETTERS/SETTERS */
  get bannerImage(): string {
    if (
      !this.selectedSession ||
      !this.selectedSession.thumbnailUri ||
      this.selectedSession.thumbnailUri.trim() === ''
    ) {
      return 'https://slidetotal.azureedge.net/slidetotal/loading.gif?v=1';
    }
    return this.selectedSession.thumbnailUri;
  }

  get cssVars(): { '--left-pos': string; '--max-video-width': string } {
    let chat = 0;
    let leftPos = 0;
    let nav = (this as Vue).$vuetify.breakpoint.mobile ? 0 : 220;
    if (!(this as Vue).$vuetify.breakpoint.smAndDown) {
      chat = 340;
      if (this.miniVariant) {
        leftPos = (chat + nav) / 2;
      } else {
        leftPos = chat / 2;
      }
    }
    // if (this.selectedSession.isLive && this.drawer && !(this as any).$vuetify.breakpoint.smAndDown) { chat = 340; }
    if (this.miniVariant) {
      nav = 56;
    }
    const maxWidth = this.screenWidth - chat - nav;
    return {
      '--left-pos': leftPos + 'px',
      '--max-video-width': maxWidth + 'px',
    };
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  private async mounted() {
    this.$root.$on("start-slide-show", (url) => {
      this.startSlideShow(url);
    })
    await this.loadData();
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}

  private async beforeDestroy() {
    console.log('Session before destory');

    window.removeEventListener('resize', this.handleResize);

    if (isMobile(window.navigator).any) {
      window.removeEventListener('pagehide', this.pageUnloaded);
      window.removeEventListener('visibilitychange', this.visibilityChanged);
    } else {
      window.removeEventListener('beforeunload', this.pageUnloaded);
    }
    await this.handleLeave();

    this.clearSelectedSession();
  }

  // private destroyed() {}
  // private errorCaptured() {}

  /* PRIVATE METHODS*/
  private getViewportSize(w: Window) {
    // Use the specified window or the current window if no argument
    w = w || window;

    // This works for all browsers except IE8 and before
    if (w.innerWidth != null) {
      return { w: w.innerWidth, h: w.innerHeight };
    }

    // For IE (or any browser) in Standards mode
    const d = w.document;
    if (document.compatMode === 'CSS1Compat') {
      return {
        h: d.documentElement.clientHeight,
        w: d.documentElement.clientWidth,
      };
    }

    // For browsers in Quirks mode
    return { w: d.body.clientWidth, h: d.body.clientHeight };
  }

  private async handleHeartBeat() {
    const res = await this.scanLog(this.heartBeat);
    if (res) {
      this.updatedCurrenRoomCount(res.count);

      if (this.selectedSession.isLive !== res.live) {
        console.log('No longer live');
        const eventCode = sessionStorage.getItem('eventCode') ?? '';
        return this.$router.replace({ name: 'welcome', params: { eventCode } });
      }
    }
  }

  private async handleLeave() {
    if (this.questionInterval) {
      clearInterval(this.questionInterval);
    }
    if (this.heartBeatInterval !== 0) {
      window.clearInterval(this.heartBeatInterval);
      this.heartBeatInterval = 0;
    }
    await this.log(this.leave);
    this.pageLoaded = false;
  }

  private handleResize() {
    this.screenWidth = this.getViewportSize(window).w;
    this.screenHeight = this.getViewportSize(window).h;
  }

  private async incrementPresentationViewCount(
    sessionId: string,
    presentationId: string,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      await Vue.$http.post(
        `/api/platform/${platformId}/sessions/${sessionId}/presentation/${presentationId}/view`,
      );
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - incrementPresentationViewCount failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private async incrementSessionViewCount(sessionId: string): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      await Vue.$http.post(
        `/api/platform/${platformId}/sessions/${sessionId}/view`,
      );
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - incrementSessionViewCount failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private async loadData() {
    this.overlay = false;
    if (!this.drawer) {
      this.invertDrawer();
    }

    if (this.isPresentation) {
      this.isMod = await this.isPresentationModerator(this.presentationId);
      await this.incrementPresentationViewCount(
        this.sessionId,
        this.presentationId,
      );
      const res = await this.loadSelectedPresentation(
        this.sessionId,
        this.presentationId,
        this.isMod,
      );
      if (!res) {
        return;
      }
      if (this.selectedSession && this.selectedSession.isAPoster) {
        this.enter = ScanTypes.ENTER_POSTER_LOG;
        this.leave = ScanTypes.LEAVE_POSTER_LOG;
        this.scanEntity = ScanEntityTypes.POSTER;
      } else {
        this.enter = ScanTypes.ENTER_PRESENTATION_LOG;
        this.leave = ScanTypes.LEAVE_PRESENTATION_LOG;
        this.scanEntity = ScanEntityTypes.PRESENTATION;
      }
      this.entityId = this.presentationId;
      await this.prepLogging();
      this.pageLoaded = true;
    } else {
      this.isMod = await this.isSessionModerator(this.sessionId);
      await this.incrementSessionViewCount(this.sessionId);
      const res = await this.loadSelectedSession({
        isMod: this.isMod,
        sessionId: this.sessionId,
      });
      if (!res) {
        return;
      }
      this.enter = ScanTypes.ENTER_SESSION_LOG;
      this.leave = ScanTypes.LEAVE_SESSION_LOG;
      this.scanEntity = ScanEntityTypes.SESSION;
      this.entityId = this.sessionId;
      await this.prepLogging();
      this.pageLoaded = true;
    }

    window.addEventListener('resize', this.handleResize);
    console.log('Player mounted');

    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const platformId: string = sessionStorage.getItem('platformId') ?? '';
    if (
      eventCode.toLowerCase() === '21eao' ||
      eventCode.toLowerCase() === '22eaovirtual' ||
      eventCode.toLowerCase() === '23eao-online' ||
      eventCode.toLowerCase() === '24eao-online'
    ) {
      const id = this.isPresentation ? this.presentationId : this.sessionId;

      try {
        const res = await this.$http.get<SessionItem[]>(
          `/api/platform/${platformId}/sessions/${id}/eaorelated?isPresentation=${this.isPresentation}`,
        );
        if (res) {
          this.setRelatedSessions(res.data);
        }
      } catch (error: any) {
        console.log('Error with related');
      }
    }

    if (this.selectedSession.isAPoster) {
      this.setTraceLogState({
        page: 'Poster',
        title: this.selectedSession.title,
        entityId1: this.sessionId,
        entityId2: this.presentationId,
      });
    } else if (this.selectedSession.isPresentation) {
      this.setTraceLogState({
        page: 'Presentation',
        title: this.selectedSession.title,
        entityId1: this.sessionId,
        entityId2: this.presentationId,
      });
    } else {
      this.setTraceLogState({
        page: 'Session',
        title: this.selectedSession.title,
        entityId1: this.sessionId,
      });
    }
  }

  private async loadSelectedPresentation(
    sessionId: string,
    presentationId: string,
    isMod: boolean,
  ): Promise<boolean | void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get<SessionItem>(
        `/api/platform/${platformId}/sessions/${sessionId}/presentation/${presentationId}?check=${isMod}`,
      );
      this.setSelectedSession(res.data);
      return true;
    } catch (e: any) {
      if (e.response.status === 404) {
        return false;
      }
      AppInsightsLogger.logError(
        'Player - incrementPresentationViewCount failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private async loadSelectedSessionQuestions(
    sessionId: string,
    isMod: boolean,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get<SessionQuestionItem[]>(
        `/api/platform/${platformId}/sessions/${sessionId}/questions?check=${isMod}`,
      );
      if (res.data) {
        this.setSelectedSessionQuestions(res.data);
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - loadSelectedSessionQuestions failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private async log(scanType: string) {
    await this.scanLog({
      entityId: this.entityId,
      scanEntityType: this.scanEntity,
      scanType,
      userId: this.currentUserId,
    });
  }

  private async openNewTab(url: string | undefined) {
    if (url && (url.startsWith('https:') || url.startsWith('http:'))) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else if (url) {
      await this.$router.push(url);
    }
  }

  private async pageUnloaded() {
    await this.handleLeave();
  }

  private async prepLogging() {
    if (isMobile(window.navigator).any) {
      window.addEventListener('pagehide', this.pageUnloaded);
      window.addEventListener('visibilitychange', this.visibilityChanged);
    } else {
      window.addEventListener('beforeunload', this.pageUnloaded);
    }
    await this.log(this.enter);

    let entityId = '';
    if (this.isPresentation) {
      entityId = this.presentationId;
    } else {
      entityId = this.sessionId;
    }

    this.heartBeat = {
      entityId: entityId,
      scanEntityType: this.scanEntity,
      scanType: ScanTypes.HEARTBEAT,
      userId: this.currentUserId,
    };

    this.heartBeatInterval = window.setInterval(
      async () => await this.handleHeartBeat(),
      300000,
    );

    if (
      this.isMod &&
      this.selectedSession &&
      this.selectedSession.enableComments &&
      this.selectedSession.isLive
    ) {
      this.questionInterval = window.setInterval(
        async () =>
          await this.loadSelectedSessionQuestions(this.sessionId, this.isMod),
        60000,
      );
    }
  }

  private validVideoIFrameUri(uri: string | undefined): boolean {
    if (uri && uri.toLowerCase().startsWith('https:')) {
      return true;
    }

    return false;
  }

  private async visibilityChanged() {
    if (document.visibilityState === 'visible') {
      await this.log(this.enter);
      if (this.heartBeatInterval === 0) {
        if (!this.heartBeat) {
          this.heartBeat = {
            entityId: this.entityId,
            scanEntityType: this.scanEntity,
            scanType: ScanTypes.HEARTBEAT,
            userId: this.currentUserId,
          };
        }
        this.heartBeatInterval = window.setInterval(
          async () => await this.handleHeartBeat(),
          300000,
        );
      }
      if (
        this.isMod &&
        this.selectedSession &&
        this.selectedSession.enableComments &&
        this.selectedSession.isLive
      ) {
        this.questionInterval = window.setInterval(
          async () =>
            await this.loadSelectedSessionQuestions(this.sessionId, this.isMod),
          60000,
        );
      }
      this.pageLoaded = true;
    } else {
      await this.handleLeave();
    }
  }

  // private played(event: any) {
  //   console.log(event);
  //   console.log('Played the video');
  // }

  // private paused(event: any) {
  //   console.log(event);
  //   console.log('Stopped the video');
  // }

  private ended(event: any) {
    this.overlay = true;
  }

  // private timeupdated(event: any) {
  //   console.log(event);
  //   console.log('Timeupdate the video');
  // }

  private startSlideShow(url: string | undefined) {
    if (!url) return;

    if ( url.includes('.pptx')) {
      // (this.$refs.pptxposteriframe as any).requestFullscreen();
      const app_url ='https://pnl1-powerpoint.officeapps.live.com/p/PowerPointFrame.aspx?PowerPointView=SlideShowView&ui=en-GB&rs=en-GB&WOPISrc=';
      const temp_url = 'http://pnl1-view-wopi.wopi.online.office.net:808/oh/wopi/files/@/wFileId?wFileId=' + encodeURIComponent(url);
      const currentDate = new Date();
      const timestamp = currentDate.getTime();
      const frame = app_url+encodeURI(temp_url)+'&access_token_ttl=0&hid=42c2c594-ec21-47b8-8c98-809f71cbb145&wdModeSwitchTime='+timestamp;

      var mapForm = document.createElement("form");
      mapForm.target = "_blank";
      mapForm.method = "POST";
      mapForm.action = frame;

      var input = document.createElement("input");
      input.type = "hidden";
      input.name = "access_token";
      input.value = "1";
      mapForm.appendChild(input);

      var input2 = document.createElement("input");
      input2.type = "hidden";
      input2.name = "loggable_user_id";
      input2.value = '';
      mapForm.appendChild(input2);

      var input3 = document.createElement("input");
      input3.type = "hidden";
      input3.name = "loggable_owner_id";
      input3.value = "In3Z5cTxfi1tHP2W/xWlXaKQC9m3nb3Oiw5cwWlo7PM=";
      mapForm.appendChild(input3);

      document.body.appendChild(mapForm);
      mapForm.submit();

              // const a = "menubar=false,fullscreen=yes,scrollbar=no,resizable=no,left=0,top=0,width=" + window.screen.width + ",height=" + window.screen.height;
        // map = window.open("", "Map", "popup");

        // if (map)
        // {

        //   map.document.form.requestFullscreen();
        // }

    } else {
      (this.$refs.pdfposteriframe as any).requestFullscreen();
    }
  }
}
