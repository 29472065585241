export interface PlatformState {
  allowAbstractDownload: boolean;
  allowPresentationDownload: boolean;
  alwaysUseDefaultThumbnail: boolean;
  authorisationForCommentsEnabled: boolean;
  bannerBarBackgroundColour: string;
  bannerBarTextColour: string;
  calendarIntervalHeight: string;
  closedText: string;
  commentsEnabled: boolean;
  defaultSessionThumbnailUri: string;
  demandGdpr: boolean;
  enableSessionFilterInPosterModule: boolean;
  eventCode: string;
  eventId: string;
  eventTitle: string;
  externalCreateAccountUri: string;
  externalForgotPasswordUri: string;
  gdprAcceptText: string;
  gdprDeclineText: string;
  gdprText: string;
  helpDocumentUri: string;
  loginBackgroundImageUri: string;
  loginCardBannerClientUri: string;
  loginCardBannerImageUri: string;
  loungeImageUri: string;
  navBarBackgroundColour: string;
  navBarTextColour: string;
  open: boolean;
  portalBackgroundUri: string;
  portalBannerClientUri: string;
  portalBannerUri: string;
  sendForgottenPassowrdUri: string;
  showKeywords: boolean;
  showLikes: boolean;
  showRatings: boolean;
  showViews: boolean;
  showPresentationDurationInProgramme: boolean;
  showAllParticipantCount: boolean;
  showSpeakerCount: boolean;
  showPosterCount: boolean;
  showVodCount: boolean;
  signInEnabled: boolean;
  navigationBarMini: boolean;

  programmeModuleEnabled: boolean;
  vodModuleEnabled: boolean;
  posterModuleEnabled: boolean;
  exhibitionModuleEnabled: boolean;
  speakersModuleEnabled: boolean;
  allParticipantsModuleEnabled: boolean;
  liveSessionModuleEnabled: boolean;
}

export enum PlatformActions {
  INITIALISATION_PLATFORM = 'INITIALISATION_PLATFORM',
  LOG_SCAN = 'LOG_SCAN',
  DOWNLOAD_LOG = 'DOWNLOAD_LOG',
  SPONSOR_AD_LOG = 'SPONSOR_AD_LOG',
}

export enum PlatformGetters {
  ALLOW_ABSTRACT_DOWNLOAD = 'ALLOW_ABSTRACT_DOWNLOAD',
  ALLOW_PRESENTATION_DOWNLOAD = 'ALLOW_PRESENTATION_DOWNLOAD',
  ALWAYS_USER_DEFAULT_THUMBNAIL = 'ALWAYS_USER_DEFAULT_THUMBNAIL',
  BANNER_BAR_BACKGROUND_COLOUR = 'BANNER_BAR_BACKROUND_COLOUR',
  BANNER_BAR_TEXT_COLOUR = 'BANNER_BAR_TEXT_COLOUR',
  CALENDAR_INTERVAL_HEIGHT = 'CALENDAR_INTERVAL_HEIGHT',
  DEFAULT_SESSION_THUMBNAIL_URI = 'DEFAULT_SESSION_THUMBNAIL_URI',
  DEMAND_GDPR = 'DEMAND_GDPR',
  ENABLE_SESSION_FILTER_IN_POSTER_MODULE = 'ENABLE_SESSION_FILTER_IN_POSTER_MODULE',
  EXTERNAL_CREATE_ACCOUNT_URI = 'EXTERNAL_CREATE_ACCOUNT_URI',
  EXTERNAL_FORGOT_PASSWORD_URI = 'EXTERNAL_FORGOT_PASSWORD_URI',
  GDPR_ACCEPT_TEXT = 'GDPR_ACCEPT_TEXT',
  GDPR_DECLINE_TEXT = 'GDPR_DECLINE_TEXT',
  GDPR_TEXT = 'GDPR_TEXT',
  HELP_DOCUMENT_URI = 'HELP_DOCUMENT_URI',
  LOGIN_BACKGROUND_IMAGE_URI = 'LOGIN_BACKGROUND_IMAGE_URI',
  LOGIN_CARD_BANNER_CLIENT_URI = 'LOGIN_CARD_BANNER_CLIENT_URI',
  LOGIN_CARD_BANNER_IMAGE_URI = 'LOGIN_CARD_BANNER_IMAGE_URI',
  LOUNGE_IMAGE_URI = 'LOUNGE_IMAGE_URI',
  NAV_BAR_BACKGROUND_COLOUR = 'NAV_BAR_BACKGROUND_COLOUR',
  NAV_BAR_MINI = 'NAV_BAR_MINI',
  NAV_BAR_TEXT_COLOUR = 'NAV_BARR_TEXT_COLOUR',
  PLATFORM_ID = 'PLATFORM_ID',
  PORTAL_BACKGROUND_URI = 'PORTAL_BACKGROUND_URI',
  PORTAL_BANNER_CLIENT_URI = 'PORTAL_BANNER_CLIENT_URI',
  PORTAL_BANNER_URI = 'PORTAL_BANNER_URI',
  PORTAL_CLOSED_TEXT = 'PORTAL_CLOSED_TEXT',
  PORTAL_HELP_DOCUMENT_URI = 'PORTAL_HELP_DOCUMENT_URI',
  PORTAL_OPEN = 'PORTAL_OPEN',
  SEND_FORGOTTEN_PASSWORD_URI = 'SEND_FORGOTTEN_PASSWORD_URI',
  SHOW_KEYWORDS = 'SHOW_KEYWORDS',
  SHOW_LIKES = 'SHOW_LIKES',
  SHOW_RATINGS = 'SHOW_RATINGS',
  SHOW_VIEWS = 'SHOW_VIEWS',
  SHOW_PRESENTATION_DURATION_IN_PROGRAMME = 'SHOW_PRESENTATION_DURATION_IN_PROGRAMME',
  SHOW_ALL_PARTICIPANT_COUNT = 'SHOW_ALL_PARTICIPANT_COUNT',
  SHOW_SPEAKER_COUNT = 'SHOW_SPEAKER_COUNT',
  SHOW_POSTER_COUNT = 'SHOW_POSTER_COUNT',
  SHOW_VOD_COUNT = 'SHOW_VOD_COUNT',
  PROGRAMME_MODULE_ENABLED = 'PROGRAMME_MODULE_ENABLED',
  VOD_MODULE_ENABLED = 'VOD_MODULE_ENABLED',
  POSTER_MODULE_ENABLED = 'POSTER_MODULE_ENABLED',
  EXHIBITION_MODULE_ENABLED = 'EXHIBITION_MODULE_ENABLED',
  SPEAKERS_MODULE_ENABLED = 'SPEAKERS_MODULE_ENABLED',
  ALL_PARTICIPANTS_MODULE_ENABLED = 'ALL_PARTICIPANTS_MODULE_ENABLED',
  LIVE_SESSION_MODULE_ENABLED = 'LIVE_SESSION_MODULE_ENABLED'

}

export enum PlatformMutations {
  SET_PLATFORM_STATE = 'SET_PLATFORM_STATE',
}
