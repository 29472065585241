import { AppInsightsLogger } from '@/services/appInsightsLogger';
import Vue from 'vue';
import { ActionTree } from 'vuex';
import { PlatformUserGetters, PlatformUserItem } from '../platformUser/types';
import {
  SignalrActions,
  SignalrMessage,
  SignalrMessageTypes,
} from '../signalr/types';
import { RootState } from '../types';
import {
  LoungeActions,
  LoungeGetters,
  LoungeMutations,
  LoungeRoom,
  LoungeState,
  NewLoungeRoom,
} from './types';

export const actions: ActionTree<LoungeState, RootState> = {
  [LoungeActions.INVERT_DRAWER]({ commit }): void {
    commit(LoungeMutations.INVERT_DRAWER);
  },

  [LoungeActions.CLEAR_CURRENT_LOUNGE_ROOM]({ commit }): void {
    commit(LoungeMutations.CLEAR_CURRENT_LOUNGE_ROOM);
  },

  async [LoungeActions.CREATE_NEW_LOUNGE_ROOM_API](
    _,
    newLoungeRoom: NewLoungeRoom,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      await Vue.$http.post(
        `/api/platform/${platformId}/lounge/${newLoungeRoom.parentId}/createroom`,
        newLoungeRoom,
      );
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Lounge Action - CREATE_NEW_LOUNGE_ROOM_API failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  },

  async [LoungeActions.DELETE_LOUNGE_ROOM_API](
    _,
    room: LoungeRoom,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      await Vue.$http.post(
        `/api/platform/${platformId}/lounge/${room.parentId}/removeroom/${room.groupId}`,
      );
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Lounge Action - DELETE_LOUNGE_ROOM_API failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  },

  async [LoungeActions.LOAD_LOUNGE_ROOMS_API](
    { commit },
    parentId: string,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    let loungeRooms: LoungeRoom[] = [];
    try {
      const uri = `/api/platform/${platformId}/lounge/${parentId}/rooms`;
      const res = await Vue.$http.get<LoungeRoom[]>(uri);
      loungeRooms = res.data;
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Lounge Action - LOAD_LOUNGE_ROOMS_API failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }

    const processed = loungeRooms.map((lr) => {
      if (localStorage.getItem(`favroom-${lr.groupId}`)) {
        lr.favourite = true;
      } else {
        lr.favourite = false;
      }
      return lr;
    });
    commit(LoungeMutations.LOAD_LOUNGE_ROOMS, processed);
  },

  [LoungeActions.LOUNGE_ROOM_CREATED](
    { commit },
    newLoungeRoom: LoungeRoom,
  ): void {
    commit(LoungeMutations.ADD_LOUNGE_ROOM, newLoungeRoom);
  },

  [LoungeActions.LOUNGE_ROOM_DELETED](
    { commit, getters, rootGetters },
    groupId: string,
  ): void {
    const eventId = sessionStorage.getItem('platformId') ?? '';
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const currentUser: PlatformUserItem =
      rootGetters[
        `platformUser/${PlatformUserGetters.GET_CURRENT_PLATFORM_USER}`
      ];
    const room: LoungeRoom = getters[LoungeGetters.LOUNGE_ROOM_BY_GROUP_ID](
      groupId,
    );

    const msg: SignalrMessage = {
      eventCode,
      eventId,
      group: room.groupName,
      groupId: room.groupId,
      groupType: room.groupType,
      messageType: SignalrMessageTypes.LEAVE_GROUP,
      userId: currentUser.userId,
      userName: currentUser.userName,
      userPhotoUri: currentUser.photoUri,
    };

    this.dispatch(`signalr/${SignalrActions.LEAVE_GROUP}`, msg, { root: true });
    if (room.favourite) {
      commit(LoungeMutations.DELETE_FAVOURITE_MESSAGES, groupId);
    }
    commit(LoungeMutations.DELETE_LOUNGE_ROOM, groupId);
  },

  [LoungeActions.INVERT_FAVOURITE]({ commit }, orgRoom: LoungeRoom): void {
    const updatedRoom = { ...orgRoom };
    updatedRoom.favourite = !orgRoom.favourite;
    commit(LoungeMutations.UPDATE_LOUNGE_ROOM, { orgRoom, updatedRoom });
    commit(LoungeMutations.SET_CURRENT_LOUNGE_ROOM, updatedRoom);

    if (updatedRoom.favourite) {
      localStorage.setItem(`favroom-${updatedRoom.groupId}`, '1');
      sessionStorage.setItem(`favroom-${updatedRoom.groupId}`, '1');
    } else {
      localStorage.removeItem(`favroom-${updatedRoom.groupId}`);
      sessionStorage.removeItem(`favroom-${updatedRoom.groupId}`);
    }
  },

  [LoungeActions.SET_CURRENT_LOUNGE_ROOM](
    { commit, getters },
    groupId: string,
  ): void {
    const room: LoungeRoom = getters[LoungeGetters.LOUNGE_ROOM_BY_GROUP_ID](
      groupId,
    );
    if (room) {
      commit(LoungeMutations.SET_CURRENT_LOUNGE_ROOM, room);
      if (room.favourite) {
        const msgs: SignalrMessage[] = getters[
          LoungeGetters.FAVOURITE_MESSAGES_FOR_GROUP_ID
        ](groupId);
        this.dispatch(`signalr/${SignalrActions.SET_MESSAGES}`, msgs, {
          root: true,
        });
      }
    }
  },

  [LoungeActions.UPDATE_LOUNGE_ROOM_COUNT](
    { commit, getters },
    { groupId, count }: { groupId: string; count: number },
  ): void {
    const orgRoom: LoungeRoom = getters[LoungeGetters.LOUNGE_ROOM_BY_GROUP_ID](
      groupId,
    );
    const updatedRoom = { ...orgRoom };
    updatedRoom.groupCount = count;

    commit(LoungeMutations.UPDATE_LOUNGE_ROOM, { orgRoom, updatedRoom });
  },
};
