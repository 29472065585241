import { render, staticRenderFns } from "./Posters.vue?vue&type=template&id=2c09f376&scoped=true&"
import script from "./Posters.vue?vue&type=script&lang=ts&"
export * from "./Posters.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c09f376",
  null
  
)

export default component.exports