
import Background from '@/components/Background.vue';
import HomeVod from '@/components/HomeVod.vue';
import { Landing } from '@/models/Landing';
import { AuthGetters } from '@/store/auth/types';
import {
  PlatformUserGetters,
  PlatformUserItem,
} from '@/store/platformUser/types';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { PlatformGetters } from '../store/platform/types';
import { NavigationActions } from '@/store/navigation/types';
import { LogActions } from '@/store/log/log';

const authNamespace = 'auth';
const logNamespace = 'log';
const navigationNamespace = 'navigation';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';

@Component({
  components: {
    Background,
    HomeVod,
  },
})
export default class Welcome extends Vue {
  /* PUBLIC PROPERTIES */

  /* PRIVATE PROPERTIES */
  private landing: Landing | null = null;
  private dialog = false;
  private accepted: string | null = null;

  /* VUEX GETTERS */
  // Auth Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Platform Getters
  @Getter(PlatformGetters.PORTAL_BACKGROUND_URI, {
    namespace: platformNamespace,
  })
  private portalBackgroundUri!: string;

  @Getter(PlatformGetters.DEMAND_GDPR, {
    namespace: platformNamespace,
  })
  private demandGdpr!: boolean;

  // Platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER, {
    namespace: platformUserNamespace,
  })
  private getCurrentPlatformUser!: PlatformUserItem;

  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  /* VUEX ACTIONS */
  // Log Actions
  @Action(LogActions.SET_TRACE_LOG_STATE, { namespace: logNamespace })
  private setTraceLogState!: ({
    entityId1,
    entityId2,
    page,
    title,
  }: {
    entityId1?: string;
    entityId2?: string;
    page: string;
    title: string;
  }) => void;

  // Navigation Actions
  @Action(NavigationActions.PROFILE_INVERT_DIALOG, {
    namespace: navigationNamespace,
  })
  private invertDialog!: () => void;
  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get dynamicComponent(): Vue.Component {
    return {
      methods: {
        openNewTab(url: string): void {
          if (url.startsWith('https:') || url.startsWith('http:')) {
            const win = window.open(url, '_blank');
            if (win) {
              win.focus();
            }
          } else {
            (this as Vue).$router.push(url);
          }
        },

        openNewTabUrevent(url: string): void {
          let isAuthenticated =  (this as Vue).$store.getters["auth/IS_AUTHENTICATED"];
          if (isAuthenticated && (url.startsWith('https:') || url.startsWith('http:'))) {
            let cpu = (this as Vue).$store.getters["platformUser/GET_CURRENT_PLATFORM_USER"];
            if (cpu)
            {
              url = `${url}?ureventref=${cpu.ureventRef}`
              const win = window.open(url, '_blank');
              if (win) {
                win.focus();
              }
            }
          }
        },

      },

      template: `${this.landing?.customHtml}`,
    };
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private async created() {}
  // private beforeMount() {}

  private async mounted() {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get<Landing>(
        `/api/platform/${platformId}/landing`,
      );
      this.landing = res.data;
    } catch (e: any) {
      AppInsightsLogger.logError('Welcome - created failed', undefined, true);
      AppInsightsLogger.logException(e, false);
    }

    if (
      this.getCurrentPlatformUser &&
      this.getCurrentPlatformUser.userId &&
      (this.demandGdpr && (this.getCurrentPlatformUser.accepted === undefined ||
        this.getCurrentPlatformUser.accepted === null))
    ) {
      this.invertDialog();
    }
    this.setTraceLogState({ page: 'Home', title: 'Home' });
  }

  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}
  /* PRIVATE METHODS*/
}
