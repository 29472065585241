
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class extends Vue {
  /* PUBLIC PROPERTIES */
  /* PUBLIC PROPERTIES */
  @Prop()
  public fullname!: string;

  @Prop()
  public alt!: string;

  @Prop()
  public colour!: number;

  @Prop()
  public default!: string;

  @Prop()
  public size!: number | string;

  @Prop()
  public src!: string;
  /* PRIVATE PROPERTIES */
  private image = '';
  /* VUEX GETTERS */
  /* VUEX ACTIONS */
  /* WATCHES */
  @Watch('src')
  private srcChanged() {
    this.image = this.src;
  }
  /* LOCAL GETTERS/SETTERS */
  get initials() {
    const words = this.fullname.split(/[\s-]+/);
    return words
      .map((word) => word.substr(0, 1))
      .join('')
      .substr(0, 3)
      .toUpperCase();
  }
  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}

  private mounted() {
    this.image = this.src;
  }

  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}

  /* PRIVATE METHODS*/
  private handleChatMessagePhotoError() {
    //TODO(David): Log error
    this.image = '';
  }
}
