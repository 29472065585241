
import SessionCard from '@/components/SessionCard.vue';
import { SessionItem } from '@/store/session/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    SessionCard,
  },
})
export default class HomeVodSessionGroup extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public items!: SessionItem[];

  @Prop()
  public groupId!: string;

  @Prop()
  public groupName!: string;

  @Prop()
  public groupType!: string;

  @Prop() textColour!: string;

  /* PRIVATE PROPERTIES */
  /* VUEX GETTERS */

  /* VUEX ACTIONS */
  /* WATCHES */
  /* LOCAL GETTERS/SETTERS */
  get cardHeight(): number {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return 179;
    } else {
      return 224;
    }
  }
  /* PRIVATE METHODS*/
  get eventCode(): string {
    return sessionStorage.getItem('eventCode') ?? '';
  }

  private async browseHeading() {
    const uri = `/${this.eventCode}/vod?${this.groupType}=${this.groupId}`;
    await this.$router.push(uri);
  }

  private moreInfoExecute(session: SessionItem) {
    this.$emit('more', session);
  }
}
