export interface ReportState {
  selectedType?: ReportType;
}

export enum ReportActions {
  SET_SELECTED_TYPE = 'SET_SELECTED_TYPE',
}

export enum ReportGetters {
  SELECTED_TYPE = 'SELECTED_TYPE',
  UNIQUE_USER_MENU_ITEMS = 'UNIQUE_USER_MENU_ITEMS',
}

export enum ReportMutations {
  SET_SELECTED_TYPE = 'SET_SELECTED_TYPE',
}

export enum ReportType {
  UNIQUE_USER_EVENT = 'event',
  UNIQUE_USER_SESSION = 'session',
  UNIQUE_USER_SPONSOR = 'sponsor',
  UNIQUE_USER_POSTER = 'poster',
}
export interface UniqueUserReportMenuItem {
  type: ReportType;
  title: string;
}
