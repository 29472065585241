import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import signalrHub from '../plugins/signalrHub';
import { auth } from './auth/index';
import { bookmark } from './bookmark/index';
import { conversation } from './conversation/index';
import { i18n } from './i18n/index';
import { log } from './log/log';
import { lounge } from './lounge/index';
import { navigation } from './navigation/index';
import { platform } from './platform/index';
import { platformUser } from './platformUser/index';
import { report } from './report/index';
import { session } from './session/index';
import { signalr } from './signalr/index';
import { RootState } from './types';

Vue.use(Vuex);
const plugin = signalrHub();

// Vuex structure based on https://codeburst.io/vuex-and-typescript-3427ba78cfa8

const store: StoreOptions<RootState> = {
  modules: {
    auth,
    bookmark,
    conversation,
    i18n,
    log,
    lounge,
    navigation,
    platform,
    platformUser,
    report,
    session,
    signalr,
  },
  plugins: [plugin],
  state: {
    version: '1.0.0', // a simple property
  },
};

export default new Vuex.Store<RootState>(store);
