import { MutationTree } from 'vuex';
import {
  ConversationItem,
  ConversationMessageItem,
  ConversationMutations,
  ConversationState,
} from './types';

export const mutations: MutationTree<ConversationState> = {
  [ConversationMutations.OPEN_CONVERSATION](
    state,
    conversation: ConversationItem,
  ): void {
    const convo = state.conversations.find((c) => c.id === conversation.id);
    if (convo) {
      convo.unreadCount = 0;
    }
    const open = state.openConversations.find((c) => c.id === conversation.id);
    if (open) {
      open.minimised = false;
    } else {
      state.openConversations.push(conversation);
    }
    state.conversationBarVisible = true;
  },

  [ConversationMutations.ADD_NEW_MESSAGE_TO_NON_OPEN_CONVERSATION](
    state,
    message: ConversationMessageItem,
  ): void {
    const convo = state.conversations.find(
      (c) => c.id === message.conversationId,
    );
    if (convo) {
      convo.latestMessage = message.message;
      convo.unreadCount = convo.unreadCount + 1;
    }
  },

  [ConversationMutations.ADD_NEW_MESSAGE_TO_OPEN_CONVERSATION](
    state,
    message: ConversationMessageItem,
  ): void {
    const open = state.openConversations.find(
      (oc) => oc.id === message.conversationId,
    );
    if (open) {
      open.messageItems.push(message);
      if (open.minimised) {
        open.unreadCount = open.unreadCount + 1;
      }
    }
    const convo = state.conversations.find(
      (c) => c.id === message.conversationId,
    );
    if (convo) {
      convo.latestMessage = message.message;
    }
  },

  [ConversationMutations.CLEAR_CONVERSATION](state): void {
    state.conversations = [];
    state.conversationBarVisible = false;
    state.openConversations = [];
  },

  [ConversationMutations.MAXIMISE_CONVERSATION](state, id: string): void {
    const convo = state.openConversations.find((c) => c.id === id);
    if (convo) {
      convo.minimised = false;
      convo.unreadCount = 0;
    }
  },

  [ConversationMutations.MINIMISE_CONVERSATION](state, id: string): void {
    const convo = state.openConversations.find((c) => c.id === id);
    if (convo) {
      convo.minimised = true;
    }
  },

  [ConversationMutations.CLOSE_CONVERSATION](
    state,
    conversation: ConversationItem,
  ): void {
    const index = state.openConversations.indexOf(conversation);
    if (index > -1) {
      state.openConversations.splice(index, 1);
    }
    if (state.openConversations.length < 1) {
      state.conversationBarVisible = false;
    }
  },

  [ConversationMutations.SET_CONVERSATIONS](
    state,
    conversations: ConversationItem[],
  ): void {
    state.conversations = conversations;
  },

  [ConversationMutations.SET_CONVERSATION_BAR_VISIBLE](
    state,
    visible: boolean,
  ): void {
    state.conversationBarVisible = visible;
  },

  [ConversationMutations.VERIFY_CONVERSATION_VISIBLE](state, id: string): void {
    const open = state.openConversations.find((c) => c.id === id);
    if (open) {
      open.unreadCount = 0;
      open.minimised = false;
      state.conversationBarVisible = true;
    }
  },
};
