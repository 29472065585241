import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { NavigationState } from './types';

export const state: NavigationState = {
  activityDrawer: true,
  activityMiniVariant: false,
  navigationDrawer: false,
  navigationMiniVariant: false,
  profileDialog: false,
  mobilePageTitle: '',
};

const namespaced = true;

export const navigation: Module<NavigationState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
