
import { HeartbeatResponse } from '@/models/HeartbeatResponse';
import isMobile from 'ismobilejs';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Scan } from '../models/Scan';
import { ScanEntityTypes } from '../models/ScanEntityTypes';
import { ScanTypes } from '../models/ScanTypes';
import { SponsorLounge } from '../models/SponsorLounge';
import {
  LoungeActions,
  LoungeGetters,
  LoungeRoom,
  NewLoungeRoom,
} from '../store/lounge/types';
import { PlatformActions } from '../store/platform/types';
import {
  PlatformUserGetters,
  PlatformUserItem,
} from '../store/platformUser/types';
import {
  SignalrActions,
  SignalrGetters,
  SignalrGroupTypes,
  SignalrMessage,
  SignalrMessageTypes,
} from '../store/signalr/types';
const loungeNamespace = 'lounge';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';
const signalrNamespace = 'signalr';

@Component({
  components: {},
})
export default class RoomListDrawer extends Vue {
  @Prop()
  public isMod!: boolean;

  @Prop()
  public sponsorId!: string;

  @Prop()
  public sponsorLounge!: SponsorLounge;

  private roomList = [];
  private newRoom = '';
  private item = '';
  private roomFilter = '';
  private loungeRoomFoyer: LoungeRoom | undefined;
  private leave = '';
  private enter = '';
  private scanEntity = '';
  private stillNotConnected = false;

  /* VUEX GETTERS */
  // Lounge Getters
  @Getter(LoungeGetters.DRAWER, { namespace: loungeNamespace })
  private drawer!: boolean;

  @Getter(LoungeGetters.FAVOURITE_LOUNGE_ROOMS, { namespace: loungeNamespace })
  private favouriteLoungeRooms!: (parentId: string) => LoungeRoom[];

  @Getter(LoungeGetters.FILTERED_NON_FAVOURITE_LOUNGE_ROOMS, {
    namespace: loungeNamespace,
  })
  private filteredNonFavouriteLoungeRooms!: (
    parentId: string,
    roomFilter: string,
  ) => LoungeRoom[];

  @Getter(LoungeGetters.CURRENT_LOUNGE_ROOM, { namespace: loungeNamespace })
  private currentLoungeRoom!: LoungeRoom;

  @Getter(LoungeGetters.LOUNGE_ROOM_BY_PARENT_ID_AND_GROUP_NAME, {
    namespace: loungeNamespace,
  })
  private loungeRoomByParentIdAndGroupName!: (
    parentId: string,
    groupName: string,
  ) => LoungeRoom | undefined;

  @Getter(LoungeGetters.LOUNGE_ROOM_LIST_LOADING, {
    namespace: loungeNamespace,
  })
  private loungeRoomListLoading!: LoungeRoom;

  // Platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER, {
    namespace: platformUserNamespace,
  })
  private currentUser!: PlatformUserItem;

  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_NAME, {
    namespace: platformUserNamespace,
  })
  private currentUserFullName!: string;

  // Signalr Getters
  @Getter(`${SignalrGetters.USER_CONNECTED}`, { namespace: signalrNamespace })
  private userConnected!: boolean;

  /* VUEX ACTIONS */
  // Lounge Actions
  @Action(LoungeActions.CLEAR_CURRENT_LOUNGE_ROOM, {
    namespace: loungeNamespace,
  })
  private clearCurrentLoungeRoom!: () => void;

  @Action(LoungeActions.INVERT_DRAWER, { namespace: loungeNamespace })
  private invertDrawer!: () => void;

  @Action(LoungeActions.CREATE_NEW_LOUNGE_ROOM_API, {
    namespace: loungeNamespace,
  })
  private createNewLoungeRoomApi!: (
    newLoungeRoom: NewLoungeRoom,
  ) => Promise<void>;

  @Action(LoungeActions.DELETE_LOUNGE_ROOM_API, {
    namespace: loungeNamespace,
  })
  private deleteLoungeRoomApi!: (room: LoungeRoom) => Promise<void>;

  @Action(LoungeActions.LOAD_LOUNGE_ROOMS_API, { namespace: loungeNamespace })
  private loadLoungeRooms!: (parentId: string) => Promise<void>;

  // Platform Actions
  @Action(PlatformActions.LOG_SCAN, { namespace: platformNamespace })
  private scanLog!: (scan: Scan) => Promise<void | HeartbeatResponse>;

  // Signalr Actions

  @Action(SignalrActions.CLEAR_CURRRENT_GROUP, { namespace: signalrNamespace })
  private clearCurrentGroup!: () => void;

  @Action(SignalrActions.CLEAR_CURRENT_GROUP_MESSAGES, {
    namespace: signalrNamespace,
  })
  private clearCurrentGroupMessages!: () => void;

  @Action(SignalrActions.ENTER_GROUP, { namespace: signalrNamespace })
  private enterGroup!: (msg: SignalrMessage) => void;

  @Action(SignalrActions.LEAVE_GROUP, { namespace: signalrNamespace })
  private leaveGroup!: (msg: SignalrMessage) => void;

  /* WATCHES */
  @Watch('userConnected')
  private userConnectedChanged() {
    this.connectionCheck();
  }

  /* PRIVATE GETTERS */
  get favouriteRooms(): LoungeRoom[] {
    if (this.sponsorId) {
      return this.favouriteLoungeRooms(this.sponsorId);
    }
    const platformId = sessionStorage.getItem('platformId') ?? '';
    return this.favouriteLoungeRooms(platformId);
  }

  get filteredNonFavouriteRooms(): LoungeRoom[] {
    if (this.sponsorId) {
      return this.filteredNonFavouriteLoungeRooms(
        this.sponsorId,
        this.roomFilter,
      );
    }
    const platformId = sessionStorage.getItem('platformId') ?? '';
    return this.filteredNonFavouriteLoungeRooms(platformId, this.roomFilter);
  }

  get nonFavouriteRooms(): LoungeRoom[] {
    if (this.sponsorId) {
      return this.filteredNonFavouriteLoungeRooms(this.sponsorId, '');
    }
    const platformId = sessionStorage.getItem('platformId') ?? '';
    return this.filteredNonFavouriteLoungeRooms(platformId, '');
  }

  /* PRIVATE METHODS*/

  private async mounted() {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    let parentId = '';
    if (this.sponsorId) {
      this.enter = ScanTypes.ENTER_SPONSOR_LOUNGE_LOG;
      this.leave = ScanTypes.LEAVE_SPONSOR_LOUNGE_LOG;
      this.scanEntity = ScanEntityTypes.SPONSOR_LOUNGE;
      parentId = this.sponsorId;
      this.loungeRoomFoyer = {
        favourite: false,
        groupCount: 0,
        groupId: parentId,
        groupName: `${SignalrGroupTypes.SPONSOR_LOUNGE_FOYER}-${parentId}`,
        groupType: SignalrGroupTypes.SPONSOR_LOUNGE_FOYER,
        hidden: true,
        parentId,
        private: true,
      };
    } else {
      this.enter = ScanTypes.ENTER_LOUNGE_LOG;
      this.leave = ScanTypes.LEAVE_LOUNGE_LOG;
      this.scanEntity = ScanEntityTypes.LOUNGE;
      parentId = platformId;
      this.loungeRoomFoyer = {
        favourite: false,
        groupCount: 0,
        groupId: parentId,
        groupName: `${SignalrGroupTypes.LOUNGE_FOYER}-${parentId}`,
        groupType: SignalrGroupTypes.LOUNGE_FOYER,
        hidden: true,
        parentId,
        private: true,
      };
    }

    await this.loadLoungeRooms(parentId);
    this.enterGroupLocal(this.loungeRoomFoyer);

    if (isMobile(window.navigator).any) {
      window.addEventListener('pagehide', this.pageUnloaded);
      window.addEventListener('visibilitychange', this.visibilityChanged);
      window.addEventListener('beforeunload', this.pageUnloaded);
    } else {
      window.addEventListener('beforeunload', this.pageUnloaded);
    }
    this.connectionCheck();
  }

  private connectionCheck() {
    if (!this.userConnected) {
      this.clearCurrentGroup();
      this.clearCurrentGroupMessages();
      this.clearCurrentLoungeRoom();
      setTimeout(() => {
        if (!this.userConnected) {
          this.stillNotConnected = true;
        }
      }, 30000);
    }
  }

  private rejoinFavouriteRoomsIfNecessary() {
    this.favouriteRooms.forEach((fr) => {
      if (!sessionStorage.getItem(`favroom-${fr.groupId}`)) {
        this.enterGroup(this.enterMessage(fr));
      }
    });
  }

  private enterMessage(loungeRoom: LoungeRoom): SignalrMessage {
    const enterMessage = this.createBaseMessage(
      loungeRoom,
      SignalrMessageTypes.ENTER_GROUP,
    );
    return enterMessage;
  }

  private leaveMessage(loungeRoom: LoungeRoom): SignalrMessage {
    const leaveMessage = this.createBaseMessage(
      loungeRoom,
      SignalrMessageTypes.LEAVE_GROUP,
    );
    return leaveMessage;
  }

  private createBaseMessage(
    loungeRoom: LoungeRoom,
    messageType: SignalrMessageTypes,
  ): SignalrMessage {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const eventId: string = sessionStorage.getItem('platformId') ?? '';
    const groupId = loungeRoom.groupId;
    const group = loungeRoom.groupName;
    const groupType = loungeRoom.groupType;
    let parentId = '';

    if (this.sponsorId) {
      parentId = this.sponsorId;
    } else {
      parentId = eventId;
    }

    const msg = {
      eventCode,
      eventId,
      group,
      groupId,
      groupType,
      isMod: this.isMod,
      messageType,
      parentId,
      userId: this.currentUserId,
      userName: this.currentUserFullName,
      userPhotoUri: this.currentUser.photoUri,
    };

    return msg;
  }

  private async createLoungeRoomExecute() {
    let loungeRoomName = this.newRoom;
    if (!loungeRoomName || loungeRoomName === '') {
      return;
    }
    loungeRoomName = loungeRoomName.trim().toLowerCase();
    loungeRoomName = loungeRoomName.replace(/\s+/g, '-');
    loungeRoomName = loungeRoomName.replace(/\W/g, '');

    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const eventId: string = sessionStorage.getItem('platformId') ?? '';
    let parentId;
    let groupType;
    let parentName;
    if (this.sponsorId) {
      parentId = this.sponsorId;
      groupType = SignalrGroupTypes.SPONSOR_LOUNGE;
      parentName = `Sponsor_${this.sponsorLounge.title}`;
    } else {
      parentId = eventId;
      groupType = SignalrGroupTypes.LOUNGE;
      parentName = eventCode;
    }
    const exists = this.loungeRoomByParentIdAndGroupName(
      parentId,
      loungeRoomName,
    );
    if (exists) {
      return;
    }

    const newLoungeroom: NewLoungeRoom = {
      eventCode,
      eventId,
      groupName: loungeRoomName,
      groupType,
      hidden: false,
      parentId,
      parentName,
      private: false,
    };

    await this.createNewLoungeRoomApi(newLoungeroom);
    this.newRoom = '';
  }

  private deleteLoungeRoomExecute(room: LoungeRoom) {
    if (
      window.confirm(
        this.$t('lounge.confirmDelete', this.$i18n.locale, [
          room.groupName,
        ]) as string,
      )
    ) {
      this.deleteLoungeRoomApi(room);
    }
  }

  private async enterGroupLocal(room: LoungeRoom, current = false) {
    await this.log(room.groupId, this.enter);
    const msg = this.enterMessage(room);
    if (current) {
      msg.isCurrent = true;
    }
    this.enterGroup(msg);
  }

  private async leaveGroupLocal(room: LoungeRoom) {
    if (!room.favourite) {
      await this.log(room.groupId, this.leave);
      this.leaveGroup(this.leaveMessage(room));
    }
  }

  private async enterRoomExecute(room: LoungeRoom) {
    if (this.currentLoungeRoom) {
      this.leaveGroupLocal(this.currentLoungeRoom);
    }
    this.enterGroupLocal(room, true);
  }

  private async log(entityId: string, scanType: string) {
    await this.scanLog({
      entityId,
      scanEntityType: this.scanEntity,
      scanType,
      userId: this.currentUserId,
    });
  }

  private async visibilityChanged() {
    try {
      if (document.visibilityState === 'visible') {
        const eventId = sessionStorage.getItem('platformId') ?? '';
        let parentId;
        if (this.sponsorId) {
          parentId = this.sponsorId;
        } else {
          parentId = eventId;
        }
        await this.loadLoungeRooms(parentId);

        if (this.loungeRoomFoyer) {
          this.enterGroupLocal(this.loungeRoomFoyer);
        } else {
          return location.reload();
        }
        if (this.currentLoungeRoom) {
          this.enterGroupLocal(this.currentLoungeRoom);
        }
      } else {
        if (this.currentLoungeRoom) {
          this.leaveGroupLocal(this.currentLoungeRoom);
        }
        if (this.loungeRoomFoyer) {
          this.leaveGroupLocal(this.loungeRoomFoyer);
        }
      }
    } catch (e: any) {
      console.error(e);
    }
  }

  private pageUnloaded() {
    if (this.currentLoungeRoom) {
      this.leaveGroupLocal(this.currentLoungeRoom);
    }
    if (this.loungeRoomFoyer) {
      this.leaveGroupLocal(this.loungeRoomFoyer);
    }
  }

  private async beforeDestroy() {
    if (this.currentLoungeRoom) {
      this.leaveGroupLocal(this.currentLoungeRoom);
    }
    if (this.loungeRoomFoyer) {
      this.leaveGroupLocal(this.loungeRoomFoyer);
    }
    if (isMobile(window.navigator).any) {
      window.removeEventListener('pagehide', this.pageUnloaded);
      window.removeEventListener('visibilitychange', this.visibilityChanged);
    } else {
      window.removeEventListener('beforeunload', this.pageUnloaded);
    }
  }
}
