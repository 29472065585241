export interface NavigationState {
  navigationMiniVariant: boolean;
  navigationDrawer: boolean;
  activityMiniVariant: boolean;
  activityDrawer: boolean;
  profileDialog: boolean;
  mobilePageTitle: string;
}

export enum NavigationActions {
  ACTIVITY_INVERT_DRAWER = 'ACTIVITY_INVERT_DRAWER',
  ACTIVITY_INVERT_MINI_VARIANT = 'ACTIVITY_INVERT_MINI_VARIANT',
  ACTIVITY_SET_DRAWER = 'ACTIVITY_SET_DRAWER',
  NAVIGATION_INVERT_DRAWER = 'NAVIGATION_INVERT_DRAWER',
  NAVIGATION_INVERT_MINI_VARIANT = 'NAVIGATION_INVERT_MINI_VARIANT',
  NAVIGATION_SET_DRAWER = 'NAVIGATION_SET_DRAWER',
  PROFILE_INVERT_DIALOG = 'PROFILE_INVERT_DIALOG',
  SET_MOBILE_PAGE_TITLE = 'SET_MOBILE_PAGE_TITLE',
}

export enum NavigationGetters {
  ACTIVITY_DRAWER = 'ACTIVITY_DRAWER',
  ACTIVITY_MINI_VARIANT = 'ACTIVITY_MINI_VARIANT',
  NAVIGATION_DRAWER = 'NAVIGATION_DRAWER',
  NAVIGATION_MINI_VARIANT = 'NAVIGATION_MINI_VARIANT',
  PROFILE_DIALOG = 'PROFILE_DIALOG',
  MOBILE_PAGE_TITLE = 'MOBILE_PAGE_TITLE',
}

export enum NavigationMutations {
  NAVIGATION_INVERT_DRAWER = 'NAVIGATION_INVERT_DRAWER',
  NAVIGATION_INVERT_MINI_VARIANT = 'NAVIGATION_INVERT_MINI_VARIANT',
  NAVIGATION_SET_DRAWER = 'NAVIGATION_SET_DRAWER',
  ACTIVITY_INVERT_DRAWER = 'ACTIVITY_INVERT_DRAWER',
  ACTIVITY_INVERT_MINI_VARIANT = 'ACTIVITY_INVERT_MINI_VARIANT',
  ACTIVITY_SET_DRAWER = 'ACTIVITY_SET_DRAWER',
  PROFILE_INVERT_DIALOG = 'PROFILE_INVERT_DIALOG',
  SET_MOBILE_PAGE_TITLE = 'SET_MOBILE_PAGE_TITLE',
}
