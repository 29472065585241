import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { I18nState } from './types';

export const state: I18nState = {
  locale: '',
};

const namespaced = true;

export const i18n: Module<I18nState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
