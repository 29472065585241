
import moment from 'moment';
import {
    SubmitPresentationItem,
    SubmitSessionItem,
    SubmitSettings,
} from '../../models/Submit';
import { Action, Getter } from 'vuex-class';
import { AppInsightsLogger } from '../../services/appInsightsLogger';
import { Component, Prop, Vue } from 'vue-property-decorator';
import SubmitUploadFileDialog from '../../components/SubmitUploadFileDialog.vue'

@Component({
    components: { SubmitUploadFileDialog },
})
export default class SessionList extends Vue {
    /* PUBLIC PROPERTIES */
    /* PRIVATE PROPERTIES */
    private loading: boolean = true;
    private loaded: boolean = false;
    private submitSettings: SubmitSettings = {};
    private dialog: boolean = false;
    private dateFilterValue = null;
    private roomFilterValue = null;
    private uploadedOnly = false;
    private search: string = '';
    private presentationItems: SubmitPresentationItem[] = [];
    private selectedPresentation: SubmitPresentationItem | null = null;

    private presentationHeaders = [
        { text: 'Id', value: 'internalCode', width: 80 },
        { text: this.$t('submit.PosterTitle'), value: 'title' },
        { text: this.$t('submit.Author'), value: 'speakerName' },
        { text: this.$t('submit.Uploaded'), value: 'posterUploadUri', align: ' d-none', filter: this.PosterUploadUriFilter },
        { text: '', value: 'actions', sortable: false, width: 250 },
    ];
    private footerProps = {
        itemsPerPageOptions: [100, 200, 500, -1],
        itemsPerPageText: this.$t('submit.RowsPerPage'),
        itemsPerPageAllText: this.$t('submit.All'),
    };

    /* VUEX GETTERS */
    /* VUEX ACTIONS */
    /* WATCHES */
    /* LOCAL GETTERS/SETTERS */
    /* LIFECYCLE METHODS */
    // private beforeCreate() {}
    // private created() {}
    // private beforeMount() {}
    private async mounted() {
        const platformId = sessionStorage.getItem('platformId') ?? '';
        const res = await Vue.$http.get<SubmitSettings>(`/api/platform/${platformId}/submit`);
        this.submitSettings = res.data
        if (this.submitSettings.closedText) {
            this.loaded = true;
        } else {
            await this.loadPresentations();
        }
    }
    // private beforeUpdate() {}
    // private updated() {}
    // private activated() {}
    // private deactivated() {}
    // private beforeDestroy() {}
    // private destroyed() {}
    // private errorCaptured() {}
    /* PRIVATE METHODS*/

    private DateFilter(value, search, item) {
        if (!this.dateFilterValue) {
            return true;
        }
        return (
            moment(item.start).locale(this.$i18n.locale).format('DD/MM/YYYY') ===
            this.dateFilterValue
        );
    }


    private formatLongDate(start: Date, end: Date) {
        let s = '';
        let e = '';
        if (start) {
            s = moment(start).locale(this.$i18n.locale).format('ddd DD MMM HH:mm');
        }

        if (end) {
            e = moment(end).locale(this.$i18n.locale).format('HH:mm');
        }
        return `${s} - ${e}`;
    }

    private async handleFileUploads(item: SubmitPresentationItem) {
        this.selectedPresentation = item;
        this.dialog = true;
    }

    private handleClose() {
        this.selectedPresentation = null;
        this.dialog = false;
    }

    private async loadPresentations() {
        const platformId = sessionStorage.getItem('platformId') ?? '';
        try {
            let url = `/api/v2/platform/${platformId}/submit/presentations?posters=true`;
            const pData = await Vue.$http.get<SubmitPresentationItem[]>(url);
            if (pData.data) {
                this.presentationItems = pData.data;
            }
            this.loaded = true;
            this.loading = false;
        } catch (e: any) {
            AppInsightsLogger.logError(
                'Submit Presentation List - created failed',
                undefined,
                true,
            );
            AppInsightsLogger.logException(e, false);
        }
    }

    private PosterUploadUriFilter(value, search, item) {
        if (!this.uploadedOnly) {
            return true;
        }
        return value ? true : false;
    }

    private RoomFilter(value, search, item) {
        if (!this.roomFilterValue) {
            return true;
        }
        return value === this.roomFilterValue;
    }
}
