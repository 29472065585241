
import Sessions from '@/components/Sessions.vue';
import { LogActions } from '@/store/log/log';
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { AppInsightsLogger } from '../services/appInsightsLogger';

const logNamespace = 'log';

@Component({
  components: {
    Sessions,
  },
})
export default class Vod extends Vue {
  /* PUBLIC PROPERTIES */

  /* PRIVATE PROPERTIES */
  private loaded = false;
  private customHtml = '';

  /* VUEX GETTERS */

  /* VUEX ACTIONS */
  // Log Actions
  @Action(LogActions.SET_TRACE_LOG_STATE, { namespace: logNamespace })
  private setTraceLogState!: ({
    entityId1,
    entityId2,
    page,
    title,
  }: {
    entityId1?: string;
    entityId2?: string;
    page: string;
    title: string;
  }) => void;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get dynamicComponent(): { template: string } {
    return {
      template: `${this.customHtml}`,
    };
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}

  private async created() {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get(`/api/platform/${platformId}/live`);
      this.customHtml = res.data;
      this.loaded = true;
    } catch (e: any) {
      AppInsightsLogger.logError('LiveNow - created failed', undefined, true);
      AppInsightsLogger.logException(e, false);
      return false;
    }
  }

  // private created() {}
  // private beforeMount() {}

  private mounted() {
    this.setTraceLogState({ page: 'LiveNow', title: 'LiveNow' });
  }

  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}
  /* PRIVATE METHODS*/
}
