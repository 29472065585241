import { AdClickLog } from '@/models/AdClickLog';
import { DownloadLog } from '@/models/DownloadLog';
import { HeartbeatResponse } from '@/models/HeartbeatResponse';
import { Scan } from '@/models/Scan';
import { ScanTypes } from '@/models/ScanTypes';
import { AppInsightsLogger } from '@/services/appInsightsLogger';
import Vue from 'vue';
import { ActionTree } from 'vuex';
import { Platform } from '../../models/Platform';
import { AuthActions, AuthGetters } from '../auth/types';
import {
  PlatformUserActions,
  PlatformUserGetters,
} from '../platformUser/types';
import { RootState } from '../types';
import { PlatformActions, PlatformMutations, PlatformState } from './types';

export const actions: ActionTree<PlatformState, RootState> = {
  async [PlatformActions.INITIALISATION_PLATFORM](
    { commit, rootGetters },
    { eventCode, andUser }: { eventCode: string; andUser: boolean },
  ): Promise<Platform | undefined> {
    try {
      let res;
      if (sessionStorage.getItem('eventCode') !== eventCode) {
        sessionStorage.setItem('eventCode', eventCode);
        const uri = `/api/platform/${eventCode}`;
        res = await Vue.$http.get<Platform>(uri);
      } else {
        const uri = `/api/platform/${eventCode}`;
        res = await Vue.$http.get<Platform>(uri);
      }
      if (res) {
        if (!sessionStorage.getItem('platformVersion')) {
          sessionStorage.setItem('platformVersion', res.data.platformVersion);
        } else if (
          sessionStorage.getItem('platformVersion') !== res.data.platformVersion
        ) {
          sessionStorage.setItem('platformVersion', res.data.platformVersion);
          location.reload();
        }

        sessionStorage.setItem('platformId', res.data.eventId);
        commit(PlatformMutations.SET_PLATFORM_STATE, res.data);
        if (andUser) {
          const isAuthenticated =
            rootGetters[`auth/${AuthGetters.IS_AUTHENTICATED}`];
          const hasToken = rootGetters[`auth/${AuthGetters.HAS_TOKEN}`];
          const currentUser =
            rootGetters[
              `platformUser/${PlatformUserGetters.GET_CURRENT_PLATFORM_USER_NAME}`
            ];

          if (hasToken && !isAuthenticated) {
            await this.dispatch(
              `auth/${AuthActions.SET_IS_AUTHENTICATED}`,
              true,
              { root: true },
            );
          }

          if (hasToken && !currentUser?.userName) {
            await this.dispatch(
              `platformUser/${PlatformUserActions.LOAD_CURRENT_PLATFORM_USER}`,
              null,
              {
                root: true,
              },
            );
          }
        }
        return res.data;
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Platform Action - INITIALISATION_PLATFORM failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  },

  // logScan
  async [PlatformActions.LOG_SCAN](
    _,
    scan: Scan,
  ): Promise<void | HeartbeatResponse> {
    if (scan && scan.entityId && scan.userId) {
      const platformId = sessionStorage.getItem('platformId') ?? '';
      const uri = `/api/platform/${platformId}/log/scan`;
      try {
        if (scan.scanType === ScanTypes.HEARTBEAT) {
          const res = await Vue.$http.post<HeartbeatResponse>(uri, scan);
          return res.data;
        } else {
          await Vue.$http.post(uri, scan);
        }
      } catch (e: any) {
        AppInsightsLogger.logError(
          'Platform Action - LOG_SCAN failed',
          undefined,
          true,
        );
        AppInsightsLogger.logException(e, false);
      }
    }
  },

  // downloadScan
  async [PlatformActions.DOWNLOAD_LOG](_, log: DownloadLog): Promise<void> {
    if (log && log.userId) {
      const platformId = sessionStorage.getItem('platformId') ?? '';
      const uri = `/api/platform/${platformId}/log/download`;
      try {
        await Vue.$http.post(uri, log);
      } catch (e: any) {
        AppInsightsLogger.logError(
          'Platform Action - DOWNLOAD_LOG failed',
          undefined,
          true,
        );
        AppInsightsLogger.logException(e, false);
      }
    }
  },

  async [PlatformActions.SPONSOR_AD_LOG](_, log: AdClickLog): Promise<void> {
    if (log && log.userId) {
      const platformId = sessionStorage.getItem('platformId') ?? '';
      const uri = `/api/platform/${platformId}/log/sda`;
      try {
        await Vue.$http.post(uri, log);
      } catch (e: any) {
        AppInsightsLogger.logError(
          'Platform Action - SPONSOR_AD_LOG failed',
          undefined,
          true,
        );
        AppInsightsLogger.logException(e, false);
      }
    }
  },
};
