
// import { AppInsightsLogger } from "../services/appInsightsLogger";
import Background from '@/components/Background.vue';
import SponsorCard from '@/components/SponsorCard.vue';
import SponsorFilters from '@/components/SponsorFilters.vue';
import { Action, Getter } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router/types/router';
import {
  SponsorsWithTypesAndHeaders,
  SponsorOrHeaderItem,
  SponsorTypeWithSponsorsItem,
  SponsorShortItem,
} from '../models/SponsorsWithTypesAndHeaders';
import { SponsorItem } from '@/models/Sponsor';
import { LogActions } from '@/store/log/log';
import { AppInsightsLogger } from '@/services/appInsightsLogger';

const contentNamespace = 'content';
const logNamespace = 'log';

@Component({
  components: {
    Background,
    SponsorCard,
    SponsorFilters,
  },
})
export default class Library extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public contentKey!: string;

  @Prop()
  public id!: string;

  /* PRIVATE PROPERTIES */
  private loading: boolean = false;
  private scrollerHeight = 0;
  private model = 0;
  private sponsorsGroupedByType: SponsorTypeWithSponsorsItem[] = [];
  private loaded = false;
  private customHtml = '';

  /* VUEX GETTERS */

  /* VUEX ACTIONS */
  // Log Actions
  @Action(LogActions.SET_TRACE_LOG_STATE, { namespace: logNamespace })
  private setTraceLogState!: ({
    entityId1,
    entityId2,
    page,
    title,
  }: {
    entityId1?: string;
    entityId2?: string;
    page: string;
    title: string;
  }) => void;

  /* WATCHES */
  @Watch('$route', { immediate: false, deep: true })
  private async onRouteChange(newRoute: Route, oldRoute: Route) {
    await this.loadContentExecute();
  }

  /* LOCAL GETTERS/SETTERS */
  // TODO(David): Add tag filters

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}

  private created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  // private beforeMount() {}

  private async mounted() {
    await this.loadContentExecute();
    this.setTraceLogState({ page: 'Exhibition', title: 'Exhibiton' });
  }

  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}

  private beforeDestroy() {}

  private destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }

  // private errorCaptured() {}

  /* PRIVATE METHODS*/
  private handleResize() {
    let height = 0;
    if (this.$vuetify.breakpoint.mobile) {
      height = window.innerHeight - 50;
    } else {
      height = window.innerHeight - 205;
    }
    this.scrollerHeight = height;
  }

  private async loadContentExecute() {
    this.loading = true;

    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get(`/api/platform/${platformId}/expo`);
      if (res) {
        this.customHtml = res.data;
      }

      if (this.customHtml) {
        this.loaded = true;
      } else {
        const sponsors = await Vue.$http.get<SponsorTypeWithSponsorsItem[]>(
          `/api/platform/${platformId}/sponsors/filter${window.location.search}`,
        );
        this.sponsorsGroupedByType = sponsors.data;
        this.loaded = true;
      }
    } catch (e: any) {
      AppInsightsLogger.logError('Expo - created failed', undefined, true);
      AppInsightsLogger.logException(e, false);
    }

    this.loading = false;
  }

  private scrollTop() {
    const e = document.getElementById('top');
    if (e) {
      e.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  private async sponsorSelected(item: SponsorShortItem) {
    if (item.externalContentUri) {
      this.openNewTab(item.externalContentUri);
    } else {
      this.$router.push({ name: 'stand', params: { sponsorId: item.id } });
    }
  }

  private async openNewTab(url: string) {
    if (url.startsWith('https:') || url.startsWith('http:')) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else {
      this.$router.push(url);
    }
  }
}
