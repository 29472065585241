
import { HeartbeatResponse } from '@/models/HeartbeatResponse';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Scan } from '../models/Scan';
import { ScanEntityTypes } from '../models/ScanEntityTypes';
import { ScanTypes } from '../models/ScanTypes';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { AuthService } from '../services/auth/authService';
import { AuthActions, AuthGetters } from '../store/auth/types';
import { PlatformActions, PlatformGetters } from '../store/platform/types';
const platformNamespace = 'platform';
const authNamespace = 'auth';

@Component({})
export default class Login extends Vue {
  private username = '';
  private password = '';
  private rememberMe = false;
  private loading = false;
  private message = '';
  private dirtyEmail: Element | null = null;
  private dirtyPassword: Element | null = null;

  private sendingRequest = false;
  private sent = false;
  private dialog = false;

  @Getter(PlatformGetters.PORTAL_CLOSED_TEXT, {
    namespace: platformNamespace,
  })
  private closedText!: string;

  @Getter(PlatformGetters.LOGIN_BACKGROUND_IMAGE_URI, {
    namespace: platformNamespace,
  })
  private loginBackgroundImageUri!: string;

  @Getter(PlatformGetters.LOGIN_CARD_BANNER_IMAGE_URI, {
    namespace: platformNamespace,
  })
  private loginCardBannerImageUri!: string;

  @Getter(PlatformGetters.LOGIN_CARD_BANNER_CLIENT_URI, {
    namespace: platformNamespace,
  })
  private loginCardBannerClientUri!: string;

  @Getter(PlatformGetters.EXTERNAL_CREATE_ACCOUNT_URI, {
    namespace: platformNamespace,
  })
  private externalCreateAccountUri!: string;

  @Getter(PlatformGetters.EXTERNAL_FORGOT_PASSWORD_URI, {
    namespace: platformNamespace,
  })
  private externalForgotPasswordUri!: string;

  @Getter(PlatformGetters.PORTAL_OPEN, {
    namespace: platformNamespace,
  })
  private open!: boolean;

  @Getter(PlatformGetters.SEND_FORGOTTEN_PASSWORD_URI, {
    namespace: platformNamespace,
  })
  private sendForgottenPassowrdUri!: string;

  @Getter(AuthGetters.LOGIN_IN_ERROR, {
    namespace: authNamespace,
  })
  private loginError!: string;

  // Platform Actions
  @Action(PlatformActions.LOG_SCAN, { namespace: platformNamespace })
  private scanLog!: (scan: Scan) => Promise<void | HeartbeatResponse>;

  @Action(AuthActions.SET_LOGIN_IN_ERROR, { namespace: authNamespace })
  private setLoginError!: (error: string) => void;

  private async submitLogin() {
    this.loading = true;
    const eventCode = sessionStorage.getItem('eventCode');
    try {
      await AuthService.logIn(
        this.username.trim(),
        this.password.trim(),
        this.rememberMe,
      );
      const userId = localStorage.getItem('userId') ?? '';
      const platformId = sessionStorage.getItem('platformId') ?? '';
      // this.$appInsights.setAuthenticatedUserContext(
      //   this.username,
      //   userId,
      //   true,
      // );

      const scan: Scan = {
        entityId: platformId,
        scanEntityType: ScanEntityTypes.EVENT_PLATFORM,
        scanType: ScanTypes.LOGIN,
        userId,
      };
      await this.scanLog(scan);

      this.$router.replace({
        path: `/${eventCode}/`,
      });
    } catch (e: any) {
      this.setLoginError(
        this.$t('errors.loginFailed', this.$i18n.locale) as string,
      );
    }
    this.loading = false;
  }

  private mounted() {
    this.checkAutofill();
  }

  private openDialog() {
    this.dialog = true;
  }

  private async sendRequest() {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    if (!this.username) {
      return;
    }
    this.sendingRequest = true;

    try {
      const url = `/api/platform/${platformId}/users/forgot?email=${this.username}`;
      Vue.$http.get(url);
      this.sent = true;
    } catch (e: any) {
      AppInsightsLogger.logError('Login - sendRequest failed', undefined, true);
      AppInsightsLogger.logException(e, false);
      return false;
    }
    this.sendingRequest = false;
  }

  private openNewTab(url: string) {
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
    }
  }

  private checkAutofill() {
    let times = 0;
    const interval = setInterval(() => {
      times += 1;
      if (this.dirtyEmail && this.dirtyPassword) {
        const emailLabel = document.querySelector(
          `label[for="${this.dirtyEmail.id}"]`,
        );
        if (emailLabel) {
          emailLabel.classList.add('v-label--active');
        }
        const passwordLabel = document.querySelector(
          `label[for="${this.dirtyPassword.id}"]`,
        );
        if (passwordLabel) {
          passwordLabel.classList.add('v-label--active');
        }
        clearInterval(interval);
      } else if (times >= 20) {
        clearInterval(interval);
      } else {
        this.dirtyEmail = document.querySelector(
          'input[type="email"]:-webkit-autofill',
        );
        this.dirtyPassword = document.querySelector(
          'input[type="password"]:-webkit-autofill',
        );
      }
    }, 100);
  }

  private close() {
    this.sendingRequest = false;
    this.sent = false;
    this.dialog = false;
  }
}
