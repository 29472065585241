import i18n from '@/plugins/i18n';
import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { I18nActions, I18nMutations, I18nState } from './types';

export const actions: ActionTree<I18nState, RootState> = {
  [I18nActions.SET_LOCALE]({ commit }, locale: string): void {
    i18n.locale = locale;
    window.localStorage.setItem('locale', JSON.stringify(locale));
    commit(I18nMutations.SET_LOCALE, locale);
  },

  setLocale({ commit }, locale: string): void {
    i18n.locale = locale;
    window.localStorage.setItem('locale', JSON.stringify(locale));
    commit(I18nMutations.SET_LOCALE, locale);
  },
};
