
import Avatar from '@/components/Avatar.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PlatformGetters } from '@/store/platform/types';
import { PlatformUserItem } from '@/store/platformUser/types';
import { Getter } from 'vuex-class';
import { AppInsightsLogger } from '@/services/appInsightsLogger';
import { PresentationItem, SessionItem } from '@/store/session/types';
import moment from 'moment';
const platformNamespace = 'platform';

@Component({
  components: {
    Avatar,
  },
})
export default class PresentationDialog extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public platformUser!: PlatformUserItem;
  /* PRIVATE PROPERTIES */
  private dialog = false;
  private loading = false;
  private presentations: PresentationItem[] = [];
  private sessions: SessionItem[] = [];

  /* VUEX GETTERS */
  // Platform Getters
  @Getter(PlatformGetters.NAV_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarTextColour!: string;

  @Getter(PlatformGetters.NAV_BAR_BACKGROUND_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarBackgroundColour!: string;
  /* VUEX ACTIONS */
  /* WATCHES */
  @Watch('dialog')
  private async onDialogChange(val: boolean) {
    if (this.dialog) {
      await this.loadUserDetails();
    }
  }
  /* LOCAL GETTERS/SETTERS */
  /* PRIVATE METHODS*/
  private formatStartEnd(start: Date, end: Date) {
    const s = moment(start).format('DD/MM/YYYY HH:mm');
    const e = moment(end).format('HH:mm');
    return `${s}-${e}`;
  }

  private async loadUserDetails() {
    this.loading = true;
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get(
        `/api/platform/${platformId}/users/${this.platformUser.userId}/?speaker=true`,
      );

      if (res.data) {
        this.presentations = res.data.presentations;
        this.sessions = res.data.sessions;
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'PresentationDialog - loadUserDetails failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.loading = false;
  }
}
