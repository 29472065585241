import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { ConversationState } from './types';

export const state: ConversationState = {
  conversationBarVisible: false,
  conversations: [],
  openConversations: [],
};

const namespaced = true;

export const conversation: Module<ConversationState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
