
import Avatar from '@/components/Avatar.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PlatformGetters } from '@/store/platform/types';
import { PlatformUserItem } from '@/store/platformUser/types';
import { Getter } from 'vuex-class';
const platformNamespace = 'platform';

@Component({
  components: {
    Avatar,
  },
})
export default class BiographyDialog extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public platformUser!: PlatformUserItem;
  /* PRIVATE PROPERTIES */
  private dialog = false;
  /* VUEX GETTERS */
  // Platform Getters
  @Getter(PlatformGetters.NAV_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarTextColour!: string;

  @Getter(PlatformGetters.NAV_BAR_BACKGROUND_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarBackgroundColour!: string;
  /* VUEX ACTIONS */
  /* WATCHES */
  /* LOCAL GETTERS/SETTERS */
  /* PRIVATE METHODS*/
}
