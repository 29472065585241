import { GetterTree } from 'vuex';
import { RootState } from '../types';
import {
  ConversationGetters,
  ConversationItem,
  ConversationState,
} from './types';

export const getters: GetterTree<ConversationState, RootState> = {
  // getConversation
  // [ConversationGetters.CONVERSATION]: (state: ConversationState) => (id: string): ConversationItem | undefined => {
  //     return state.conversations.find((c) => c.id === id);
  // },

  // getConversations
  [ConversationGetters.CONVERSATIONS](
    state: ConversationState,
  ): ConversationItem[] {
    return state.conversations;
  },

  // getOpenConversations
  [ConversationGetters.CONVERSATIONS_OPEN](
    state: ConversationState,
  ): ConversationItem[] {
    return state.openConversations;
  },

  // getConversationsUnreadCount
  [ConversationGetters.CONVERSATIONS_UNREAD_COUNT](
    state: ConversationState,
  ): number {
    let count = 0;
    state.conversations.map((c) => {
      count = count + c.unreadCount;
    });
    return count;
  },

  // getConversationBarVisible
  [ConversationGetters.CONVERSATION_BAR_VISIBLE](
    state: ConversationState,
  ): boolean {
    return state.conversationBarVisible;
  },

  // conversationExists
  [ConversationGetters.CONVERSATION_EXISTS]: (state: ConversationState) => (
    id: string,
  ): boolean => {
    return state.conversations.findIndex((oc) => oc.id === id) > -1;
  },

  // conversationIsOpen
  [ConversationGetters.CONVERSATION_IS_OPEN]: (state: ConversationState) => (
    id: string,
  ): boolean => {
    return state.openConversations.findIndex((oc) => oc.id === id) > -1;
  },
};
