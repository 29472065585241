export interface PlatformUserState {
  currentPage: number;
  currentPlatformUser?: PlatformUserItem;
  platformUserCardVisible: boolean;
  platformUserCardXPos: number;
  platformUserCardYPos: number;
  platformUserListVisible: boolean;
  platformUserListXPos: number;
  platformUserListYPos: number;
  platformUserLoading: boolean;
  platformUsersLoading: boolean;
  platformUsersSearch: string;
  platformUsers: PlatformUserItem[];
  totalPlatformUsersCount: number;
  visiblePlatformUser: PlatformUserItem;
  visiblePlatformUserList: PlatformUserItem[];
}

export interface PlatformUserItem {
  accepted?: boolean;
  biography: string;
  businessCardImageClickUri: string;
  businessCardImageUri: string;
  country: string;
  firstName: string;
  isSpeaker: boolean;
  isModerator: boolean;
  jobTitle: string;
  lastName: string;
  organisation: string;
  photoUri: string;
  title: string;
  town: string;
  userId: string;
  userName: string;
  urevetnRef: string;
}

export enum PlatformUserActions {
  CLEAR_CURRENT_PLATFORM_USER = 'CLEAR_CURRENT_PLATFORM_USER',
  IS_PLATFORM_MODERATOR = 'IS_PLATFORM_MODERATOR',
  IS_LOUNGE_MODERATOR = 'IS_LOUNGE_MODERATOR',
  IS_PRESENTATION_MODERATOR = 'IS_PRESENTATION_MODERATOR',
  IS_SESSION_MODERATOR = 'IS_SESSION_MODERATOR',
  IS_SPONSOR_LOUNGE_MODERATOR = 'IS_SPONSOR_LOUNGE_MODERATOR',
  IS_SPONSOR_MODERATOR = 'IS_SPONSOR_MODERATOR',
  LOAD_CURRENT_PLATFORM_USER = 'LOAD_CURRENT_PLATFORM_USER',
  LOAD_PATFORM_USERS_BY_FILTERS = 'LOAD_PATFORM_USERS_BY_FILTERS',
  LOAD_PLATFORM_USER = 'LOAD_PLATFORM_USER',
  LOAD_PLATFORM_USERS_FOR_GROUP = 'LOAD_PLATFORM_USERS_FOR_GROUP',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  SET_CURRENT_PLATFORM_USER_PHOTO_URI = 'SET_CURRENT_PLATFORM_USER_PHOTO_URI',
  SET_PLATFORM_USER_CARD_POSITION = 'SET_PLATFORM_USER_CARD_POSITION',
  SET_PLATFORM_USER_CARD_VISIBILITY = 'SET_PLATFORM_USER_CARD_VISIBILITY',
  SET_PLATFORM_USER_DATA = 'SET_PLATFORM_USER_DATA',
  SET_PLATFORM_USER_LIST_POSITION = 'SET_PLATFORM_USER_LIST_POSITION',
  SET_PLATFORM_USER_LIST_VISIBILITY = 'SET_PLATFORM_USER_LIST_VISIBILITY',
  SET_PLATFORM_USER_LOADING = 'SET_PLATFORM_USER_LOADING',
  SET_PLATFORM_USERS_LOADING = 'SET_PLATFORM_USERS_LOADING',
  SET_PLATFORM_USERS_SEARCH = 'SET_PLATFORM_USERS_SEARCH',
  UPDATE_PLATFORM_USER_PROFILE = 'UPDATE_PLATFORM_USER_PROFILE',
}

export enum PlatformUserGetters {
  GET_CURRENT_PAGE = 'GET_CURRENT_PAGE',
  GET_CURRENT_PLATFORM_USER = 'GET_CURRENT_PLATFORM_USER',
  GET_CURRENT_PLATFORM_USER_FIRST_NAME = 'GET_CURRENT_USER_PLATFORM_FIRST_NAME',
  GET_CURRENT_PLATFORM_USER_LAST_NAME = 'GET_CURRENT_PLATFORM_USER_LAST_NAME',
  GET_CURRENT_PLATFORM_USER_INITIALS = 'GET_CURRENTUSER_INITIALS',
  GET_CURRENT_PLATFORM_USER_NAME = 'GET_CURRENT_PLATFORM_USER_NAME',
  GET_CURRENT_PLATFORM_USER_USER_ID = 'GET_CURRENT_USER_ID',
  PLATFORM_USER_CARD_VISIBLE = 'PLATFORM_USER_CARD_VISIBLE',
  PLATFORM_USER_CARD_X_POS = 'PLATFORM_USER_CARD_X_POS',
  PLATFORM_USER_CARD_Y_POS = 'PLATFORM_USER_CARD_Y_POS',
  PLATFORM_USER_LIST_VISIBLE = 'PLATFORM_USER_LIST_VISIBLE',
  PLATFORM_USER_LIST_X_POS = 'PLATFORM_USER_LIST_X_POS',
  PLATFORM_USER_LIST_Y_POS = 'PLATFORM_USER_LIST_Y_POS',
  PLATFORM_USER_LOADING = 'PLATFORM_USER_LOADING',
  PLATFORM_USERS = 'PLATFORM_USERS',
  PLATFORM_USERS_LOADING = 'PLATFORM_USERS_LOADING',
  PLATFORM_USERS_SEARCH = 'PLATFORM_USERS_SEARCH',
  TOTAL_PLATFORM_USER_COUNT = 'TOTAL_PLATFORM_USER_COUNT',
  VISIBLE_PLATFORM_USER = 'VISIBLE_PLATFORM_USER',
  VISIBLE_PLATFORM_USER_LIST = 'VISIBLE_PLATFORM_USER_LIST',
  VISIBLE_PLATFORM_USER_EXISTS = 'VISIBLE_PLATFORM_USER_EXISTS',
}

export enum PlatformUserMutations {
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  SET_CURRENT_PLATFORM_USER = 'SET_CURRENT_PLATFORM_USER',
  SET_CURRENT_PLATFORM_USER_PHOTO_URI = 'SET_CURRENT_PLATFORM_USER_PHOTO_URI',
  SET_PLATFORM_USER_CARD_POSITION = 'SET_PLATFORM_USER_CARD_POSITION',
  SET_PLATFORM_USER_CARD_VISIBILITY = 'SET_PLATFORM_USER_CARD_VISIBILITY',
  SET_PLATFORM_USER_DATA = 'SET_PLATFORM_USER_DATA',
  SET_PLATFORM_USER_LIST_POSITION = 'SET_PLATFORM_USER_LIST_POSITION',
  SET_PLATFORM_USER_LIST_VISIBILITY = 'SET_PLATFORM_USER_LIST_VISIBILITY',
  SET_PLATFORM_USER_LOADING = 'SET_PLATFORM_USER_LOADING',
  SET_PLATFORM_USERS_LOADING = 'SET_PLATFORM_USERS_LOADING',
  SET_PLATFORM_USERS_SEARCH = 'SET_PLATFORM_USERS_SEARCH',
  SET_VISIBLE_PLATFORM_USER = 'SET_VISIBLE_PLATFORM_USER',
  SET_VISIBLE_PLATFORM_USER_LIST = 'SET_VISIBLE_PLATFORM_USER_LIST',
}
