import { SignalrMessage } from '../signalr/types';

export interface LoungeState {
  connected: boolean;
  currentLoungeRoom?: LoungeRoom;
  drawer: boolean;
  error: string;
  favouriteGroupMessages: SignalrMessage[];
  favouriteLoungeRoomLimit: number;
  loungeRooms: LoungeRoom[];
  maxGroupMessageLimit: number;
  loungeRoomLoading: boolean;
  loungeRoomListLoading: boolean;
}

export interface LoungeRoom {
  favourite: boolean;
  groupCount: number;
  groupId: string;
  groupName: string;
  groupType: string;
  hidden: boolean;
  parentId: string;
  private: boolean;
  unread?: number;
}

export interface NewLoungeRoom {
  eventCode: string;
  eventId: string;
  groupName: string;
  groupType: string;
  hidden: boolean;
  parentId: string;
  parentName: string;
  private: boolean;
}

export enum LoungeActions {
  CLEAR_CURRENT_LOUNGE_ROOM = 'CLEAR_CURRENT_LOUNGE_ROOM',
  CREATE_NEW_LOUNGE_ROOM_API = 'CREATE_NEW_LOUNGE_ROOM_API',
  DELETE_LOUNGE_ROOM_API = 'DELETE_LOUNGE_ROOM_API',
  INVERT_DRAWER = 'INVERT_DRAWER',
  INVERT_FAVOURITE = 'INVERT_FAVOURITE',
  LOAD_LOUNGE_ROOMS_API = 'LOAD_LOUNGE_ROOMS_API',
  LOUNGE_ROOM_CREATED = 'LOUNGE_ROOM_CREATED',
  LOUNGE_ROOM_DELETED = 'LOUNGE_ROOM_DELETED',
  SET_CURRENT_LOUNGE_ROOM = 'SET_CURRENT_LOUNGE_ROOM',
  UPDATE_LOUNGE_ROOM_COUNT = 'UPDATE_LOUNGE_ROOM_COUNT',
}

export enum LoungeGetters {
  CURRENT_LOUNGE_ROOM = 'CURRENT_LOUNGE_ROOM',
  DRAWER = 'DRAWER',
  FAVOURITE_LOUNGE_ROOMS = 'FAVOURITE_LOUNGE_ROOMS',
  FAVOURITE_MESSAGES_FOR_GROUP_ID = 'FAVOURITE_MESSAGES_FOR_GROUP_ID',
  FILTERED_NON_FAVOURITE_LOUNGE_ROOMS = 'FILTERED_NON_FAVOURITE_LOUNGE_ROOMS',
  IS_FAVOURITE = 'IS_FAVOURITE',
  LOUNGE_ROOM_BY_GROUP_ID = 'LOUNGE_ROOM_BY_GROUP_ID',
  LOUNGE_ROOM_BY_PARENT_ID_AND_GROUP_NAME = 'LOUNGE_ROOM_BY_PARENT_ID_AND_GROUP_NAME',
  REACHED_FAVOURITE_LIMIT = 'REACHED_FAVOURITE_LIMIT',
  LOUNGE_ROOM_LOADING = 'LOUNGE_ROOM_LOADING',
  LOUNGE_ROOM_LIST_LOADING = 'LOUNGE_ROOM_LIST_LOADING',
}

export enum LoungeMutations {
  ADD_LOUNGE_ROOM = 'ADD_LOUNGE_ROOM',
  CLEAR_CURRENT_LOUNGE_ROOM = 'CLEAR_CURRENT_LOUNGE_ROOM',
  DELETE_FAVOURITE_MESSAGES = 'DELETE_FAVOURITE_MESSAGES',
  DELETE_LOUNGE_ROOM = 'DELETE_LOUNGE_ROOM',
  INVERT_DRAWER = 'INVERT_DRAWER',
  LOAD_LOUNGE_ROOMS = 'LOAD_LOUNGE_ROOMS',
  SET_CURRENT_LOUNGE_ROOM = 'SET_CURRENT_LOUNGE_ROOM',
  UPDATE_LOUNGE_ROOM = 'UPDATE_LOUNGE_ROOM',
}
