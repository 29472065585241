
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { SponsorShortItem } from '../models/SponsorsWithTypesAndHeaders';

@Component
export default class extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public sponsor!: SponsorShortItem;

  /* PRIVATE PROPERTIES */
  private thumbnailError = false;
  private defaultThumbnailError = false;

  /* VUEX GETTERS */
  /* VUEX ACTIONS */
  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get cardWidth(): number {
    if (this.$vuetify.breakpoint.mobile) {
      return window.innerWidth - 40;
    } else {
      return 340;
    }
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  // private mounted() {}
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}

  /* PRIVATE METHODS*/

  private handleImageError() {
    if (!this.thumbnailError) {
      this.thumbnailError = true;
    } else if (!this.defaultThumbnailError) {
      this.defaultThumbnailError = true;
    }
  }
}
