export interface ConversationState {
  conversations: ConversationItem[];
  conversationBarVisible: boolean;
  openConversations: ConversationItem[];
}

export interface ConversationItem {
  id: string;
  otherUserName: string;
  otherUserId: string;
  latestMessage: string;
  unreadCount: number;
  messageItems: ConversationMessageItem[];
  minimised: boolean;
}

export interface ConversationMessageItem {
  conversationId: string;
  messageId: string;
  message: string;
  senderId: string;
  senderName: string;
  tiemestamp: Date;
}

export interface NewConversationMessage {
  eventId: string;
  message: string;
  sender: string;
  recipient: string;
}

export enum ConversationActions {
  ADD_NEW_MESSAGE_TO_NON_OPEN_CONVERSATION = 'ADD_NEW_MESSAGE_TO_NON_OPEN_CONVERSATION',
  ADD_NEW_MESSAGE_TO_OPEN_CONVERSATION = 'ADD_NEW_MESSAGE_TO_OPEN_CONVERSATION',
  CLEAR_CONVERSATION = 'CLEAR_CONVERSATION',
  CLOSE_CONVERSATION = 'CLOSE_CONVERSATION',
  MAXIMISE_CONVERSATION = 'MAXIMISE_CONVERSATION',
  MINIMISE_CONVERSATION = 'MINIMISE_CONVERSATION',
  OPEN_CONVERSATION = 'OPEN_CONVERSATION',
  RECIEVE_NEW_CONVERSATION_ITEM = 'RECIEVE_NEW_CONVERSATION_ITEM',
  SET_CONVERSATIONS = 'SET_CONVERSATIONS',
  SET_CONVERSATION_BAR_VISIBLE = 'SET_CONVERSATION_BAR_VISIBLE',
  OPEN_NEW_CONVERSATION = 'OPEN_NEW_CONVERSATION',
  VERIFY_CONVERSATION_VISIBLE = 'VERIFY_CONVERSATION_VISIBLE',
}

export enum ConversationGetters {
  CONVERSATION = 'CONVERSATION',
  CONVERSATIONS = 'CONVERSATIONS',
  CONVERSATIONS_OPEN = 'CONVERSATIONS_OPEN',
  CONVERSATIONS_UNREAD_COUNT = 'CONVERSATIONS_UNREAD_COUNT',
  CONVERSATION_BAR_VISIBLE = 'SET_CONVERSATION_BAR_VISIBLE',
  CONVERSATION_EXISTS = ' CONVERSATION_EXISTS',
  CONVERSATION_IS_OPEN = 'CONVERSATION_IS_OPEN',
}

export enum ConversationMutations {
  ADD_NEW_MESSAGE_TO_NON_OPEN_CONVERSATION = 'ADD_NEW_MESSAGE_TO_NON_OPEN_CONVERSATION',
  ADD_NEW_MESSAGE_TO_OPEN_CONVERSATION = 'ADD_NEW_MESSAGE_TO_OPEN_CONVERSATION',
  CLEAR_CONVERSATION = 'CLEAR_CONVERSATION',
  CLOSE_CONVERSATION = 'CLOSE_CONVERSATION',
  MAXIMISE_CONVERSATION = 'MAXIMISE_CONVERSATION',
  MINIMISE_CONVERSATION = 'MINIMISE_CONVERSATION',
  OPEN_CONVERSATION = 'OPEN_CONVERSATION',
  SET_CONVERSATIONS = 'SET_CONVERSATIONS',
  SET_CONVERSATION_BAR_VISIBLE = 'SET_CONVERSATION_BAR_VISIBLE',
  VERIFY_CONVERSATION_VISIBLE = 'VERIFY_CONVERSATION_VISIBLE',
}
