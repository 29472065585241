import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { SessionState } from './types';

export const state: SessionState = {
  currentPage: 1,
  keywordItems: [],
  keywords: '',
  listView: false,
  loadingSessions: false,
  posterSessions: [],
  relatedSessions: [],
  rooms: [],
  selectedPosterSession: undefined,
  selectedRoom: undefined,
  selectedSession: undefined,
  selectedSessionAutoApproveQuestions: false,
  selectedSessionQuestions: [],
  selectedTheme: '',
  selectedType: '',
  sessions: [],
  themes: [],
  totalSessionCount: 0,
  types: [],
};

const namespaced = true;

export const session: Module<SessionState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
