import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { NavigationGetters, NavigationState } from './types';

export const getters: GetterTree<NavigationState, RootState> = {
  [NavigationGetters.ACTIVITY_MINI_VARIANT](state): boolean {
    return state.activityMiniVariant;
  },

  [NavigationGetters.ACTIVITY_DRAWER](state): boolean {
    return state.activityDrawer;
  },

  [NavigationGetters.NAVIGATION_MINI_VARIANT](state): boolean {
    return state.navigationMiniVariant;
  },

  [NavigationGetters.NAVIGATION_DRAWER](state): boolean {
    return !!state.navigationDrawer;
  },

  [NavigationGetters.MOBILE_PAGE_TITLE](state): string {
    return state.mobilePageTitle;
  },

  [NavigationGetters.PROFILE_DIALOG](state): boolean {
    return state.profileDialog;
  },
};
