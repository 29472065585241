
import { PlatformUserGetters } from '@/store/platformUser/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import {
  ConversationActions,
  ConversationItem,
  NewConversationMessage,
} from '../store/conversation/types';
const conversationNamespace = 'conversation';
const platformUserNamespace = 'platformUser';

@Component({})
export default class ConversationBox extends Vue {
  @Prop()
  public conversation!: ConversationItem;

  private newMessage = '';

  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  @Action(ConversationActions.CLOSE_CONVERSATION, {
    namespace: conversationNamespace,
  })
  private closeConversation!: (conversation: ConversationItem) => void;

  @Action(ConversationActions.MINIMISE_CONVERSATION, {
    namespace: conversationNamespace,
  })
  private minimiseConversation!: (id: string) => void;

  @Action(ConversationActions.MAXIMISE_CONVERSATION, {
    namespace: conversationNamespace,
  })
  private maximiseConverstaion!: (id: string) => void;

  private scrollToBottom() {
    const e = document.getElementById(`bottom-${this.conversation.id}`);
    if (e) {
      e.scrollIntoView();
    }
  }

  get minimised(): boolean {
    return this.conversation.minimised;
  }

  private maximise(id: string) {
    this.maximiseConverstaion(id);
    this.$nextTick(() => {
      this.scrollToBottom();
    });
  }

  private postConversationExecute() {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    if (this.newMessage.length > 1) {
      const msg: NewConversationMessage = {
        eventId: platformId,
        message: this.newMessage,
        recipient: this.conversation.otherUserId,
        sender: this.currentUserId,
      };

      this.postConversationMessage(msg);
      this.newMessage = '';
    }
  }

  private async postConversationMessage(
    msg: NewConversationMessage,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      await Vue.$http.post(`/api/platform/${platformId}/conversations`, msg);
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - postConversationMessage failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private mounted() {
    this.scrollToBottom();
  }
}
