
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AdClickLog } from '../models/AdClickLog';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { PlatformActions, PlatformGetters } from '../store/platform/types';
import { PresentationItem, SessionActions, SessionItem } from '../store/session/types';
import {
  BookmarkActions,
  BookmarkEntityType,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '../store/bookmark/types';
import { LocaleMessages } from 'vue-i18n';
import { PlatformUserGetters } from '@/store/platformUser/types';
import { AuthGetters } from '@/store/auth/types';

const authNamespace = 'auth';
const bookmarkNamespace = 'bookmark';
const sessionNamespace = 'session';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';

@Component({})
export default class SessionDetails extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public session!: SessionItem;

  /* PRIVATE PROPERTIES */
  private show = true;
  private ratingReadonly = false;
  private liked = false;
  private currentRatingValue = 0;

  /* VUEX GETTERS */
  // Bookmark Getters
  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  @Getter(BookmarkGetters.IS_A_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private isABookmark!: (entityId: string, entityUri?: string) => boolean;

  // Platform Getters
  @Getter(PlatformGetters.ALLOW_ABSTRACT_DOWNLOAD, {
    namespace: platformNamespace,
  })
  private allowAbstractDownload!: boolean;

  @Getter(PlatformGetters.ALLOW_PRESENTATION_DOWNLOAD, {
    namespace: platformNamespace,
  })
  private allowPresentationDownload!: boolean;

  @Getter(PlatformGetters.SHOW_VIEWS, {
    namespace: platformNamespace,
  })
  private showViews!: boolean;

  @Getter(PlatformGetters.SHOW_RATINGS, {
    namespace: platformNamespace,
  })
  private showRatings!: boolean;

  @Getter(PlatformGetters.SHOW_LIKES, {
    namespace: platformNamespace,
  })
  private showLikes!: boolean;

  @Getter(PlatformGetters.SHOW_KEYWORDS, {
    namespace: platformNamespace,
  })
  private showKeywords!: boolean;

  // Platform user getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  /* VUEX ACTIONS */
  // Auth Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Bookmark Actions
  @Action(BookmarkActions.ADD_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private bookmarkItem!: (bookmark: BookmarkItem) => Promise<void>;

  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  // Platform Actions
  @Action(PlatformActions.SPONSOR_AD_LOG, { namespace: platformNamespace })
  private adClickLog!: (adClickLog: AdClickLog) => Promise<void>;

  // Session Actions
  @Action(SessionActions.UPDATE_SELECTED_SESSION_RATING, {
    namespace: sessionNamespace,
  })
  private updateSelectedSessionRating!: ({
    rating,
    rates,
  }: {
    rating: number;
    rates: number;
  }) => void;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */

  /* PRIVATE METHODS*/
  private formatStartEnd(start: Date, end: Date) {
    const s = moment(start).format('DD/MM/YYYY HH:mm');
    const e = moment(end).format('HH:mm');
    return `${s}-${e}`;
  }

  private registerSessionViewRatingExecute(rating: number) {
    if (this.session.isSession) {
      this.registerSessionViewRating(this.session.id, rating);
      this.ratingReadonly = true;
    } else if (this.session.isPresentation) {
      this.registerPresentationViewRating(
        this.session.sessionId,
        this.session.id,
        rating,
      );
      this.ratingReadonly = true;
    }
  }

  private likeButtonExecute() {
    this.liked = !this.liked;
    if (this.session.isSession) {
      this.registerSessionLike(this.session.id, this.liked);
    } else if (this.session.isPresentation) {
      this.registerPresentationLike(
        this.session.sessionId,
        this.session.id,
        this.liked,
      );
    }
  }

  private created() {
    if (localStorage.getItem(this.session.id)) {
      this.ratingReadonly = true;
    }
    if (localStorage.getItem(`${this.session.id}_liked`) === 'true') {
      this.liked = true;
    }
  }

  private async registerSessionLike(
    sessionId: string,
    liked: boolean,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const data = {
        liked,
        sessionId,
        userId: this.currentUserId,
      };
      await Vue.$http.post(
        `/api/platform/${platformId}/sessions/${sessionId}/like`,
        data,
      );
      localStorage.setItem(`${sessionId}_liked`, liked.toString());
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - registerSessionLike failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private async registerPresentationLike(
    sessionId: string,
    presentationId: string,
    liked: boolean,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const data = {
        liked,
        presentationId,
        sessionId,
        userId: this.currentUserId,
      };
      await Vue.$http.post(
        `/api/platform/${platformId}/sessions/${sessionId}/presentation/${presentationId}/like`,
        data,
      );

      localStorage.setItem(`${presentationId}_liked`, liked.toString());
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - registerPresentationLike failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private async registerSessionViewRating(
    sessionId: string,
    rating: number,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.post(
        `/api/platform/${platformId}/sessions/${sessionId}/rate/${rating}`,
      );
      this.updateSelectedSessionRating({
        rates: res.data.rates,
        rating: res.data.rating,
      });
      localStorage.setItem(sessionId, 'true');
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - registerSessionViewRating failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private async registerPresentationViewRating(
    sessionId: string,
    presentationId: string,
    rating: number,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.post(
        `/api/platform/${platformId}/sessions/${sessionId}/presentation/${presentationId}/rate/${rating}`,
      );
      this.updateSelectedSessionRating({
        rates: res.data.rates,
        rating: res.data.rating,
      });
      localStorage.setItem(presentationId, 'true');
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - registerPresentationViewRating failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private async adClick() {
    if (this.session.sponsorThumbnailLinkUri) {
      const log: AdClickLog = {
        entityId: this.session.id,
        link: this.session.sponsorThumbnailLinkUri,
        title: this.session.title,
        type: 'SESSION',
        userId: this.currentUserId,
      };
      await this.adClickLog(log);
      await this.openNewTab(this.session.sponsorThumbnailLinkUri);
    }
  }

  private async openNewTab(url: string | undefined) {
    if (url && (url.startsWith('https:') || url.startsWith('http:'))) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else if (url) {
      await this.$router.push(url);
    }
  }

  private getExtension(uri: string) {
    const a = uri.split('?')[0].split('.');
    if (a.length > 0) {
      return a[a.length - 1];
    } else {
      return 'pdf';
    }
  }

  private async downloadFile(uri: string, type: string) {
    try {
      const res = await fetch(uri, {
        method: 'GET',
      });
      const url = window.URL.createObjectURL(new Blob([await res.blob()]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${type}.${this.getExtension(uri)}`);
      document.body.appendChild(link);
      link.click();
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Session Details Side - download failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private bookmarkExecute(s: SessionItem) {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const bi = this.getBookmark(s.id);
    if (bi) {
      this.unbookmarkItem(bi);
    } else {
      let t = '';
      let et = '';
      let eu = '';
      if (s.isAPoster) {
        t = BookmarkType.POSTER;
        et = BookmarkEntityType.POSTER;
        eu = `/${eventCode}/session/${s.sessionId}/presentation/${s.id}`;
      } else if (s.isPresentation) {
        t = BookmarkType.VIDEO;
        et = BookmarkEntityType.PRESENTATION;
        eu = `/${eventCode}/session/${s.sessionId}/presentation/${s.id}`;
      } else {
        t = BookmarkType.VIDEO;
        et = BookmarkEntityType.SESSION;
        eu = `/${eventCode}/session/${s.id}`;
      }

      const bm: BookmarkItem = {
        end: s.end,
        entityId: s.id,
        entityType: et,
        type: t,
        entityUri: eu,
        start: s.start,
        title: s.title,
      };
      this.bookmarkItem(bm);
    }
  }

  private bookmarkFileExecute(
    title: string | LocaleMessages,
    url: string | undefined,
  ) {
    if (url) {
      const bi = this.getBookmark(this.session.id, url);
      if (bi) {
        this.unbookmarkItem(bi);
      } else {
        let et = '';
        if (this.session.isAPoster) {
          et = BookmarkEntityType.POSTER_DOCUMENT;
        } else if (this.session.isPresentation) {
          et = BookmarkEntityType.PRESENTATION_DOCUMENT;
        } else {
          et = BookmarkEntityType.SESSION_DOCUMENT;
        }

        const bm: BookmarkItem = {
          entityId: this.session.id,
          entityType: et,
          type: BookmarkType.DOCUMENT,
          entityUri: url,
          title: `${title} - ${this.session.title}`,
        };
        this.bookmarkItem(bm);
      }
    }
  }

  private navigateToSpeaker(pres: PresentationItem) {
    if (pres.speakerNameOverride) return;

    if (!pres.speakerId) return;

    this.$router.push({
      name: 'speakers',
      query: {
        search: pres.speakerName,
        id: pres.speakerId,
        speaker: 'true',
      },
    });
  }

}
