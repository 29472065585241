import { MutationTree } from 'vuex';
import {
  SignalrGroup,
  SignalrMessage,
  SignalrMutations,
  SignalrState,
} from './types';

export const mutations: MutationTree<SignalrState> = {
  // NEW MUTATIONS

  [SignalrMutations.ADD_MESSAGE_TO_CURRENT_GROUP](
    state: SignalrState,
    msg: SignalrMessage,
  ): void {
    if (state.currentGroupMessages.length === 100) {
      state.currentGroupMessages.splice(0, 1);
    }
    state.currentGroupMessages.push(msg);
  },

  [SignalrMutations.CLEAR_CURRENT_GROUP_MESSAGES](state: SignalrState): void {
    state.currentGroupMessages = [];
  },

  [SignalrMutations.CLEAR_CURRRENT_GROUP](state: SignalrState): void {
    state.currentGroup = undefined;
  },

  [SignalrMutations.REMOVE_MESSAGE_FROM_CURRENT_GROUP](
    state: SignalrState,
    msg: SignalrMessage,
  ): void {
    const index = state.currentGroupMessages.indexOf(msg);
    if (index > -1) {
      state.currentGroupMessages.splice(index, 1);
    }
  },

  [SignalrMutations.SET_CONNECTION_ERROR](
    state: SignalrState,
    error: string,
  ): void {
    state.connectionError = error;
  },

  [SignalrMutations.SET_CONNECTION_STATE](
    state: SignalrState,
    connState: boolean,
  ): void {
    state.signalrConnected = connState;
  },

  [SignalrMutations.SET_CURRENT_GROUP](
    state: SignalrState,
    group: SignalrGroup,
  ): void {
    state.currentGroup = group;
  },

  [SignalrMutations.SET_USER_CONNECTED_STATE](
    state: SignalrState,
    connState: boolean,
  ): void {
    state.userConnected = connState;
  },

  [SignalrMutations.SET_CURRENT_GROUP_MESSAGES](state, msgs: SignalrMessage[]) {
    if (msgs) {
      state.currentGroupMessages = msgs;
    }
  },

  [SignalrMutations.UPDATE_CURRENT_ROOM_COUNT](
    state: SignalrState,
    count: number,
  ): void {
    if (state.currentGroup) {
      state.currentGroup.groupCount = count;
    }
  },
};
