import {
  ApplicationInsights,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { ICustomProperties } from '@microsoft/applicationinsights-core-js';
import Vue from 'vue';

export class AppInsightsLogger {
  public static logError(
    message: string,
    customProperties: ICustomProperties | undefined,
    toConsole: boolean,
  ): void {
    // (Vue.prototype.$appInsights as ApplicationInsights).trackTrace(
    //   { message, severityLevel: SeverityLevel.Error },
    //   customProperties,
    // );
    // if (toConsole) {
    //   console.log(message);
    // }
  }

  public static logInformation(
    message: string,
    customProperties: ICustomProperties | undefined,
    toConsole: boolean,
  ): void {
    // (Vue.prototype.$appInsights as ApplicationInsights).trackTrace(
    //   { message, severityLevel: SeverityLevel.Information },
    //   customProperties,
    // );
    // if (toConsole) {
    //   console.log(message);
    // }
  }

  public static logWarning(
    message: string,
    customProperties: ICustomProperties | undefined,
    toConsole: boolean,
  ): void {
    // (Vue.prototype.$appInsights as ApplicationInsights).trackTrace(
    //   { message, severityLevel: SeverityLevel.Error },
    //   customProperties,
    // );
    // if (toConsole) {
    //   console.log(message);
    // }
  }

  public static logException(exception: Error, toConsole: boolean): void {
    // (Vue.prototype.$appInsights as ApplicationInsights).trackException({
    //   exception,
    //   severityLevel: SeverityLevel.Error,
    // });
    // if (toConsole) {
    //   console.log(exception);
    // }
  }
}
