
import { SponsorMapItem } from '@/models/Sponsor';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { DownloadLog } from '../models/DownloadLog';
import { PlatformActions } from '../store/platform/types';
import {
  BookmarkActions,
  BookmarkEntityType,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '../store/bookmark/types';
import { PlatformUserGetters } from '@/store/platformUser/types';

const bookmarkNamespace = 'bookmark';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';

@Component({
  components: {},
})
export default class SponsorDocument extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public item!: SponsorMapItem;

  @Prop()
  public sponsorId!: string;

  @Prop() sponsorTitle!: string;

  /* PRIVATE PROPERTIES */
  private brochure = 0;

  /* VUEX GETTERS */
  // Bookmark Getters
  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  @Getter(BookmarkGetters.IS_A_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private isABookmark!: (entityId: string, entityUri?: string) => boolean;

  // Platofrm getters
  @Action(PlatformActions.DOWNLOAD_LOG, { namespace: platformNamespace })
  private downloadLog!: (downloadLog: DownloadLog) => Promise<void>;

  // Platform user Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  /* VUEX ACTIONS */
  // Bookmark Actions
  @Action(BookmarkActions.ADD_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private bookmarkItem!: (bookmark: BookmarkItem) => Promise<void>;

  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */

  /* PRIVATE METHODS*/
  private bookmarkExecute(title: string, url: string) {
    const bi = this.getBookmark(this.sponsorId, url);
    if (bi) {
      this.unbookmarkItem(bi);
    } else {
      const bm: BookmarkItem = {
        entityId: this.sponsorId,
        entityType: BookmarkEntityType.SPONSOR_DOCUMENT,
        type: BookmarkType.DOCUMENT,
        entityUri: url,
        title: `${this.sponsorTitle} - ${title}`,
      };
      this.bookmarkItem(bm);
    }
  }

  private async openNewTab(url: string, id: string, title: string) {
    if (id) {
      const log: DownloadLog = {
        entityId: id,
        itemContent: url,
        itemTitle: title,
        sponsorId: this.sponsorId,
        userId: this.currentUserId,
        logType: 'DOWNLOAD_ITEM',
      };
      await this.downloadLog(log);
    }

    if (url.startsWith('https:') || url.startsWith('http:')) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else {
      this.$router.push({
        path: url,
      });
    }
  }
}
