
import ActivityDrawer from '@/components/ActivityDrawer.vue';
import Avatar from '@/components/Avatar.vue';
import SponsorDocument from '@/components/SponsorDocument.vue';
import SponsorVideo from '@/components/SponsorVideo.vue';
import { DownloadLog } from '@/models/DownloadLog';
import { HeartbeatResponse } from '@/models/HeartbeatResponse';
import { DataTable, UniqueUserItem } from '@/models/Reports/DataTable';
import { EntityListItem } from '@/models/Reports/EntityListItem';
import { AppInsightsLogger } from '@/services/appInsightsLogger';
import {
  PlatformUserActions,
  PlatformUserGetters,
} from '@/store/platformUser/types';
import isMobile from 'ismobilejs';
import { Component, Vue } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import { Action, Getter } from 'vuex-class';
import { Scan } from '../models/Scan';
import { ScanEntityTypes } from '../models/ScanEntityTypes';
import { ScanTypes } from '../models/ScanTypes';
import { SponsorItem, SponsorMapItem } from '../models/Sponsor';
import {
  BookmarkActions,
  BookmarkEntityType,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '../store/bookmark/types';
import {
  NavigationActions,
  NavigationGetters,
} from '../store/navigation/types';
import { PlatformActions } from '../store/platform/types';
import {
  SignalrActions,
  SignalrGetters,
  SignalrGroup,
  SignalrGroupTypes,
} from '../store/signalr/types';
import xlsx from 'xlsx';
import moment from 'moment-timezone';
import { LogActions } from '@/store/log/log';
import { AuthGetters } from '@/store/auth/types';

const authNamespace = 'auth';
const bookmarkNamespace = 'bookmark';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';
const signalrNamespace = 'signalr';
const navigationNamespace = 'navigation';

const logNamespace = 'log';

Component.registerHooks(['beforeRouteUpdate']);

@Component({
  components: {
    ActivityDrawer,
    Avatar,
    SponsorDocument,
    SponsorVideo,
  },
})
export default class Vod extends Vue {
  /* PUBLIC PROPERTIES */

  /* PRIVATE PROPERTIES */
  private sponsorId!: string;
  private LINK_TYPE = 'Link';
  private YOUTUBE_TYPE = 'Youtube';
  private VIMEO_TYPE = 'Vimeo';
  private IFRAME_TYPE = 'IFrame';
  private DOCUMENT_TYPE = 'Documents';
  private isMod = false;
  private pageLoaded = false;
  private sponsor?: SponsorItem | null = null;
  private heartBeat!: Scan;
  private heartBeatInteral = 0;
  private thumbnailError = false;
  private defaultThumbnailError = false;
  private sponsorStats: DataTable<UniqueUserItem> | null = null;

  private downloadingViewers = false;
  private downloadingDownloads = false;
  private downloadingVideoViews = false;
  private downloadDialog = false;

  /* VUEX GETTERS */
  // Auth Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Bookmark Gettters
  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  @Getter(BookmarkGetters.IS_A_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private isABookmark!: (entityId: string, entityUri?: string) => boolean;

  // navigation Getters
  @Getter(NavigationGetters.ACTIVITY_DRAWER, {
    namespace: navigationNamespace,
  })
  private drawer!: boolean;

  // platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  @Getter(PlatformUserGetters.VISIBLE_PLATFORM_USER_EXISTS, {
    namespace: platformUserNamespace,
  })
  private isOnline!: (userId: string) => boolean;

  @Action(NavigationActions.ACTIVITY_INVERT_DRAWER, {
    namespace: navigationNamespace,
  })
  private invertDrawer!: () => void;

  @Getter(SignalrGetters.CURRENT_GROUP, { namespace: signalrNamespace })
  private currentGroup!: SignalrGroup;

  /* VUEX ACTIONS */
  // Bookmark Actions
  @Action(BookmarkActions.ADD_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private bookmarkItem!: (bookmark: BookmarkItem) => Promise<void>;

  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  // Log Actions
  @Action(LogActions.SET_TRACE_LOG_STATE, { namespace: logNamespace })
  private setTraceLogState!: ({
    entityId1,
    entityId2,
    page,
    title,
  }: {
    entityId1?: string;
    entityId2?: string;
    page: string;
    title?: string;
  }) => void;

  // Platform actions
  @Action(PlatformActions.DOWNLOAD_LOG, { namespace: platformNamespace })
  private downloadLog!: (downloadLog: DownloadLog) => Promise<void>;

  @Action(PlatformUserActions.LOAD_PLATFORM_USERS_FOR_GROUP, {
    namespace: platformUserNamespace,
  })
  private loadPlatformUsersForGroup!: ({
    groupId,
    groupType,
  }: {
    groupId: string;
    groupType: string;
  }) => Promise<void>;

  // Platform user actions
  @Action(PlatformUserActions.IS_SPONSOR_MODERATOR, {
    namespace: platformUserNamespace,
  })
  private isSponsorModerator!: (sponsorId: string) => Promise<boolean>;

  @Action(PlatformActions.LOG_SCAN, { namespace: platformNamespace })
  private scanLog!: (scan: Scan) => Promise<void | HeartbeatResponse>;

  // signalr actions
  @Action(SignalrActions.UPDATE_CURRENT_ROOM_COUNT, {
    namespace: signalrNamespace,
  })
  private updatedCurrenRoomCount!: (count: number) => void;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get hasDocumentDownloads(): boolean {
    if (
      this.sponsorStats &&
      this.sponsorStats.downloadUniqueUsers &&
      this.sponsorStats.downloadUniqueUsers.items
    ) {
      return true;
    }
    return false;
  }

  get hasEntityDownloads(): boolean {
    if (this.sponsorStats && this.sponsorStats.items) {
      return true;
    }
    return false;
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}

  private async mounted() {
    this.sponsorId = this.$route.params.sponsorId;
    await this.load();
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}

  private async beforeDestroy() {
    if (isMobile(window.navigator).any) {
      window.removeEventListener('pagehide', this.pageUnloaded);
      window.removeEventListener('visibilitychange', this.visibilityChanged);
    } else {
      window.removeEventListener('beforeunload', this.pageUnloaded);
    }
    await this.handleLeave();
  }

  // private destroyed() {}
  // private errorCaptured() {}

  private async beforeRouteUpdate(
    to: Route,
    from: Route,
    next: NavigationGuardNext,
  ) {
    await this.handleLeave();
    this.sponsorId = to.params.sponsorId;
    await this.load();
    next();
  }

  /* PRIVATE METHODS*/
  private bookmarkDocumentExecute(title: string, url: string) {
    const bi = this.getBookmark(this.sponsorId, url);
    if (bi) {
      this.unbookmarkItem(bi);
    } else {
      const bm: BookmarkItem = {
        entityId: this.sponsorId,
        entityType: BookmarkEntityType.SPONSOR_DOCUMENT,
        type: BookmarkType.DOCUMENT,
        entityUri: url,
        title: `${this.sponsor?.title} - ${title}`,
      };
      this.bookmarkItem(bm);
    }
  }

  private bookmarkExecute(s: SponsorItem | null | undefined) {
    if (!s) {
      return;
    }
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const bi = this.getBookmark(s.id);
    if (bi) {
      this.unbookmarkItem(bi);
    } else {
      const bm: BookmarkItem = {
        entityId: s.id,
        entityType: BookmarkEntityType.SPONSOR_STAND,
        type: BookmarkType.EXHIBITION,
        entityUri: `/${eventCode}/sponsor/${s.id}`,
        title: s.title,
      };
      this.bookmarkItem(bm);
    }
  }

  private async downloadDocumentUniqueUsers() {
    try {
      if (this.isMod) {
        this.downloadingDownloads = true;
        const platformId = sessionStorage.getItem('platformId') ?? '';
        const res = await Vue.$http.get<DataTable<UniqueUserItem>>(
          `/api/platform/${platformId}/report/${this.sponsorId}/sponsorstats`,
        );
        this.sponsorStats = res.data;
      }

      if (
        this.sponsorStats &&
        this.sponsorStats.downloadUniqueUsers &&
        this.sponsorStats.downloadUniqueUsers.items &&
        this.sponsorStats.downloadUniqueUsers.items.length > 0
      ) {
        const eventCode = sessionStorage.getItem('eventCode') ?? '';
        const title = `${eventCode}_downloaduniqueusers_${this.sponsor?.title}`;
        const fileName = title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        const workSheet = xlsx.utils.json_to_sheet(
          this.sponsorStats.downloadUniqueUsers.items,
          { cellDates: true },
        );
        const wb = xlsx.utils.book_new();

        xlsx.utils.book_append_sheet(wb, workSheet, 'export');
        xlsx.writeFile(wb, `${fileName}.xlsx`);
      } else {
        this.downloadDialog = true;
      }
    } catch (e: any) {
      console.log(e);
      AppInsightsLogger.logError(
        'Player - downloadEntityItems failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.downloadingDownloads = false;
  }

  private async downloadEntityItems() {
    try {
      if (this.isMod) {
        this.downloadingViewers = true;
        const platformId = sessionStorage.getItem('platformId') ?? '';
        const res = await Vue.$http.get<DataTable<UniqueUserItem>>(
          `/api/platform/${platformId}/report/${this.sponsorId}/sponsorstats`,
        );
        this.sponsorStats = res.data;

        if (
          this.sponsorStats &&
          this.sponsorStats.items &&
          this.sponsorStats.items.length > 0
        ) {
          const eventCode = sessionStorage.getItem('eventCode') ?? '';
          const title = `${eventCode}_uniqueusers_${this.sponsor?.title}`;
          const fileName = title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
          const items = this.sponsorStats.items ?? this.sponsorStats.items;
          const parsed = this.parseDates(items);
          const workSheet = xlsx.utils.json_to_sheet(parsed, {
            cellDates: true,
            dateNF: 'dd/mm/yyyy hh:mm',
          });
          const wb = xlsx.utils.book_new();

          xlsx.utils.book_append_sheet(wb, workSheet, 'export');
          xlsx.writeFile(wb, `${fileName}.xlsx`);
        } else {
          this.downloadDialog = true;
        }
      }
    } catch (e: any) {
      console.log(e);
      AppInsightsLogger.logError(
        'Player - downloadEntityItems failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.downloadingViewers = false;
  }

  private async downloadVideoViewsUniqueUsers() {
    try {
      if (this.isMod) {
        this.downloadingVideoViews = true;
        const platformId = sessionStorage.getItem('platformId') ?? '';
        const res = await Vue.$http.get<DataTable<UniqueUserItem>>(
          `/api/platform/${platformId}/report/${this.sponsorId}/sponsorstats`,
        );
        this.sponsorStats = res.data;
      }

      if (
        this.sponsorStats &&
        this.sponsorStats.videoViewUniqueUsers &&
        this.sponsorStats.videoViewUniqueUsers.items &&
        this.sponsorStats.videoViewUniqueUsers.items.length > 0
      ) {
        const eventCode = sessionStorage.getItem('eventCode') ?? '';
        const title = `${eventCode}_videoviewuniqueusers_${this.sponsor?.title}`;
        const fileName = title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        const workSheet = xlsx.utils.json_to_sheet(
          this.sponsorStats.videoViewUniqueUsers.items,
          { cellDates: true },
        );
        const wb = xlsx.utils.book_new();

        xlsx.utils.book_append_sheet(wb, workSheet, 'export');
        xlsx.writeFile(wb, `${fileName}.xlsx`);
      } else {
        this.downloadDialog = true;
      }
    } catch (e: any) {
      console.log(e);
      AppInsightsLogger.logError(
        'Player - downloadEntityItems failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.downloadingVideoViews = false;
  }

  private goToExpo() {
    this.$router.push({ name: 'expo' });
  }

  private async handleHeartBeat() {
    const res = await this.scanLog(this.heartBeat);
    if (res) {
      this.updatedCurrenRoomCount(res.count);
    }
  }

  private handleImageError() {
    if (!this.thumbnailError) {
      this.thumbnailError = true;
    } else if (!this.defaultThumbnailError) {
      this.defaultThumbnailError = true;
    }
  }

  private async handleLeave() {
    if (this.heartBeatInteral !== 0) {
      window.clearInterval(this.heartBeatInteral);
      this.heartBeatInteral = 0;
    }
    this.log(ScanTypes.LEAVE_STAND_LOG);
    this.pageLoaded = false;
  }

  private async load() {
    if (!this.drawer) {
      this.invertDrawer();
    }

    if (!this.sponsorId) {
      // TODO: Handle properly
      return;
    }

    const platformId = sessionStorage.getItem('platformId') ?? '';
    this.isMod = await this.isSponsorModerator(this.sponsorId);
    try {
      const res = await Vue.$http.get(
        `/api/platform/${platformId}/sponsors/${this.sponsorId}`,
      );
      if (isMobile(window.navigator).any) {
        window.addEventListener('pagehide', this.pageUnloaded);
        window.addEventListener('visibilitychange', this.visibilityChanged);
      } else {
        window.addEventListener('beforeunload', this.pageUnloaded);
      }

      this.sponsor = res.data;
      await this.log(ScanTypes.ENTER_STAND_LOG);

      this.heartBeat = {
        entityId: this.sponsorId,
        scanEntityType: ScanEntityTypes.SPONSOR,
        scanType: ScanTypes.HEARTBEAT,
        userId: this.currentUserId,
      };

      this.heartBeatInteral = window.setInterval(
        async () => await this.handleHeartBeat(),
        300000,
      );

      // await this.loadPlatformUsersForGroup({
      //   groupId: this.sponsorId,
      //   groupType: SignalrGroupTypes.SPONSOR,
      // });

      this.pageLoaded = true;

      this.setTraceLogState({
        entityId1: this.sponsorId,
        title: this.sponsor ? this.sponsor.title : 'Stand',
        page: 'Stand',
      });
    } catch (e: any) {
      // TODO Insights
    }
  }

  private async log(scanType: string) {
    if (this.sponsor) {
      await this.scanLog({
        entityId: this.sponsor.id,
        scanEntityType: ScanEntityTypes.SPONSOR,
        scanType,
        userId: this.currentUserId,
      });
    }
  }

  private async openDocumentNewTab(url: string, id: string, title: string) {
    if (id) {
      const log: DownloadLog = {
        entityId: id,
        itemContent: url,
        itemTitle: title,
        sponsorId: this.sponsorId,
        userId: this.currentUserId,
        logType: 'DOWNLOAD_ITEM',
      };
      await this.downloadLog(log);
    }

    if (url.startsWith('https:') || url.startsWith('http:')) {
      if (this.$vuetify.breakpoint.mobile) {
        console.log('here');
      } else {
        const win = window.open(url, '_blank');
        if (win) {
          win.focus();
        } else {
          this.$router.push({
            path: url,
          });
        }
      }
    }
  }

  private async videoViewLog(item: SponsorMapItem) {
    if (item) {
      const log: DownloadLog = {
        entityId: item.id,
        itemContent: item.itemContent1,
        itemTitle: item.title,
        sponsorId: this.sponsorId,
        userId: this.currentUserId,
        logType: 'VIDEO_VIEW_ITEM',
      };
      await this.downloadLog(log);
    }
  }

  private async openNewTab(url: string) {
    if (typeof url !== 'string') {
      return;
    }

    if (
      url.startsWith('https:') ||
      url.startsWith('http:') ||
      url.startsWith('mailto')
    ) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else {
      this.$router.push({
        path: url,
      });
    }
  }

  private async pageUnloaded() {
    await this.handleLeave();
  }

  private parseDates(arr: UniqueUserItem[]): UniqueUserItem[] {
    const newArr = arr.map((obj: UniqueUserItem) => {
      const newObj: UniqueUserItem = {
        ...obj,
        firstConnection:
          typeof obj.firstConnection === 'string'
            ? moment.utc(obj.firstConnection).toDate()
            : '',
        lastConnection:
          typeof obj.lastConnection === 'string'
            ? moment.utc(obj.lastConnection).toDate()
            : '',
        start:
          typeof obj.start === 'string'
            ? obj.start === '0001-01-01T00:00:00'
              ? ''
              : moment.tz(obj.start, 'Europe/Paris').toDate()
            : '',
      };

      return newObj;
    });
    return newArr;
  }

  private async visibilityChanged() {
    if (document.visibilityState === 'visible') {
      await this.log(ScanTypes.ENTER_STAND_LOG);
      if (this.heartBeatInteral === 0) {
        if (!this.heartBeat && this.sponsorId) {
          this.heartBeat = {
            entityId: this.sponsorId,
            scanEntityType: ScanEntityTypes.SPONSOR,
            scanType: ScanTypes.HEARTBEAT,
            userId: this.currentUserId,
          };
          this.heartBeatInteral = window.setInterval(
            async () => await this.handleHeartBeat(),
            300000,
          );
        }
      }
    } else {
      await this.handleLeave();
    }
  }
}
