
/* eslint-disable */
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NotFound extends Vue {
  private canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D | null;
  private height: number;
  private width: number;
  private bcontext: CanvasRenderingContext2D | null;

  constructor() {
    super();
    this.canvas = document.getElementById('canvas') as HTMLCanvasElement;
    if (this.canvas) {
      this.context = this.canvas.getContext('2d');
      this.height = this.canvas.height = 256;
      this.width = this.canvas.width = this.height;
    } else {
      this.context = null;
      this.height = 256;
      this.width = 256;
    }
    // eslint-disable-next-line
    this.bcontext =
      'getCSSCanvasContext' in document
        ? (document as any).getCSSCanvasContext(
            '2d',
            'noise',
            this.width,
            this.height,
          )
        : this.context;
  }

  private mounted() {
    this.noise();
  }

  private noise() {
    if (!(this.context && this.bcontext)) return;
    requestAnimationFrame(this.noise);
    let idata = this.context.getImageData(0, 0, this.width, this.height);
    for (var i = 0; i < idata.data.length; i += 4) {
      idata.data[i] =
        idata.data[i + 1] =
        idata.data[i + 2] =
          Math.floor(Math.random() * 255);
      idata.data[i + 3] = 255;
    }
    this.bcontext.putImageData(idata, 0, 0);
  }
}
