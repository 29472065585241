import { GetterTree } from 'vuex';
import { RootState } from '../types';
import {
  BookmarkEntityType,
  BookmarkGetters,
  BookmarkItem,
  BookmarkState,
  BookmarkType,
} from './types';

export const getters: GetterTree<BookmarkState, RootState> = {
  [BookmarkGetters.GET_BOOKMARKS](state: BookmarkState): BookmarkItem[] {
    return state.bookmarks;
  },

  [BookmarkGetters.GET_BOOKMARK]: (state: BookmarkState) => (
    entityId: string,
    entityUri?: string,
  ): BookmarkItem | undefined => {
    if (entityUri) {
      return state.bookmarks.find(
        (b) =>
          b.entityId === entityId &&
          b.type === BookmarkType.DOCUMENT &&
          b.entityUri === entityUri,
      );
    } else {
      return state.bookmarks.find(
        (b) => b.entityId === entityId && b.type !== BookmarkType.DOCUMENT,
      );
    }
  },

  [BookmarkGetters.GET_SESSION_BOOKMARKS](
    state: BookmarkState,
  ): BookmarkItem[] {
    return state.bookmarks.filter(
      (b) => (b.entityType = BookmarkEntityType.SESSION),
    );
  },

  [BookmarkGetters.IS_A_BOOKMARK]: (state: BookmarkState) => (
    entityId: string,
    entityUri: string,
  ): boolean => {
    if (entityUri) {
      return state.bookmarks.some(
        (b) => b.entityId === entityId && b.entityUri === entityUri,
      );
    } else {
      return state.bookmarks.some(
        (b) => b.entityId === entityId && !b.entityType.includes('DOCUMENT'),
      );
    }
  },
};
