import { GetterTree } from 'vuex';
import { TokenStorage } from '../../services/auth/tokenStorage';
import { RootState } from '../types';
import { AuthGetters, AuthState } from './types';

export const getters: GetterTree<AuthState, RootState> = {
  [AuthGetters.IS_AUTHENTICATED](state): boolean {
    return state.isAuthenticated;
  },

  [AuthGetters.HAS_TOKEN](): boolean {
    return TokenStorage.isAuthenticated();
  },

  [AuthGetters.LOGIN_IN_ERROR](state): string {
    return state.loginError;
  },
};
