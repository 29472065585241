import { AppInsightsLogger } from '@/services/appInsightsLogger';
import Vue from 'vue';
import { ActionTree } from 'vuex';
import { RootState } from '../types';
import {
  ConversationActions,
  ConversationGetters,
  ConversationItem,
  ConversationMessageItem,
  ConversationMutations,
  ConversationState,
} from './types';

export const actions: ActionTree<ConversationState, RootState> = {
  [ConversationActions.SET_CONVERSATION_BAR_VISIBLE](
    { commit },
    visible: boolean,
  ): void {
    commit(ConversationMutations.SET_CONVERSATION_BAR_VISIBLE, visible);
  },

  [ConversationActions.CLOSE_CONVERSATION](
    { commit },
    conversation: ConversationItem,
  ): void {
    commit(ConversationMutations.CLOSE_CONVERSATION, conversation);
  },

  [ConversationActions.OPEN_CONVERSATION](
    { commit },
    conversation: ConversationItem,
  ): void {
    commit(ConversationMutations.OPEN_CONVERSATION, conversation);
  },

  [ConversationActions.ADD_NEW_MESSAGE_TO_OPEN_CONVERSATION](
    { commit },
    message: ConversationMessageItem,
  ): void {
    commit(ConversationMutations.ADD_NEW_MESSAGE_TO_OPEN_CONVERSATION, message);
  },

  [ConversationActions.ADD_NEW_MESSAGE_TO_NON_OPEN_CONVERSATION](
    { commit },
    message: ConversationMessageItem,
  ): void {
    commit(
      ConversationMutations.ADD_NEW_MESSAGE_TO_NON_OPEN_CONVERSATION,
      message,
    );
  },

  async [ConversationActions.RECIEVE_NEW_CONVERSATION_ITEM](
    { commit, getters },
    message: ConversationMessageItem,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    if (
      getters[ConversationGetters.CONVERSATION_IS_OPEN](message.conversationId)
    ) {
      commit(
        ConversationMutations.ADD_NEW_MESSAGE_TO_OPEN_CONVERSATION,
        message,
      );
      try {
        await Vue.$http.put(
          `/api/platform/${platformId}/conversations/${message.conversationId}/read`,
        );
      } catch (e: any) {
        AppInsightsLogger.logError(
          'Conversation Action - RECIEVE_NEW_CONVERSATION_ITEM failed',
          undefined,
          true,
        );
        AppInsightsLogger.logException(e, false);
      }
    } else if (
      getters[ConversationGetters.CONVERSATION_EXISTS](message.conversationId)
    ) {
      commit(
        ConversationMutations.ADD_NEW_MESSAGE_TO_NON_OPEN_CONVERSATION,
        message,
      );
    } else {
      try {
        const res = await Vue.$http.get<ConversationItem[]>(
          `/api/platform/${platformId}/conversations`,
        );
        if (res && res.data) {
          commit(ConversationMutations.SET_CONVERSATIONS, res.data);
        }
      } catch (e: any) {
        AppInsightsLogger.logError(
          'Conversation Action - CONVERSATION_EXISTS failed',
          undefined,
          true,
        );
        AppInsightsLogger.logException(e, false);
      }
    }
  },

  [ConversationActions.CLEAR_CONVERSATION]({ commit }): void {
    commit(ConversationMutations.CLEAR_CONVERSATION);
  },

  [ConversationActions.MINIMISE_CONVERSATION]({ commit }, id: string): void {
    commit(ConversationMutations.MINIMISE_CONVERSATION, id);
  },

  [ConversationActions.MAXIMISE_CONVERSATION]({ commit }, id: string): void {
    commit(ConversationMutations.MAXIMISE_CONVERSATION, id);
  },

  [ConversationActions.SET_CONVERSATIONS](
    { commit },
    conversations: ConversationItem[],
  ): void {
    commit(ConversationMutations.SET_CONVERSATIONS, conversations);
  },

  [ConversationActions.VERIFY_CONVERSATION_VISIBLE](
    { commit },
    id: string,
  ): void {
    commit(ConversationMutations.VERIFY_CONVERSATION_VISIBLE, id);
  },
};
