
import { PlatformGetters } from '@/store/platform/types';
import {
  PlatformUserActions,
  PlatformUserGetters,
  PlatformUserItem,
} from '@/store/platformUser/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';

@Component
export default class ParticipantFilters extends Vue {
  @Prop()
  public onlySpeakers!: boolean;

  /* PRIVATE PROPERTIES */
  private debounce: number | undefined;
  private mounting = false;
  private searchText: string | null = '';

  /* VUEX GETTERS */
  @Getter(PlatformUserGetters.PLATFORM_USERS_SEARCH, {
    namespace: platformUserNamespace,
  })
  private search!: string;

  @Getter(PlatformUserGetters.PLATFORM_USERS_LOADING, {
    namespace: platformUserNamespace,
  })
  private loading!: boolean;

  @Getter(PlatformUserGetters.TOTAL_PLATFORM_USER_COUNT, {
    namespace: platformUserNamespace,
  })
  private totalPlatformUserCount!: number;

  @Getter(PlatformGetters.SHOW_ALL_PARTICIPANT_COUNT, {
    namespace: platformNamespace,
  })
  private showAllParticipantCount!: boolean;

  @Getter(PlatformGetters.SHOW_SPEAKER_COUNT, {
    namespace: platformNamespace,
  })
  private showSpeakerCount!: boolean;

  /* VUEX ACTIONS */
  @Action(PlatformUserActions.SET_PLATFORM_USER_DATA, {
    namespace: platformUserNamespace,
  })
  private setUserPlatformData!: (
    data: { items: PlatformUserItem[]; totaItemsCount: number } | undefined,
  ) => void;

  @Action(PlatformUserActions.SET_PLATFORM_USERS_LOADING, {
    namespace: platformUserNamespace,
  })
  private setPlatformUsersLoading!: (loading: boolean) => void;

  @Action(PlatformUserActions.SET_PLATFORM_USERS_SEARCH, {
    namespace: platformUserNamespace,
  })
  private setSearch!: (keywords: string) => Promise<void>;

  /* WATCHES */
  @Watch('searchText', { immediate: false })
  private async searchTextChanged() {
    console.log('searchTextChanged');
    if (this.mounting) {
      console.log('searchTextChanged: mounting');
      return;
    }
    console.log('searchTextChanged: not mounting');
    // Do search if more than 3 or more characters
    if (this.searchText && this.searchText.length >= 3) {
      this.setSearch(this.searchText);
    } else if (!this.searchText) {
      this.setSearch('');
    }
  }

  /* LOCAL GETTERS/SETTERS */
  get keyword(): string {
    return this.search;
  }
  set keyword(value: string) {
    this.setSearch(value);
  }
  /* PRIVATE METHODS*/
  private beforeMount() {
    console.log('beforeMount');
    this.mounting = true;
    let params = new URLSearchParams(window.location.search);
    let search = params.get('search');
    console.log('search: ', search);
    if (search) {
      this.searchText = search;
    }
    console.log('beforeMount end');
  }

  private mounted() {
    console.log('mounted');
    this.$nextTick(() => {
      this.mounting = false;
    });
  }

  private clearSearch() {
    this.searchText = '';
  }

  private debounceSearch(event: string) {
    if (event === null || event === undefined) {
      return;
    }

    if (this.debounce) {
      clearTimeout(this.debounce);
    }
    this.debounce = window.setTimeout(() => {
      this.searchText = event;
    }, 600);
  }

  // private loadData() {}

  private userCountString() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return '';
    }
    if (this.onlySpeakers && this.showSpeakerCount) {
      if (this.totalPlatformUserCount === 1) {
        return `${this.totalPlatformUserCount} ${this.$t(
          'entities.speaker',
        )} ${this.$t('common.found')}`;
      } else {
        return `${this.totalPlatformUserCount} ${this.$t(
          'entities.speakers',
        )} ${this.$t('common.founds')}`;
      }
    } else if (this.showAllParticipantCount) {
      if (this.totalPlatformUserCount === 1) {
        return `${this.totalPlatformUserCount} ${this.$t(
          'entities.participant',
        )} ${this.$t('common.found')}`;
      } else {
        return `${this.totalPlatformUserCount} ${this.$t(
          'entities.participants',
        )} ${this.$t('common.founds')}`;
      }
    } else {
      return '';
    }
  }
}
