import { MutationTree } from 'vuex';
import {
  PlatformUserItem,
  PlatformUserMutations,
  PlatformUserState,
} from './types';

export const mutations: MutationTree<PlatformUserState> = {
  [PlatformUserMutations.SET_CURRENT_PAGE](state, page: number): void {
    state.currentPage = page;
  },

  [PlatformUserMutations.SET_CURRENT_PLATFORM_USER](
    state,
    user: PlatformUserItem,
  ): void {
    state.currentPlatformUser = user;
  },

  [PlatformUserMutations.SET_CURRENT_PLATFORM_USER_PHOTO_URI](
    state,
    uri: string,
  ): void {
    if (state.currentPlatformUser) {
      state.currentPlatformUser.photoUri = uri;
    }
  },

  [PlatformUserMutations.SET_PLATFORM_USER_CARD_POSITION](
    state,
    { x, y }: { x: number; y: number },
  ): void {
    state.platformUserCardXPos = x;
    state.platformUserCardYPos = y;
  },

  [PlatformUserMutations.SET_PLATFORM_USER_CARD_VISIBILITY](
    state,
    visible: boolean,
  ): void {
    state.platformUserCardVisible = visible;
  },

  [PlatformUserMutations.SET_PLATFORM_USER_DATA](
    state,
    data: { items: PlatformUserItem[]; totalItemCount: number },
  ): void {
    if (!data) {
      state.platformUsers = [];
      state.totalPlatformUsersCount = 0;
    } else {
      state.platformUsers.push(...data.items);
      state.totalPlatformUsersCount = data.totalItemCount;
    }
  },

  [PlatformUserMutations.SET_PLATFORM_USER_LIST_POSITION](
    state,
    { x, y }: { x: number; y: number },
  ): void {
    state.platformUserListXPos = x;
    state.platformUserListYPos = y;
  },

  [PlatformUserMutations.SET_PLATFORM_USER_LIST_VISIBILITY](
    state,
    visible: boolean,
  ): void {
    state.platformUserListVisible = visible;
  },

  [PlatformUserMutations.SET_PLATFORM_USER_LOADING](
    state,
    loading: boolean,
  ): void {
    state.platformUserLoading = loading;
  },

  [PlatformUserMutations.SET_PLATFORM_USERS_LOADING](
    state,
    loading: boolean,
  ): void {
    state.platformUsersLoading = loading;
  },

  [PlatformUserMutations.SET_PLATFORM_USERS_SEARCH](
    state,
    search: string,
  ): void {
    state.platformUsersSearch = search;
  },

  [PlatformUserMutations.SET_VISIBLE_PLATFORM_USER](
    state,
    user: PlatformUserItem,
  ): void {
    state.visiblePlatformUser = user;
  },

  setVisiblePlatformUserList(state, users: PlatformUserItem[]): void {
    state.visiblePlatformUserList = users;
  },
};
