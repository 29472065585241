'use strict';

import Vue from 'vue';
import axios from 'axios';
import router from '../router';
import { TokenStorage } from '../services/auth/tokenStorage';
import { AuthService } from '../services/auth/authService';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

if (TokenStorage.getToken()) {
  _axios.defaults.headers.common.Authorization =
    TokenStorage.getAuthentication().Authorization;
}

_axios.interceptors.request.use(
  (config) => {
    if (TokenStorage.getToken()) {
      config.headers.common.Authorization =
        TokenStorage.getAuthentication().Authorization;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const platformId = sessionStorage.getItem('platformId') ?? '';

    if (error.response.status === 404) {
      if (platformId) {
        router.push({ name: 'welcome', params: { eventCode } }).catch((e) => {
          console.log(e);
        });
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }

    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    // Logout user if token refresh didn't work or user is disabled
    const tokenUrl = `${process.env.VUE_APP_OPEN_ID_AUTHORITY}/connect/token`;
    if (
      error.config.url == tokenUrl ||
      error.response.message == 'Account is disabled.'
    ) {
      AuthService.logOut(false);
      router.push({ name: 'login', params: { eventCode } }).catch((e) => {
        console.log(e);
      });
      return Promise.reject(error);
    }

    if (TokenStorage.isAuthenticated()) {
      // Try request again with new token
      try {
        const token = await TokenStorage.getNewToken();
        if (token) {
          const config = error.config;
          config.headers['Authorization'] = `Bearer ${token}`;
          var res = await axios.request(config);
          return res;
        }
      } catch (e) {
        AuthService.logOut(false);
        router.push({ name: 'login', params: { eventCode } }).catch((e) => {
          console.log(e);
        });
        return Promise.reject(error);
      }
    } else {
      AuthService.logOut(false);
      router.push({ name: 'login', params: { eventCode } }).catch((e) => {
        console.log(e);
      });
      return Promise.reject(error);
    }
  },
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  Vue.$http = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $http: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
