export interface AuthState {
  isAuthenticated: boolean;
  loginError: string;
}

export enum AuthActions {
  SET_REMEMBER_ME = 'SET_REMEMBER_ME',
  SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED',
  SET_LOGIN_IN_ERROR = 'SET LOGIN_IN_ERROR',
}

export enum AuthGetters {
  IS_AUTHENTICATED = 'IS_AUTHENTICATED',
  HAS_TOKEN = 'HAS_TOKEN',
  LOGIN_IN_ERROR = 'LOGIN_IN_ERROR',
}

export enum AuthMutations {
  SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED',
  SET_LOGIN_IN_ERROR = 'SET LOGIN_IN_ERROR',
}
