
import { PlatformGetters } from '@/store/platform/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
const platformNamespace = 'platform';

@Component
export default class Background extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public colour!: string;

  @Prop()
  public overlay!: boolean;

  @Prop()
  public override!: string;
  /* PRIVATE PROPERTIES */

  /* VUEX GETTERS */
  @Getter(PlatformGetters.PORTAL_BACKGROUND_URI, {
    namespace: platformNamespace,
  })
  private portalBackgroundUri!: string;
  /* VUEX ACTIONS */
  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get cssVars(): unknown {
    return {
      '--bg-image': `
        url('${this.override ? this.override : this.portalBackgroundUri}')
      `,
      '--bg-colour': `${this.colour ? this.colour : '#141414'}`,
    };
  }
  /* PRIVATE METHODS*/
}
