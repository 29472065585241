import { ConversationActions } from '@/store/conversation/types';
import { PlatformUserActions } from '@/store/platformUser/types';
import axios, { AxiosRequestConfig } from 'axios';
import jwtDecode from 'jwt-decode';
import store from '../../store/index';
import { IJwtBody } from './jwtBody';
import { TokenStorage } from './tokenStorage';

export class AuthService {
  public static async logIn(
    username: string,
    password: string,
    rememberMe: boolean,
  ): Promise<void> {
    const requestOptions: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        common: {
          Authorization: undefined,
        },
      },
      method: 'POST',
    };
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const grantType = 'password';
    const scope =
      'openid profile roles email offline_access slide-meet-api platform';
    const params = new URLSearchParams();
    params.append('grant_type', grantType);
    params.append('username', username);
    params.append('password', password);
    params.append('platform_id', platformId);
    params.append('scope', scope);

    if (!process.env.VUE_APP_OPEN_ID_AUTHORITY) {
      console.error('VUE_APP_OPEN_ID_AUTHORITY not set');
      return;
    }
    const tokenUrl = `${process.env.VUE_APP_OPEN_ID_AUTHORITY}/connect/token`;

    try {
      const jwt = await axios.post<IJwtBody>(tokenUrl, params, requestOptions);
      TokenStorage.storeRememberMe(rememberMe);
      TokenStorage.storeRefreshToken(jwt.data.refresh_token);
      TokenStorage.storeToken(jwt.data.access_token);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const id: any = jwtDecode(jwt.data.id_token);
      localStorage.setItem('userId', id.sub);
      await store.dispatch(
        `platformUser/${PlatformUserActions.LOAD_CURRENT_PLATFORM_USER}`,
      );
    } catch (e: any) {
      console.error(e);
      throw new Error('Login failed!');
    }
  }

  public static async logOut(revoke = true): Promise<void> {
    if (revoke) {
      const requestOptions: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          accept: 'application/x-www-form-urlencoded',
        },
        method: 'POST',
      };
      const userId = localStorage.getItem('userId');
      const url = `${process.env.VUE_APP_OPEN_ID_AUTHORITY}/revoke/token/${userId}`;
      await axios.post(url, { config: requestOptions }).catch((e) => {
        console.error(e);
      });
    }
    TokenStorage.clear();
    localStorage.removeItem('userId');
    store.dispatch(
      `platformUser/${PlatformUserActions.CLEAR_CURRENT_PLATFORM_USER}`,
    );
    store.dispatch(`conversation/${ConversationActions.CLEAR_CONVERSATION}`);
    store.dispatch('signalr/logOff');
  }
}
