
import RoomUserList from '@/components/RoomUserList.vue';
import {
  PlatformUserActions,
  PlatformUserGetters,
} from '@/store/platformUser/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  LoungeActions,
  LoungeGetters,
  LoungeRoom,
} from '../store/lounge/types';
const platformUserNamespace = 'platformUser';
const loungeNamespace = 'lounge';

@Component({
  components: {
    RoomUserList,
  },
})
export default class LoungeRoomDetails extends Vue {
  @Prop()
  public isMod!: boolean;

  /* VUEX GETTERS */
  // Lounge Getters
  @Getter(LoungeGetters.REACHED_FAVOURITE_LIMIT, { namespace: loungeNamespace })
  private reachedFavouriteLimit!: boolean;

  @Getter(LoungeGetters.IS_FAVOURITE, { namespace: loungeNamespace })
  private isFavourite!: (groupId: string) => boolean;

  @Getter(LoungeGetters.CURRENT_LOUNGE_ROOM, { namespace: loungeNamespace })
  private currentLoungeRoom!: LoungeRoom;

  // Platform User Getters
  @Getter(PlatformUserGetters.PLATFORM_USER_LIST_VISIBLE, {
    namespace: platformUserNamespace,
  })
  private platformUserListVisible!: boolean;

  @Getter(PlatformUserGetters.PLATFORM_USER_CARD_VISIBLE, {
    namespace: platformUserNamespace,
  })
  private platformUserCardVisible!: boolean;

  /* VUEX ACTIONS */
  // Lounge Actions
  @Action(LoungeActions.INVERT_FAVOURITE, { namespace: loungeNamespace })
  private invertFavourite!: (room: LoungeRoom) => void;

  // Platform User Actions
  @Action(PlatformUserActions.SET_PLATFORM_USER_CARD_VISIBILITY, {
    namespace: platformUserNamespace,
  })
  private setPlatformUserCardVisibility!: (visible: boolean) => void;

  @Action(PlatformUserActions.LOAD_PLATFORM_USERS_FOR_GROUP, {
    namespace: platformUserNamespace,
  })
  private loadPlatformUsersForGroup!: ({
    groupId,
    groupType,
  }: {
    groupId: string;
    groupType: string;
  }) => Promise<void>;

  @Action(PlatformUserActions.SET_PLATFORM_USER_LIST_POSITION, {
    namespace: platformUserNamespace,
  })
  private setPlatformUserListPosition!: ({
    x,
    y,
  }: {
    x: number;
    y: number;
  }) => void;

  /* PRIVATE METHODS*/
  private invertFavouriteStateExecute(room: LoungeRoom) {
    if (!room.favourite && this.reachedFavouriteLimit) {
      window.alert('Favourite limit reached (5)');
    } else {
      this.invertFavourite(room);
    }
  }

  private async showRoomUsers(group: LoungeRoom, e: MouseEvent) {
    if (this.platformUserCardVisible) {
      this.setPlatformUserCardVisibility(false);
    }
    if (this.platformUserListVisible) {
      return;
    }

    const x = e.clientX + 10;
    const y = e.clientY + 10;
    this.setPlatformUserListPosition({ x, y });
    await this.loadPlatformUsersForGroup({
      groupId: group.groupId,
      groupType: group.groupType,
    });
  }
}
