
import SessionCard from '@/components/SessionCard.vue';
import { SessionGetters, SessionItem } from '@/store/session/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

const sessionNamespace = 'session';

@Component({
  components: {
    SessionCard,
  },
})
export default class SessionRelatedSide extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public isActive!: boolean;

  @Prop()
  public pageLoaded!: boolean;

  /* PRIVATE PROPERTIES */
  private dialog = false;

  /* VUEX GETTERS */
  // Session Getters
  @Getter(SessionGetters.RELATED_SESSIONS, { namespace: sessionNamespace })
  private related!: SessionItem[];

  /* VUEX ACTIONS */
  /* WATCHES */
  /* LOCAL GETTERS/SETTERS */

  /* PRIVATE METHODS*/
}
