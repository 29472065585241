
import Background from '@/components/Background.vue';
import BannerAd from '@/components/BannerAd.vue';
import SessionAd from '@/components/SessionAd.vue';
import SessionCard from '@/components/SessionCard.vue';
import SessionFilters from '@/components/SessionFilters.vue';
import { session } from '@/store/session';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AdItem } from '../models/AdItem';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { PlatformGetters } from '../store/platform/types';
import {
  SessionActions,
  SessionGetters,
  SessionItem,
} from '../store/session/types';
const sessionNamespace = 'session';
const platformNamespace = 'platform';

Component.registerHooks(['beforeRouteUpdate']);

@Component({
  components: {
    Background,
    BannerAd,
    SessionAd,
    SessionCard,
    SessionFilters,
  },
})
export default class Sessions extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public live!: boolean;

  @Prop()
  public poster!: boolean;

  /* PRIVATE PROPERTIES */
  private PAGINATION_LENGTH = 25;
  private ads: AdItem[] = [];
  private dialog = false;
  private dialog2 = false;
  private selectedPoster: SessionItem | null = null;

  /* VUEX GETTERS */
  // Platform Getters
  @Getter(PlatformGetters.PORTAL_BACKGROUND_URI, {
    namespace: platformNamespace,
  })
  private portalBackgroundUri!: string;

  // Session Getters
  @Getter(SessionGetters.CURRENT_PAGE, { namespace: sessionNamespace })
  private currentPage!: number;

  @Getter(SessionGetters.LOADING_SESSIONS, { namespace: sessionNamespace })
  private loading!: boolean;

  @Getter(SessionGetters.SESSIONS, { namespace: sessionNamespace })
  private sessions!: SessionItem[];

  @Getter(SessionGetters.TOTAL_SESSION_COUNT, { namespace: sessionNamespace })
  private totalSessionCount!: number;

  @Getter(SessionGetters.LIST_VIEW, { namespace: sessionNamespace })
  private listView!: boolean;

  /* VUEX ACTIONS */

  // Session Actions
  @Action(SessionActions.APPLY_INITIAL_QUERY_FILTERS, {
    namespace: sessionNamespace,
  })
  private applyInitialQueryFilters!: () => void;

  @Action(SessionActions.SET_SESSION_DATA, { namespace: sessionNamespace })
  private setSessionData!: (
    data: { items: SessionItem[]; totaItemsCount: number } | undefined,
  ) => void;

  @Action(SessionActions.SET_CURRENT_PAGE, { namespace: sessionNamespace })
  private setCurrentPage!: (page: number) => Promise<void>;

  @Action(SessionActions.SET_LOADING_SESSIONS, { namespace: sessionNamespace })
  private setLoadingSessions!: (val: boolean) => void;

  /* WATCHES */
  @Watch('$route.query', { immediate: false, deep: true })
  private async onQueryChange() {
    this.setLoadingSessions(true);
    this.setCurrentPage(1);
    this.clearSessionItems();
    this.applyInitialQueryFilters();
    await this.loadData();
    this.setLoadingSessions(false);
  }

  /* LOCAL GETTERS/SETTERS */
  get cssVars(): unknown {
    return {
      '--bg-image': `url('${this.portalBackgroundUri}')`,
    };
  }
  get paginationLength(): number {
    return Math.ceil(this.totalSessionCount / 12);
  }

  get scollerHeight(): number {
    let height = window.innerHeight - 245;
    if (
      this.ads &&
      this.ads.length > 0 &&
      this.ads.filter((a) => a.type.includes('Banner')).length > 0
    ) {
      height = height - 110;
    }
    return height;
  }

  get page(): number {
    return this.currentPage;
  }
  set page(value: number) {
    this.setCurrentPage(value);
  }

  /* PRIVATE METHODS*/
  private async beforeMount() {
    this.setLoadingSessions(true);
    this.clearSessionItems();
    this.setCurrentPage(1);
    await this.loadData();
    this.setLoadingSessions(false);
  }

  private clearSessionItems() {
    this.setSessionData(undefined);
  }

  private async intersected() {
    if (
      !this.loading &&
      this.currentPage * this.PAGINATION_LENGTH < this.totalSessionCount
    ) {
      this.setCurrentPage(this.currentPage + 1);
      await this.loadData();
    }
  }

  private async loadData(): Promise<void> {
    this.setLoadingSessions(true);
    const platformId = sessionStorage.getItem('platformId') ?? '';

    let queryString = '?page=' + this.currentPage;
    if (this.live) {
      queryString = queryString + '&live=true';
    } else if (this.poster) {
      queryString = queryString + `&poster=true`;
    }

    if (window.location.search) {
      queryString = queryString + '&' + window.location.search.substring(1); // remove ?
    }

    let adsQueryString = '';
    if (window.location.search) {
      adsQueryString = `${window.location.search}&datype=vod`;
    } else {
      adsQueryString = '?datype=vod';
    }

    try {
      if (!this.live) {
        const res = await Vue.$http.get<AdItem[]>(
          `/api/platform/${platformId}/sponsors/sda${adsQueryString}`,
        );
        this.ads = res.data;
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Sessions - loadSessionData failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }

    try {
      const sessionData = await Vue.$http.get(
        `/api/platform/${platformId}/sessions${queryString}`,
      );
      this.setSessionData(sessionData.data);
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Sessions - loadSessionData failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.setLoadingSessions(false);
  }

  private scrollTop() {
    const e = document.getElementById('top');
    if (e) {
      e.scrollIntoView();
    }
  }

  private viewPosterDescription(poster: SessionItem): void {
    if (poster.description) {
      this.selectedPoster = poster;
      this.dialog2 = true;
    } else {
      this.dialog2 = false;
      this.selectedPoster = null;
    }
  }

  private hidePosterDescription(): void {
    this.dialog2 = false;
    this.selectedPoster = null;
  }
}
