
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AdClickLog } from '../models/AdClickLog';
import { AdItem } from '../models/AdItem';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { ModuleItem } from '../models/ModuleItem';
import { PlatformActions, PlatformGetters } from '../store/platform/types';
import { AuthGetters } from '@/store/auth/types';
import { PlatformUserGetters } from '@/store/platformUser/types';
import { NavigationActions, NavigationGetters } from '@/store/navigation/types';

const authNamespace = 'auth';
const navigationNamespace = 'navigation';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';

@Component
export default class NavDrawer extends Vue {
  /* PUBLIC PROPERTIES */

  /* PRIVATE PROPERTIES */
  private eventCode = sessionStorage.getItem('eventCode');
  private ads: AdItem[] = [];
  private items: ModuleItem[] = [];
  private loaded = false;
  private mini = true;
  private model = 1;
  private pinned = false;

  /* VUEX GETTERS */
  // Auth Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Navigation Getters
  @Getter(NavigationGetters.NAVIGATION_DRAWER, {
    namespace: navigationNamespace,
  })
  private drawer!: boolean;

  @Action(NavigationActions.NAVIGATION_INVERT_DRAWER, {
    namespace: navigationNamespace,
  })
  private invertDrawer!: () => void;

  // Platform Getters
  @Getter(PlatformGetters.NAV_BAR_MINI, {
    namespace: platformNamespace,
  })
  private navBarMini!: boolean;

  @Getter(PlatformGetters.NAV_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarTextColour!: string;

  @Getter(PlatformGetters.NAV_BAR_BACKGROUND_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarBackgroundColour!: string;

  // Platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  /* VUEX ACTIONS */
  // Navigation Actions
  @Action(NavigationActions.SET_MOBILE_PAGE_TITLE, {
    namespace: navigationNamespace,
  })
  private setMobilePageTitle!: (title: string) => void;

  // Platform Actions
  @Action(NavigationActions.NAVIGATION_SET_DRAWER, {
    namespace: navigationNamespace,
  })
  private setDrawer!: (value: boolean) => void;

  @Action(PlatformActions.SPONSOR_AD_LOG, { namespace: platformNamespace })
  private adClickLog!: (adClickLog: AdClickLog) => void;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get drawerState(): boolean {
    return this.drawer;
  }
  set drawerState(state: boolean) {
    if (state !== this.drawer) {
      this.invertDrawer();
    }
  }

  get locale(): string {
    return this.$i18n.locale;
  }

  get showAds(): boolean {
    if (this.pinned) {
      return true;
    } else {
      return !this.mini;
    }
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}

  private async created() {
    this.loaded = false;
    const platformId = sessionStorage.getItem('platformId') ?? '';

    if (platformId) {
      try {
        const mods = await Vue.$http.get<ModuleItem[]>(
          `/api/platform/${platformId}/modules`,
        );

        // if (this.$vuetify.breakpoint.smAndDown) {
        //   mods.data.filter((d: ModuleItem) => {
        //     if (
        //       d.title.includes('home') ||
        //       d.title.includes('vod') ||
        //       d.title.includes('livenow') ||
        //       d.title.includes('posters') ||
        //       d.title.includes('participantItems') ||
        //       d.title.includes('exhibition')
        //     ) {
        //       this.items.push(d);
        //     }
        //   });
        // } else {
        //   }
        this.items = mods.data;

        const ads = await Vue.$http.get(
          `/api/platform/${platformId}/sponsors/sda?datype=sidebar`,
        );
        this.ads = ads.data;
      } catch (e: any) {
        AppInsightsLogger.logError('Player - created failed', undefined, true);
        AppInsightsLogger.logException(e, false);
      }
      if (!this.$vuetify.breakpoint.mobile) {
        this.setDrawer(true);
      }
      this.loaded = true;
    }
  }

  private beforeMount() {
    this.mini = this.navBarMini;
    this.pinned = !this.navBarMini;
  }
  // private mounted() {}
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}

  /* PRIVATE METHODS*/
  private async adClick(ad: AdItem) {
    if (ad.link) {
      const log: AdClickLog = {
        entityId: ad.id,
        link: ad.link,
        title: ad.title,
        type: ad.type,
        userId: this.currentUserId,
      };
      await this.adClickLog(log);
      await this.openNewTab(ad.link);
    }
  }

  private async handleItem(item: ModuleItem) {
    if (item[`Title_${this.$i18n.locale}`]) {
      this.setMobilePageTitle(item[`Title_${this.$i18n.locale}`]);
    } else {
      this.setMobilePageTitle(this.$t(item.title) as string);
    }
    if (item) {
      const eventCode = sessionStorage.getItem('eventCode') ?? '';
      let url = '';
      if (item.custom) {
        const eventCode = sessionStorage.getItem('eventCode') ?? '';
        url = `/${eventCode}/${item.parent}/custom/${item.id}`;
        await this.$router.push(url);
      } else if (item.link) {
        await this.openNewTab(item.link);
      } else {
        url = `/${eventCode}/${item.parent}`;
        await this.openNewTab(url);
      }
    }
  }

  private invertPinned() {
    this.pinned = !this.pinned;
    setTimeout(() => {
      if (this.mini) {
        this.mini = false;
      }
    }, 1);
  }

  private async openNewTab(url: string) {
    if (url.startsWith('https:') || url.startsWith('http:')) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else {
      if (this.$route.fullPath === url) return;
      if (this.$route.query && !this.$route.fullPath.includes('?')) {
        this.$router.replace({ path: url, query: {} });
      } else {
        await this.$router.push(url);
      }
    }
  }
}
