
import Avatar from '@/components/Avatar.vue';
import LoungeRoomDetails from '@/components/LoungeRoomDetails.vue';
import RoomListDrawer from '@/components/RoomListDrawer.vue';
import { LogActions } from '@/store/log/log';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { SponsorLounge } from '../models/SponsorLounge';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { LoungeActions, LoungeGetters } from '../store/lounge/types';
import { PlatformGetters } from '../store/platform/types';
import {
  PlatformUserActions,
  PlatformUserGetters,
  PlatformUserItem,
} from '../store/platformUser/types';
import {
  SignalrActions,
  SignalrGetters,
  SignalrGroup,
  SignalrMessage,
  SignalrMessageTypes,
} from '../store/signalr/types';

const logNamespace = 'log';
const loungeNamespace = 'lounge';
const signalrNamespace = 'signalr';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';

@Component({
  components: {
    Avatar,
    LoungeRoomDetails,
    RoomListDrawer,
  },
})
export default class Lounge extends Vue {
  @Prop()
  public sponsorId!: string;
  private newChatMessageText = '';
  private isMod = false;

  private loaded = false;
  private customHtml = '';
  private sponsorLounge: SponsorLounge | null = null;

  get dynamicComponent(): { template: string } {
    return {
      template: `${this.customHtml}`,
    };
  }

  /* VUEX GETTERS */
  // Lounge Getters
  @Getter(LoungeGetters.DRAWER, { namespace: loungeNamespace })
  private drawer!: boolean;

  // Platform Getter
  @Getter(PlatformGetters.LOUNGE_IMAGE_URI, { namespace: platformNamespace })
  private loungeImageUri!: string;

  // Platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER, {
    namespace: platformUserNamespace,
  })
  private currentUser!: PlatformUserItem;

  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_NAME, {
    namespace: platformUserNamespace,
  })
  private currentUserFullName!: string;

  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  // Signalr Getters
  @Getter(SignalrGetters.CURRENT_GROUP, { namespace: signalrNamespace })
  private currentGroup!: SignalrGroup;

  @Getter(SignalrGetters.CURRENT_GROUP_MESSAGES, {
    namespace: signalrNamespace,
  })
  private currentGroupMessages!: SignalrMessage[];

  @Getter(`${SignalrGetters.USER_CONNECTED}`, { namespace: signalrNamespace })
  private userConnected!: boolean;

  /* VUEX ACTIONS */
  // Log Actions
  @Action(LogActions.SET_TRACE_LOG_STATE, { namespace: logNamespace })
  private setTraceLogState!: ({
    entityId1,
    entityId2,
    page,
    title,
  }: {
    entityId1?: string;
    entityId2?: string;
    page: string;
    title: string;
  }) => void;

  // Lounge Actions
  @Action(LoungeActions.INVERT_DRAWER, { namespace: loungeNamespace })
  private invertDrawer!: () => void;

  // Platform User Actions
  @Action(PlatformUserActions.IS_LOUNGE_MODERATOR, {
    namespace: platformUserNamespace,
  })
  private isLoungeModerator!: () => Promise<boolean>;

  @Action(PlatformUserActions.IS_SPONSOR_LOUNGE_MODERATOR, {
    namespace: platformUserNamespace,
  })
  private isSponsorLoungeModerator!: (sponsorId: string) => boolean;

  // Signalr Actions
  @Action(SignalrActions.POST_CHAT_MESSAGE, { namespace: signalrNamespace })
  private postChatMessage!: (msg: SignalrMessage) => void;

  @Action(SignalrActions.REMOVE_CHAT_MESSAGE, { namespace: signalrNamespace })
  private removeChatMessage!: (msg: SignalrMessage) => void;

  /* LOCAL GETTERS */

  /* PRIVATE METHODS*/

  private entryImage() {
    if (this.sponsorLounge) {
      return this.sponsorLounge.sponsorLoungeImageUri;
    } else if (this.loungeImageUri) {
      return this.loungeImageUri;
    }
    return 'https://slidetotal.azureedge.net/slidetotal/lounge.jpg';
  }

  private welcome() {
    if (this.sponsorLounge) {
      return this.$t('lounge.sponsorWelcome', this.$i18n.locale, [
        this.sponsorLounge.title,
      ]);
    } else {
      return this.$t('lounge.welcome', this.$i18n.locale);
    }
  }

  private onNewMessage() {
    const e = document.getElementById('bottom');
    if (e) {
      e.scrollIntoView();
    }
  }

  private postChatMessageExecute() {
    if (this.currentGroup) {
      if (this.newChatMessageText.length > 1) {
        const msg: SignalrMessage = this.createBaseMessage(
          SignalrMessageTypes.POST_CHAT,
        );
        msg.message = this.newChatMessageText;

        this.postChatMessage(msg);
        this.newChatMessageText = '';
      }
    } else {
      console.error('Not currently in a room');
    }
  }

  private removeChatMessageExecute(msg: SignalrMessage) {
    if (this.currentGroup) {
      msg.messageType = SignalrMessageTypes.REMOVE_CHAT;
    }
    this.removeChatMessage(msg);
  }

  private async created() {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    if (this.sponsorId) {
      try {
        const res = await Vue.$http.get<SponsorLounge>(
          `/api/platform/${platformId}/sponsors/${this.sponsorId}/lounge`,
        );
        this.sponsorLounge = res.data;
        this.isMod = await this.isSponsorLoungeModerator(this.sponsorId);
        this.loaded = true;
      } catch (e: any) {
        AppInsightsLogger.logError('Lounge - created failed', undefined, true);
        AppInsightsLogger.logException(e, false);
        return false;
      }
    } else {
      try {
        const res = await Vue.$http.get(`/api/platform/${platformId}/lounge`);
        this.customHtml = res.data;

        if (this.customHtml) {
          this.loaded = true;
        } else {
          this.isMod = await this.isLoungeModerator();
          this.loaded = true;
        }
      } catch (e: any) {
        AppInsightsLogger.logError('Lounge - created failed', undefined, true);
        AppInsightsLogger.logException(e, false);
        return false;
      }
    }
  }

  private mounted() {
    this.setTraceLogState({ page: 'Lounge', title: 'Lounge' });
  }

  private createBaseMessage(messageType: SignalrMessageTypes): SignalrMessage {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const eventId: string = sessionStorage.getItem('platformId') ?? '';
    const groupId = this.currentGroup.groupId;
    const group = this.currentGroup.group;
    const groupType = this.currentGroup.groupType;
    let parentId = '';

    if (this.sponsorId) {
      parentId = this.sponsorId;
    } else {
      parentId = eventId;
    }

    const msg = {
      eventCode,
      eventId,
      group,
      groupId,
      groupType,
      isMod: this.isMod,
      messageType,
      parentId,
      userId: this.currentUserId,
      userName: this.currentUserFullName,
      userPhotoUri: this.currentUser.photoUri,
    };

    return msg;
  }
}
